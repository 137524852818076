import React, { useEffect, useState } from 'react';
import '../Scss/WriteDocument.scss';
import { Button } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import Step5 from './components/writeDocument/Step5';
import Step6Service from './components/writeDocument/Step6Service';
import Step1 from './components/writeDocument/Step1';
import Step2 from './components/writeDocument/Step2';
import { useSelector, connect, useDispatch } from 'react-redux';
import { changeUser, refreshComplaint } from '../store/modules/app';

import Step3 from './components/writeDocument/Step3';
import Step4 from './components/writeDocument/Step4';
import Preview from './components/writeDocument/Preview';
import ApplicationPreview from './components/writeDocument/ApplicationPreview';

import CircularProgress from '@mui/material/CircularProgress';

//etc
import { get, post } from '../utils/Api'
import _ from 'lodash'
import moment from 'moment';

import html2canvas from 'html2canvas';
import SignInView from './user/SignInView';
import { useAppSelector } from '../store';
import { useNavigate } from 'react-router';
import { init, initApplication, refreshTelecomList, refreshThirdDebtorInfo } from '../store/modules/app';
import { Bond, initBond, Payback } from '../model/complaint.model';




export default function WriteDocument(props: any) {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isInit = useAppSelector(state => state.app.isInit)
  let user = useAppSelector((state) => state.app.user);
  let type = useAppSelector((state) => state.app.type);
  let complaint = useAppSelector((state) => state.app.complaint);
  let claimCauseList = useAppSelector((state) => state.app.claimCauseList);
  let claimPurpose = useAppSelector((state) => state.app.claimPurpose);
  let hurtTextList = useAppSelector((state) => state.app.hurtTextList);
  let lossPriceTextList = useAppSelector((state) => state.app.lossPriceTextList);

  const plaintiff = useAppSelector((state) => state.app.plaintiff)
  const defendantList = useAppSelector((state => state.app.defendantList))

  const decision = useAppSelector((state) => state.app.application.decision);

  const application = useAppSelector((state) => state.app.application);

  const [stepList, setStepList] = useState([
    { title: '당사자 정보', imgOn: '../../Image/write-document-step1-on.png' },
    { title: '채권 정보', imgOn: '../../Image/write-document-step2-on.png', imgOff: '../../Image/write-document-step2-off.png' },
    { title: '소송 절차 선택', imgOn: '../../Image/write-document-step3-on.png', imgOff: '../../Image/write-document-step3-off.png' },
    { title: '증거 업로드', imgOn: '../../Image/write-document-step4-on.png', imgOff: '../../Image/write-document-step4-off.png' },
    { title: '최종 확인', imgOn: '../../Image/write-document-step5-on.png', imgOff: '../../Image/write-document-step5-off.png' },
    { title: '서비스 선택', imgOn: '../../Image/write-document-step6-on.png', imgOff: '../../Image/write-document-step6-off.png' }
  ])

  const [selectedPosition, setSelectedPosition] = useState(0);
  const [selectedStep, setSelectedStep] = useState('당사자 정보');

  const [evidenceList, setEvidenceList] = useState<any[]>([]);
  const [normalAttachmentList, setNormalAttachmentList] = useState<any[]>([]);
  const [attachmentList, setAttachmentList] = useState<any[]>([]);

  const [isShowLoading, setShowLoading] = useState(false);
  const [showSignInView, setShowSignInView] = useState(false);

  const [selectedService, setSelectedService] = useState('basic');

  const [isNext, setIsNext] = useState(true);

  useEffect(() => {

    const documentType = props.complaintType;

    if(documentType === '채무불이행자명부 등재 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '송달증명원', format: null},
        { file: null, url: null, name: '확정증명원', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '부동산 강제경매 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '판결정본송달증명원', format: null},
        { file: null, url: null, name: '부동산등기사항증명서', format: null},
        { file: null, url: null, name: '건축물대장등본', format: null},
        { file: null, url: null, name: '토지대장등본', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
        { file: null, url: null, name: '등록세 지방교육세영수필확인서', format: null},
        { file: null, url: null, name: '집행비용예납서', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '채권압류 및 추심명령 신청서') {
      let attachmentList = [
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '송달증명원', format: null},
        { file: null, url: null, name: '확정증명원', format: null},
        { file: null, url: null, name: '등기사항전부증명서(채무자)', format: null},
        { file: null, url: null, name: '등기사항전부증명서(제3채무자)', format: null},
      ];

      if(decision.isOn) {
        attachmentList.push({ file: null, url: null, name: '가압류결정', format: null})
        attachmentList.push({ file: null, url: null, name: '가압류결정 송달증명원', format: null});
      }
      setNormalAttachmentList(attachmentList)
      //getThirdDebtorInfo();
    }
  }, [decision])

  useEffect(() => {

    const type = props.complaintType.includes('신청서') ? '신청서' : '소장';
    const documentType = props.complaintType;


    if(type === '소장') {
      setStepList([
        { title: '당사자 정보', imgOn: '../../Image/write-document-step1-on.png' },
        { title: '채권 정보', imgOn: '../../Image/write-document-step2-on.png', imgOff: '../../Image/write-document-step2-off.png' },
        { title: '소송 절차 선택', imgOn: '../../Image/write-document-step3-on.png', imgOff: '../../Image/write-document-step3-off.png' },
        { title: '증거 업로드', imgOn: '../../Image/write-document-step4-on.png', imgOff: '../../Image/write-document-step4-off.png' },
        { title: '최종 확인', imgOn: '../../Image/write-document-step5-on.png', imgOff: '../../Image/write-document-step5-off.png' },
        { title: '서비스 선택', imgOn: '../../Image/write-document-step6-on.png', imgOff: '../../Image/write-document-step6-off.png' }
      ])
    }
    else if(type === '신청서') {
      if(documentType === '사실조회 신청서') {
        setStepList([
          { title: '신청 정보', imgOn: '../../Image/write-document-step2-on.png', imgOff: '../../Image/write-document-step2-off.png' },
          { title: '첨부서류 업로드', imgOn: '../../Image/write-document-step4-on.png', imgOff: '../../Image/write-document-step4-off.png' },
          { title: '최종 확인', imgOn: '../../Image/write-document-step5-on.png', imgOff: '../../Image/write-document-step5-off.png' },
          { title: '서비스 선택', imgOn: '../../Image/write-document-step6-on.png', imgOff: '../../Image/write-document-step6-off.png' }
        ]);
        setSelectedStep('신청 정보');

        getTelecomList();
      }
      else {
        setStepList([
          { title: '당사자 정보', imgOn: '../../Image/write-document-step1-on.png' },
          { title: '신청 정보', imgOn: '../../Image/write-document-step2-on.png', imgOff: '../../Image/write-document-step2-off.png' },
          { title: '첨부서류 업로드', imgOn: '../../Image/write-document-step4-on.png', imgOff: '../../Image/write-document-step4-off.png' },
          { title: '최종 확인', imgOn: '../../Image/write-document-step5-on.png', imgOff: '../../Image/write-document-step5-off.png' },
          { title: '서비스 선택', imgOn: '../../Image/write-document-step6-on.png', imgOff: '../../Image/write-document-step6-off.png' }
        ])
      }
    }

    if(documentType === '재산명시 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '송달증명원', format: null},
        { file: null, url: null, name: '확정증명원', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '재산조회 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '재산명시결정', format: null},
        { file: null, url: null, name: '재산명시각하결정', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '채무불이행자명부 등재 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '송달증명원', format: null},
        { file: null, url: null, name: '확정증명원', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '부동산 강제경매 신청서') {
      setNormalAttachmentList([
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '판결정본송달증명원', format: null},
        { file: null, url: null, name: '부동산등기사항증명서', format: null},
        { file: null, url: null, name: '건축물대장등본', format: null},
        { file: null, url: null, name: '토지대장등본', format: null},
        { file: null, url: null, name: '주민등록표초본(채무자)', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
        { file: null, url: null, name: '등록세 지방교육세영수필확인서', format: null},
        { file: null, url: null, name: '집행비용예납서', format: null},
        { file: null, url: null, name: '송달료납부서', format: null},
      ])
    }
    else if(documentType === '채권압류 및 추심명령 신청서') {
      let attachmentList = [
        { file: null, url: null, name: '집행력 있는 판결정본', format: null},
        { file: null, url: null, name: '송달증명원', format: null},
        { file: null, url: null, name: '확정증명원', format: null},
        { file: null, url: null, name: '등기사항전부증명서(채무자)', format: null},
        { file: null, url: null, name: '등기사항전부증명서(제3채무자)', format: null},
      ];

      if(decision.isOn) {
        attachmentList.push({ file: null, url: null, name: '가압류결정', format: null})
        attachmentList.push({ file: null, url: null, name: '가압류결정 송달증명원', format: null});
      }
      setNormalAttachmentList(attachmentList)
      getThirdDebtorInfo();
    }


    if(type === '소장') {
      dispatch(init(props.complaintType))
      //props.init(props.complaintType);
    }
    else if(type === '신청서') {
      dispatch(initApplication(props.complaintType))
      //props.initApplication(props.complaintType);
    }
    
  }, []);

  useEffect(() => {
    if(isInit) {

      // const paybackList: Payback[] = [
      //   {
      //     id: 0,
      //     isChecked: true,
      //     date: '2021.10.28',
      //     price: 100000,
      //     balance: 0,
      //   },
      //   {
      //     id: 1,
      //     isChecked: true,
      //     date: '2021.9.21',
      //     price: 100000,
      //     balance: 0,
      //   },
      //   {
      //     id: 2,
      //     isChecked: true,
      //     date: '2021.11.04',
      //     price: 50000,
      //     balance: 0,
      //   },
      //   {
      //     id: 3,
      //     isChecked: true,
      //     date: '2021.12.18',
      //     price: 5000,
      //     balance: 0,
      //   }
      // ]

      // const bond: Bond = {...initBond(0), 
      //   lendDate: '2021.7.31',
      //   price: 3000000,
      //   willPaybackDateType: 'fix',
      //   fixDate: '2022.1.20',
      //   isInterestRate: true,
      //   interestRate: 16.2,
      //   isDeferInterestRate: false
      // }
      
      // dispatch(refreshComplaint({
      //   ...complaint,
      //   bondList: [bond],
      //   didPaybackDateList: paybackList
      // }))

      dispatch(refreshComplaint({...complaint, bondList: [initBond(0, props.complaintType)]}))
    }
  }, [isInit])

  const getThirdDebtorInfo = () => {
    get('debtor', null)
    .then(response => {
      if(response.data.success) {
        dispatch(refreshThirdDebtorInfo(response.data.data))
        //props.refreshThirdDebtorInfo(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  const getTelecomList = () => {
    get('telecom', null)
    .then(response => {
      if(response.data.success) {
        dispatch(refreshTelecomList(response.data.data))
        //props.refreshTelecomList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  const prev = () => {
    if(selectedPosition === 0) return;

    setSelectedPosition(selectedPosition - 1);
    setSelectedStep(stepList[selectedPosition - 1].title);
    //setIsNext(true);
  }

  const next = () => {
    //downloadTable();
    if(stepList.length - 1 <= selectedPosition) {
      if(selectedService === 'basic') clickedDownload(true);
      else if(selectedService === 'premium') clickedDownload(false);
      return
    }

    setSelectedPosition(selectedPosition + 1);
    setSelectedStep(stepList[selectedPosition + 1].title);

    console.log(selectedPosition, stepList.length)
    //setIsNext(selectedPosition + 2 <= stepList.length);

    return
  }

  const onAddEvidence = () => {
    setEvidenceList(evidenceList.concat({
      no: `갑 제${evidenceList.length + 1}호증`,
      name: '',
      file: null
    }))
  }

  const onRemoveEvidence = (index: number) => {
    setEvidenceList(evidenceList.filter((d, i) => i !== index));
  }

  const onEditEvidence = (index: number, evidence: any) => {
    const list = evidenceList.slice();
    list[index] = evidence;
    setEvidenceList(list);
  }

  const onAddAttachment = () => {
    setAttachmentList(attachmentList.concat({
      name: '',
      file: null
    }))
  }

  const onRemoveAttachment = (index: number) => {
    setAttachmentList(attachmentList.filter((d, i) => i !== index));
  }

  const onRemoveNormalAttachment = (index: number) => {
    setNormalAttachmentList(normalAttachmentList.filter((d, i) => i !== index));
  }

  const onEditAttachment = (index: number, attachment: any) => {
    const list = attachmentList.slice();
    list[index] = attachment;
    setAttachmentList(list);
  }

  const onEditNormalAttachment = (index: number, attachment: any) => {
    const list = normalAttachmentList.slice();
    list[index] = attachment;
    setNormalAttachmentList(list);
  }
  

  const completeSignIn = (user: any) => {
    dispatch(changeUser(user))
    //props.changeUser(user);
    setShowSignInView(false);
  }

  const getTableImage = async (element: any): Promise<Blob|null> => {
    return new Promise((resolve, reject) => {

      if(!element) {
        resolve(null);
      }
      else {
        html2canvas(element, {width: element.scrollWidth}).then(canvas => {
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/jpeg');
  
  
        }).catch(error => reject(null));
      }

      
    })
  }

  const getTableImageUrl = async (element: any) => {
    return new Promise((resolve, reject) => {

      if(!element) {
        resolve(null);
      }
      else {
        html2canvas(element, {width: element.scrollWidth}).then(canvas => {
          resolve(canvas.toDataURL('image/jpeg'));
          // canvas.toBlob((blob) => {
          //   resolve(blob);
          // }, 'image/jpeg');
  
  
        }).catch(error => reject(null));
      }

      
    })
  }

  const clickedOrder = (fileName: any) => {
    props.onClose();
    navigate({
      pathname: '/order',
    }, {
      state: {
        fileName: fileName,
        complaint: complaint,
        plaintiff: plaintiff,
        defendantList: defendantList,
        application: application
      }
    })
  }

  const clickedDownload = async (isFree: boolean) => {

    if(!user) {
      setShowSignInView(true);
      return;
    }

    if(type === '신청서') {
      downloadApplication(isFree);
      return;
    }
    

    let data: any = {};

    data.createdAt = moment().format('YYYY.  M.  D.')
    data.type = complaint.type;
    data.documentType = complaint.documentType;
    data.plaintiff = plaintiff;
    data.defendantList = defendantList;
    data.claimCauseList = claimCauseList;
    data.claimPurpose = claimPurpose;
    data.hurtTextList = hurtTextList;
    data.lossPriceTextList = lossPriceTextList;
    data.claimPrice = complaint.claimPrice;
    data.recognition1 = complaint.recognition1;
    data.recognition2 = complaint.recognition2;
    data.deliveryCharge1 = complaint.deliveryCharge1;
    data.deliveryCharge2 = complaint.deliveryCharge2;
    
    //data.courtList = complaint.courtList;
    data.court = complaint.selectedCourt;

    const curTime = new Date().getTime();

    const formData = new FormData()
    

    data.evidenceList = evidenceList.map((evidence, index) => {
      evidence.fileName = curTime + '_' + index + '.' + evidence.file.name.split('.')[evidence.file.name.split('.').length - 1];
      formData.append("files", evidence.file.file, evidence.fileName);

      return {
        //file: evidence.file,
        no: evidence.no,
        format: evidence.file.format,
        fileName: evidence.fileName,
        name: evidence.name
      }
    });

    data.attachmentList = attachmentList.map((attachment, index) => {
      attachment.fileName = curTime + '_' + (data.evidenceList.length + index) + '.' + attachment.file.name.split('.')[attachment.file.name.split('.').length - 1];
      formData.append("files", attachment.file.file, attachment.fileName);

      return {
        //file: attachment.file,
        format: attachment.file.format,
        fileName: attachment.fileName,
        name: attachment.name
      }
    })

    const tableList = [];

    setShowLoading(true);

    if(document.getElementById('bond-table')) {
      const bondBlob = await getTableImage(document.getElementById('bond-table'));
      if(bondBlob) {
        const name = `table${new Date().getTime()}0.jpg`;
        formData.append("files", bondBlob, name);
        tableList.push({
          name: name
        })
      }
    }
    
    if(document.getElementById('payback-table')) {
      const paybackBlob = await getTableImage(document.getElementById('payback-table'));
    
      if(paybackBlob) {
        const name = `table${new Date().getTime()}1.jpg`;
        formData.append("files", paybackBlob, name);
        tableList.push({
          name: name
        })
      };
    }
    

    data.tableList = tableList;


    formData.append('documents', JSON.stringify(data));



    post('download', formData)
    .then(response => {

      setShowLoading(false);
      console.log(response.data);

      if(response.data.success) {
        if(isFree) {
          const link = document.createElement('a')
          link.href = `https://sjez.s3.ap-northeast-2.amazonaws.com/pdf/${response.data.data}`
          link.target = '_blank'
          link.setAttribute('download', response.data.data)
          document.body.appendChild(link);
          link.click();
        }
        else {
          clickedOrder(response.data.data);
        }
      }
      else {

      }

    })
    .catch(error => {
      setShowLoading(false);
      console.log('error', error)
    })
  }

  const downloadApplication = async (isFree: boolean) => {

    const curTime = new Date().getTime();

    const formData = new FormData();

    const app = JSON.parse(JSON.stringify(application));

    console.log(application)

    app.attachmentList = [...normalAttachmentList, ...attachmentList].map((attachment, index) => {

      let format = '';
      let fileName = '';

      if(attachment.file) {
        format = attachment.file.format;
        fileName = `${curTime}_${index}.${attachment.file.name.split('.')[attachment.file.name.split('.').length - 1]}`;
        formData.append("files", attachment.file.file, fileName);
      }
      
      return {
        //file: attachment.file,
        format: format,
        fileName: fileName,
        name: attachment.name
      }
    })

    // app.attachmentList = attachmentList.map((attachment, index) => {
    //   attachment.fileName = `${curTime}_${index}.${attachment.file.name.split('.')[attachment.file.name.split('.').length - 1]}`;
    //   formData.append("files", attachment.file.file, attachment.fileName);
    //   return {
    //     //file: attachment.file,
    //     format: attachment.file.format,
    //     fileName: attachment.fileName,
    //     name: attachment.name
    //   }
    // })

    const tableList = [];

    setShowLoading(true);

    if(document.getElementById('bond-table')) {
      const bondBlob = await getTableImage(document.getElementById('bond-table'));
      if(bondBlob) {
        const name = `table${new Date().getTime()}0.jpg`;
        formData.append("files", bondBlob, name);
        tableList.push({
          name: name
        })
      }
    }
    
    if(document.getElementById('payback-table')) {
      const paybackBlob = await getTableImage(document.getElementById('payback-table'));
    
      if(paybackBlob) {
        const name = `table${new Date().getTime()}1.jpg`;
        formData.append("files", paybackBlob, name);
        tableList.push({
          name: name
        })
      };
    }

    app.plaintiff = plaintiff
    app.defendantList = defendantList
    
    formData.append('application', JSON.stringify(app));



    post('download/application', formData)
    .then(response => {

      setShowLoading(false);

      if(response.data.success) {
        if(isFree) {
          const link = document.createElement('a')
          link.href = `https://sjez.s3.ap-northeast-2.amazonaws.com/pdf/${response.data.data}`
          link.target = '_blank'
          link.setAttribute('download', response.data.data)
          document.body.appendChild(link);
          link.click();
        }
        else {
          clickedOrder(response.data.data);
        }
      }
      else {

      }

    })
    .catch(error => {
      setShowLoading(false);
      console.log('error', error)
    })
  }

  return (
    <div className="write-document ta-center">
      <div className="modal-bx">
        <Button className="close-btn" onClick={e => {props.onClose()}}><CloseIcon /></Button>
        <div className="step-area flex">
          {stepList.map((stepItem, index)=> (
            <div className={`step-bx ${index <= selectedPosition ? 'active' : ''}`} key={`stepItem${index}`}>
              <div className="line"></div>
              <Button className={"icon-btn flex-center"} onClick={e => {
                setSelectedPosition(index);
                setSelectedStep(stepItem.title);
              }}>
                <img src={index <= selectedPosition ? stepItem.imgOn : stepItem.imgOff} alt={stepItem.title} />
              </Button>
              <p className="ttl">{stepItem.title}</p>
            </div>
          ))}
        </div>

        <p className="preview-txt">오른쪽에 미리보기가 가능합니다.</p>
        
        <div className="write-document-area flex">
          <div className="write-area flex1">
            <div className="write">
              
              {selectedStep === '당사자 정보' && (
                <Step1 complaint={complaint}/>
              )}

              {(selectedStep === '채권 정보' || selectedStep === '신청 정보') && (
                <Step2 />
              )}

              {selectedStep === '소송 절차 선택' && (
                <Step3/>
              )}

              {(selectedStep === '증거 업로드' || selectedStep === '첨부서류 업로드') && (
                <Step4
                  documentType={complaint.documentType}
                  evidenceList={evidenceList}
                  onAddEvidence={onAddEvidence}
                  onRemoveEvidence={onRemoveEvidence}
                  onEditEvidence={onEditEvidence}
                  normalAttachmentList={normalAttachmentList}
                  onRemoveNormalAttachment={onRemoveNormalAttachment}
                  onEditNormalAttachment={onEditNormalAttachment}
                  attachmentList={attachmentList}
                  onAddAttachment={onAddAttachment}
                  onRemoveAttachment={onRemoveAttachment}
                  onEditAttachment={onEditAttachment}
                />
              )}

              {selectedStep === '최종 확인' && (
                <div className="step5">
                  <p className="head">최종 확인</p>
                  <Step5 
                    evidenceList={evidenceList}
                    attachmentList={attachmentList}
                    normalAttachmentList={normalAttachmentList}/>
                </div>
              )}

              {selectedStep === '서비스 선택' && (
                <div className="step6">
                  <p className="head">서비스 선택</p>
                  <Step6Service 
                    // onClickDownload={() => clickedDownload(true)}
                    // onClickOrder={() => clickedDownload(false)}
                    service={selectedService}
                    onClickDownload={() => setSelectedService('basic')}
                    onClickOrder={() => setSelectedService('premium')}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="document-area">
            {type === '소장' && (
              <Preview 
              claimCauseList={claimCauseList || []}
              claimPurpose={claimPurpose}
              evidenceList={evidenceList}
              attachmentList={attachmentList}
              hurtTextList={hurtTextList}
              lossPriceTextList={lossPriceTextList}
            />
            )}
            {type === '신청서' && (
              <ApplicationPreview
              evidenceList={evidenceList}
              normalAttachmentList={normalAttachmentList}
              attachmentList={attachmentList}
              />
            )}
          </div>
        </div>

        <div className="btn-group flex ai-cetner jc-center">
          <Button className="prev-btn" onClick={e => prev()}>이전</Button>
          <Button className="next-btn contained" disabled={!isNext} onClick={e => next()}>다음</Button>
        </div>
      </div>

      {isShowLoading && (
        <div className='loading-bx'>
          <CircularProgress />
        </div>
      )}

      {showSignInView && (
        <SignInView 
          title="로그인 후 다운로드가 가능합니다."
          onClose={() => setShowSignInView(false)}
          onCompleteSignIn={(user: any) => completeSignIn(user)}
        />
      )}

    </div>
  )
}
