import React from 'react';
import './Step.scss';
import { Button } from '@material-ui/core';
import { useAppSelector } from '../../../store';

type Step3ProcedureProps = {
  onSelectDocumentType: (type: string) => void
}

export default function Step3Procedure(props: Step3ProcedureProps) {
  
  const complaint = useAppSelector(state => state.app.complaint)

  return(
    <div className="cont-bx step3-procedure">
      <div className="bg-gray">
        <div className="procedure-card flex" onClick={e => props.onSelectDocumentType('소장')}>
          <Button className={`ttl-btn column ${complaint.documentType === '소장' && 'selected'}`}>
            <p>소장</p>
            <p>(소송절차)</p>
          </Button>
          <div className="cont-bx flex1 column ta-center">
            <p className="cont-ttl">법원 출석 및 정식 재판</p>
            <p className="txt">피고는 소장 송달일로부터 30일 이내 답변서 제출할 의무 있음</p>
            <div className="fees-bx flex-center">
              <p>법원 수수료</p>
              <p>(인지대: 1,000원, 송달료: 102,000원)</p>
            </div>
            <div className="fees-bx flex-center" style={{marginTop: '6px'}}>
              <p>대법원 전자소송 사이트에서 소장을 제출하면<br/>인지대가 10% 할인됩니다.</p>
            </div>
          </div>
        </div>
        <div className="procedure-card flex" onClick={e => props.onSelectDocumentType('지급명령')}>
        <Button className={`ttl-btn column ${complaint.documentType === '지급명령' && 'selected'}`}>
            <p>지급 명령 신청서</p>
            <p>(독촉절차)</p>
          </Button>

          <div className="cont-bx flex1 column ta-center">
            <p className="cont-ttl">법원출석 없이 서류 판단</p>
            <p className="txt">피고가 지급명령 송달일로부터 14일 이내</p>
            <p className="txt">답변서 제출하지 않으면 원고 자동 승소</p>
            <div className="fees-bx flex-center">
              <p>법원 수수료</p>
              <p>(인지대: 1,000원, 송달료: 61,200원)</p>
            </div>
            <div className="fees-bx flex-center" style={{marginTop: '6px'}}>
              <p>대법원 전자소송 사이트에서 지급명령 신청서를 제출하면<br/>인지대가 10% 할인됩니다.</p>
            </div>
          </div>
        </div>
      </div>
   </div>
  )
}