import { useState, useEffect } from 'react';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './Faq.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { get } from "../../utils/Api";

function Faq(props) {
  // const [ showAnswer, setShowAnswer ] = useState(true)

  const [faqList, setFaqList] = useState([])

  useEffect(() => {
    getFaqList();
  }, [])

  const getFaqList = () => {
    get('qna')
    .then(response => {
      if(response.data.success) {
        setFaqList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="faq page-view">
      <div className="h-top"></div>

      <div className="inner mw1280">
        <div className="head-area">
          <span className="head">FAQ</span>
        </div>

        {/* accordion 사용한 곳입니당~ */}
        {/* https://mui.com/components/accordion/ */}

        <div className="cont-area">
          {faqList.map((faq, index) => (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <p>{faq.question}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p>{faq.answer}</p>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* <Accordion
            // expanded={expanded === 'panel1'}
            // onChange={handleChange('panel1')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <p>2. 다른 사이트와 차이가 뭔가요?</p>
            </AccordionSummary>
            <AccordionDetails>
              <p>타사는 이용자가 사실관계를 입력하면 변호사가 작성하는 방식으로 가격이 비싸며, 소장 작성까지 며칠이 걸립니다. 그러나 「소장이지」는 법을 모르는 이용자도 간단한 사실관계 입력만으로 5분 내에 소장을 완성할 수 있습니다. 「소장이지」에서 내려받은 파일을 곧바로 출력해 법원에 제출하면 됩니다.</p>
            </AccordionDetails>
          </Accordion> */}

        </div>
      </div>

    </div>
  )
}

export default Faq;
