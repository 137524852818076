import PreviewBondTable from "./PreviewBondTable";
import PreviewPaybackTable from "./PreviewPaybackTable";
import './Preview.scss';

import { getPhone, addComma } from "../../../utils/utils";
import { useEffect, useState } from "react";
import moment from "moment";
import LossPreview from "./preview/LossPreview";
import { useAppSelector } from "../../../store";
import { Person } from "../../../store/modules/app";

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아'];

type PreviewProps = {
  claimCauseList: any[]
  claimPurpose: {header: string, firstList: any[], secondList: any[]}
  evidenceList: any[]
  attachmentList: any[]
  hurtTextList: any[]
  lossPriceTextList: any[]
}

export default function Preview(props: PreviewProps) {

  const complaint = useAppSelector(state => state.app.complaint)
  const plaintiff = useAppSelector(state => state.app.plaintiff)
  const defendantList = useAppSelector(state => state.app.defendantList)

  // const complaint = props.complaint;
  // const documentType = props.complaint.documentType;

  const getName = (person: Person) => {
    let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : getSpacingWord(person?.name);
    if(!name || name === '') name = 'O O O';

    if(person?.type === 0 || person?.type === 1) {
      if(person?.isEmptyName === true) {
        name = '성명 불상';
      }
    }
    else {
      if(person?.isEmptyCompany === true) {
        name = '상호 불상';
      }
    }

    return name;
  }

  const getRRNumber = (person: Person) => {

    if(person.isEmptyRRNumber) return '';

    if(person.RRNumber1 === '' && person.RRNumber2 === '') {
      return '000000-0000000';
    }

    return `${person.RRNumber1}-${person.RRNumber2}`;

    // if(person.RRNumber1 !== '' && person.RRNumber2 !== '') {
    //   return `${person.RRNumber1}-${person.RRNumber2}`
    // }

    // if(person.isEmptyRRNumber) return '';
  
    // return '000000-0000000';
  }

  const getAddress = (person: Person) => {
    if(person.isEmptyAddress) return '주소 불상';

    return person.address !== '' ? `${person.displayAddress} ${person.detailAddress}` : '서울특별시 서초구 법원로3길 14';
  }

  const getPhone = (person: Person): string => {

    let phone = '010-0000-0000';

    if(person.phone1 != '' && person.phone2 != '' && person.phone3 != '') {
      phone = `${person.phone1}-${person.phone2}-${person.phone3}`;
    }

    if(person.isEmptyPhone) {
      phone = '';
    }


  
    return phone;
  }

  const isEmptySpotName = (person: Person) => {
    if(person.type === 2 || person.type === 3) {
      if(person.spot !== '' || person.name !== '') {
        return false;
      }
    }

    return true;
  }

  const getSpacingWord = (word: string) => {
    return word.split('').join(' ')
  }

  return (
    <div>
      {complaint && (
        <div className="document">
          <h1 className="head" style={{wordSpacing: `${complaint.documentType === '소장' ? '20px' : '8px'}`}}>{complaint.documentType === '소장' ? '소 장' : '지 급 명 령 신 청 서'}</h1>
          <div className="inner-content">
            <div className="plaintiff-area flex">
              {complaint.documentType === '소장' && (
                <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>원</p><p>고</p></div>
              )}
              {complaint.documentType === '지급명령' && (
                <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>채</p><p>권</p><p>자</p></div>
              )}
              <div className="info-item">
                <p>
                  <span>{getName(plaintiff)}</span>
                  <span>({getRRNumber(plaintiff)})</span>
                </p>
                {!isEmptySpotName(plaintiff) && (
                  <p><span>{plaintiff.spot} </span><span>{getSpacingWord(plaintiff.name)}</span></p>
                )}
                <p>{getAddress(plaintiff)}</p>
                <p>{getPhone(plaintiff) || '010-0000-0000'}{plaintiff.email !== '' ? `, ${plaintiff.email}` : ''}</p>
              </div>
            </div>

            <div className="defendant-area flex">
            {complaint.documentType === '소장' && (
              <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>피</p><p>고</p></div>
            )}
            {complaint.documentType === '지급명령' && (
              <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>채</p><p>무</p><p>자</p></div>
            )}
              <div className="info-group">
                {defendantList.map((defendant, index) => (
                  <div className="info-item" key={`defendant${index}`}>
                    <p>
                      {defendantList.length !== 1 && (
                        <span>{index + 1}. </span>
                      )}
                      <span>{getName(defendant) !== '' ? getName(defendant) : 'O O O'}</span>
                      {!defendant.isEmptyRRNumber && <span>({getRRNumber(defendant)})</span>}
                    </p>
                    {!isEmptySpotName(defendant) && (
                      <p><span>{defendant.spot} </span><span style={{wordSpacing: '2px'}}>{getSpacingWord(defendant.name)}</span></p>
                    )}
                    <p>{getAddress(defendant)}</p>
                    {getPhone(defendant) && <p>{getPhone(defendant)}</p>}
                  </div>
                ))}
              </div>
            </div>

            <b className="sub-ttl">{complaint.type} 청구의 {complaint.documentType === '소장' ? '소' : '독촉사건'}</b>
            
            {complaint.documentType === '지급명령' && (
              <div className="price-area">
                <p>청구금액 : {addComma(complaint.claimPrice)}원</p>
                <p>독촉절차비용 : {addComma(
                  complaint.recognition2 + complaint.deliveryCharge2)}원(인지대 : {addComma(complaint.recognition2)}원, 송달료 : {addComma(complaint.deliveryCharge2)}원)</p>
              </div>
            )}

            <div className="purpose-area">
              <b className="ttl">{complaint.documentType === '소장' ? '청 구 취 지' : '신 청 취 지'}</b>
              <div className="list">
                {props.claimPurpose.secondList.length === 0 && (
                  <div>
                    {props.claimPurpose.firstList.map((first, index) => (
                      <div className="list-item" key={`first${index}`}>
                        <p>{index + 1}.</p>
                        <p>{first}</p>
                      </div>
                    ))}
                  </div>
                )}
                {props.claimPurpose.secondList.length !== 0 && (
                  <div>
                    <div className="list-item">
                      <p>{1}.</p>
                      <p>{props.claimPurpose.header}</p>
                    </div>
                    {props.claimPurpose.secondList.map((second, index) => (
                      <div className="list-item" key={`second${index}`}>
                        <p>{HANGEUL_INDEX[index]}.</p>
                        <p>{second}</p>
                      </div>
                    ))}
                    {props.claimPurpose.firstList.map((first, index) => (
                      <div className="list-item" key={`first${index}`}>
                        <p>{index + 2}.</p>
                        <p>{first}</p>
                      </div>
                    ))}
                  </div>
                )}
                <p>{complaint.documentType === '소장' ? '라는 판결을 구합니다.' : '라는 재판을 구합니다.'}</p>
              </div>
            </div>

            {complaint.type !== '손해배상' && (
              <div className="cause-area">
                <b className="ttl">{complaint.documentType === '소장' ? '청 구 원 인' : '신 청 원 인'}</b>
                <div className="list">
                  {props.claimCauseList.map((cause, index) => (
                    <div className="list-item" key={`cause${index}`}>
                      <p>{index + 1}.</p>
                      <p>{cause}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {complaint.type === '손해배상' && (
              <div className="cause-area">
                <b className="ttl">청 구 원 인</b>
                <LossPreview
                  //complaint={complaint}
                  // hurtTextList={props.hurtTextList}
                  // lossPriceTextList={props.lossPriceTextList}
                  // claimCauseList={props.claimCauseList}
                  // claimPurpose={props.claimPurpose}
                />
              </div>
            )}

            {props.evidenceList.length !== 0 && (
              <div className="evidence-area">
                <b className="ttl">입 증 방 법</b>
                {props.evidenceList.map((evidence, index) => (
                  <p key={`evidence${index}`}>{index + 1}. {evidence.no} {evidence.name}</p>
                ))}
              </div>
            )}

            {props.attachmentList.length !== 0 && (
              <div className="attachment-area">
                <b className="ttl">첨 부 서 류</b>
                {props.attachmentList.map((attachment, index) => (
                  <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
                ))}
              </div>
            )}

            <p className="date">{moment(complaint.createdAt).format('YYYY. M. D.')}</p>
            
            <p className="sign">
              <span>위 {complaint.documentType === '소장' ? '원고' : '채권자'}</span>
              <span>(인)</span>
            </p>

            <h1>{complaint.selectedCourt?.name} 귀중</h1>

            {complaint.type !== '임금' && complaint.type !== '용역대금' && complaint.didPaybackDateList.length !== 0 && (
              <div className="table-group">
                <PreviewBondTable/>

                <PreviewPaybackTable
                  didPaybackDateList={complaint.didPaybackDateList}
                  paymentTableList={complaint.paymentTableList}/>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}