import { useEffect, useState } from "react";

import { TextField } from '@mui/material';
export default function AddressPicker(props) {

  const [address, setAddress] = useState('');

  useEffect(() => {
    setAddress(props.address);
  }, [props.address]);

  const onClickAddress = (e) => {
    let postcode = {
      oncomplete: onChangeAddress
    };
    window.daum.postcode.load(() => {
      new window.daum.Postcode(postcode).open();
    });
  }

  const onChangeAddress = (data) => {
    

    let displayAddress = '';
    const addressList = data.address.split(' ');
    
    if(addressList[0] == '경기' && addressList[1].charAt(addressList[1].length - 1) == '시') {
      data.displayAddress = data.address.replace('경기 ', '');
    }
    else if(addressList[0] == '강원' && addressList[1].charAt(addressList[1].length - 1) == '시') {
      data.displayAddress = data.address.replace('강원 ', '');
    }
    else if(addressList[0] == '제주특별자치도') {
      data.displayAddress = data.address.replace('제주특별자치도 ', '');
    }
    else {
      if(addressList[1].charAt(addressList[1].length - 1) == '시') {
        data.displayAddress = data.address.replace(addressList[0] + ' ', '');  
      }
      else {
        data.displayAddress = data.address
      }
    }

    props.onSelectAddress(data);

    setAddress(data.address);
  }

  return (
    // <TextField
    //   variant="outlined"
    //   // disabled
    //   
    //   value={address}
    //   placeholder="서울특별시 서초구 법원로3길 14"
    // />
    <p
      className="input_address"
      onClick={e => onClickAddress()}
    >
      {address === '' ? <span>서울특별시 서초구 법원로3길 14</span> : address}
    </p>
  )
}