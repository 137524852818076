import { Button, TextField } from '@mui/material';
import { useState } from 'react';

//대지권의 표시
export default function EditSetBuildingSub3(props) {

  const [contents1, setContents1] = useState(props.info3?.contents1 || '');
  const [contents2, setContents2] = useState(props.info3?.contents2 || '');

  return (
    <div className="modal-container edit-set-building-sub">
      <div className="modal">
        <p className="top-area">대지권의 표시</p>

        <div className="middle-area">
          <div className="item">
            <p className="ttl">대지권종류</p>
            <div className="textarea-wrap">
              <TextField
                value={contents1}
                onChange={e => setContents1(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>
          <div className="item">
            <p className="ttl">대지권비율</p>
            <div className="textarea-wrap">
              <TextField
                value={contents2}
                onChange={e => setContents2(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>
        </div>

        <div className="bottom-area">
          <Button className="outlined" onClick={e => props.onClose()}>취소</Button>
          <Button className="contained" onClick={e => props.info3 ? props.onEdit({contents1, contents2}) : props.onAdd({contents1, contents2})}>추가</Button>
        </div>
      </div>
    </div>
  )
}
