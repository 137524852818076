
import axios, { AxiosResponse } from 'axios';
import * as AxiosLogger from 'axios-logger';
import Error from '../models/Error.model';
import ResponseData from '../models/ResponseData.model';


export const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  timeout: 15000
});

if(process.env.NEXT_PUBLIC_MODE !== 'production') instance.interceptors.request.use(AxiosLogger.requestLogger)

instance.interceptors.request.use(request => {
	return request;
})
instance.interceptors.response.use(response => {

	const data = response.data as ResponseData

	// if(data.message_code === 20002) {
	// 	return Promise.reject(new Error(data.message_code, data.message));
	// }

	return response;
}, async error => {
	const { config, response: {status, data} } = error;

	if(status === 401) {
		//console.log('401 error', error);
		// try {
		// 	const refreshToken = sessionStorage.getItem('refreshToken');

		// 	const {data} = await axios.post(`${process.env.NEXT_PUBLIC_API_HOST}v1/admin/auth/token`, {type: 'refreshToken', refreshToken: refreshToken});

		// 	if(data.success) {
		// 		initToken(data.data.token);
		// 		config.headers['Authorization'] = `Bearer ${data.data.token}`;
		// 	}
			


		// } catch (error) {
		// 	console.log(error);
		// }

		return axios(config);

	}
	else {
		console.log(`${status} error`)
	}

	return Promise.reject(error);
})

export const initToken = (token: string|null) => {
	if(token === null) return delete instance.defaults.headers.common['Authorization']

  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


const responseBody = (response: AxiosResponse) => {

	const data = response.data as ResponseData

	if(data.success) {
		return data.data
	}

	throw new Error(data.code)
}



export const requests = {
	get: <T>(url: string, body: {} = {}): Promise<T> => instance.get(url, {params: body}).then(responseBody),
	post: <T>(url: string, body: {} = {}): Promise<T> => instance.post(url, body).then(responseBody),
	posts: <T>(url: string, body: any = {}): Promise<T> => {
		const formData = new FormData()
		Object.keys(body).forEach(value => {
			formData.append(value, body[value])
		})
		return instance.post(url, formData).then(responseBody)
	},
	form: <T>(url: string, body: FormData): Promise<T> => instance.post(url, body).then(responseBody),
	patch: <T>(url: string, body: {} = {}): Promise<T> => instance.patch(url, body).then(responseBody),
	delete: <T>(url: string): Promise<T> => instance.delete(url).then(responseBody),
};
