import { Button } from '@mui/material';
import Step4Evidence from './Step4Evidence'
import Step4Attach from './Step4Attach'

//etc
import _ from 'lodash'
import { useAppSelector } from '../../../store';

function Step4(props) {

  const documentType = useAppSelector((state) => state.app.type);

  const onRefreshEvidence = (index, evidence) => {
    props.onEditEvidence(index, evidence);
  }

  const onRefreshAttachment = (index, attachment) => {
    props.onEditAttachment(index, attachment);
  }

  const onRefreshNormalAttachment = (index, attachment) => {
    props.onEditNormalAttachment(index, attachment);
  }

  return (
    <div className="step4">
      {documentType === '소장' && (
        <div>
          <p className="head">증거서류</p>
          <p>증거서류를 업로드 하세요.</p>
          <p className="sub-txt">.jpg, .png, .pdf 확장자 파일만 업로드 가능합니다.</p>
          {props.evidenceList.map((evidence, index) => (
            <Step4Evidence key={`evidence${index}`} index={index}
              evidence={evidence}
              onClickRemove={() => props.onRemoveEvidence(index)}
              onRefreshEvidence={(evidence) => onRefreshEvidence(index, evidence)}
            />
          ))}
          <Button className="contained evidence-add-btn" onClick={e => props.onAddEvidence()}>증거서류 추가</Button>
          <hr />
      </div>
      )}
      <p className="head">첨부서류</p>
      <p>첨부서류를 업로드 하세요.</p>
      <p className="sub-txt">.jpg, .png, .pdf 확장자 파일만 업로드 가능합니다.</p>
      <p className="sub-txt">* 원고 또는 피고가 법인인 경우, 법인등기부를 첨부서류로 제출하여야 함</p>
      {props.normalAttachmentList.map((attachment, index) => (
        <Step4Attach key={`attachment${index}`} index={index}
          attachment={attachment}
          onClickRemove={() => props.onRemoveNormalAttachment(index)}
          onRefreshAttachment={(attachment) => onRefreshNormalAttachment(index, attachment)}
        />
      ))}
      {props.attachmentList.map((attachment, index) => (
        <Step4Attach key={`attachment${(props.normalAttachment ? props.normalAttachment.length : 0) + index}`} index={index}
          attachment={attachment}
          onClickRemove={() => props.onRemoveAttachment(index)}
          onRefreshAttachment={(attachment) => onRefreshAttachment(index, attachment)}
        />
      ))}
      <Button className="contained attach-add-btn" onClick={e => props.onAddAttachment()}>첨부서류 추가</Button>
    </div>
  )
}


export default Step4;