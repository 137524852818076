import '../Preview.scss';

import { addComma, calcDelayPriceInfo } from "../../../../utils/utils";
import { useEffect, useState } from "react";
import moment from "moment";

import {josa} from 'josa'

import LossTitle from './LossTitle';
import { useAppSelector } from '../../../../store';

import _ from 'lodash'

const Spacer = () => {
  return (
    <p style={{height: '20px'}}></p>
  )
}

export default function BondSeizurePreview(props) {

  const configuration = useAppSelector((state) => state.app.configuration)
  const billingAmount = useAppSelector((state) => state.app.application.billingAmount);
  const billingAmountList = useAppSelector((state) => state.app.application.billingAmountList);

  const decision = useAppSelector((state) => state.app.application.decision);

  const thirdDebtorText = useAppSelector((state) => {
    const app = state.app.application;
    const count = app.bankDebtorList.length + app.cardDebtorList.length + app.depositDebtorList.length;

    if(count > 1) {
      return '제3채무자들';
    }
    else {
      return '제3채무자';
    }
  })

  const thirdDebtorCount = useAppSelector((state) => {
    const app = state.app.application;
    return app.bankDebtorList.length + app.cardDebtorList.length + app.depositDebtorList.length;
  })

  const execution = useAppSelector((state) => state.app.application.execution);

  const [totalPrice, setTotalPrice] = useState('');
  const [contents, setContents] = useState('');
  const [executionPrice, setExecutionPrice] = useState('');
  const [executionContents, setExecutionContents] = useState('');

  useEffect(() => {

    console.log(billingAmount)
    //const price = billingAmount.type === 'all' ? billingAmountList[billingAmountList.length - 1].price : billingAmount.price;
    const price = billingAmountList[billingAmountList.length - 1].price

    const amountList = billingAmountList.map(b => {
      //b.info = calcDelayPriceInfo(b.startDate, b.endDate, price, b.rate);
      return {...b, info: calcDelayPriceInfo(b.startDate, b.endDate, price, b.rate)}
    });

    //setTotalPrice(`① 금 ${addComma(price + amountList.map(b => b.info.price).reduce((a, c) => a + c, 0))}원 `);
    setTotalPrice(`${addComma(price + amountList.map(b => b.info.price).reduce((a, c) => a + c, 0))}`);

    let contents = `[원금 ${addComma(price)}원 + 지연손해금 ${addComma(amountList.map(b => b.info.price).reduce((a, c) => a + c, 0))}원(${amountList.map(b => {
      return `${moment(b.startDate).format('YYYY. M. D.')}부터 ${moment(b.endDate).format('YYYY. M. D.')}까지 연 ${b.rate}%`;
    }).join('의, ')}`;

    if(amountList.length === 1) {
      contents += '의 지연손해금';
    }
    else {
      contents += '의 각 지연손해금';
      contents = contents.replace(/까지/gi, '까지는');
    }

    contents += ')]'

    setContents(contents);

    const executionPrice = 4000 + ((thirdDebtorCount + 2) * configuration.deliveryCharge);

    setExecutionPrice(`② 금 ${addComma(executionPrice)}원`);
    setExecutionContents(`(집행비용의 내역 : 인지액 4,000원, 송달료 ${addComma((thirdDebtorCount + 2) * configuration.deliveryCharge)}원)`)

  }, [billingAmount, billingAmountList]);

  const getBillingAmountText = () => {
    if(billingAmount.type === 'all') {
      return `금 ${addComma(billingAmountList[billingAmountList.length - 1].price)}원`;
    }
    else {
      return `금 ${totalPrice}원 중 일부 금 ${addComma(billingAmount.price)}원`;
    }

  }

  const getIncidentText = () => {
    const { court, year, code, subName } = decision.incident;
    return `${court} ${year}${code}${subName}`;
  }

  const getExecutionText = (execution) => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}`;
    } 
    else if(execution.type === '기타') {
      return execution.execution3;
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${execution.type}`;
    }
  }

  return (
    <div className="inner-content">
      
      <Spacer/>

      <LossTitle
        title="청구채권의 표시 : "
        contents={`${getBillingAmountText()}`}
        bold={true}
      />

      <LossTitle
        title={`① 금 ${totalPrice}원 `}
        contents={contents}
      />

      <LossTitle
        title={executionPrice}
        contents={executionContents}
      />

      {/* <p className="bold">청구채권의 표시 : {getBillingAmountText()}</p> */}

      <LossTitle
        title="압류 및 추심할 채권의 표시 :"
        contents="별지 목록 기재와 같음"
        bold={true}
      />

      <Spacer/>

      <p className="ta-center bold">신 청 취 지</p>

      <Spacer/>

      {!decision.isOn && (
        <LossTitle
          title="1. "
          contents={`채무자의 ${thirdDebtorText}에 대한 별지 기재 채권을 압류한다.`}/>
      )}
      {decision.isOn && (
        <LossTitle
          title="1. "
          contents={`채권자와 채무자 사이의 ${getIncidentText()} 채권가압류 결정에 의한 별지 기재 채권에 대한 가압류 금 ${addComma(decision.price)}원을 본압류로 이전한다.`}/>
      )}

      <LossTitle
        title="2. "
        contents={`${josa(`${thirdDebtorText}#{은}`)} 채무자에게 위 채권에 관한 지급을 하여서는 아니 된다.`}/>
      <LossTitle
        title="3. "
        contents="채무자는 위 채권의 처분과 영수를 하여서는 아니 된다."/>
      <LossTitle
        title="4. "
        contents="위 압류된 채권은 채권자가 추심할 수 있다."/>

      <p>라는 결정을 구합니다.</p>

      <Spacer/>

      <p className="ta-center bold">신 청 이 유</p>

      <Spacer/>

      <p>채권자는 채무자에 대하여 {getExecutionText(execution)}에 기한 위 청구채권 표시의 채권을 갖고 있으나 채무자는 이를 변제하지 아니하므로, 채무자가 위 {thirdDebtorText}에 대하여 가지는 별지 기재의 채권을 압류하고, 이를 추심하고 이 건 신청을 합니다.</p>

      
      <Spacer/>

      {/* {props.attachmentList.length !== 0 && (
        <div className="attachment-area">
          <b className="ttl">첨 부 서 류</b>
          {props.attachmentList.map((attachment, index) => (
            <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
          ))}
        </div>
      )} */}
    </div>
  )
}
