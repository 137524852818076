import { useCallback, useEffect, useState } from 'react';

import { FormControl, Select, MenuItem, TextField, Checkbox } from '@mui/material';
import Incident from '../components/application/Incident';
import Execution from '../components/application/Execution';
import SelectCourt from '../components/application/SelectCourt';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { refreshAccount, refreshApplicationCourt, refreshAssetFindReasonArray, refreshDeptText, refreshExecution, refreshIncident } from '../../store/modules/app';
import RefundBank from '../../models/RefundBank.model';

//const bankArray = ["KB국민", "IBK기업", "NH농협", "신한", "시티", "SC제일", "우리", "카카오뱅크", "케이뱅크", "하나", "경남", "광주", "대구", "부산", "KDB산업", "수협", "우체국", "전북", "제주", "새마을금고", "신협", "SBI저축", "저축", "미래에셋대우", "삼성", "SK", "유안타", "유진투자", "한국투자" ];

//재산조회신청서
export default function AssetFind() {

  const dispatch = useDispatch()

  const execution = useAppSelector((state) => state.app.application.execution);
  const incident = useAppSelector((state) => state.app.application.incident);
  const debt = useAppSelector((state) => state.app.application.deptText);
  const court = useAppSelector((state) => state.app.application.court);
  const reasonArray = useAppSelector((state) => state.app.application.assetFindReasonArray);
  const account = useAppSelector((state) => state.app.application.account);

  const refundBankList = useAppSelector(state => state.app.configuration.refundBankList)

  const [selectedBank, setSelectedBank] = useState<RefundBank>()

  useEffect(() => {
    setSelectedBank(refundBankList.find(v => v.displayName === account.bank))
  }, [refundBankList, account])

  const selectReason = (isChecked: boolean, reason: string) => {
    if(isChecked) {
      dispatch(refreshAssetFindReasonArray(reasonArray.concat(reason)))
    }
    else {
      dispatch(refreshAssetFindReasonArray(reasonArray.filter(r => r !== reason)))
    }
  }

  return (
    <div className="asset-find">
      <div className="cont-bx">

        <p className="ttl">1) 재산명시사건 정보를 입력해 주세요.</p>
        <Incident
          incident={incident}
          onData={(e: any) => dispatch(refreshIncident(e))}
        />
        <hr />
        
        <p className="ttl">2) 집행권원을 입력해 주세요.</p>
        <Execution 
          execution={execution}
          onData={(e: any) => dispatch(refreshExecution(e))}/>
        <hr />

        <p className="ttl">3) 채무자가 이행하지 아니하는 금전채무액을 입력해 주세요.</p>
        <div className="answer-area textarea-wrap">
          <TextField
            value={debt}
            onChange={e => dispatch(refreshDeptText(e.target.value))}
            multiline
            rows={2}
            placeholder="금 10,000원 및 이에 대한 2020. 1. 1.부터 다 갚는 날까지 연 20%의 비율에 의한 이자 및 지연손해금"
          />
        </div>

        <hr />

        <p className="ttl">4) 신청사유를 선택해 주세요. <span>(복수선택가능)</span></p>
        <div className="answer-area border-bx item6">
          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('명시기일 불출석')}
              onChange={e => selectReason(e.target.checked, '명시기일 불출석')}
            />
            <span>명시기일 불출석</span>
          </label>

          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('재산목록 제출거부')}
              onChange={e => selectReason(e.target.checked, '재산목록 제출거부')}
            />
            <span>재산목록 제출거부</span>
          </label>

          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('선서 거부')}
              onChange={e => selectReason(e.target.checked, '선서 거부')}
            />
            <span>선서 거부</span>
          </label>

          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('거짓 재산목록 제출')}
              onChange={e => selectReason(e.target.checked, '거짓 재산목록 제출')}
            />
            <span>거짓 재산목록 제출</span>
          </label>

          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('집행채권의 만족을 얻기에 부족함')}
              onChange={e => selectReason(e.target.checked, '집행채권의 만족을 얻기에 부족함')}
            />
            <span>집행채권의 만족을 얻기에 부족함</span>
          </label>

          <label className="checkbox-item">
            <Checkbox 
              checked={reasonArray.includes('주소불명으로 인하여 명시절차를 거치지 못함')}
              onChange={e => selectReason(e.target.checked, '주소불명으로 인하여 명시절차를 거치지 못함')}
            />
            <span>주소불명으로 인하여 명시절차를 거치지 못함</span>
          </label>
        </div>

        <hr />

        <p className="ttl">5) 비용환급용 예금계좌를 입력해주세요.</p>
        <div className="answer-area item7">
          <FormControl className="select">
            <Select
              value={selectedBank ? selectedBank.displayName : ''}
              onChange={e => dispatch(refreshAccount({...account, bank: e.target.value}))}
            >
              {refundBankList.map((bank, index) => (
                <MenuItem key={`bank${index}`} value={bank.displayName}>{bank.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            value={account.name}
            onChange={e => dispatch(refreshAccount({...account, name: e.target.value}))}
            placeholder="예금주"
          />

          <TextField
            variant="outlined"
            value={account.no}
            onChange={e => dispatch(refreshAccount({...account, no: e.target.value}))}
            placeholder="계좌번호"
          />
        </div>

        <hr />

        <p className="ttl">6) 관할법원을 선택해 주세요.</p>
        <SelectCourt
          court={court}
          onSelectCourt={(court: any) => dispatch(refreshApplicationCourt(court))}
          />
      </div>
    </div>
  )
}
