import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { post } from '../../utils/Api';

import SignUpView from './SignUpView';
import FindIdView from './FindIdView';
import ResetPwView from './ResetPwView';

export default function SignInView(props) {

  const [id, setId] = useState('');
  const [pw, setPw] = useState('');

  const [showSignUp, setShowSignUp] = useState(false);
  const [showFindId, setShowFindId] = useState(false);
  const [showResetPw, setShowResetPw] = useState(false);

  const [isSaveEmail, setIsSaveEmail] = useState(false);

  useEffect(() => {
    if(sessionStorage.getItem('savedEmail')) {
      setId(sessionStorage.getItem('savedEmail'));
      setIsSaveEmail(true);
    }
  }, [])

  const signIn = () => {
    post('auth/signin', {
      email: id,
      pw: pw
    })
    .then(response => {
      if(response.data.success) {

        if(isSaveEmail) {
          sessionStorage.setItem('savedEmail', id);
        }
        else {
          sessionStorage.removeItem('savedEmail');
        }

        sessionStorage.setItem('isLogin', true);
        sessionStorage.setItem('user', JSON.stringify(response.data.data));
        props.onCompleteSignIn(response.data.data);
      }
      else {
        if(response.data.errorCode === 1) {
          alert('아이디 혹은 비밀번호가 맞지 않거나 가입되어있지 않습니다.')
        }
      }
    }).catch(err => console.log(err))
  }

  return (
    <div className="sign-in user user-modal">
      <div className="card">
        <Button className="clear-btn" onClick={e => props.onClose()}><ClearIcon /></Button>
        
        <div className="head-area">
          <span className="head">{props.title ? props.title : '로그인'}</span>
        </div>

        <div className="flex-row">
          <p className="ttl">ID</p>
          <TextField
            variant="outlined"
            value={id}
            onChange={e => setId(e.target.value)}
          />
        </div>

        <div className="flex-row">
          <p className="ttl">Password</p>
          <TextField
            variant="outlined"
            type="password"
            value={pw}
            onChange={e => setPw(e.target.value)}
        />
        </div>

        
        
        <div className="checkbox-item">
          <FormControlLabel control={<Checkbox checked={isSaveEmail} onChange={e => setIsSaveEmail(e.target.checked)} />} label="아이디 저장" />
        </div>

        <Button className="signin-btn contained" onClick={e => signIn()}>로그인</Button>

        <Button className="signup-btn" onClick={() => setShowSignUp(true)}>회원가입</Button>

        <div className="btn-group flex-center">
          <Button className="find-id-btn" onClick={() => setShowFindId(true)}>아이디 찾기</Button>

          <Button className="reset-pw-btn" onClick={() => setShowResetPw(true)}>비밀번호 재설정</Button>
        </div>
        {/* 비밀번호 비밀번호 확인 입력 후 확인 버튼 */}
      </div>

      {showSignUp && (
        <SignUpView
          onClose={() => setShowSignUp(false)}
          onCompleteSignUp={() => setShowSignUp(false)}
        />
      )}

      {showFindId && (
        <FindIdView
          onClose={() => setShowFindId(false)}
        />
      )}

      {showResetPw && (
        <ResetPwView
          onClose={() => setShowResetPw(false)}
        />
      )}
    </div>
  )
}