import SJDatePicker from './SJDatePicker';
import AddressPicker from './AddressPicker';
import { Radio, TextField, Button, Select, MenuItem, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { connect } from 'react-redux';

import { 
  refreshComplaint,
  refreshLossList,
  setLossHurt
} from '../../../store/modules/app';

import _, { bind } from 'lodash';
import SJDateTimePicker from './SJDateTimePicker';
import { useMemo, useState } from 'react';
import { useAppSelector } from '../../../store';
import { FormControl } from '@material-ui/core';
import { initLoss, initLossPrice } from '../../../model/Loss.model';

const HURT_TYPE = [
  {name: '상해', hint: '[가해방법, 치료기간, 상해결과]', contents: '피고가 오른 주먹으로 원고의 얼굴을 2회 때려 원고에게 약 3주간의 치료가 필요한 상구순부열창 등을 가하였습니다.'},
  {name: '폭행', hint: '[폭행방법, 폭행행위]', contents: '피고가 오른 주먹으로 원고의 얼굴을 2회 때려 폭행을 가하였습니다.'},
  {name: '교통사고(업무상과실치상)', hint: '[주의의무, 사고상황, 치료기간, 상해결과]', contents: '피고는 2021. 2. 29. 07:15경 서울 서초구 서초동에 있는 서울중앙지방법원 앞 편도 3차로의 도로 를 지하철 교대역 쪽에서 고속터미널 쪽으로 2차로를 따라 시속 약 70km로 진행하게 되었습니다. 피고는 전방주시의무를 게을리 한 과실로 횡단보도 좌측에서 우측으로 횡단하던 원고를 XX호XXXX 승용차 조수석 앞 범퍼로 들이받아 약 6주간의 치료가 필요한 대퇴부골절 등 상해를 입게 하였습 니다.'},
  {name: '강제추행', hint: '[추행행위]', contents: '피고는 ○○클럽 내에서 원고 옆으로 다가가 원고의 엉덩이를 수회 만져 강제로 추행하였습니다.'},
  {name: '명예훼손', hint: '[공연성, 사실적시행위]', contents: '피고는 ○○○에게 전화를 걸어 "△△△은 학교에서 왕따이다."라고 말하여 공연히 허위의 사실을 적시하여 원고의 명예를 훼손하였다.'},
  {name: '모욕', hint: '[공연성, 모욕행위]', contents: '피고는 리그오브레전드 게임 중 아무런 이유 없이 고소인에게 "대리충"이라는 채팅 메시지를 불특정 다수인이 볼 수 있도록 하여 공연히 모욕하였습니다.'},
  {name: '절도', hint: '[소유관계, 시가, 품목, 수량, 절취방법]', contents: '피고들은 합동하여 원고 소유인 시가 100만 원 상당 텔레비전, 시가 150만 원 상당 노트북, 시가 200만 원 상당 냉장고를 가지고 나가는 방법으로 절취하였습니다.'},
  {name: '강도', hint: '[폭행 ・ 협박, 반항억압, 강취행위]', contents: '피고는 원고의 얼굴을 2회 때리고 “소리치면 가만히 두지 않는다. 돈을 다 내 놓아라.”라고 말하면서 반항하지 못하게 한 후 원고로부터 현금 500,000원을 빼앗아 가서 강취하였습니다.'},
  {name: '사기', hint: '[의사 ・ 능력 없음, 기망행위, 재물교부]', contents: '피고는 원고로부터 돈을 빌리더라도 이를 갚을 의사나 능력이 없음에도 불구하고 원고에게 전화하 여 “개인적으로 급하게 쓸 일이 있으니 돈을 빌려주면 10일 내에 갚겠다.”는 취지로 거짓말하여 이 에 속은 원고로부터 차용금 명목으로 100만 원을 송금받아 편취하였습니다.'},
  {name: '공갈', hint: '[폭행 ・ 협상, 갈취행위]', contents: '피고는 원고에게 전화하여 “3,000만 원을 빌려주라. 그렇지 않으면 남편에게 우리 관계를 알리겠 다.”라는 취지로 그녀에게 겁을 주었습니다. 피고는 이에 겁을 먹은 원고로부터 2021. 2. 29. 지인 ○○○의 ○○은행 계좌로 3,000만 원을 송금받았습니다. 이로써 피고는 원고를 공갈하여 재물을 교부받았습니다.'},
  {name: '업무상횡령', hint: '[보관자신분, 횡령행위]', contents: '피고는 원고의 이사로서, ○○○으로부터 물품대금 1,000,000원을 수금하여 고소인을 위하여 업무상 보관하던 중, 개인적인 용도에 임의로 사용하여 업무상 횡령하였습니다.'},
  {name: '업무상배임', hint: '[업무종사자의 신분, 임무내용, 임무위배행위]', contents: '피고는 2010. 3. 1.부터 서울 서초구 서초동에 있는 △△은행 ▽▽지점 대리로 근무하면서 대출 담 당 업무에 종사하여 왔습니다. △△은행 내규에 의하면, 30,000,000원 이상의 대출을 함에 있어서는 채무자로부터 반드시 담보를 제공받아야 합니다. 그럼이도 피고는 2021. 2. 29. △△은행 ▽▽지점에서 위 내규를 준수할 업무상 임무에 위배하여 ○○○에게 무담보로 50,000,000원을 대출하여 그 회수를 어렵게 하였습니다. 이로써 피고는 ○○○에게 50,000,000원에 해당하는 재산상의 이득을 취득하게 하고 △△은행에 같은 액수에 해당하는 손해를 가하였습니다.'},
  {name: '손괴', hint: '[손괴행위]', contents: '피고는 원고 소유인 시가 1,000,000원 상당 휴대전화 1대를 바닥에 던져 깨뜨려 손괴하였습니다.'},
  {name: '기타', hint: '[상대방의 불법행위를 자유 입력]', contents: ''},
]

function LossBond(props) {

  let complaint = useAppSelector((state) => state.app.complaint);
  
  // const lossList = useMemo(() => {
  //   return complaint.lossList;
  // }, [complaint.lossList]);

  const [hurtType, setHurtType] = useState(HURT_TYPE[4]);

  const addBond = () => {
    let c = _.cloneDeep(complaint);
    c.lossList.push(initLoss())
    props.refreshComplaint(c);
  }

  const changeBond = (index, bond) => {
    let c = _.cloneDeep(complaint);
    c.lossList[index] = bond;
    props.refreshComplaint(c);
  }


  return (
    <div className="loss">
      <p className="head">손해배상</p>
      {complaint.lossList.map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          {index !== 0 && (
            <Button className="remove-btn" 
              onClick={e => {
                //props.removeBond(index)
                props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).filter((value, bIndex) => index !== bIndex)})
              }}
            ><RemoveIcon /></Button>
          )}
          <p className="ttl">1) 가해행위가 발생한 날짜가 언제인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.occurrenceDateType === 0}
                onChange={e => changeBond(index, {...bond, occurrenceDateType: 0})}
              />
              <SJDateTimePicker
                date={bond.occurrence1DateTime1}
                onChangeDate={date => changeBond(index, {...bond, occurrence1DateTime1: date})}
              />
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.occurrenceDateType === 1}
                onChange={e => changeBond(index, {...bond, occurrenceDateType: 1})}
              />
              <SJDatePicker
                date={bond.occurrence2Date1}
                onChangeDate={date => changeBond(index, {...bond, occurrence2Date1: date})}
              />
              <span>이나, 시각은 알지 못합니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.occurrenceDateType === 2}
                onChange={e => changeBond(index, {...bond, occurrenceDateType: 2})}
              />
              <SJDatePicker
                date={bond.occurrence3Date1}
                onChangeDate={date => changeBond(index, {...bond, occurrence3Date1: date})}
              />
              <span className="mr_10">부터</span>
              <SJDatePicker
                date={bond.occurrence3Date2}
                onChangeDate={date => changeBond(index, {...bond, occurrence3Date2: date})}
              />
              <span>까지입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.occurrenceDateType === 3}
                onChange={e => changeBond(index, {...bond, occurrenceDateType: 3})}
              />
              <SJDatePicker
                date={bond.occurrence4Date1}
                onChangeDate={date => changeBond(index, {...bond, occurrence4Date1: date})}
              />
              <span>부터 현재까지입니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">2) 가해행위가 발생한 장소가 언제인가요?</p>
          <div className="answer-area answer2">
            <label className="radio-item">
              <Radio
                checked={bond.occurrencePlaceType === 0}
                onChange={e => changeBond(index, {...bond, occurrencePlaceType: 0})}
              />
              <AddressPicker 
                address={bond.occurrence1Place1}
                onSelectAddress={address => changeBond(index, {...bond, occurrence1Place1: address.address})}
              />
              <TextField
                value={bond.occurrence1Place2}
                onChange={e => changeBond(index, {...bond, occurrence1Place2: e.target.value})}
                placeholder="상세주소"
              />
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.occurrencePlaceType === 1}
                onChange={e => changeBond(index, {...bond, occurrencePlaceType: 1})}
              />
              <TextField
                value={bond.occurrence2Place1}
                onChange={e => changeBond(index, {...bond, occurrence2Place1: e.target.value})}
                placeholder="한국대학교 본관 2층"
              />
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.occurrencePlaceType === 2}
                onChange={e => changeBond(index, {...bond, occurrencePlaceType: 2})}
              />
              <span>모릅니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">3) 가해행위 내용이 무엇인가요?</p>
          <div className="answer-area item3">
            <div className='flex-row'>
              <FormControl variant="outlined" className="select">
                <Select
                  id="demo-simple-select-outlined"
                  value={hurtType || ''}
                  onChange={e => setHurtType(e.target.value)}
                >
                  {HURT_TYPE.map((hurtType, hurtIndex) => (
                    <MenuItem value={hurtType} key={`hurt${index}${hurtIndex}`}>{hurtType.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span>{hurtType.hint}</span>
            </div>
            <div className="textarea-wrap">
              <TextField
                // variant="outlined"
                value={bond.hurt}
                onChange={e => changeBond(index, {...bond, hurt: e.target.value})}
                // value={bond.hurt}
                // onChange={e => props.refreshLossList(lossList.map((loss, lossIndex) => {
                //   if(lossIndex === index) loss.hurt = e.target.value;
                //   return loss;
                // }))}
                // className="input-price"
                placeholder={hurtType.contents || ''}
                multiline
                rows={4}
              />
            </div>
          </div>

          <hr />

          <p className="ttl">4) 원고에게 발생한 손해가 무엇인가요?</p>
          <div className="answer-area textarea-wrap">
            <TextField
              // variant="outlined"
              value={bond.result}
              onChange={e => changeBond(index, {...bond, result: e.target.value})}
              multiline
              rows={4}
              placeholder="원고는 ○○병원에 2주간 입원해 치료비를 ○○○원 지출하였고, 2주 동안 출근을 못 하여 임금을 받지 못하였으며, 원고 소유 시가 ○○○원 상당 휴대전화가 파손되었습니다."
            />
          </div>

          <hr />

          <div className="ttl-bx">
            <p className="ttl">5) 청구하는 손해배상금이 얼마인가요?</p>
            <Button className="add-btn btn-24" onClick={e => {
              if(bond.priceList.length < 13) {
                props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                  if(index === bIndex) {
                    value.priceList = bond.priceList.concat(initLossPrice('', ''))
                  }
                  return value
                })})
                //changeBond(index, {...bond, priceList: bond.priceList.concat(new LossPrice('', ''))})
              }
            }}><AddIcon /></Button>
          </div>
          
          <div className="answer-area item5">
            {bond.priceList.map((price, pIndex) => {
              if(pIndex < 7) {
                return (
                  <label className="checkbox-item" key={`price${index}${pIndex}`}>
                    <Checkbox
                      checked={price.isSelected}
                      onChange={e => {
                        props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                          if(index === bIndex) {
                            value.priceList = value.priceList.map((p, i) => {
                              if(i === pIndex) p.isSelected = e.target.checked
                              return p
                            })
                          }
                          return value
                        })})
                      }}
                    />
                    <span className="mr_10">{price.contents}: </span>
                    <TextField
                      // variant="outlined"
                      value={price.price === 0 ? '' : price.price}
                      onChange={e => {
                        props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                          if(index === bIndex) {
                            value.priceList = value.priceList.map((p, i) => {
                              if(i === pIndex) p.price = e.target.value ? parseInt(e.target.value) : 0;
                              return p
                            })
                          }
                          return value
                        })})
                      }}
                    className="input-price"
                    placeholder="1,000,000"
                    />
                    <span>원입니다.</span>
                  </label>
                )
              }
              
              return (
                <div className="flex-row" key={`price${index}${pIndex}`}>
                  <label className="checkbox-item">
                    <Checkbox
                      checked={price.isSelected}
                      onChange={e => {
                        props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                          if(index === bIndex) {
                            value.priceList = value.priceList.map((p, i) => {
                              if(i === pIndex) p.isSelected = e.target.checked
                              return p
                            })
                          }
                          return value
                        })})
                      }}
                    />
                    <TextField
                      variant="outlined"
                      value={price.type}
                      onChange={e => {
                        props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                          if(index === bIndex) {
                            value.priceList = value.priceList.map((p, i) => {
                              if(i === pIndex) p.type = e.target.value;
                              return p
                            })
                          }
                          return value
                        })})
                      }}
                    className="w140"
                    placeholder="진단서 발급비"
                    />
                    <span className="mr_10">: </span>
                    <TextField
                      value={price.price === 0 ? '' : price.price}
                      onChange={e => {
                        props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                          if(index === bIndex) {
                            value.priceList = value.priceList.map((p, i) => {
                              if(i === pIndex) p.price = e.target.value ? parseInt(e.target.value) : 0;
                              return p
                            })
                          }
                          return value
                        })})
                      }}
                    className="input-price"
                    placeholder="1,000,000"
                    />
                    <span>원입니다.</span>
                  </label>
                  <Button className="remove-btn btn-24" 
                    onClick={e => {
                      props.refreshComplaint({...complaint, lossList: _.cloneDeep(complaint.lossList).map((value, bIndex) => {
                        if(index === bIndex) {
                          value.priceList = value.priceList.filter((p, i) => pIndex !== i)
                        }
                        return value
                      })})
                      //changeBond(index, {...bond, priceList: bond.priceList.filter((p, i) => pIndex !== i)})
                    }}
                  ><RemoveIcon /></Button>
                </div>
              )
            })}
            
          </div>
        </div>
      ))}
      <Button className="contained" onClick={e => addBond()}>채권 추가</Button>
    </div>
  )
}


let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      refreshLossList: (lossList) => dispatch(refreshLossList(lossList)),
      setLossHurt: (data) => dispatch(setLossHurt(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LossBond)
