
function LossTitle(props) {
  return (
    <div className="loss-title">
      <p className={`${props.bold && 'bold'}`}>{props.title}</p>
      <p>{props.contents}</p>
    </div>
  )
}

export default LossTitle