import { TextField, Radio } from '@mui/material';

//redux
import { connect, useDispatch } from 'react-redux'
import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'

//etc
import { getName } from '../../../utils/utils'
import _ from 'lodash'
import SJDatePicker from './SJDatePicker';
import { useAppSelector } from '../../../store';

export default function Wage() {

  const dispatch = useDispatch()

  const complaint = useAppSelector((state) => state.app.complaint)
  const defendantList = useAppSelector(state => state.app.defendantList)

  return (
    <div className="wage">
      <p className="head">임금</p>
      {complaint.bondList.map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          <p className="ttl">1) 주채무자</p>
          <div className="answer-area">
            <span>{getName(defendantList[0])} 입니다.</span>
          </div>
          
          <hr />
    
          <p className="ttl">2) 미지급 임금 총액이 얼마인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              type="number"
              variant="outlined"
              value={bond.price === 0 ? '' : bond.price}
              onChange={e => {
                dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                  if(bond.id === value.id) {
                    value.price = e.target.value === '' ? 0 : parseInt(e.target.value)
                  }
                  return value
                })}))
              }}
              placeholder="1,000,000"
              className="input-price"
            />
            <span>원을 받지 못했습니다.</span>
          </div>
    
          <hr />
    
          <p className="ttl">3) 언제 입사하였나요?</p>
          <div className="answer-area flex-row">
            <span className="mr_10">입사한 날짜는</span>
            <SJDatePicker
              date={bond.joinDate}
              onChangeDate={(date: any) => {
                dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                  if(bond.id === value.id) {
                    value.joinDate = date
                  }
                  return value
                })}))
                //changeJoinDate(date, index)
              }}/>
            <span>입니다.</span>
          </div>
    
          <hr />
    
          <p className="ttl">4) 언제 퇴사하였나요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.isLeave}
                onChange={e => {
                  dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                    if(bond.id === value.id) {
                      value.isLeave = e.target.checked
                    }
                    return value
                  })}))
                }}
              />
              <span className="mr_10">퇴사한 날짜는</span>
              <SJDatePicker
                date={bond.leaveDate}
                onChangeDate={(date: any) => {
                  dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                    if(bond.id === value.id) {
                      value.leaveDate = date
                    }
                    return value
                  })}))
                  //changeLeaveDate(date, index)
                }}/>
              <span>입니다.</span>
            </label>
    
            <label className="radio-item">
              <Radio
                checked={!bond.isLeave}
                onChange={e => {
                  
                  console.log(e.target.checked)
                  dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                    if(bond.id === value.id) {
                      value.isLeave = !e.target.checked
                    }
                    return value
                  })}))
                }}
              />
              <span>재직 중입니다.</span>
            </label>
          </div>
        </div>
      ))}
    </div>
  )
}

// let mapStateToProps = (state, /*ownProps*/) => {
//   return {
//       complaint: state.app.complaint,
//   };
// };

// let mapDispatchToProps = (dispatch, /*ownProps*/) => {
//   return {
//       addDefendant: () => dispatch(addDefendant()),
//       removeDefendant: (index) => dispatch(removeDefendant(index)),
//       addBond: () => dispatch(addBond()),
//       removeBond: (index) => dispatch(removeBond(index)),
//       addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
//       removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
//       addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
//       removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
//       refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
//       setTextList: (textList) => dispatch(setTextList(textList))
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Wage);
