import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../Scss/Home.scss';
import { Button } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

import { get } from '../utils/Api'
import moment from 'moment';
import Youtube from 'react-youtube'
import { useAppSelector } from '../store';
import { addComma } from '../utils/utils';

const applicationList = [
  {name: '사실조회 신청서', img: '../../Image/type/application1.png'},
  {name: '재산조회 신청서', img: '../../Image/type/application2.png'},
  {name: '재산명시 신청서', img: '../../Image/type/application3.png'},
  {name: '부동산 강제경매 신청서', img: '../../Image/type/application4.png'},
  {name: '채무불이행자명부 등재 신청서', img: '../../Image/type/application5.png'},
  {name: '채권압류 및 추심명령 신청서', img: '../../Image/type/application6.png'},
]

export default function HomePage() {

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const configuration = useAppSelector(state => state.app.configuration)

  const [lawsuitTypeList] = useState([
    { txt: '대여금', img: '../../Image/type/type1.png' },
    { txt: '약정금', img: '../../Image/type/type2.png' },
    { txt: '임금', img: '../../Image/type/type3.png' },
    { txt: '양수금', img: '../../Image/type/type4.png' },
    { txt: '매매대금(동산)', img: '../../Image/type/type5.png' },
    { txt: '매매대금(부동산)', img: '../../Image/type/type6.png' },
    { txt: '용역대금', img: '../../Image/type/type7.png' },
    { txt: '임대차보증금', img: '../../Image/type/type8.png' },
    { txt: '매매대금반환', img: '../../Image/type/type9.png' },
    { txt: '용역대금반환', img: '../../Image/type/type10.png' },
    { txt: '손해배상', img: '../../Image/type/type11.png' }
  ]);

  const [noticeList, setNoticeList] = useState<any[]>([]);
  const [articleList, setArticleList] = useState<any[]>([]);
  const [faqList, setFaqList] = useState<any[]>([])

  const [videoList, setVideoList] = useState<any[]>([]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [youtube, setYoutube] = useState('');
  const [showVideoModal, setShowVideoModal] = useState(false);

  useEffect(() => {
    //props.onSelectComplaint('부동산 강제경매 신청서')
    //props.onSelectComplaint('재산조회 신청서')
    //props.onSelectComplaint('채권압류 및 추심명령 신청서')
    // props.onSelectComplaint('채무불이행자명부 등재 신청서');
    //props.onSelectComplaint('매매대금반환')
    
    getNoticeList();
    getArticleList();
    getVideoList();
    getFaqList();
  }, []);

  const getNoticeList = () => {
    get('notice')
    .then(response => {
      if(response.data.success) {
        setNoticeList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  const getArticleList = () => {
    get('article')
    .then(response => {
      if(response.data.success) {
        setArticleList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  const getVideoList = () => {
    get('video')
    .then(response => {
      if(response.data.success) {
        setVideoList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  const getFaqList = () => {
    get('qna')
    .then(response => {
      if(response.data.success) {
        setFaqList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  return(
    <div className="home">
      <div className="img-area flex-center">
        <div className="contents-bx ta-center">
          <p className="first-line flex-center">나홀로 소송 솔루션,<img src="../../Image/logo-white.png" alt="소장이지" />
          </p>
          <p>현직 변호사가 만든 소장 자동 완성 솔루션을 만나면</p>
          <p>혼자서도 쉽고, 빠르고, 빈틈없는 소송이 가능합니다.
          </p>
        </div>
      </div>

      <div className="lawsuit-type-area mw1280">
        <p className="ta-center">원하는 소송 종류를 선택하세요</p>
        <div className="lawsuit-type-group">
          {lawsuitTypeList.map((typeItem, index) => (
            <Button className="lawsuit-type-bx" key={`typeItem${index}`}
              onClick={e => {
                setSearchParams({
                  writeType: typeItem.txt
                })
                //props.onSelectComplaint(typeItem.txt)
              }}>
              <img src={typeItem.img} alt={typeItem.txt} />
              <p>{typeItem.txt}</p>
            </Button>
          ))}
        </div>
      </div>

      <div className="lawsuit-type-area mw1280">
        <p className="ta-center">원하는 신청서 종류를 선택하세요</p>
        <div className="lawsuit-type-group">
          {applicationList.map((application, index) => (
            <Button className="lawsuit-type-bx" key={`application${index}`}
              onClick={e => {
                setSearchParams({
                  writeType: application.name
                })
                //props.onSelectComplaint(application.name)
              }}>
              <img src={application.img} alt={application.name} />
              <p>{application.name}</p>
            </Button>
          ))}
        </div>
      </div>

      <div className="user-count-area ta-center">
        <p className="color-222">현재까지 총 <b className="color-6bbaff">{addComma(configuration.downloadCount)}</b>명이</p>
        <p className="color-222">소장이지 서비스를 사용하셨습니다.</p>
      </div>

      <div className="card-view-area">
        <div className="mw1280">
          <div className="top-bx">
            <div className="btn-group">
              <button type="button" onClick={(e) => setSelectedTabIndex(0)} className={selectedTabIndex === 0 ? 'selected' : ''}>공지사항</button>
              <button type="button" onClick={(e) => setSelectedTabIndex(1)} className={selectedTabIndex === 1 ? 'selected' : ''}>언론보도</button>
              <button type="button" onClick={(e) => setSelectedTabIndex(2)} className={selectedTabIndex === 2 ? 'selected' : ''}>FAQ</button>
            </div>
          </div>

          
          {selectedTabIndex === 0 &&(
            <div className="notice">
              <div className="txt-card-view-group flex">
                {noticeList.slice(0, 3).map((notice, index) => (
                  <div className="card-wrap" key={`notice${index}`} onClick={e => navigate(`/notice/${notice.id}`)}>
                    <div className="card">
                      <p className="color-6bbaff">공지사항</p>
                      <b className="ttl color-222">{notice.title}</b>
                      <p className="txt">{notice.contents}</p>
                      <p className="date">{moment(notice.created_at).format('YYYY-M-D')}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectedTabIndex === 1 &&(
            <div className="article">
              <div className="txt-card-view-group flex">
                {articleList.slice(0, 3).map((article, index) => (
                  <div className="card-wrap" key={`article${index}`} onClick={e => window.open(article.link, '_blank')}>
                    <div className="card">
                      <p className="color-6bbaff">언론보도</p>
                      <b className="ttl color-222">{article.title}</b>
                      <p className="txt">{article.contents}</p>
                      <p className="date">{moment(article.writing_date).format('YYYY-M-D')}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectedTabIndex === 2 &&(
            <div className="faq">
              <div className="txt-card-view-group flex"  onClick={e => navigate(`/faq`)}>
                {faqList.slice(0, 3).map((faq, index) => (
                  <div className="card-wrap" key={`faq${index}`}>
                    <div className="card">
                      <p className="color-6bbaff">FAQ</p>
                      <b className="ttl color-222">{faq.question}</b>
                      <p className="txt">{faq.answer}</p>
                      <p className="date"> </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

        </div>
      </div>

      <div className="video-area">
        <div className="mw1280">
          <div className="video-group flex wrap">
            {videoList.map((video, index) => (
              <div className="video-bx-wrap" key={`video${index}`} onClick={e => {
                setYoutube(video.link);
                setShowVideoModal(true);
              }}>
                <div className="video-bx flex">
                  <img src={`https://sjez.s3.ap-northeast-2.amazonaws.com/thumbnail/${video.image}`} alt={video.title} />
                  <div className="video-info-bx flex jc-sb">
                    <div className="txt-bx">
                      <p className="video-ttl">{video.title}</p>
                      <p className="video-txt">{video.contents}</p>
                    </div>
                    <button><PlayCircleOutlineIcon /></button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showVideoModal && (
        <div className="video-modal">
          <div className="cont">
            <Button onClick={e => setShowVideoModal(false)}><ClearIcon/></Button>
            <Youtube videoId={youtube}/>
          </div>
        </div>
      )}

    </div>
  )
}