
import { useAppSelector } from "../../../../store";

function RelationTable(props) {

  const relationList = useAppSelector((state) => state.app.application.relationList);

  return (
    <div className="relation-table">
      <p className="bold">이해관계인 일람표</p>
      <table>
        <thead>
          <tr>
            <th style={{width: '180px'}}>이해관계</th>
            <th style={{width: '70px'}}>성 명</th>
            <th style={{width: '60px'}}>우편번호</th>
            <th style={{width: '180px', maxWidth: '180px'}}>주 소</th>
          </tr>
        </thead>

        <tbody>
          {relationList.map((relation, index) => (
            <tr key={`relation${index}`}>
              <td>{relation.type}</td>
              <td>{relation.name}</td>
              <td>{relation.zoneCode}</td>
              <td>{relation.address} {relation.detailAddress}</td>
            </tr>
          ))}

        </tbody>
      </table>
    </div>
  )
}

export default RelationTable;