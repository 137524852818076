import './App.scss';
import { Route, BrowserRouter, Routes } from "react-router-dom";

import Main from './Page/Main';
import Introduce from './Page/Introduce';
import Expense from './Page/Expense';
import Notice from './Page/notice/Notice';
import NoticeDetail from './Page/notice/NoticeDetail';
import Article from './Page/article/Article';
import Faq from './Page/faq/Faq';
import MyInfoView from './Page/user/MyInfoView';
import PaymentListView from './Page/user/PaymentListView';
import OrderView from './Page/order/OrderView';
import CompleteOrderView from './Page/order/CompleteOrderView';
import HomePage from './Page/Home';
import { useEffect } from 'react';
import { ConfigurationApi } from './api/Configuration.api';
import { useDispatch } from 'react-redux';
import { setDeliveryCharge } from './utils/Const';
import { setConfiguration } from './store/modules/app';

export default function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    getConfiguration()
  }, [])

  const getConfiguration = () => {
    ConfigurationApi.getConfiguration()
    .then(result => {
      dispatch(setConfiguration(result))
      setDeliveryCharge(result.deliveryCharge)
    })
  }

  return (
    // <Router className="app">
    //   <Switch>
    //     <Route path="/" component={Main} />
    //   </Switch>
    // </Router>
    <BrowserRouter>
    <Routes>
      {/* <Route path="/login" element={<LoginPage />} />
      <Route path="/policy/:type" element={<PolicyPage />} /> */}

      <Route path="/" element={<Main />}>
        <Route path="" element={<HomePage />} />
        <Route path="introduce" element={<Introduce />} />
        <Route path="expense" element={<Expense />} />

        <Route path="notice" element={<Notice />} />
        <Route path="notice/:id" element={<NoticeDetail />} />

        <Route path="article" element={<Article />} />
        <Route path="faq" element={<Faq />} />

        <Route path="myinfo" element={<MyInfoView />} />
        <Route path="payment-list" element={<PaymentListView />} />

        <Route path="order" element={<OrderView />} />
        <Route path="order-complete" element={<CompleteOrderView />} />
        
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

/**
 * 
<Route path="/" exact>
<Home
  onSelectComplaint={onSelectComplaint}/>
</Route>
<GuardedRoute 
path="/myinfo"
component={() => <MyInfoView onCompleteDrop={() => logout()}/>}
redirectTo="/"
login={true}
/>
<GuardedRoute 
path="/payment-list"
component={PaymentListView}
redirectTo="/"
login={true}
/>
<GuardedRoute 
path="/order"
component={OrderView}
redirectTo="/"
login={true}
/>
<GuardedRoute 
path="/order-complete"
component={CompleteOrderView}
redirectTo="/"
login={true}
/>
 */
