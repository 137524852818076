import { combineReducers, PayloadAction } from "@reduxjs/toolkit";
import app, { AppState } from "./app";

export interface RootStates {
	app: AppState;
}


// const reducer = (state: RootStates, action: AnyAction): CombinedState<RootStates> => {
//   return combineReducers({
//     app
//   })(state, action)
// }

const reducer = combineReducers({
  app
})


export type ReducerType = ReturnType<typeof reducer>;

export default reducer;