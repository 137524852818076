import moment from "moment";
import { Payback } from "../../../model/complaint.model";
import { useAppSelector } from "../../../store";
import { addComma, getName } from "../../../utils/utils";


type PreviewPaybackTableProps = {
  paymentTableList: any[]
  didPaybackDateList: Payback[]
}

export default function PreviewPaybackTable(props: PreviewPaybackTableProps) {

  const complaint = useAppSelector((state) => state.app.complaint);

  return (
    <div className="payback-table" id="payback-table">
      <p>- 변제충당 내역</p>
      <table>
        <thead>
          <tr>
            <th rowSpan={2}>순번</th>
            <th rowSpan={2} style={{minWidth: '100px'}}>변제일</th>
            <th rowSpan={2}>변제자</th>
            <th rowSpan={2}>변제금액</th>
            <th colSpan={complaint.type !== '매매대금' ? 3 : 2}>변제충당 전 원리금</th>
            <th colSpan={complaint.type !== '매매대금' ? 3 : 2}>변제충당 후 원리금</th>
          </tr>
          {complaint.type !== '매매대금' && (
            <tr>
              <th>원금</th>
              <th>이자</th>
              <th>지연손해금</th>
              <th>원금</th>
              <th>이자</th>
              <th>지연손해금</th>
            </tr>
          )}
          {complaint.type === '매매대금' && (
            <tr>
              <th>원금</th>
              <th>지연손해금</th>
              <th>원금</th>
              <th>지연손해금</th>
            </tr>
          )}
        </thead>

        {complaint.type !== '매매대금' && [...props.didPaybackDateList].sort((a, b) => {
          if(moment(a.date).isBefore(moment(b.date))) return -1
          else if(moment(a.date).isAfter(moment(b.date))) return 1
          return 0
        }).map((didPaybackDate, index) => (
          <tbody key={`didPaybackDate${index}`}>
            <tr>
              <td rowSpan={5}>{index + 1}</td>
              <td rowSpan={5}>{moment(didPaybackDate.date).format('YYYY. M. D.')}</td>
              <td rowSpan={5}>{getName(didPaybackDate.defendant)}</td>
              <td rowSpan={5} style={{borderRight: 'none'}}>{addComma(didPaybackDate.price)}</td>
              
            </tr>
            {props.paymentTableList.map((value, index) => {
              console.log('paymentTableList', index, value)
              return value
            }).filter(p => p.didPaybackDateId === didPaybackDate.id).map((table, tableIndex) => (
              <tr key={`table${index}${tableIndex}`}>
                <td>{addComma(table.beforePrice)}</td>
                <td>{addComma(table.beforeInterestPrice)}</td>
                <td>{addComma(table.beforeDeferInterestPrice)}</td>
                <td>{addComma(table.afterPrice)}</td>
                <td>{addComma(table.afterInterestPrice)}</td>
                <td>{addComma(table.afterDeferInterestPrice)}</td>
              </tr>
            ))}
          </tbody> 
        ))}
        {complaint.type === '매매대금' && [...props.didPaybackDateList].sort((a, b) => {
          if(moment(a.date).isBefore(moment(b.date))) return -1
          else if(moment(a.date).isAfter(moment(b.date))) return 1
          return 0
        }).map((didPaybackDate, index) => (
          <tbody key={`didPaybackDate${index}`}>
            <tr>
              <td rowSpan={4}>{index + 1}</td>
              <td rowSpan={4}>{moment(didPaybackDate.date).format('YYYY. M. D.')}</td>
              <td rowSpan={4}>{getName(didPaybackDate.defendant)}</td>
              <td rowSpan={4} style={{borderRight: 'none'}}>{addComma(didPaybackDate.price)}</td>
              
            </tr>
            {props.paymentTableList.filter(p => p.didPaybackDateId === didPaybackDate.id).map((table, tableIndex) => (
              <tr key={`table${index}${tableIndex}`}>
                <td>{addComma(table.beforePrice)}</td>
                <td>{addComma(table.beforeDeferInterestPrice)}</td>
                <td>{addComma(table.afterPrice)}</td>
                <td>{addComma(table.afterDeferInterestPrice)}</td>
              </tr>
            ))}
          </tbody> 
        ))}
      </table>
    </div>
  )
}