import PreviewBondTable from "./PreviewBondTable";
import PreviewPaybackTable from "./PreviewPaybackTable";
import './Preview.scss';

import { getPhone, addComma } from "../../../utils/utils";
import { useEffect, useState } from "react";
import moment from "moment";
import LossPreview from "./preview/LossPreview";
import FactFindPreview from './preview/FactFindPreview';
import AssetFindPreview from './preview/AssetFindPreview';
import AssetDisplayPreview from './preview/AssetDisplayPreview'
import { useSelector, connect } from "react-redux";
import NameRegisterPreview from "./preview/NameRegisterPreview";
import AuctionPreview from "./preview/AuctionPreview";
import BondSeizurePreview from "./preview/BondSeizurePreview";
import RelationTable from "./preview/RelationTable";
import RealEstateTable from "./preview/RealEstateTable";
import { useAppSelector } from "../../../store";
import { Person } from "../../../store/modules/app";

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아'];

type ApplicationPreviewProps = {
  evidenceList: any[]
  normalAttachmentList: any[]
  attachmentList: any[]
}

export default function ApplicationPreview(props: ApplicationPreviewProps) {

  const complaint = useAppSelector(state => state.app.complaint)

  const applicationType = useAppSelector(state => state.app.application.type)

  const plaintiff = useAppSelector((state) => state.app.plaintiff);
  const defendantList = useAppSelector((state) => state.app.defendantList);
  const bankDebtorList = useAppSelector((state) => state.app.application.bankDebtorList);
  const cardDebtorList = useAppSelector((state) => state.app.application.cardDebtorList);
  const depositDebtorList = useAppSelector((state) => state.app.application.depositDebtorList);

  const thirdDebtorCount = bankDebtorList.length + cardDebtorList.length + depositDebtorList.length;

  const createdAt = useAppSelector((state) => state.app.application.createdAt);

  const court = useAppSelector((state) => state.app.application.court);
  const incident = useAppSelector((state) => state.app.application.incident);

  const [wongo, setWongo] = useState('');

  useEffect(() => {
    setWongo(applicationType === '사실조회 신청서' ? '원고' : '채권자')
  }, [applicationType])

  const getName = (person: Person) => {
    console.log('getname', person)
    let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : getSpacingWord(person?.name);
    if(!name || name === '') name = 'O O O';

    if(person?.type === 0 || person?.type === 1) {
      if(person?.isEmptyName === true) {
        name = '성명 불상';
      }
    }
    else {
      if(person?.isEmptyCompany === true) {
        name = '성명 불상';
      }
    }

    return name;
  }

  const getRRNumber = (person: Person) => {
    if(person.isEmptyRRNumber) return '';

    if(person.RRNumber1 === '' && person.RRNumber2 === '') {
      return '000000-0000000';
    }

    return `${person.RRNumber1}-${person.RRNumber2}`;
  }

  const getAddress = (person: Person) => {
    if(person.isEmptyAddress) return '주소 불상';

    return person.address !== '' ? `${person.displayAddress} ${person.detailAddress}` : '서울특별시 서초구 법원로3길 14';
  }

  const getPhone = (person: Person) => {

    let phone = '010-0000-0000';

    if(person.phone1 != '' && person.phone2 != '' && person.phone3 != '') {
      phone = `${person.phone1}-${person.phone2}-${person.phone3}`;
    }

    if(person.isEmptyPhone) {
      phone = '';
    }


  
    return phone;
  }

  const isEmptySpotName = (person: Person) => {
    if(person.type === 2 || person.type === 3) {
      if(person.spot !== '' || person.name !== '') {
        return false;
      }
    }

    return true;
  }

  const getSpacingWord = (word: string) => {
    return word.split('').join(' ')
  }

  return (
    <div>
      <div className="document">
        <h1 className="head" style={{letterSpacing: `2px`, wordSpacing: '2px'}}>{applicationType}</h1>
        <div className="inner-content">
          {applicationType === '사실조회 신청서' && (
            <FactFindPreview/>
          )}
          {applicationType === '재산조회 신청서' && (
            <AssetFindPreview/>
          )}
          {(applicationType !== '사실조회 신청서' && applicationType !== '재산조회 신청서') && (
            <div>
              <div className="plaintiff-area flex">
                {/* <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>원</p><p>고</p></div> */}
                <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>채</p><p>권</p><p>자</p></div>
                <div className="info-item">
                  <p>
                    <span>{getName(plaintiff)}</span>
                    <span>({getRRNumber(plaintiff)})</span>
                  </p>
                  {!isEmptySpotName(plaintiff) && (
                    <p><span>{plaintiff.spot} </span><span>{getSpacingWord(plaintiff.name)}</span></p>
                  )}
                  <p>{getAddress(plaintiff)}</p>
                  <p>{getPhone(plaintiff) || '010-0000-0000'}{plaintiff.email !== '' ? `, ${plaintiff.email}` : ''}</p>
                </div>
              </div>

              <div className="defendant-area flex">
                <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>채</p><p>무</p><p>자</p></div>
                <div className="info-group">
                  {defendantList.map((defendant, index) => (
                    <div className="info-item" key={`defendant${index}`}>
                      <p>
                        {defendantList.length !== 1 && (
                          <span>{index + 1}. </span>
                        )}
                        <span>{getName(defendant) !== '' ? getName(defendant) : 'O O O'}</span>
                        {!defendant.isEmptyRRNumber && <span>({getRRNumber(defendant)})</span>}
                      </p>
                      {!isEmptySpotName(defendant) && (
                        <p><span>{defendant.spot} </span><span style={{wordSpacing: '2px'}}>{getSpacingWord(defendant.name)}</span></p>
                      )}
                      <p>{getAddress(defendant)}</p>
                      {getPhone(defendant) && <p>{getPhone(defendant)}</p>}
                    </div>
                  ))}
                </div>
              </div>

              {applicationType === '채권압류 및 추심명령 신청서' && (
                <div className="defendant-area flex">
                  <div style={{display: 'flex', justifyContent: 'space-between', width: '60px', marginRight: '40px'}}><p>제3채무자</p></div>
                  <div className="info-group">
                    {bankDebtorList.map((bankDebtor, index) => (
                      <div className="info-item" key={`bankDebtor${index}`}>
                        <p>
                          {thirdDebtorCount !== 1 && (
                            <span>{index + 1}. </span>
                          )}
                          <span>{bankDebtor.bank?.company}</span>
                        </p>
                        <p>{bankDebtor.bank?.address} {bankDebtor.bank?.detailAddress}</p>
                        <p>{bankDebtor.bank?.name}</p>
                      </div>
                    ))}
                    {cardDebtorList.map((cardDebtor, index) => (
                      <div className="info-item" key={`cardDebtor${index}`}>
                        <p>
                          {thirdDebtorCount !== 1 && (
                            <span>{bankDebtorList.length + index + 1}. </span>
                          )}
                          <span>{cardDebtor.card?.company}</span>
                        </p>
                        <p>{cardDebtor.card?.address} {cardDebtor.card?.detailAddress}</p>
                        <p>{cardDebtor.card?.name}</p>
                      </div>
                    ))}
                    {depositDebtorList.map((depositDebtor, index) => (
                      <div className="info-item" key={`depositDebtor${index}`}>
                        <p>
                          {thirdDebtorCount !== 1 && (
                            <span>{bankDebtorList.length + cardDebtorList.length + index + 1}. </span>
                          )}
                          <span>{depositDebtor.name}</span>
                        </p>
                        <p>{depositDebtor.address} {depositDebtor.detailAddress}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {applicationType === '재산명시 신청서' && (
            <AssetDisplayPreview/>
          )}
          {applicationType === '채무불이행자명부 등재 신청서' && (
            <NameRegisterPreview/>
          )}
          {applicationType === '부동산 강제경매 신청서' && (
            <AuctionPreview/>
          )}
          {applicationType === '채권압류 및 추심명령 신청서' && (
            <BondSeizurePreview/>
          )}

          {applicationType !== '재산조회 신청서' && (
            <div>
              {(props.normalAttachmentList.length !== 0 || props.attachmentList.length !== 0) && (
                <div className="attachment-area">
                  <b className="ttl">첨 부 서 류</b>
                  {props.normalAttachmentList.map((attachment, index) => (
                    <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
                  ))}
                  {props.attachmentList.map((attachment, index) => (
                    <p key={`attachment${props.normalAttachmentList.length + index}`}>{props.normalAttachmentList.length + index + 1}. {attachment.name}</p>
                  ))}
                </div>
              )}

              <p className="date">{moment(createdAt).format('YYYY. M. D.')}</p>
              
              <p className="sign-app">
                <span>위 {wongo}</span>
                <span>{getName(plaintiff)}</span>
                <span>(인)</span>
              </p>

              <h1>{applicationType === '사실조회 신청서' ? incident.court : court} 귀중</h1>
            </div>
          )}

          {applicationType === '부동산 강제경매 신청서' && (
            <div>
              <RelationTable/>
              <RealEstateTable/>
            </div>
          )}
          

        </div>
      </div>
    </div>
  )
}
