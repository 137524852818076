import Step3Procedure from './Step3Procedure';
import Step3Court from './Step3Court';
import SJDatePicker from '../bond/SJDatePicker';

//etc
import _ from 'lodash'
import { useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { refreshComplaint } from '../../../store/modules/app';

export default function Step3(props: any) {

  const dispatch = useDispatch()

  let complaint = useAppSelector((state) => state.app.complaint)

  const onSelectDocumentType = (type: any) => {
    let c = _.cloneDeep(complaint);

    if(type === '지급명령') {
      if(c.type === '용역대금반환') {
        if(c.bondList[0].expressionType === 2) {
          return;
        }

        if(c.bondList[0].serviceType === 'delivery' && (c.bondList[0].contractTradingPriceClaimReason === 1 || c.bondList[0].contractTradingPriceClaimReason === 2) && c.bondList[0].dunningType === 4) {
          return;
        }
        else if(c.bondList[0].serviceType === 'complete' && c.bondList[0].contractTradingPriceClaimReason === 0 && c.bondList[0].dunningType === 4) {
          return;
        }
      }
      else if(c.type === '매매대금반환') {

        if(c.bondList[0].expressionType === 2) {
          return;
        }

        if((c.bondList[0].contractTradingPriceClaimReason === 1 || c.bondList[0].contractTradingPriceClaimReason === 2) && c.bondList[0].dunningType === 4) {
          return;
        }
      }
    }

    c.documentType = type;
    c.selectedCourt = null;
    dispatch(refreshComplaint(c))
  }

  return (
    <div className="step3">
      <p className="head">작성 할 문서 형태를 선택하세요.</p>
      <Step3Procedure
        onSelectDocumentType={onSelectDocumentType}/>
      <hr />
      
      <p className="head">작성일을 선택해 주세요.</p>
      <SJDatePicker 
        date={complaint.createdAt}
        onChangeDate={(date: any) => dispatch(refreshComplaint({...complaint, createdAt: date}))}
      />

      <hr />
      <p className="head">관할법원을 선택하세요.</p>
      <Step3Court
        onSelectCourt={(court: any) => dispatch(refreshComplaint({...complaint, selectedCourt: court}))}
      />
    </div>
  )
}
