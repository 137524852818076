import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment';
import { initRelation, initBillingAmount, Application, newApplication } from '../../model/application.model'
import { Bond, initBond, initJointGuarantor, initPayback, JointGuarantor, Payback } from '../../model/complaint.model';
import { Configuration } from '../../models/Configuration.model';

//import { Loss } from '../model/Loss.model';
//import { Person } from '../model/person.model';
import { calcAppropriationForPerformance } from '../../utils/utils';

type Loss = {
  occurrenceDateType: number
  occurrence1DateTime1: string
  occurrence2Date1: string
  occurrence3Date1: string
  occurrence3Date2: string
  occurrence4Date1: string

  //발생장소
  occurrencePlaceType: number
  occurrence1Place1: string
  occurrence1Place2: string
  occurrence2Place1: string

  //hurt = '상해'; //상해, 폭행, 교통사고, 강제추행, 명예훼손, 모욕, 절도, 강도, 사기, 공갈, 업무상횡령, 업무상배임, 손괴, 기타
  //hurtHint = '가해방법, 치료기간, 상해결과'
  hurt: string //가해행위 내용
  result: string //원고에게 발생한 손해 내용

  priceList: LossPrice[]
  
  //priceList[0].isSelected = true;
  //priceList[0].price = 1000000;

  price1: number
  price2: number
  price3: number
  price4: number
  price5: number
  price6: number
  price7: number
  price8List: any[]
}

type LossPrice = {
  isSelected: boolean
  type: string
  contents: string
  price: number
}

type Complaint = {
  type: string
  documentType: string
  tradingPriceType: string
  // plaintiff: Person
  // defendantList: Person[]
  bondList: Bond[],
  didPaybackDateList: Payback[],
  selectedCourt: any,
  courtList: any[],
  claimPrice: number
  recognition1: number
  recognition2: number
  deliveryCharge1: number
  deliveryCharge2: number,
  lossList: Loss[]
  comment?: any
  isShowComment?: any,
  paymentTableList: any[]

  virtualBondList: any[],

  createdAt: string
}

export type Person = {
  id: number
  type: number
  company: string
  spot: string
  name: string
  RRNumber1: string
  RRNumber2: string
  address: string
  displayAddress: string
  detailAddress: string
  phone: string
  phone1: string
  phone2: string
  phone3: string
  email: string
  isMerchant: boolean

  BNumber1: string
  BNumber2: string
  BNumber3: string

  isEmptyName: boolean
  isEmptyCompany: boolean
  isEmptyPhone: boolean
  isEmptyRRNumber: boolean
  isEmptyAddress: boolean
  isEmptyBNumber: boolean
}


const initPerson = (id: number): Person => {
  return {
    id: id,
    type: 0,
    company: '',
    spot: '',
    name: '',
    RRNumber1: '',
    RRNumber2: '',
    address: '',
    displayAddress: '',
    detailAddress: '',
    phone: '',
    phone1: '',
    phone2: '',
    phone3: '',
    email: '',
    isMerchant: false,
  
    BNumber1: '',
    BNumber2: '',
    BNumber3: '',
  
    isEmptyName: false,
    isEmptyCompany: false,
    isEmptyPhone: false,
    isEmptyRRNumber: false,
    isEmptyAddress: false,
    isEmptyBNumber: false
  }
}

const initLoss = (): Loss => {
  return {
    occurrenceDateType: 0,
    occurrence1DateTime1: '2021-01-01 12:00',
    //occurrence1Date1: '2021-05-13',
    //occurrence1Time1: '16:40',
    occurrence2Date1: '2021-01-01',
    occurrence3Date1: '2021-01-01',
    occurrence3Date2: '2021-01-31',
    occurrence4Date1: '2021-01-01',

    //발생장소
    occurrencePlaceType: 1,
    occurrence1Place1: '',
    occurrence1Place2: '',
    occurrence2Place1: '',

    //hurt: '상해', //상해, 폭행, 교통사고, 강제추행, 명예훼손, 모욕, 절도, 강도, 사기, 공갈, 업무상횡령, 업무상배임, 손괴, 기타
    //hurtHint: '가해방법, 치료기간, 상해결과'
    hurt: '', //가해행위 내용
    result: '', //원고에게 발생한 손해 내용

    priceList: [
      {isSelected: false, type: '기왕 치료비', contents: '지금까지 지출한 치료비', price: 0},
      {isSelected: false, type: '향후 치료비', contents: '앞으로 지출할 치료비', price: 0},
      {isSelected: false, type: '기왕 간병비', contents: '지금까지 지출한 간병비', price: 0},
      {isSelected: false, type: '향후 간병비', contents: '앞으로 지출할 간병비', price: 0},
      {isSelected: false, type: '일실수입', contents: '일을 못 해 입은 소득 상실액', price: 0},
      {isSelected: false, type: '물건훼손', contents: '물질적 재산상 손해액', price: 0},
      {isSelected: false, type: '위자료', contents: '위자료(정신적 손해액)', price: 0},
      {isSelected: false, type: '', contents: '', price: 0},
    ],

    //priceList[0].isSelected: true,
    //priceList[0].price: 1000000,

    price1: 0,
    price2: 0,
    price3: 0,
    price4: 0,
    price5: 0,
    price6: 0,
    price7: 0,
    price8List: [],
  }
}

export interface AppState {
  user?: any
  configuration: Configuration
  type: string
  isInit: boolean
  plaintiff: Person
  defendantList: Person[]
  complaint: Complaint
  application: Application
  textList: any[]
  claimPurpose: any
  claimCauseList: any[]
  claimPurposeList: any[]
  evidenceList: any[]
  attachmentList: any[]
  hurtTextList: any[]
  lossPriceTextList: any[][]
}

const initialState: AppState = {
  isInit: false,
    configuration: {downloadCount: 0, deliveryCharge: 0, refundBankList: []},
    type: '소장',
    plaintiff: initPerson(0),
    defendantList: [initPerson(0)],
    complaint: {
      type: '대여금',
      documentType: '소장',
      tradingPriceType: '동산',
      bondList: [initBond(0)],
      didPaybackDateList: [],
      selectedCourt: null,
      courtList: [],
      claimPrice: 0,
      recognition1: 0,
      recognition2: 0,
      deliveryCharge1: 0,
      deliveryCharge2: 0,
      paymentTableList: [],
      lossList: [initLoss()],
      virtualBondList: [],
      createdAt: moment().format('YYYY-MM-DD')
    },
    application: newApplication(),

    textList: [],
    claimPurpose: {
      firstList: [],
      secondList: [],
    },
    claimCauseList: [],
    claimPurposeList: [],
    evidenceList: [],
    attachmentList: [],
    hurtTextList: [],
    lossPriceTextList: [[]],
}

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setConfiguration: (state, action: PayloadAction<Configuration>) => {
      state.configuration = action.payload
    },
    changeComplaintType: (state, action) => {
      state.complaint.type = action.payload
    },
    addDefendant: (state, action: PayloadAction<undefined>) => {
      state.defendantList.push(initPerson(state.defendantList.length))
      
      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    removeDefendant: (state, action) => {
      state.defendantList = state.defendantList.filter((d, i) => i !== action.payload)

      state.complaint.didPaybackDateList = state.complaint.didPaybackDateList.map(didPaybackDate => {
        if(didPaybackDate.defendant && didPaybackDate.defendant.id === action.payload) {
          didPaybackDate.defendant = undefined
        }
        return didPaybackDate
      })

      state.complaint.bondList = state.complaint.bondList.map(bond => {
        bond.jointGuarantorList = bond.jointGuarantorList.map(jointGuarantor => {
          if(jointGuarantor.defendant && jointGuarantor.defendant.id === action.payload) {
            jointGuarantor.defendant = undefined
          }
          return jointGuarantor
        });

        return bond;
      })

      

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    refreshPlaintiff: (state, action: PayloadAction<Person>) => {
      state.plaintiff = action.payload
    },
    refreshDefendant: (state, action: PayloadAction<Person>) => {
      state.defendantList = state.defendantList.map((person, index) => {
        if(person.id === action.payload.id) return action.payload
        return person
      })
    },
    addBond: (state, action) => {
      // state.complaint.bondList.push({
      //   id: state.complaint.bondList.length,
      //   law: '',
      //   price: 10000,
      //   balance: 0,
      //   lendDate: '2019.01.01',

      //   //대여금
      //   //fix: 정함, condition: 조건, reminder: 독촉, none: 정하지않음
      //   //매매대금
      //   //fix, division, none
      //   willPaybackDateType: 'fix', 
        
      //   willPaybackDate: '2020.01.01',
      //   interestRate: 0.0,
      //   realInterestRate: 0.0,
      //   deferInterestRate: 10.0,
      //   realDeferInterestRate: 0.0,
      //   isInterestRate: false,
      //   interestPrice: 0,
      //   interestBalance: 0,
      //   isDeferInterestRate: true,
      //   deferInterestPrice: 0,
      //   deferInterestBalance: 0,
      //   delayPrice: 0,
      //   isMerchant: false,
      //   isRelativeWork: false, //원고의 업무와 관련된 채권(대여금)
      //   jointGuarantorList: [],
        
      //   //양수금
      //   assignmentDateType: 0, //채권양도 통지나 승낙
      //   assignmentDate1: '2019.01.01',
      //   assignmentDate2: '2019.01.01',
      //   assignmentDate3: '2019.01.01',
      //   assignmentDate: '2019.01.01',
      //   realAssignmentDate: '2019.02.01',
      //   assignmentName: '양도계약체결자', //양도계약체결자
      //   isRelativeAssignment1: false,
      //   isRelativeAssignment2: false,

      //   //대여금, 약정금
      //   fixDate: '2020.01.01',
      //   conditionDate: '2020.01.01',
      //   reminderDate: '2020.01.01',

      //   //임금
      //   joinDate: '',
      //   leaveDate: '',
      //   isLeave: false,

      //   //매매대금
      //   tradingPriceContractDate: '2020.01.01',
      //   //willPaybackDateType: 'none', //매매대금 지금 날짜 타입 fix, division, none
      //   //willPaybackDate: '2020-01-01', //단일 지급
      //   willPaybackDateList: [], // 분할 지급인 경우
      //   tradingPrice: 100000, //매매대금 총액
      //   stuffList: [{name: '사과', count: '100', unit: '개'}], //팔기로 한 물건, stuff(name, count, unit)
      //   willDeliveryDateType: 'fix', //물건을 인도하기로한 날짜 타입 fix, division, none
      //   willDeliveryDate: '2020.03.01', //고정 인도약정일인 경우
      //   //willDeliveryDateList: [{date: '2020-03-02', stuff: {name: '사과', count: 10, unit: '개'}}, {date: '2020-03-02', stuff: {name: '오렌지', count: 20, unit: '개'}}], // 분할 인도약정일인 경우, date, stuff(name, count, unit)
      //   willDeliveryDateList: [], // 분할 인도약정일인 경우, date, stuff(name, count, unit)
      //   didDeliveryDateType: 'all', //all, part, none
      //   didDeliveryDate: '2020.03.01', //물건을 인도한 날짜
      //   didPaybackDateList: [],

      //   //매매대금-부동산
      //   landList: [{address: '서울 서초구 서초중앙로 198', category: '대', area: '300'}], //토지
      //   buildingList: [{address: '', detail: ''}], //건물
      //   didDeliveryDate01: '', //소유권이전등기 경료일
      //   didDeliveryDate02: '', //소유권이전등기에 필요한 서류를 계속 제공하고 있는 날짜

      //   //용역대금
      //   serviceTitle: '',
      //   serviceType: 'complete',
        

      //   //임대차보증금
      //   isChangedMaster: false,
      //   changedMasterDate: '',
      //   changedMasterName: '',
      //   depositPriceType: 'fix',
      //   depositPrice: 100000,
      //   prevDepositPrice: 0,
      //   nextDepositPrice: 0,
      //   didDepositDate: '', //보증금을 모두 지급한 날짜
      //   rentType: 'none',
      //   monthRentPrice: 0,
      //   yearRentPrice: 0,
      //   contractDateType: 'none',
      //   startContractDate: '',
      //   endContractDate: '',
      //   delayStartContractDate:  '',
      //   delayEndContractDate: '',
      //   delayFinalContractDate: '',

      //   //계약종료
      //   contractExitReason: 'midway', //expiration: 만료,  midway: 해지
      //   cancelContractDate: '',

      //   //공제
      //   isDeduction: true,
      //   isDeductionDeposit: false,
      //   deductionDeposit: 0,
      //   isDeductionRent: false,
      //   deductionRent: 0,
      //   isDeductionFix: false,
      //   deductionFix: 0,
      //   isDeductionEtc: false,
      //   deductionEtcReason: '기타',
      //   deductionEtc: 0,

      //   //임대차 목적
      //   depositPurpose: 'home',
      // });

      state.complaint.bondList.push(initBond(state.complaint.bondList.length))
      
      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    removeBond: (state, action) => {
      state.complaint.bondList = state.complaint.bondList.filter((b, i) => i !== action.payload)

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
    },
    addDidPaybackDate: (state, action: PayloadAction<boolean>) => {
      state.complaint.didPaybackDateList.push(initPayback(state.complaint.didPaybackDateList.length, action.payload))

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    removeDidPaybackDate: (state, action) => {
      state.complaint.didPaybackDateList = state.complaint.didPaybackDateList.filter((p, i) => i !== action.payload);

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
    },
    addJointGuarantor: (state, action) => {
      state.complaint.bondList[action.payload].jointGuarantorList.push(initJointGuarantor());

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    removeJointGuarantor: (state, action) => {
      const bondIndex = action.payload.bondIndex;
      const index = action.payload.index;
      state.complaint.bondList[bondIndex].jointGuarantorList = state.complaint.bondList[bondIndex].jointGuarantorList.filter((j, i) => i !== index);

      const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);
      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    refreshComplaint: (state, action: PayloadAction<Complaint>) => {
      const result = calcAppropriationForPerformance(action.payload, state.plaintiff, state.defendantList);

      console.log('result', result)

      if(result.complaint.type === '손해배상') {
        state.hurtTextList = result.hurtTextList ?? [];
        state.lossPriceTextList = result.lossPriceTextList ?? [];
      }

      state.complaint = result.complaint;
      state.textList = result.textList;
      state.claimCauseList = result.claimCauseList;
      state.claimPurpose = result.claimPurpose;
      state.plaintiff = result.plaintiff;
      state.defendantList = result.defendantList ?? [];
    },
    refreshLossList: (state, action) => {
      state.complaint.lossList = action.payload;

      // const result = calcAppropriationForPerformance(state.complaint, state.plaintiff, state.defendantList);

      // if(result.complaint.type === '손해배상') {
      //   state.hurtTextList = result.hurtTextList;
      //   state.lossPriceTextList = result.lossPriceTextList;
      // }

      // state.complaint = result.complaint;
      // state.textList = result.textList;
      // state.claimCauseList = result.claimCauseList;
      // state.claimPurpose = result.claimPurpose;
    },
    setLossHurt: (state, action) => {
      
      state.complaint.lossList = state.complaint.lossList?.map((l, i) => {
        if(i === action.payload.index) {
          l.result = action.payload.result;
        }
        return l
      });
    },
    setTextList: (state, action) => {
      state.textList = action.payload
    },
    setClaimPurposeList: (state, action) => {
      state.claimPurposeList = action.payload;
    },
    setClaimCauseList: (state, action) => {
      state.claimCauseList = action.payload;
    },
    setAttachmentList: (state, action) => {
      state.attachmentList = action.payload;
    },
    addAttachment: (state, action) => {
      state.attachmentList.push({
        
      })
    },
    removeAttachment: (state, action) => {
      state.attachmentList = state.attachmentList.filter((item, i) => i !== action.payload);
    },
    setEvidenceList: (state, action) => {
      state.evidenceList = action.payload;
    },
    addEvidence: (state, action) => {
      state.evidenceList.push({
        no: `갑 제${state.evidenceList.length + 1}호증`,
        name: '',
      })
    },
    removeEvidence: (state, action) => {
      state.evidenceList = state.evidenceList.filter((item, i) => i !== action.payload);
    },
    initApplication: (state, action: PayloadAction<string>) => {

      state.type = '신청서';

      let application = newApplication();
      application.type = action.payload;

      state.application = application;

      state.isInit = true
    },
    init: (state, action) => {
      
      let tradingPriceType = '';
      let type = action.payload;

      if(action.payload.includes('반환')) {

      }
      else if(action.payload.includes('매매대금')) {
        type = '매매대금';
        if(action.payload.includes('부동산')) {
          tradingPriceType = '부동산'
        }
        else if(action.payload.includes('동산')) {
          tradingPriceType = '동산'
        }
      }

      state.complaint.type = type
      state.complaint.tradingPriceType = tradingPriceType

      state.isInit = true
    
    
    },
    clear: (state, action: PayloadAction<undefined>) => {
      state.isInit = false
      state.complaint = {
        type: '대여금',
        documentType: '소장',
        tradingPriceType: '동산',
        bondList: [initBond(0)],
        didPaybackDateList: [],
        selectedCourt: null,
        courtList: [],
        claimPrice: 0,
        recognition1: 0,
        recognition2: 0,
        deliveryCharge1: 0,
        deliveryCharge2: 0,
        paymentTableList: [],
        lossList: [initLoss()],
        virtualBondList: [],
        createdAt: moment().format('YYYY-MM-DD'),
      }
      state.type = '소장'
      state.plaintiff = initPerson(0)
      state.defendantList = [initPerson(0)]
      state.application = newApplication()
      state.textList = []
      state.claimPurpose = {
        firstList: [],
        secondList: [],
      }
      state.claimCauseList = []
      state.claimPurposeList = []
      state.evidenceList = []
      state.attachmentList = []
      state.hurtTextList = []
      state.lossPriceTextList = []
    },
    refreshComment: (state, action) => {
      state.complaint.comment = action.payload;
    },
    refreshIsShowComment: (state, action) => {
      state.complaint.isShowComment = action.payload;
    },
    refreshApplication: (state, action) => {
      state.application = action.payload;
    },
    refreshIncident: (state, action) => {
      state.application.incident = action.payload;
    },
    // refreshApplicationPlantiff: (state, action) => {
    //   state.application.plaintiff = action.payload;
    // },
    // addApplicationDefendant: (state, action) => {
    //   state.application.defendantList = state.application.defendantList.concat(initPerson(state.application.defendantList.length));
    // },
    // removeApplicationDefendant: (state, action) => {
    //   const newArray = [...state.application.defendantList];
    //   newArray.splice(action.payload, 1);
    //   state.application.defendantList = newArray;
    // },
    // refreshApplicationDefendant: (state, action) => {
    //   const newArray = [...state.application.defendantList];
    //   newArray[action.payload.index] = action.payload.defendant;
    //   state.application.defendantList = newArray;
    // },
    addRelation: (state, action) => {
      state.application.relationList = state.application.relationList.concat(initRelation(action.payload));
    },
    removeRelation: (state, action) => {
      const newArray = [...state.application.relationList];
      newArray.splice(action.payload, 1);
      state.application.relationList = newArray;
    },
    refreshRelation: (state, action) => {
      const newArray = [...state.application.relationList];
      newArray[action.payload.index] = action.payload.relation;
      state.application.relationList = newArray;
    },
    addBillingAmount: (state, action) => {
      state.application.billingAmountList = state.application.billingAmountList.concat(initBillingAmount());
    },
    removeBillingAmount: (state, action) => {
      const newArray = [...state.application.billingAmountList];
      newArray.splice(action.payload, 1);
      state.application.billingAmountList = newArray;
    },
    refreshBillingAmountList: (state, action) => {
      const newArray = [...state.application.billingAmountList];
      newArray[action.payload.index] = action.payload.billingAmount;
      state.application.billingAmountList = newArray;
    },
    refreshDeptText: (state, action) => {
      state.application.deptText = action.payload;
    },
    refreshApplicationCourt: (state, action) => {
      state.application.court = action.payload;
    },
    refreshExecution: (state, action) => {
      state.application.execution = action.payload;
    },
    refreshBillingAmount: (state, action) => {
      state.application.billingAmount = action.payload;
    },
    refreshThirdDebtorInfo: (state, action) => {
      state.application.thirdDebtor = action.payload;
    },
    addBankDebtor: (state, action) => {
      state.application.bankDebtorList = state.application.bankDebtorList.concat({
        bank: null,
        price: 0
      });
    },
    removeBankDebtor: (state, action) => {
      const newArray = [...state.application.bankDebtorList];
      newArray.splice(action.payload, 1);
      state.application.bankDebtorList = newArray;
    },
    refreshBankDebtor: (state, action) => {
      const newArray = [...state.application.bankDebtorList];
      newArray[action.payload.index] = action.payload.bankDebtor;
      state.application.bankDebtorList = newArray;
    },
    addCardDebtor: (state, action) => {
      state.application.cardDebtorList = state.application.cardDebtorList.concat({
        card: null,
        price: 0
      });
    },
    removeCardDebtor: (state, action) => {
      const newArray = [...state.application.cardDebtorList];
      newArray.splice(action.payload, 1);
      state.application.cardDebtorList = newArray;
    },
    refreshCardDebtor: (state, action) => {
      const newArray = [...state.application.cardDebtorList];
      newArray[action.payload.index] = action.payload.cardDebtor;
      state.application.cardDebtorList = newArray;
    },
    addDepositDebtor: (state, action) => {
      state.application.depositDebtorList = state.application.depositDebtorList.concat({
        name: '',
        address: '',
        detailAddress: '',
        contractDate: '2021-01-01',
        dontKnowContractDate: '',
        price: 0,
      });
    },
    removeDepositDebtor: (state, action) => {
      const newArray = [...state.application.depositDebtorList];
      newArray.splice(action.payload, 1);
      state.application.depositDebtorList = newArray;
    },
    refreshDepositDebtor: (state, action) => {
      const newArray = [...state.application.depositDebtorList];
      newArray[action.payload.index] = action.payload.depositDebtor;
      state.application.depositDebtorList = newArray;
    },
    refreshDecision: (state, action) => {
      state.application.decision = action.payload;
    },
    refreshAssetFindReasonArray: (state, action) => {
      state.application.assetFindReasonArray = action.payload;
    },
    refreshAccount: (state, action) => {
      state.application.account = action.payload;
    },
    refreshTelecomList: (state, action) => {
      state.application.telecomList = action.payload;
    },
    refreshTelecom: (state, action) => {
      state.application.telecom = action.payload;
    },
    refreshApplicationLandList: (state, action) => {
      state.application.landList = action.payload;
    },
    refreshApplicationBuildingList: (state, action) => {
      state.application.buildingList = action.payload;
    },
    refreshApplicationSetBuildingList: (state, action) => {
      state.application.setBuildingList = action.payload;
    },
    refreshApplicationCreatedAt: (state, action) => {
      state.application.createdAt = action.payload;
    },
    changeUser: (state, action) => {
      state.user = action.payload;
    },
  }
})

export const { 
  setConfiguration,
  refreshComplaint, addDefendant, removeDefendant, addBond, removeBond,
  addDidPaybackDate, removeDidPaybackDate, addJointGuarantor, removeJointGuarantor, setTextList,
  setClaimCauseList, setClaimPurposeList,
  setEvidenceList, addEvidence, removeEvidence,
  setAttachmentList, addAttachment, removeAttachment,
  init,
  refreshLossList,
  setLossHurt,
  refreshComment, refreshIsShowComment,

  //신청서
  initApplication, refreshApplication, 
  refreshIncident, 
  //refreshApplicationPlantiff, refreshApplicationDefendant, addApplicationDefendant, removeApplicationDefendant,
  refreshApplicationCourt,
  refreshExecution, refreshDeptText,
  addRelation, removeRelation, refreshRelation,
  addBillingAmount, removeBillingAmount, refreshBillingAmount, refreshBillingAmountList,
  refreshThirdDebtorInfo, refreshBankDebtor, refreshCardDebtor, refreshDepositDebtor,
  addBankDebtor, removeBankDebtor, addCardDebtor, removeCardDebtor, addDepositDebtor, removeDepositDebtor,
  refreshDecision,
  refreshAssetFindReasonArray, refreshAccount,
  refreshTelecomList, refreshTelecom,
  refreshApplicationLandList, refreshApplicationBuildingList, refreshApplicationSetBuildingList,
  refreshApplicationCreatedAt,

  refreshPlaintiff, refreshDefendant,
  changeUser, clear
} = slice.actions;
export default slice.reducer;