import LendPrice from '../bond/LendPrice.js';
import Wage from '../bond/Wage';
import AssignmentPrice from '../bond/AssignmentPrice';
import ServicePrice from '../bond/ServicePrice';
import TradingPrice from '../bond/TradingPrice';

import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//redux
import { connect, useDispatch, useSelector } from 'react-redux'
import { 
  refreshComplaint, refreshIsShowComment, refreshComment,
  refreshApplication, refreshApplicationCreatedAt
} from '../../../store/modules/app'

//etc
import _ from 'lodash'
import Deposit from '../bond/Deposit';
import ContractTradingPrice from '../bond/ContractTradingPrice';
import ContractServicePrice from '../bond/ContractServicePrice';
import { useEffect, useState } from 'react';
import Loss from '../bond/Loss';
import AssetDisplay from '../../application/AssetDisplay';
import AssetFind from '../../application/AssetFind';
import FactFind from '../../application/FactFind';
import Auction from '../../application/Auction';
import BondSeizure from '../../application/BondSeizure';
import NameRegister from '../../application/NameRegister';
import SJDatePicker from '../bond/SJDatePicker';
import { useAppSelector } from '../../../store';


export default function Step2() {

  const dispatch = useDispatch()

  const [showCommentInput, setShowCommentInput] = useState(false);

  const documentType = useAppSelector((state) => state.app.type);

  const type = useAppSelector((state) => state.app.type === '소장' ? state.app.complaint.type : state.app.application.type)
  let complaint = useAppSelector((state) => state.app.complaint)

  const isShowComment = useAppSelector((state) => state.app.complaint.isShowComment);
  const comment = useAppSelector((state) => state.app.complaint.comment);

  const createdAt = useAppSelector((state) => state.app.application.createdAt);

  return (
    <div className="step2">
      {(type === '대여금' || type === '약정금') && (<LendPrice/>)}
      {type === '양수금' && (<AssignmentPrice/>)}
      {type === '임금' && (<Wage/>)}
      {type === '용역대금' && (<ServicePrice/>)}
      {type === '매매대금' && (<TradingPrice/>)}
      {type === '임대차보증금' && (<Deposit/>)}
      {type === '매매대금반환' && (<ContractTradingPrice/>)}
      {type === '용역대금반환' && (<ContractServicePrice/>)}
      {type === '손해배상' && (<Loss/>)}
      {(type === '재산명시 신청서') && (<AssetDisplay/>)}
      {type === '채무불이행자명부 등재 신청서' && (<NameRegister/>)}
      
      {type === '사실조회 신청서' && (<FactFind/>)}
      {type === '부동산 강제경매 신청서' && (<Auction/>)}
      {type === '재산조회 신청서' && (<AssetFind/>)}
      {type === '채권압류 및 추심명령 신청서' && (<BondSeizure />)}

      {documentType === '소장' && (
        <div className="comment-area">
          <div className="head-bx">
            <p className="head">추가로 설명할 사실관계가 있나요?</p>
            {!isShowComment && <Button className="add-btn" onClick={e => dispatch(refreshComplaint({..._.cloneDeep(complaint), isShowComment: true}))}><AddIcon /></Button>}
            {isShowComment && <Button className="remove-btn" onClick={e => dispatch(refreshComplaint({..._.cloneDeep(complaint), isShowComment: false}))}><RemoveIcon /></Button>}
          </div>

          {isShowComment && (
            <div className="cont-bx">
              <div className="textarea-wrap">
                <TextField
                  value={comment}
                  //onChange={e => props.refreshComment(e.target.value)}
                  onChange={e => dispatch(refreshComplaint({..._.cloneDeep(complaint), comment: e.target.value}))}
                  multiline
                  rows={4}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {documentType === '신청서' && (
        <div>
          <p className="head">작성일을 선택해 주세요.</p>
          <SJDatePicker 
            date={createdAt}
            onChangeDate={(date: any) => dispatch(refreshApplicationCreatedAt(date))}
          />
        </div>
      )}
    </div>
  )
}
