import { useCallback } from 'react';
import { connect } from 'react-redux';

import { 
  refreshApplicationCourt, refreshExecution, refreshDeptText, refreshIncident,
  addBillingAmount, removeBillingAmount, refreshBillingAmount, refreshBillingAmountList,
  refreshBankDebtor, addBankDebtor, removeBankDebtor,
  refreshCardDebtor, addCardDebtor, removeCardDebtor,
  refreshDepositDebtor, addDepositDebtor, removeDepositDebtor,
  refreshDecision
 } from '../../store/modules/app';

import { Button, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, Radio, Select, MenuItem, Checkbox, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import SJDatePicker from '../components/bond/SJDatePicker';
import AddressPicker from '../components/bond/AddressPicker';
import Execution from '../components/application/Execution';
import Incident from '../components/application/Incident';
import SelectCourt from '../components/application/SelectCourt';
import { addComma } from '../../utils/utils';
import { useAppSelector } from '../../store';



function BondSeizure(props) {

  const Bank = [ "KB국민", "IBK기업", "NH농협", "신한", "시티", "SC제일", "우리", "카카오뱅크", "케이뱅크", "하나", "경남", "광주", "대구", "부산", "KDB산업", "수협", "우체국", "전북", "제주", "새마을금고", "신협", "SBI저축", "저축", "미래에셋대우", "삼성", "SK", "유안타", "유진투자", "한국투자" ]

  const billingAmount = useAppSelector((state) => state.app.application.billingAmount);
  const billingAmountList = useAppSelector((state) => state.app.application.billingAmountList);
  const execution = useAppSelector((state) => state.app.application.execution);
  const court = useAppSelector((state) => state.app.application.court);

  const thirdDebtor = useAppSelector((state) => state.app.application.thirdDebtor);
  const bankDebtorList = useAppSelector((state) => state.app.application.bankDebtorList);
  const cardDebtorList = useAppSelector((state) => state.app.application.cardDebtorList);
  const depositDebtorList = useAppSelector((state) => state.app.application.depositDebtorList);
  const decision = useAppSelector((state) => state.app.application.decision);

  const onExecutionData = useCallback(execution => {
    props.refreshExecution(execution);
  }, []);

  const onIncidentData = useCallback(incident => {
    props.refreshDecision({...decision, incident: incident});

  }, [decision])

  const getBillingAmountText = () => {
    if(billingAmountList.length === 0) return '0';

    return `${addComma(billingAmountList[billingAmountList.length -1].price)}`
  }

  
  return (
    <div className="bond-seizure">
      <p className="head">채권압류 및 추심명령 신청서</p>

      <div className="cont-bx">
        <div className="ttl-bx">
          <p className="ttl">1) 청구금액이 얼마인가요?</p>
          <Button className="add-btn btn-24" onClick={e => props.addBillingAmount()}><AddIcon/></Button>
        </div>
        <div className="answer-area">
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>원금(원)</TableCell>
                    <TableCell>시작일</TableCell>
                    <TableCell>종료일</TableCell>
                    <TableCell>이율(연%)</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {billingAmountList.map((billingAmount, index) => (
                    <TableRow hover tabIndex={-1} key={`billing${index}`}>
                      <TableCell>
                        <TextField
                          value={billingAmount.price === 0 ? '' : billingAmount.price}
                          onChange={e => props.refreshBillingAmountList({index, billingAmount: {...billingAmount, price: parseInt(e.target.value) || 0}})}
                        />
                      </TableCell>
                      <TableCell>
                        <SJDatePicker 
                          date={billingAmount.startDate}
                          onChangeDate={date => props.refreshBillingAmountList({index, billingAmount: {...billingAmount, startDate: date}})}
                        />
                      </TableCell>
                      <TableCell>
                        <SJDatePicker 
                          date={billingAmount.endDate}
                          onChangeDate={date => props.refreshBillingAmountList({index, billingAmount: {...billingAmount, endDate: date}})}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={billingAmount.rate}
                          onChange={e => props.refreshBillingAmountList({index, billingAmount: {...billingAmount, rate: e.target.value}})}
                          className="percentage"
                        />
                      </TableCell>
                      <TableCell>
                        <Button className="btn-24" onClick={e => props.removeBillingAmount(index)}><RemoveIcon /></Button>
                      </TableCell>
                    </TableRow> 
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <label className="radio-item">
            <Radio
              checked={billingAmount.type === 'all'}
              onChange={e => props.refreshBillingAmount({...billingAmount, type: 'all'})}
            />
            <span>{getBillingAmountText()}원 전부를 청구합니다.</span>
          </label>
          <label className="radio-item">
            <Radio
              checked={billingAmount.type === 'part'}
              onChange={e => props.refreshBillingAmount({...billingAmount, type: 'part'})}
            />
            <span className="mr_10">{getBillingAmountText()}원 중 일부인</span>
            <TextField
              value={billingAmount.price === 0 ? '' : billingAmount.price}
              onChange={e => props.refreshBillingAmount({...billingAmount, price: parseInt(e.target.value) || 0})}
              className="input-price"
            />
            <span>원을 청구합니다.</span>
          </label>
        </div>

        <hr />

        <p className="ttl">2) 제3채무자 및 압류할 금액을 선택해 주세요.</p>
        <div className="answer-area item4">
          <div className="flex-row">
            <span>예금</span>
            <Button className="add-btn btn-24" onClick={e => props.addBankDebtor()}><AddIcon /></Button>
          </div>
          {bankDebtorList.map((bankDebtor, index) => (
            <div key={`bankDebtor${index}`}>
              <FormControl className='select'>
                <Select 
                  // id="demo-simple-select-outlined"
                  value={bankDebtor.bank?.company || ''}
                  onChange={e => props.refreshBankDebtor({index, bankDebtor: {...bankDebtor, bank: thirdDebtor.bankList[thirdDebtor.bankList.map(b => b.company).indexOf(e.target.value)]}})}
                >
                  {thirdDebtor.bankList.map((bank, index) => (
                    <MenuItem value={bank.company} key={`bank${index}`}>{bank.company}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={bankDebtor.price === 0 ? '' : bankDebtor.price}
                onChange={e => props.refreshBankDebtor({index, bankDebtor: {...bankDebtor, price: parseInt(e.target.value) || 0}})}
                type="number"
                className="input-price"
              />
              <span>원</span>
              <Button className="remove-btn btn-24" onClick={e => props.removeBankDebtor(index)}><RemoveIcon /></Button>
            </div>
          ))}
        </div>

        <div className="answer-area item4">
          <div className="flex-row">
            <span>카드</span>
            <Button className="add-btn btn-24" onClick={e => props.addCardDebtor()}><AddIcon /></Button>
          </div>
          {cardDebtorList.map((cardDebtor, index) => (
            <div key={`bankDebtor${index}`}>
              <FormControl className='select'>
                <Select
                  // id="demo-simple-select-outlined"
                  value={cardDebtor.card?.company || ''}
                  onChange={e => props.refreshCardDebtor({index, cardDebtor: {...cardDebtor, card: thirdDebtor.cardList[thirdDebtor.cardList.map(c => c.company).indexOf(e.target.value)]}})}
                >
                  {thirdDebtor.cardList.map((card, index) => (
                    <MenuItem value={card.company} key={`card${index}`}>{card.company}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                value={cardDebtor.price === 0 ? '' : cardDebtor.price}
                onChange={e => props.refreshCardDebtor({index, cardDebtor: {...cardDebtor, price: parseInt(e.target.value) || 0}})}
                type="number"
                className="input-price"
              />
              <span>원</span>
              <Button className="remove-btn btn-24" onClick={e => props.removeCardDebtor(index)}><RemoveIcon /></Button>
            </div>
          ))}
        </div>

        <div className="answer-area item4">
          <div className="flex-row mb_4">
            <span>임대차보증금</span>
            {depositDebtorList.length === 0 && <Button className="add-btn btn-24" onClick={e => props.addDepositDebtor()}><AddIcon/></Button>}
            {depositDebtorList.length !== 0 && <Button className="remove-btn btn-24" onClick={e => props.removeDepositDebtor(0)}><RemoveIcon/></Button>}
          </div>

          {depositDebtorList.map((depositDebtor, index) => (
            <div key={`depositDebtor${index}`}>
              <div className="flex-row">
                <p className="row-ttl">임대인 이름</p>
                <TextField
                  value={depositDebtor.name}
                  className="w200"
                  onChange={e => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, name: e.target.value}})}
                />
              </div>
              <div className="flex-row address-row">
                <p className="row-ttl">주소</p>
                <AddressPicker 
                  address={depositDebtor.address}
                  onSelectAddress={address => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, address: address.address}})}
                />
              </div>
              <div className="flex-row">
                <p className="row-ttl">상세주소</p>
                <TextField
                  value={depositDebtor.detailAddress}
                  onChange={e => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, detailAddress: e.target.value}})}
                  className="w240"
                />
              </div>
              <div className="flex-row">
                <p className="row-ttl">임대차계약 체결일</p>
                <SJDatePicker 
                  date={depositDebtor.contractDate}
                  onChangeDate={date => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, contractDate: date}})}
                />
                <label className="radio-item">
                  <Checkbox 
                    value={depositDebtor.dontKnowContractDate}
                    onChange={e => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, dontKnowContractDate: e.target.checked}})}
                  />
                  <span>모름</span>
                </label>
              </div>
              <div className="flex-row">
                <p className="row-ttl">청구금액</p>
                <TextField
                  value={depositDebtor.price === 0 ? '' : depositDebtor.price}
                  onChange={e => props.refreshDepositDebtor({index, depositDebtor: {...depositDebtor, price: parseInt(e.target.value) || 0}})}
                  className="input-price"
                  type="number"
                />
                <span>원</span>
              </div>
            </div>
          ))}
        </div>

        <hr />

        <p className="ttl">3) 집행권원을 입력해 주세요.</p>
        <div className="answer-area">
          <Execution 
            execution={execution}
            onData={onExecutionData}/>
        </div>

        <hr />

        <p className="ttl">4) 이 사건과 관련하여 채권가압류결정을 받은 적이 있나요?</p>
        <div className="answer-area item6">
          <label className="radio-item">
            <Radio
              checked={decision.isOn}
              onChange={e => props.refreshDecision({...decision, isOn: true})}
            />
            {/* <Incident
            incident={incident}
            onData={onIncidentData}/> */}
            <Incident
              incident={decision.incident}
              onData={onIncidentData}
            >
              <span className="mr_10"> 채권가압류 결정을 받았으며, 가압류한 금액은</span>
              <TextField
                checked={decision.price === 0 ? '' : decision.price}
                onChange={e => props.refreshDecision({...decision, price: parseInt(e.target.value) || 0})}
                type="number"
                className="input-price"
              />
              <span>원입니다.</span>
            </Incident>            
          </label>
          <label className="radio-item">
            <Radio
              checked={!decision.isOn}
              onChange={e => props.refreshDecision({...decision, isOn: false})}
            />
            <span>채권가압류결정을 받은 적이 없습니다.</span>
          </label>
        </div>

        <hr />

        <p className="ttl">5) 관할법원을 선택해 주세요.</p>
        <SelectCourt
          court={court}
          onSelectCourt={court => props.refreshApplicationCourt(court)}
        />
      </div>

    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      application: state.app.application,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      refreshApplicationCourt: (data) => dispatch(refreshApplicationCourt(data)),
      refreshIncident: (incident) => dispatch(refreshIncident(incident)),
      refreshExecution: (data) => dispatch(refreshExecution(data)),
      refreshDeptText: (data) => dispatch(refreshDeptText(data)),
      addBillingAmount: () => dispatch(addBillingAmount()),
      removeBillingAmount: (index) => dispatch(removeBillingAmount(index)),
      refreshBillingAmountList: (data) => dispatch(refreshBillingAmountList(data)),
      refreshBillingAmount: (data) => dispatch(refreshBillingAmount(data)),
      refreshBankDebtor: (data) => dispatch(refreshBankDebtor(data)),
      addBankDebtor: () => dispatch(addBankDebtor()),
      removeBankDebtor: (index) => dispatch(removeBankDebtor(index)),
      refreshCardDebtor: (data) => dispatch(refreshCardDebtor(data)),
      addCardDebtor: () => dispatch(addCardDebtor()),
      removeCardDebtor: (index) => dispatch(removeCardDebtor(index)),
      refreshDepositDebtor: (data) => dispatch(refreshDepositDebtor(data)),
      addDepositDebtor: () => dispatch(addDepositDebtor()),
      removeDepositDebtor: (index) => dispatch(removeDepositDebtor(index)),
      refreshDecision: (data) => dispatch(refreshDecision(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BondSeizure)