import { useState } from 'react';

import { TextField, Select, MenuItem, Button, FormControl } from '@mui/material';


//토지 추가, 수정
export default function EditLand(props) {
  
  const CATEGORY = [
    '대', '전', '답', '임야', '도로', '과수원',
    '창고용지', '공장용지', '잡종지', '하천', '제방',
    '주차장', '주유소용지', '목장용지', '학교용지', '광천지', 
    '염전', '철도용지', '구거', '유지', '양어장', '수도용지', 
    '공원', '체육용지', '유원지', '종교용지', '사적지', '묘지'
  ]

  const [address, setAddress] = useState(props.land?.address || '');
  const [category, setCategory] = useState(props.land?.category || '');
  const [area, setArea] = useState(props.land?.area || '');


  return (
    <div className="modal-container">
      <div className="modal">
        <p className="top-area">토지</p>

        <div className="middle-area">
          <div className="item">
            <p className="ttl">소재지번</p>
            <div className="textarea-wrap">
              <TextField
                value={address}
                onChange={e => setAddress(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>

          <div className="item">
            <p className="ttl">지목</p>
            <FormControl className='select'>
              <Select
                value={category} 
                onChange={e => setCategory(e.target.value)}
              >
                {CATEGORY.map((category, index) => (
                  <MenuItem value={category} key={`category${index}`}>{category}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="item">
            <p className="ttl">면적</p>
            <TextField
              value={area}
              onChange={e => setArea(e.target.value)}
              style={{width: '282px'}}
            />
            <span className="ml_4">㎡</span>
          </div>
        </div>

        <div className="bottom-area">
          <Button className="outlined" onClick={e => props.onClose()}>취소</Button>
          <Button className="contained" onClick={e => props.land ? props.onEdit({address, category, area}) : props.onAdd({address, category, area})}>추가</Button>
        </div>
      </div>
    </div>
  )
}
