import React, { useState } from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { post } from '../../utils/Api';


//회원탈퇴
export default function WithDrawalView(props) {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const signIn = () => {
    post('auth/signin', {
      email: email,
      pw: password
    })
    .then(response => {
      const user = JSON.parse(sessionStorage.getItem('user'));

      if(response.data.success) {
        if(response.data.data.id === user.id) {
          dropUser(user.id);
        }
        else {
          alert('로그인된 정보와 일치하지 않습니다.');
        }
      }
      else {
        if(response.data.errorCode === 1) {
          alert('아이디 혹은 비밀번호가 맞지 않거나 가입되어있지 않습니다.')
        }
      }
    }).catch(err => console.log(err))
  }

  const dropUser = (userId) => {
    post(`auth/drop`, {
      userId: userId
    }).then(response => {
      if(response.data.success) {
        alert('회원탈퇴가 완료되었습니다.');
        props.onCompleteDrop();
      }
      else {
        
      }
    })
  }

  return (
    <div className="with-drawal user user-modal">
      <div className="card">
        <Button className="clear-btn" onClick={e => props.onClose()}><ClearIcon /></Button>

        <div className="head-area">
          <span className="head">회원 탈퇴</span>
        </div>

        <div className="txt-bx">
          <p>회원 탈퇴를 하시겠습니까?</p>
          <p>- 가입하신 방법에 따라 "이용자 정보 확인" 후 회원탈퇴가 가능합니다.</p>
          <p>- 입력하신 정보는 회원탈퇴 이외의 목적으로 사용하지 않습니다.</p>          
        </div>

        <p className="sub-head">이용자 정보 확인</p>

        <div className="flex-row">
          <p className="ttl">아이디</p>
          <TextField
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="flex-row">
          <p className="ttl">비밀번호</p>
          <TextField
            variant="outlined"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <Button className="contained" onClick={e => signIn()}>확인</Button>
      </div>
    </div>
  )
}