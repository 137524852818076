import axios from 'axios'

const get = (endPoint, data) => {
  const options = {
    method: 'GET',
    //headers: { 'Authorization': `Bearer ${token}` },
    params: data,
    url: `${process.env.REACT_APP_SERVER_URL}${endPoint}`
  }

  return axios(options)
}

const post = (endPoint, data) => {
  const options = {
    method: 'POST',
    //headers: { 'Authorization': `Bearer ${token}` },
    data: data,
    url: `${process.env.REACT_APP_SERVER_URL}${endPoint}`
  }

  return axios(options)
}

const put = (endPoint, data) => {
  const options = {
    method: 'PUT',
    //headers: { 'Authorization': `Bearer ${token}` },
    data: data,
    url: `${process.env.REACT_APP_SERVER_URL}${endPoint}`
  }

  return axios(options)
}

const del = (endPoint, data) => {
  const options = {
    method: 'DELETE',
    //headers: { 'Authorization': `Bearer ${token}` },
    data: data,
    url: `${process.env.REACT_APP_SERVER_URL}${endPoint}`
  }

  return axios(options)
}

export {
  get, post, put, del
}