import '../Preview.scss';

import LossTitle from './LossTitle';
import { useAppSelector } from '../../../../store';

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아'];

const Spacer = () => {
  return (
    <p style={{height: '20px'}}></p>
  )
}

export default function AuctionPreview(props) {

  const execution = useAppSelector((state) => state.app.application.execution);
  const deptText = useAppSelector((state) => state.app.application.deptText);

  const getExecutionText = (execution) => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}` 
    } 
    else if(execution.type === '기타') {
      return execution.execution3;
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${execution.type}`  
    }
  }

  return (
    <div className="inner-content">
      
      <Spacer/>

      <LossTitle
        title="집행권원의 표시 : "
        contents={getExecutionText(execution)}
        bold={true}
      />

      <LossTitle
        title="청구 금액 : "
        contents={deptText}
        bold={true}
      />

      <LossTitle
        title="경매할 부동산의 표시 : "
        contents="별지목록 기재와 같음"
        bold={true}
      />

      <Spacer/>

      <p className="ta-center bold">신 청 취 지</p>

      <LossTitle
        title="1. "
        contents="채권자의 채무자에 대한 위 청구금액의 변제에 충당하기 위하여 별지 목록 기재 부동산에 대한 강제경매절차를 개시한다."
      />

      <LossTitle
        title="2. "
        contents="채권자를 위하여 별지 목록 기재 부동산을 압류한다."
      />

      <p>라는 재판을 구합니다.</p>

      <Spacer/>

      <p className="ta-center bold">신 청 이 유</p>

      <Spacer/>

      <LossTitle
        title="1. "
        contents={`채권자는 채무자에 대하여 위 집행권원의 집행력 있는 판결정본에 의한 ${deptText} 채권을 가지고 있습니다.`}
      />

      <LossTitle
        title="2. "
        contents="그런데 채무자는 위 채무를 지금까지 이행하지 않고 있습니다."
      />

      <LossTitle
        title="3. "
        contents="따라서 채권자는 위 채권의 변제에 충당하기 위하여 채무자 소유의 별지 목록 기재 부동산에 대하여 강제경매를 신청합니다."
      />

      <Spacer/>

    </div>
  )
}
