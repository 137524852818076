import '../Preview.scss';

import LossTitle from './LossTitle';
import { useAppSelector } from '../../../../store';

const Spacer = () => {
  return (
    <p style={{height: '20px'}}></p>
  )
}

export default function AssetDisplayPreview(props) {

  const execution = useAppSelector((state) => state.app.application.execution);
  const deptText = useAppSelector((state) => state.app.application.deptText);

  const getName = (person) => {
    let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : getSpacingWord(person?.name);
    if(!name || name === '') name = 'O O O';

    if(person?.type === 0 || person?.type === 1) {
      if(person?.isEmptyName === true) {
        name = '성명 불상';
      }
    }
    else {
      if(person?.isEmptyCompany === true) {
        name = '성명 불상';
      }
    }

    return name;
  }

  const getRRNumber = (person) => {
    if(person.RRNumber1 !== '' && person.RRNumber2 !== '') {
      return `${person.RRNumber1}-${person.RRNumber2}`
    }

    if(person.isEmptyRRNumber) return '';
  
    return '000000-0000000';
  }

  const getAddress = (person) => {
    if(person.isEmptyAddress) return '주소 불상';

    return person.address !== '' ? person.address : '서울특별시 서초구 법원로3길 14';
  }

  const getPhone = (person) => {

    let phone = '010-0000-0000';

    if(person.phone1 != '' && person.phone2 != '' && person.phone3 != '') {
      phone = `${person.phone1}-${person.phone2}-${person.phone3}`;
    }

    if(person.isEmptyPhone) {
      phone = null;
    }


  
    return phone;
  }

  const isEmptySpotName = (person) => {
    if(person.type === 2 || person.type === 3) {
      if(person.spot !== '' || person.name !== '') {
        return false;
      }
    }

    return true;
  }

  const getExecutionText = (execution) => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}` 
    } 
    else if(execution.type === '기타') {
      return execution.execution3;
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${execution.type}`  
    }
  }

  const getSpacingWord = (word) => {
    return word.split('').join(' ')
  }

  return (
    <div className="inner-content">
      <Spacer/>
      
      <LossTitle
        title="집행권원의 표시 : "
        contents={getExecutionText(execution)}
        bold={true}
      />

      <LossTitle
        title="채무자가 이행하지 아니하는 금전채무액 :"
        contents={deptText}
        bold={true}
      />

      <Spacer/>

      <p className="ta-center bold">신 청 취 지</p>

      <Spacer/>

      <p>채무자는 재산상태를 명시한 재산목록을 제출하라.</p>
      <p>라는 명령을 구합니다.</p>

      <Spacer/>

      <p className="ta-center bold">신 청 이 유</p>

      <Spacer/>

      <LossTitle
        title="1. "
        contents="채권자는 채무자에 대하여 위 표시 집행권원을 가지고 있고, 채무자는 이를 변제하지 아니하고 있습니다."
      />

      <LossTitle
        title="2. "
        contents="채권자는 부득이 강제집행을 하기 위하여 채무자의 재산을 여러 방면으로 찾아보았 지만 교묘한 방법으로 재산을 감추고 있어 채무자의 재산 발견이 아주 어려워 강제집행을 할 수 없는 실정입니다."
      />

      <LossTitle
        title="3. "
        contents="따라서 채권자는 민사집행법 제61조에 의하여 채무자에 대한 재산명시명령을 신청합니다."
      />

      <Spacer/>

      {/* {props.evidenceList.length !== 0 && (
        <div className="evidence-area">
          <b className="ttl">입 증 방 법</b>
          {props.evidenceList.map((evidence, index) => (
            <p key={`evidence${index}`}>{index + 1}. {evidence.no} ${evidence.name}</p>
          ))}
        </div>
      )}

      {props.attachmentList.length !== 0 && (
        <div className="attachment-area">
          <b className="ttl">첨 부 서 류</b>
          {props.attachmentList.map((attachment, index) => (
            <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
          ))}
        </div>
      )} */}
    </div>
  )
}

