import React, { useEffect, useState } from 'react';
import './Step.scss';
import InfoBx from '../InfoBx';
import { FormControlLabel, FormControl, Select, MenuItem, TextField, FormGroup, Checkbox, Button } from '@mui/material';
import AddressPicker from '../bond/AddressPicker';

import _ from 'lodash';
import { Person } from '../../../store/modules/app';

type AssetFindPersonProps = {
  type: number
  person: Person
  onChange: (person: Person) => void
}

export default function AssetFindPerson(props: AssetFindPersonProps) {

  return(
    <div className="cont-bx bg-gray">
      <p className="ttl">귀하(법인)의 정보를 입력해주세요.</p>
      <div className="info-item-bx flex-row">
        <p className="ttl">이름</p>
        <TextField
          variant="outlined"
          value={props.person.name}
          onChange={e => props.onChange({...props.person, name: e.target.value})}
          placeholder="OOO"
        />
        {/* {(props.type === 1) && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox name="모름"/>
              }
              checked={props.person.isEmptyName}
              onChange={onChangeIsEmptyName}
              label="모름"
            />
          </FormGroup>
        )} */}
      </div>

      {props.type === 0 && (
        <div className="info-item-bx flex-row tel-item-bx">
          <p className="ttl">전화번호</p>
          <TextField
            variant="outlined"
            value={props.person.phone1}
            onChange={e => props.onChange({...props.person, phone1: e.target.value})}
            placeholder="010"
          />
          <p className="hyphen">-</p>
          <TextField
            variant="outlined"
            value={props.person.phone2}
            onChange={e => props.onChange({...props.person, phone2: e.target.value})}
            placeholder="0000"
          />
          <p className="hyphen">-</p>
          <TextField
            variant="outlined"
            value={props.person.phone3}
            onChange={e => props.onChange({...props.person, phone3: e.target.value})}
            placeholder="0000"
          />
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="모름"  />}
              label="모름"
              checked={props.person.isEmptyPhone}
              onChange={(e, checked) => props.onChange({...props.person, isEmptyPhone: checked})}
            />
          </FormGroup>
        </div>
      )}
      
      {(props.person.type === 0 || props.person.type === 1) && (
        <div className="info-item-bx flex-row rrNum-item-bx">
          <p className="ttl">주민(법인)등록번호</p>
          <TextField
            variant="outlined"
            value={props.person.RRNumber1}
            onChange={e => props.onChange({...props.person, RRNumber1: e.target.value})}
            placeholder="000000"
          />
          <p className="hyphen">-</p>
          <TextField
            variant="outlined"
            value={props.person.RRNumber2}
            onChange={e => props.onChange({...props.person, RRNumber2: e.target.value})}
            placeholder="0000000"
          />
          {props.type === 1 && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="모름"  />}
                label="모름"
                checked={props.person.isEmptyRRNumber}
                onChange={(e, checked) => props.onChange({...props.person, isEmptyRRNumber: checked})}
              />
            </FormGroup>
          )}
        </div>
      )}

      {(props.type === 1) && (
        <div className="info-item-bx flex-row cNum-item-bx">
          <p className="ttl">사업자등록번호</p>
          <div className="input-group">
            <TextField
              variant="outlined"
              value={props.person.BNumber1}
              onChange={e => props.onChange({...props.person, BNumber1: e.target.value})}
              placeholder="000"
            />
            <p className="hyphen">-</p>
            <TextField
              variant="outlined"
              value={props.person.BNumber2}
              onChange={e => props.onChange({...props.person, BNumber2: e.target.value})}
              placeholder="00"
            />
            <p className="hyphen">-</p>
            <TextField
              variant="outlined"
              value={props.person.BNumber3}
              onChange={e => props.onChange({...props.person, BNumber3: e.target.value})}
              placeholder="00000"
            />
          </div>
          {props.type === 1 && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="모름"  />}
                label="모름"
                checked={props.person.isEmptyBNumber}
                onChange={(e, checked) => props.onChange({...props.person, isEmptyBNumber: checked})}
              />
            </FormGroup>
          )}
        </div>
      )}

      <div className="info-item-bx flex-row">
        <p className="ttl">주소</p>
        <AddressPicker
          address={props.person.address}
          onSelectAddress={(address: any) => {
            props.onChange({...props.person, address: address.address, displayAddress: address.displayAddress});
          }}
        />
        {props.type === 1 && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="모름"  />}
              label="모름"
              checked={props.person.isEmptyAddress}
              onChange={(e, checked) => props.onChange({...props.person, isEmptyAddress: checked})}
              //onChange={onChangeIsEmptyAddress}
            />
          </FormGroup>
        )}
      </div>

      <div className="info-item-bx flex-row">
        <p className="ttl">상세주소</p>
        <TextField
          variant="outlined"
          value={props.person.detailAddress}
          onChange={e => props.onChange({...props.person, detailAddress: e.target.value})}
        />
      </div>

      {props.type === 0 && (
        <div className="info-item-bx flex ai-center">
          <p className="ttl">이메일(선택)</p>
          <TextField
            variant="outlined"
            value={props.person.email}
            onChange={e => props.onChange({...props.person, email: e.target.value})}
            placeholder="sj-ez@gmail.com"
          />
        </div>
      )}
    </div>
  )
}