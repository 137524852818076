
import { connect, useSelector } from 'react-redux'
import EditJointGuarantor from "../EditJointGuarantor";
import SJDatePicker from './SJDatePicker';
import { TextField, Radio, Button, Checkbox, Select, MenuItem, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddressPicker from './AddressPicker';

import { getName } from '../../../utils/utils'
import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'

import _ from 'lodash'
import { useAppSelector } from '../../../store';


const CATEGORY = [
  '대', '전', '답', '임야', '도로', '과수원',
  '창고용지', '공장용지', '잡종지', '하천', '제방',
  '주차장', '주유소용지', '목장용지', '학교용지', '광천지', 
  '염전', '철도용지', '구거', '유지', '양어장', '수도용지', 
  '공원', '체육용지', '유원지', '종교용지', '사적지', '묘지'
]

const TradingPrice = (props) => {

  let complaint = useAppSelector((state) => state.app.complaint)
  const defendantList = useAppSelector(state => state.app.defendantList)

  const changeTradingPriceType = (e) => {
    let c = _.cloneDeep(complaint);
    c.tradingPriceType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPriceContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeTradingPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPrice = price;
    props.refreshComplaint(c);
  }

  const changeDeferInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeferInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDeferInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.bondList[index].deferInterestRate = rate;
    props.refreshComplaint(c);
  }

  const refreshJointGuarantorList = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].jointGuarantorList = e;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateType = e.target.value;

    if(e.target.value !== 'division') {
      const didPaybackDateList = [...c.bondList[index].didPaybackDateList];
      didPaybackDateList.forEach(d => {
        d.isChecked = false;
      })
      c.bondList[index].didPaybackDateList = didPaybackDateList;
    }

    props.refreshComplaint(c);
  }

  const changeWillPaybackDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDate = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackListDate = (date, index, willPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].date = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateUnit = (e, index, willPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].unit = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDatePrice = (e, index, willPaybackDateIndex) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].price = price;
    props.refreshComplaint(c);
  }

  const addWillPaybackDate = (bondIndex) => {
      
    let c = _.cloneDeep(complaint);

    const bond = c.bondList[bondIndex];

    if(bond.willPaybackDateList.length === 0) {
      bond.willPaybackDateList = [{unitPlaceHolder: '계약금', unit: '계약금', price: 0, date: '2020-01-01'}, {unitPlaceHolder: '잔금', unit: '잔금', price: 0, date: '2020-03-01'}];
      bond.didPaybackDateList = [{ unit: '계약금', price: 0, date: '', defendant: null, isChecked: false}, {unit: '잔금', price: 0, date: '', defendant: null, isChecked: false}];
    }
    else {

      const insertIndex = bond.willPaybackDateList.length - 1;
      let unit = '';

      if(bond.willPaybackDateList.length === 2) {
        unit = '중도금';
      }
      else {
        if(bond.willPaybackDateList.length === 3) {
          bond.willPaybackDateList[1].unitPlaceHolder = '제1차 중도금';
          bond.willPaybackDateList[1].unit = '제1차 중도금';
          bond.didPaybackDateList[1].unit = '제1차 중도금';
        }
        unit = `제${bond.willPaybackDateList.length - 1}차 중도금`;
      }

      bond.willPaybackDateList.splice(insertIndex, 0, {unitPlaceHolder: unit, unit: unit, date: '2020-02-01', price: 0})
      bond.didPaybackDateList.splice(insertIndex, 0, {unit: unit, price: 0, date: '', defendant: null, isChecked: false})
    }

    
    props.refreshComplaint(c);
  }

  const removeWillPaybackDate = (bondIndex, willPaybackDateIndex) => {

    let c = _.cloneDeep(complaint);

    const bond = c.bondList[bondIndex];

    bond.willPaybackDateList.splice(willPaybackDateIndex, 1);
    bond.didPaybackDateList.splice(willPaybackDateIndex, 1);

    if(bond.willPaybackDateList.length === 3) {
      bond.willPaybackDateList[1].unitPlaceHolder = '중도금';
    }

    props.refreshComplaint(c);
  }

  const changeStuffName = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].name = e.target.value;

    props.refreshComplaint(c);
  }

  const changeStuffCount = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].count = parseInt(e.target.value);

    props.refreshComplaint(c);
  }

  const changeStuffUnit = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].unit = e.target.value;

    props.refreshComplaint(c);
  }

  const addStuff = (bondIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList.push({name: '', count: '', unit: ''})

    props.refreshComplaint(c);
  }

  const removeStuff = (bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList.splice(stuffIndex, 1);

    props.refreshComplaint(c);
  }

  const changeLandAddress = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].address = e.target.value;

    props.refreshComplaint(c);
  }

  const changeLandCategory = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].category = e.target.value;

    props.refreshComplaint(c);
  }

  const changeLandArea = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].area = e.target.value;

    props.refreshComplaint(c);
  }

  const addLand = (bondIndex) => {
    let c = _.cloneDeep(complaint);

    c.bondList[bondIndex].landList.push({address: '', category: '', area: ''})

    props.refreshComplaint(c);
  }

  const removeLand = (bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList.splice(index, 1);

    props.refreshComplaint(c);
  }

  const changeBuildingAddress = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].buildingList[index].address = e.target.value;

    props.refreshComplaint(c);
  }


  const addBuilding = (bondIndex) => {
    let c = _.cloneDeep(complaint);

    c.bondList[bondIndex].buildingList.push({address: '', detail: ''})

    props.refreshComplaint(c);
  }

  const removeBuilding = (bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].buildingList.splice(index, 1);

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willDeliveryDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillDeliveryDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.willDeliveryDate = e.target.value;

    props.refreshComplaint(c);
  }

  const addWillDeliveryDate = (bondIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    if(bond.willDeliveryDateList.length === 0) {
      bond.willDeliveryDateList = [{date: '2020-03-01', stuff: {name: '사과', count: 10, unit: '개'}}, {date: '2020-03-01', stuff: {name: '오렌지', count: 10, unit: '개'}}];
    }
    else {
      bond.willDeliveryDateList.push({date: '2020-03-01', stuff: {name: '사과', count: 10, unit: '개'}});
    }

    props.refreshComplaint(c);
  }

  const removeWillDeliveryDate = (bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList.splice(willDeliveryDateIndex, 1);

    props.refreshComplaint(c);
  }

  const changeWillDeliveryListDate = (date, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].date = date;

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffName = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.name = e.target.value;

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffCount = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.count = parseInt(e.target.value);

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffUnit = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.unit = e.target.value;

    props.refreshComplaint(c);
  }

  const changeDidDeliveryDateType = (e, index) => {

    let c = _.cloneDeep(complaint);
    
    c.bondList[index].didDeliveryDateType = e.target.value;

    props.refreshComplaint(c);
  }


  const changeBondDidPaybackDate = (e, index, didPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];
    bond.didPaybackDateList[didPaybackDateIndex].isChecked = e.target.checked;
    props.refreshComplaint(c);
  }

  const changeBondDidPaybackDateDefendant = (e, index, didPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    const defendantIndex = defendantList.map(d => d.id).indexOf(parseInt(e.target.value));
    c.bondList[index].didPaybackDateList[didPaybackDateIndex].defendant = defendantList[defendantIndex]
    props.refreshComplaint(c);
  }

  const checkDidPaybackDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.didPaybackDateList[index].isChecked = e.target.checked;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDefendant = (e, index) => {
    let c = _.cloneDeep(complaint);
    const defendantIndex = defendantList.map(d => d.id).indexOf(parseInt(e.target.value));
    c.didPaybackDateList[index].defendant = defendantList[defendantIndex];
    props.refreshComplaint(c);
  }

  const changeDidPaybackDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].date = date;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDatePrice = (e, index) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].price = price;
    props.refreshComplaint(c);
  }

  const changeBond = (index, bond) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index] = bond;
    props.refreshComplaint(c);
  }

  return(
    <div className="trading-price">
      <p className="head">매매대금</p>
      {_.cloneDeep(complaint.bondList).map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          {index !== 0 && <Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon/></Button>}
          <p className="ttl">1) 주채무자</p>
          <div className="answer-area">
          <span>{getName(defendantList[0])} 입니다.</span>
          </div>

          <hr />

          <EditJointGuarantor
            isShowAnswer={false}
            bondIndex={index}
            jointGuarantorList={bond.jointGuarantorList} 
            defendantList={defendantList} 
            onAdd={e => props.addJointGuarantor(index)}
            onRemove={jIndex => props.removeJointGuarantor({bondIndex: index, index: jIndex})}
            refresh={e => refreshJointGuarantorList(e, index)}/>

          <hr />

          <p className="ttl">3) 매매계약을 체결한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker 
              date={bond.tradingPriceContractDate}
              onChangeDate={date => changeBond(index, {...bond, tradingPriceContractDate: date})}
            />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />

          <p className="ttl">4) 매매대금 총액이 얼마인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              value={bond.tradingPrice === 0 ? '' : bond.tradingPrice}
              onChange={e => changeTradingPrice(e, index)}
              placeholder="1,000,000"
              className="input-price"
            />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />

          <p className="ttl">5) 매매대금을 지급하기로 한 날짜가 언제인가요?</p>
          <div className="answer-area item5">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willPaybackDateType === 'fix'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker
                date={bond.willPaybackDate}
                onChangeDate={date => changeWillPaybackDate(date, index)}
              />
              <span className="ml_4">입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='division'
                checked={bond.willPaybackDateType === 'division'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">분할하여 지급하기로 정하였습니다.</span>
              <Button className="add-btn btn-24" onClick={e => addWillPaybackDate(index)}><AddIcon/></Button>
            </label>
            {bond.willPaybackDateList.map((willPaybackDate, wIndex) => (
              <div className="radio-sub-bx flex-row jc-sb" key={`willPaybackDate${index}${wIndex}`}>
                <div className="flex-row">
                  <SJDatePicker
                    date={willPaybackDate.date}
                    onChangeDate={date => changeWillPaybackListDate(date, index, wIndex)}
                    />
                  <span className="mr_10">에</span>
                  <TextField
                    variant="outlined"
                    value={bond.unit}
                    onChange={e => changeWillPaybackDateUnit(e, index, wIndex)}
                    placeholder={willPaybackDate.unitPlaceHolder}
                    className="input-text"
                  />
                  <TextField
                    variant="outlined"
                    value={willPaybackDate.price === 0 ? '' : willPaybackDate.price}
                    onChange={e => changeWillPaybackDatePrice(e, index, wIndex)}
                    placeholder="1,000,000"
                    className="input-price"
                  />
                  <span>원</span>
                </div>
                {(wIndex !== 0 && wIndex !== (bond.willPaybackDateList.length - 1)) && 
                <Button className="add-btn btn-24" onClick={e => removeWillPaybackDate(index, wIndex)}><RemoveIcon /></Button>}
              </div>
            ))}
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willPaybackDateType === 'none'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">6) 지연이자율을 얼마로 정하였나요? <span>(갚기로 약속한 날짜 이후 이자)</span></p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" variant="outlined" autoComplete='off'
                value={bond.deferInterestRate === 0 ? '' : bond.deferInterestRate}
                onChange={e => changeDeferInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          {complaint.tradingPriceType === '동산' && (
            <div>
              <div className="ttl-bx">
              <p className="ttl">7) 팔기로 한 물건이 무엇인가요?</p>
              <Button className="add-btn btn-24" onClick={e => addStuff(index)}><AddIcon /></Button>
            </div>
            {bond.stuffList.map((stuff, sIndex) => (
              <div className="answer-area flex-row item7" key={`stuff${index}${sIndex}`}>
                <TextField
                  variant="outlined"
                  value={stuff.name}
                  onChange={e => changeStuffName(e, index, sIndex)}
                  placeholder="물건명"
                  className="input-price"
                />
                <TextField
                  variant="outlined"
                  value={stuff.count}
                  onChange={e => changeStuffCount(e, index, sIndex)}
                  placeholder="수량"
                  className="input-percentage"
                />
                <TextField
                  variant="outlined"
                  value={stuff.unit}
                  onChange={e => changeStuffUnit(e, index, sIndex)}
                  placeholder="개"
                  className="input-percentage"
                />
                {sIndex !== 0 && <Button className="remove-btn btn-24" onClick={e => removeStuff(index, sIndex)}><RemoveIcon /></Button>}
              </div>
            ))}
            </div>
          )}

          {/* 부동산일 경우 */}
          {complaint.tradingPriceType === '부동산' && (
            <div>
              <p className="ttl">7) 팔기로 한 부동산이 무엇인가요?</p>
              <div className="answer-area">
                <div className="answer-area__ttl flex-row land">
                  <span className="mr_10">토지</span>
                  <Button className="add-btn btn-24" onClick={e => addLand(index)}><AddIcon /></Button>
                </div>
                {bond.landList.map((land, lIndex) => (
                  <div className="answer-area__land flex-row" key={`land${index}${lIndex}`}>
                    <AddressPicker
                      address={land.address}
                      onSelectAddress={address => {
                        bond.landList[lIndex].address = address.address;
                        changeBond(index, bond)
                      }}
                    />
                    <Select
                      value={land.category} 
                      onChange={e => {
                        bond.landList[lIndex].category = e.target.value;
                        changeBond(index, bond)
                      }}
                    >
                      <MenuItem value='' disabled>선택</MenuItem>
                      {CATEGORY.map((category, index) => (
                        <MenuItem value={category} key={`category${index}`}>{category}</MenuItem>
                      ))}
                      
                    </Select>
                    <TextField
                      variant="outlined"
                      value={land.area}
                      onChange={e => {
                        bond.landList[lIndex].area = e.target.value;
                        changeBond(index, bond)
                      }}
                      placeholder="면적"
                      className="w100 mr_10"
                    />
                    <span className="mr_10">㎡</span>
                    <Button className="remove-btn btn-24" onClick={e => removeLand(index, lIndex)}><RemoveIcon /></Button>
                  </div>
                ))}

                <div className="answer-area__ttl flex-row building" style={{marginTop: '6px'}}>
                  <span className="mr_10">건물</span>
                  <Button className="add-btn btn-24" onClick={e => addBuilding(index)}><AddIcon /></Button>
                </div>
                {bond.buildingList.map((building, bIndex) => (
                  <div className="answer-area__building flex-row" key={`building${index}${bIndex}`}>
                    <AddressPicker
                      address={building.address}
                      onSelectAddress={address => {
                        bond.buildingList[bIndex].address = address.address;
                        changeBond(index, bond)
                      }}
                    />
                    <TextField
                      variant="outlined"
                      value={building.detail}
                      onChange={e => {
                        bond.buildingList[bIndex].detail = e.target.value;
                        changeBond(index, bond);
                      }}
                      placeholder="상세주소"
                      className="w240 mr_10"
                    />
                    <Button className="remove-btn btn-24" onClick={e => removeBuilding(index, bIndex)}><RemoveIcon /></Button>
                  </div>
                ))}
              </div>
            </div>
          )}

          <hr />

          <p className="ttl">8) {complaint.tradingPriceType === '동산' ? '물건을 인도하기로' : '부동산 소유권이전등기를 해주기로'} 한 날짜가 언제인가요?</p>
          <div className="answer-area item8">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willDeliveryDateType === 'fix'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker
                date={bond.willDeliveryDate}
                onChangeDate={date => {
                  changeBond(index, {...bond, willDeliveryDate: date})
                }}
              />
              <span className="ml_4">입니다.</span>
            </label>
            {bond.tradingPriceType === '동산' && (
              <div>
                <label className="radio-item">
                <Radio
                  value='division'
                  checked={bond.willDeliveryDateType === 'division'}
                  onChange={e => changeWillDeliveryDateType(e, index)}
                />
                <span className="mr_10">분할하여 인도하기로 정하였습니다.</span>
                <Button className="add-btn btn-24" onClick={e => addWillDeliveryDate(index)}><AddIcon /></Button>
              </label>
              {bond.willDeliveryDateList.map((willDeliveryDate, wIndex) => (
                <div className="radio-sub-bx flex-row jc-sb" key={`willDeliveryDate${index}${wIndex}`}>
                  <div className="flex-row">
                    <SJDatePicker
                      date={willDeliveryDate.date}
                      onChangeDate={date => changeWillDeliveryListDate(date, index, wIndex)}
                    />
                    <span className="mr_10">에</span>
                    <TextField
                      variant="outlined"
                      value={willDeliveryDate.stuff.name}
                      onChange={e => changeWillDeliveryDateStuffName(e, index, wIndex)}
                      placeholder="물건명"
                      className="input-price"
                    />
                    <TextField
                      variant="outlined"
                      value={willDeliveryDate.stuff.count}
                      onChange={e => changeWillDeliveryDateStuffCount(e, index, wIndex)}
                      placeholder="수량"
                      className="input-percentage"
                    />
                    <TextField
                      variant="outlined"
                      value={willDeliveryDate.stuff.unit}
                      onChange={e => changeWillDeliveryDateStuffUnit(e, index, wIndex)}
                      placeholder="개"
                      className="input-percentage"
                    />
                  </div>
                  {wIndex !== 0 && wIndex !== 1 && <Button className="remove-btn btn-24" onClick={e => removeWillDeliveryDate(index, wIndex)}><RemoveIcon/></Button>}
                </div>
              ))}
              </div>
            )}
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willDeliveryDateType === 'none'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          {complaint.tradingPriceType === '동산' && (
            <div>
              <p className="ttl">9) 물건을 모두 인도하였나요?</p>
              <div className="answer-area">
                <label className="radio-item">
                  <Radio
                    value='all'
                    checked={bond.didDeliveryDateType === 'all'}
                    onChange={e => changeDidDeliveryDateType(e, index)}
                  />
                  <SJDatePicker
                    date={bond.didDeliveryDate}
                    onChangeDate={date => {
                      changeBond(index, {...bond, didDeliveryDate: date})
                    }}/>
                  <span>에 모두 인도하였습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    value='part'
                    checked={bond.didDeliveryDateType === 'part'}
                    onChange={e => changeDidDeliveryDateType(e, index)}
                  />
                  <span>물건을 일부 인도하였습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    value='none'
                    checked={bond.didDeliveryDateType === 'none'}
                    onChange={e => changeDidDeliveryDateType(e, index)}
                  />
                  <span>물건을 인도하지 않았습니다.</span>
                </label>
              </div>
            </div>
          )}

          {/* 부동산일경우 */}
          {complaint.tradingPriceType === '부동산' && (
            <div>
              <p className="ttl">9) 부동산 소유권이전등기를 경료하였나요?</p>
              <div className="answer-area">
                <label className="radio-item">
                  <Radio
                    checked={bond.didDeliveryDateType === 'all'}
                    onChange={e => {
                      changeBond(index, {...bond, didDeliveryDateType: 'all'})
                    }}
                  />
                  <SJDatePicker
                    date={bond.didDeliveryDate01}
                    onChangeDate={date => {
                      changeBond(index, {...bond, didDeliveryDate01: date})
                    }}/>
                  <span>에 소유권이전등기를 경료하였습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    checked={bond.didDeliveryDateType === 'part'}
                    onChange={e => {
                      changeBond(index, {...bond, didDeliveryDateType: 'part'})
                    }}
                  />
                  <SJDatePicker
                    date={bond.didDeliveryDate02}
                    onChangeDate={date => {
                      changeBond(index, {...bond, didDeliveryDate02: date})
                    }}/>
                  <span>부터 상대방에게 소유권이전등기에 필요한 서류를 계속</span>
                  <span>제공하고 있습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    checked={bond.didDeliveryDateType === 'none'}
                    onChange={e => {
                      changeBond(index, {...bond, didDeliveryDateType: 'none'})
                    }}
                  />
                  <span>아직 소유권이전등기를 경료하지 않았습니다.</span>
                </label>
              </div>
            </div>
          )}
        </div>
      ))}
      {complaint.tradingPriceType !== '부동산' && <Button className="contained" onClick={e => props.addBond()}>채권 추가</Button>}
      <div className="head-bx">
        <p className="head">피고가 매매대금을 일부 지급했나요?</p>
        <Button className="add-btn" onClick={e => props.addDidPaybackDate()}><AddIcon/></Button>
      </div>
      <div className="cont-bx">
        <p className="sub-txt">* 일부 지급했다면, 우측의 +버튼을 눌러 추가하세요.</p>
        <p className="sub-txt">* 지급하지 않았다면, 다음 단계로 넘어가세요.</p>
        <div className="border-bx">
          {complaint.bondList.map((bond, index) => {
            if(bond.willPaybackDateType === 'division') {
              return (
                <div key={`bondpayback${index}`}>
                  <p>{index + 1}번 채권</p>
                  {bond.didPaybackDateList.map((didPaybackDate, dIndex) => (
                    <label className="checkbox-item" key={`bondpayback${index}${dIndex}`}>
                      <Checkbox
                        checked={didPaybackDate.isChecked}
                        onChange={e => changeBondDidPaybackDate(e, index, dIndex)}
                      />
                      <FormControl variant="outlined" className="select">
                        <Select
                          value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1} 
                          onChange={e => changeBondDidPaybackDateDefendant(e, index, dIndex)}
                        >
                          {defendantList.map((defendant, defendantIndex) => (
                            <MenuItem value={defendant.id} key={`didPaybackDate${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <span className="ml_4">이(가) {didPaybackDate.unit}을 지급하였습니다.</span>
                    </label>
                  ))}
                </div>
              )
            }
          })}
          {complaint.didPaybackDateList.map((didPaybackDate, index) => (
            <label className="checkbox-item" key={`didPaybackDate${index}`}>
              <Checkbox
                checked={didPaybackDate.isChecked}
                onChange={e => checkDidPaybackDate(e, index)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span className="mr_10">그밖에</span>
              <FormControl variant="outlined" className="select">
                <Select
                  value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1} 
                  onChange={e => changeDidPaybackDateDefendant(e, index)}
                >
                  {defendantList.map((defendant, defendantIndex) => (
                    <MenuItem value={defendant.id} key={`didPaybackDateDefendant${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                  ))}
                  
                </Select>
              </FormControl>
              <span className="ml_4 mr_10">이(가)</span>
              <SJDatePicker 
                date={didPaybackDate.date}
                onChangeDate={date => changeDidPaybackDate(date, index)}
              />
              <span className="ml_4">에</span>
              <TextField
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => changeDidPaybackDatePrice(e, index)}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원을 지급하였습니다.</span>
              <Button className="remove-btn btn-24" onClick={e => props.removeDidPaybackDate(index)}><RemoveIcon/></Button>
            </label>
          ))}
        </div>
      </div>
    </div>



  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingPrice)