
import './Step.scss';
import { Button, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

import _ from 'lodash'
import { useAppSelector } from '../../../store';

export default function Step4Attach(props) {

  const type = useAppSelector(state => state.app.complaint.type)

  const changeName = (e) => {
    let attachment = _.cloneDeep(props.attachment);
    attachment.name = e.target.value;
    props.onRefreshAttachment(attachment);
  }

  const changeFile = (file) => {
    let attachment = _.cloneDeep(props.attachment);
    attachment.file = file;
    props.onRefreshAttachment(attachment);
  }

  const getFile = async (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve({ file: file, url: reader.result, name: file.name, format: file.name.split('.')[file.name.split('.').length - 1] });
      };
      reader.readAsDataURL(file);
    })  
  }

  const clickedFile = () => {

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    //input.setAttribute('multiple', false);
    input.onchange = async function() {
      
      const file = input.files[0];
      const result = await getFile(file);

      changeFile(result);

      
    }.bind(this);

    input.click();

  }

  return(
    <div className="cont-bx step4-attach">
      <Button className="remove-btn" onClick={e => props.onClickRemove()}><RemoveIcon /></Button>

      <div className="item-bx flex-row">
        <p className="ttl">서류명(필수)</p>
        <TextField
          variant="outlined"
          value={props.attachment.name}
          onChange={changeName}
          placeholder={type === '대여금' ? '법인등기사항증명서' : '차용증'}
        />
      </div>

      <div className="item-bx file-row-bx flex-row">
        <p className="ttl">파일</p>
        <TextField
          variant="outlined"
          className="file-name"
          disabled
          value={props.attachment.file?.name || ''}
        />
        <label className="flex-row" onClick={e => clickedFile()}>파일선택</label>
      </div>
   </div>
  )
}