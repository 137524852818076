import { useEffect, useState } from 'react';

import {useParams} from 'react-router-dom'

import { get } from '../../utils/Api'
import moment from 'moment';

function NoticeDetail(props) {

  const { id } = useParams();

  const [notice, setNotice] = useState(null);

  useEffect(() => {
    getNotice(id);
  }, []);

  const getNotice = (id) => {
    get(`notice/${id}`)
    .then(response => {
      if(response.data.success) {
        setNotice(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }  

  return (
    <div className="notice-detail detail-page page-view">
      <div className="h-top"></div>
      
      <div className="inner">
        {/* <div className="head-area">
          <span className="head">공지사항 tkdtp</span>
        </div> */}

        <p className="color-6bbaff">공지사항</p>
        <b className="ttl color-222">{notice?.title}</b>
        <p className="date">{moment(notice?.created_at).format('YYYY-M-D')}</p>
        <p className="txt">{notice?.contents}</p>
      </div>
    </div>
  )
}

export default NoticeDetail;
