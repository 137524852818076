
export type LossPrice = {
  isSelected: Boolean
  type: string
  contents: string
  price: Number
}


export type Loss = {
  occurrenceDateType: Number
  occurrence1DateTime1: string
  occurrence2Date1: string
  occurrence3Date1: string
  occurrence3Date2: string
  occurrence4Date1: string

  //발생장소
  occurrencePlaceType: Number
  occurrence1Place1: string
  occurrence1Place2: string
  occurrence2Place1: string

  //hurt = '상해'; //상해, 폭행, 교통사고, 강제추행, 명예훼손, 모욕, 절도, 강도, 사기, 공갈, 업무상횡령, 업무상배임, 손괴, 기타
  //hurtHint = '가해방법, 치료기간, 상해결과'
  hurt: string //가해행위 내용
  result: string //원고에게 발생한 손해 내용

  priceList: LossPrice[]

  //priceList[0].isSelected = true;
  //priceList[0].price = 1000000;

  price1: number
  price2: number
  price3: number
  price4: number
  price5: number
  price6: number
  price7: number
  price8List: any[];
}

export const initLossPrice = (type: string, contents: string): LossPrice => {
  return {
    isSelected: false,
    type: type,
    contents: contents,
    price: 0,
  }
}

export const initLoss = (): Loss => {
  return {
    //발생날짜
    occurrenceDateType: 0,
    occurrence1DateTime1: '2021-01-01 12:00',
    //occurrence1Date1: '2021-05-13',
    //occurrence1Time1: '16:40',
    occurrence2Date1: '2021-01-01',
    occurrence3Date1: '2021-01-01',
    occurrence3Date2: '2021-01-31',
    occurrence4Date1: '2021-01-01',

    //발생장소
    occurrencePlaceType: 1,
    occurrence1Place1: '',
    occurrence1Place2: '',
    occurrence2Place1: '',

    //hurt: '상해', //상해, 폭행, 교통사고, 강제추행, 명예훼손, 모욕, 절도, 강도, 사기, 공갈, 업무상횡령, 업무상배임, 손괴, 기타
    //hurtHint: '가해방법, 치료기간, 상해결과'
    hurt: '', //가해행위 내용
    result: '', //원고에게 발생한 손해 내용

    priceList: [
      initLossPrice('기왕 치료비', '지금까지 지출한 치료비'),
      initLossPrice('향후 치료비', '앞으로 지출할 치료비'),
      initLossPrice('기왕 간병비', '지금까지 지출한 간병비'),
      initLossPrice('향후 간병비', '앞으로 지출할 간병비'),
      initLossPrice('일실수입', '일을 못 해 입은 소득 상실액'),
      initLossPrice('물건훼손', '물질적 재산상 손해액'),
      initLossPrice('위자료', '위자료(정신적 손해액)'),
      initLossPrice('', ''),
    ],

    //priceList[0].isSelected: true,
    //priceList[0].price: 1000000,

    price1: 0,
    price2: 0,
    price3: 0,
    price4: 0,
    price5: 0,
    price6: 0,
    price7: 0,
    price8List: [],
  }
}

// class Loss {
//   constructor() {

//     //발생날짜
//     this.occurrenceDateType = 0;
//     this.occurrence1DateTime1 = '2021-01-01 12:00';
//     //this.occurrence1Date1 = '2021-05-13';
//     //this.occurrence1Time1 = '16:40';
//     this.occurrence2Date1 = '2021-01-01';
//     this.occurrence3Date1 = '2021-01-01';
//     this.occurrence3Date2 = '2021-01-31';
//     this.occurrence4Date1 = '2021-01-01';

//     //발생장소
//     this.occurrencePlaceType = 1;
//     this.occurrence1Place1 = '';
//     this.occurrence1Place2 = '';
//     this.occurrence2Place1 = '';

//     //this.hurt = '상해'; //상해, 폭행, 교통사고, 강제추행, 명예훼손, 모욕, 절도, 강도, 사기, 공갈, 업무상횡령, 업무상배임, 손괴, 기타
//     //this.hurtHint = '가해방법, 치료기간, 상해결과'
//     this.hurt = ''; //가해행위 내용
//     this.result = ''; //원고에게 발생한 손해 내용

//     this.priceList = [
//       new LossPrice('기왕 치료비', '지금까지 지출한 치료비'),
//       new LossPrice('향후 치료비', '앞으로 지출할 치료비'),
//       new LossPrice('기왕 간병비', '지금까지 지출한 간병비'),
//       new LossPrice('향후 간병비', '앞으로 지출할 간병비'),
//       new LossPrice('일실수입', '일을 못 해 입은 소득 상실액'),
//       new LossPrice('물건훼손', '물질적 재산상 손해액'),
//       new LossPrice('위자료', '위자료(정신적 손해액)'),
//       new LossPrice('', ''),
//     ];

//     //this.priceList[0].isSelected = true;
//     //this.priceList[0].price = 1000000;

//     this.price1 = 0;
//     this.price2 = 0;
//     this.price3 = 0;
//     this.price4 = 0;
//     this.price5 = 0;
//     this.price6 = 0;
//     this.price7 = 0;
//     this.price8List = [];
//   }
// }

// class LossPrice {
//   constructor(type, contents) {
//     this.isSelected = false;
//     this.type = type;
//     this.contents = contents;
//     this.price = 0;
//   }
// }

