import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import moment from 'moment';

import { useSelector } from 'react-redux';
import { useAppSelector } from '../../../../store';
import { Person } from '../../../../store/modules/app';


export default function AssetFindPreview(props: any) {

  const plaintiff = useAppSelector((state) => state.app.plaintiff);
  const defendant = useAppSelector((state) => state.app.defendantList[0]);

  const execution = useAppSelector((state) => state.app.application.execution);
  const incident = useAppSelector((state) => state.app.application.incident);
  const deptText = useAppSelector((state) => state.app.application.deptText);

  const reasonArray = useAppSelector((state) => state.app.application.assetFindReasonArray);

  const account = useAppSelector((state) => state.app.application.account);

  const court = useAppSelector((state) => state.app.application.court);

  const createdAt = useAppSelector((state) => state.app.application.createdAt);

  const getRRNumber = (person: Person) => {
    if(person.isEmptyRRNumber) return '';

    if(person.RRNumber1 === '' && person.RRNumber2 === '') {
      return '000000-0000000';
    }

    return `${person.RRNumber1}-${person.RRNumber2}`;
  }

  const getBNumber = (person: Person) => {
    if(person.isEmptyBNumber) return '';

    if(person.BNumber1 === '' && person.BNumber2 === '' && person.BNumber3 === '') {
      return '000-00-00000';
    }

    return `${person.BNumber1}-${person.BNumber2}-${person.BNumber3}`;
  }

  const getPhone = (person: Person) => {

    let phone = '010-0000-0000';

    if(person.phone1 !== '' || person.phone2 !== '' || person.phone3 !== '') {
      phone = `${person.phone1}-${person.phone2}-${person.phone3}`;
    }

    if(person.isEmptyPhone) {
      phone = '';
    }

    return phone;
  }

  const getExecutionText = () => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}` 
    } 
    else if(execution.type === '기타') {
      return execution.execution3;
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${execution.type}`  
    }
  }

  const getIncidentText = () => {
    const { court, year, code, subName } = incident;
    return `${court} ${year}카명${subName}호`;
  }

  return (
    <div className="asset-find-preview">
      <div className="table">
        <div className="row-item">
          <p className="ttl">채권자</p>
          <div className="cont">
            <div className="flex-row jc-sb">
              <p className="creditor-name">이름 : {plaintiff.name}</p>
              <p className="rr-num">주민(법인)등록번호 : {getRRNumber(plaintiff)}</p>
            </div>
            <p>주소 : {plaintiff.displayAddress} {plaintiff.detailAddress}</p>
            <p>전화번호 : {getPhone(plaintiff)}</p>
            <p>이메일 주소 : {plaintiff.email}</p>
          </div>
        </div>
        
        <div className="row-item">
          <p className="ttl">채무자</p>
          <div className="cont">
            <div className="flex-row jc-sb">
              <p className="creditor-name">이름 : {defendant.name}</p>
              <p className="rr-num">주민(법인)등록번호 : {getRRNumber(defendant)}</p>
            </div>
            <p>사업자등록번호 : {getBNumber(defendant)}</p>
            <p>주소 : {defendant.isEmptyAddress ? '' : `${defendant.displayAddress} ${defendant.detailAddress}`}</p>
          </div>
        </div>

        <div className="row-item">
          <p className="ttl">조회대상기관 조회대상재산</p>
          <p className="cont flex-row">별지와 같음</p>
        </div>

        <div className="row-item">
          <p className="ttl">재산명시사건</p>
          <p className="cont flex-row">{getIncidentText()}</p>
        </div>

        <div className="row-item">
          <p className="ttl">집행권원</p>
          <p className="cont flex-row">{getExecutionText()}</p>
        </div>

        <div className="row-item">
          <p className="ttl">불이행 채권액</p>
          <p className="cont flex-row">{deptText}</p>
        </div>

        <div className="row-item">
          <p className="ttl">신청취지</p>
          <p className="cont flex-row">위 기관의 장에게 채무자 명의의 위 재산에 대하여 조회를 실시한다.</p>
        </div>

        <div className="row-item">
          <p className="ttl">신청사유</p>
          <div className="cont">
            <p>채권자는 아래와 같은 사유가 있으므로 민사집행법 제74조 제1항의 규정에 의하여 채무자에 대한 재산조회를 신청합니다. (해당란 □에 ∨표시)</p>
            <div className="checkbox-group flex-row">
              <div className="checkbox-item flex-row">
                {reasonArray.includes('명시기일 불출석') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                <span>명시기일 불출석</span>
              </div>
              <div className="checkbox-item flex-row">
                {reasonArray.includes('재산목록 제출거부') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                <span>재산목록 제출거부</span>
              </div>
            </div>
            <div className="checkbox-group flex-row">
              <div className="checkbox-item flex-row">
                {reasonArray.includes('선서 거부') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                <span>선서 거부</span>
              </div>
              <div className="checkbox-item flex-row">
                {reasonArray.includes('거짓 재산목록 제출') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                <span>거짓 재산목록 제출</span>
              </div>
            </div>
            <div className="checkbox-item flex-row">
              {reasonArray.includes('집행채권의 만족을 얻기에 부족함') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
              <span>집행채권의 만족을 얻기에 부족함</span>
            </div>
            <div className="checkbox-item flex-row">
              {reasonArray.includes('주소불명으로 인하여 명시절차를 거치지 못함') ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
              <span>주소불명으로 인하여 명시절차를 거치지 못함</span>
            </div>
          </div>
        </div>

        <div className="row-item">
          <p className="ttl">비용환급용 예금계좌</p>
          <div className="cont flex-row">
            <p>{account.bank} {account.no} {account.name}</p>
          </div>
        </div>

        <div className="row-item">
          <p className="ttl">첨부서류</p>
          <div className="cont flex-row">
          </div>
        </div>

        <div className="row-item signature-item">
          <p className="ttl">(인지 첨부란)</p>
          <div className="cont">
            <p>{moment(createdAt).format('YYYY. M. D.')}</p>
            <div className="flex-center">
              <span>신청인</span>
              <span>{plaintiff.name}</span>
              <span>(날인 또는 서명)</span>
            </div>
            <p>{court} 귀중</p>
          </div>
        </div>

      </div>

    </div>
  )
}