import React, { useState } from 'react';

import { FormControl, Select, MenuItem, TextField, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { get } from '../../utils/Api';
import { useEffect } from 'react';
import moment from 'moment';
import { addComma } from '../../utils/utils';

export default function PaymentListView() {
  // const [age, setAge] = useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  const [paymentList, setPaymentList] = useState([]);

  useEffect(() => {
    getPaymentList();
  }, []);

  const getPaymentList = () => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    get(`user/${user.id}/payment`, null)
    .then(response => {
      if(response.data.success) {
        console.log(response.data.data);
        setPaymentList(response.data.data.map(payment => {
          payment.created_at = moment(payment.created_at).format('YYYY-MM-DD HH:mm:ss');
          payment.payment_data = JSON.parse(payment.payment_data);
          //payment.complaint = JSON.parse(payment.complaint);
          payment.price = addComma(payment.payment_data.amount);
          return payment;
        }))
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="payment-list user page-view">
      <div className="h-top"></div>
      
      <div className="inner mw1280">
        <div className="head-area">
          <span className="head">결제 내역</span>
        </div>

        {/* <div className="search-area flex-row jc-sb">
          <div className="search-bx flex-row">
            <TextField
              variant="outlined"
              // value={}
              // onChange={onChangeName}
            />
            <Button className="search-btn">
              <SearchIcon />
            </Button>
          </div>

          <FormControl>
            <Select
              // value={age}
              // onChange={handleChange}
            >
              <MenuItem value={1}>최신순</MenuItem>
              <MenuItem value={2}>aa</MenuItem>
              <MenuItem value={3}>bb</MenuItem>
            </Select>
          </FormControl>
        </div> */}

        <ul className="list-area">
          {paymentList.map((payment, index) => (
            <li className="flex-row" key={`payment${index}`}>
              {/* <p className="status flex-center ing">진행중</p> */}
              <div className="info-txt">
                <p className="num">주문번호: {payment.id}</p>
                <p className="type">프리미엄 서비스</p>
                <p className="date">결제일: {payment.created_at}</p>
              </div>
              <p className="price">{payment.price}원</p>
            </li>
          ))}
           
          {/* <li className="flex-row">
            <p className="status flex-center">소장완료</p>
            <div className="info-txt">
              <p className="num">주문번호: 123456798</p>
              <p className="type">프리미엄 서비스</p>
              <p className="date">결제일: 2021-08-01 09:00</p>
            </div>
            <p className="price"> 0원</p>
          </li> */}
        </ul>
      </div>
    </div>
  )
}