import { useEffect, useState }  from 'react'

import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {koKR} from '@mui/x-date-pickers/locales/koKR'

import moment from 'moment';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/ko';


function SJDatePicker(props) {

  const [date, setDate] = useState()

  useEffect(() => {
    console.log(props)

    //setDate(moment(props.date).format('YYYY-MM-DD'))
    //setDate(new Date(date))

  }, [props])

  const changeDate = (date) => {
    console.log('changeDate', date, moment(date))
    
    if(props.onChangeDate)
      //props.onChangeDate(moment(date).format('YYYY.MM.DD'));
      props.onChangeDate(moment(new Date(date)).format('YYYY-MM-DD'));
  }

  return (
    <div className="date-picker">
      <LocalizationProvider 
        dateAdapter={AdapterDayjs}
        //adapterLocale={koKR}
      >
        <div>
          {/* <DatePicker
            mask={'____.__.__'}
            value={props.date}
            onChange={changeDate}
            renderInput={(params) => <TextField {...params} />}
          /> */}
          <DatePicker
            inputFormat={'YYYY.MM.DD'}
            mask={'____.__.__'}
            value={props.date}
            onChange={changeDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default SJDatePicker