
function LossContent(props) {
  return (
    <div className="loss-content">
      <p>{props.contentLeft}</p>
      <p>{props.contentRight}</p>
    </div>
  )
}

export default LossContent