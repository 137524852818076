export const addComma = (num: number|string|undefined) => {
  let regexp = /\B(?=(\d{3})+(?!\d))/g;

  if(typeof(num) === 'number') {
    return num.toString().replace(regexp, ',');
  }
  else if(typeof(num) === 'string'){
    return num.replace(regexp, ',');
  }
  
  return '0'

  
}