import '../Preview.scss';

import { useAppSelector } from '../../../../store';

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아'];

const Spacer = () => {
  return (
    <p style={{height: '20px'}}></p>
  )
}

export default function FactFindPreview(props) {

  const plaintiff = useAppSelector((state) => state.app.plaintiff);
  const defendant = useAppSelector((state) => state.app.defendantList[0]);
  const incident = useAppSelector((state) => state.app.application.incident);
  const telecom = useAppSelector((state) => state.app.application.telecom);

  const getName = (person) => {
    let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : getSpacingWord(person?.name);
    if(!name || name === '') name = 'O O O';

    if(person?.type === 0 || person?.type === 1) {
      if(person?.isEmptyName === true) {
        name = '성명 불상';
      }
    }
    else {
      if(person?.isEmptyCompany === true) {
        name = '성명 불상';
      }
    }

    return name;
  }

  const getRRNumber = (person) => {
    if(person.RRNumber1 !== '' && person.RRNumber2 !== '') {
      return `${person.RRNumber1}-${person.RRNumber2}`
    }

    if(person.isEmptyRRNumber) return '';
  
    return '000000-0000000';
  }

  const getAddress = (person) => {
    if(person.isEmptyAddress) return '주소 불상';

    return person.address !== '' ? person.address : '서울특별시 서초구 법원로3길 14';
  }

  const getPhone = (person) => {

    let phone = '010-0000-0000';

    if(person.phone1 != '' || person.phone2 != '' || person.phone3 != '') {
      phone = `${person.phone1}-${person.phone2}-${person.phone3}`;
    }

    if(person.isEmptyPhone) {
      phone = null;
    }


  
    return phone;
  }

  const isEmptySpotName = (person) => {
    if(person.type === 2 || person.type === 3) {
      if(person.spot !== '' || person.name !== '') {
        return false;
      }
    }

    return true;
  }

  const getSpacingWord = (word) => {
    return word.split('').join(' ')
  }

  return (
    <div className="inner-content fact-find-preview">
      <div className="plaintiff-area flex">
        <div><p>사</p><p>건</p></div>
        <div className="info-item">
          <p>{`${incident.year}${incident.code}${incident.subName} ${incident.name}`}</p>
        </div>
      </div>
      <div className="plaintiff-area flex">
        <div><p>원</p><p>고</p></div>
        <div className="info-group">
        <p>{plaintiff.name}</p>
        </div>
      </div>

      <div className="defendant-area flex">
      <div><p>피</p><p>고</p></div>
        <div className="info-group">
        <p>{defendant.name}</p>
        </div>
      </div>

      <Spacer/>

      <p>위 사건에 관하여 원고는 다음과 같이 사실조회를 신청합니다.</p>

      <Spacer/>

      <pre className="ta-center bold">다       음</pre>

      <Spacer/>

      <p className="bold">1.사실조회촉탁의 목적</p>

      <Spacer/>

      <p>이 사건 소송을 진행하기 위하여 휴대전화번호 {getPhone(defendant)}의 사용자 이름, 주민등 록번호, 주소 등 인적사항을 확인하기 위함입니다.</p>

      <Spacer/>

      <p className="bold">2. 사실조회기관의 명칭 및 주소</p>

      <Spacer/>

      <p>명칭 : {telecom?.company}</p>
      <p>주소 : {telecom?.address} {telecom?.detailAddress}</p>

      <Spacer/>

      <p className="bold">3. 사실조회사항</p>

      <Spacer/>

      <p>휴대전화(전화번호 : {getPhone(defendant)}) 가입자의 인적사항(1 성명, 2 주민등록번호, 3 주소, 4 근무지 등) 일체</p>

      <Spacer/>

    </div>
  )
}
