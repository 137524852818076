import React, { useState } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { changeUser } from '../../store/modules/app';


import { TextField, Button } from '@mui/material';

import WithDrawalView from './WithDrawalView';
import { useEffect } from 'react';

import { put } from '../../utils/Api';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../store';


export default function MyInfoView() {

  const dispatch = useDispatch()

  const user = useAppSelector((state) => state.app.user);
  const navigate = useNavigate()

  const [showWithdrawal, setShowWithDrawal] = useState(false);

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [curPassword, setCurPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  
  useEffect(() => {
    if(user) {
      setName(user.name);
      setPhone(user.phone);
      setEmail(user.email);
    }
  }, [user]);

  const completeDrop = () => {
    setShowWithDrawal(false);
    dispatch(changeUser(null))
    sessionStorage.removeItem('isLogin');
    sessionStorage.removeItem('user');
    navigate('/')
  }

  const editPassword = () => {
    if(curPassword.length === 0) {
      return alert('현재 비밀번호르 입력해주세요.');
    }

    if(newPassword.length === 0) {
      return alert('새 비밀번호르 입력해주세요.');
    }

    if(newPassword !== newConfirmPassword) {
      return alert('비밀번호가 일치하지 않습니다.');
    }

    put(`user/${user.id}/password`, {
      oldPw: curPassword,
      newPw: newPassword,
    }).then(response => {
      if(response.data.success) {
        alert('비밀번호 변경이 완료되었습니다.');
        setCurPassword('');
        setNewPassword('');
        setNewConfirmPassword('');
      }
      else {
        if(response.data.errorCode === 3) {
          alert('현재 비밀번호가 일치하지 않습니다.');
        }
      }
    })
    

    
  }
  
  return (
    <div className="myinfo user page-view">
      <div className="h-top"></div>
      <div className="inner mw1280">
        <div className="head-area">
          <span className="head">내정보</span>
        </div>

        <div className="cont-area">
          <div className="flex-row">
            <p className="ttl">이름</p>
            <TextField
              variant="outlined"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled
            />
          </div>

          <div className="flex-row">
            <p className="ttl">전화번호</p>
            <TextField
              variant="outlined"
              value={phone}
              onChange={e => setPhone(e.target.value)}
              disabled
            />
          </div>
          
          <div className="flex-row">
            <p className="ttl">아이디</p>
            <TextField
              variant="outlined"
              value={email}
              onChange={e => setEmail(e.target.value)}
              disabled
            />
          </div>

          <p className="sub-txt">* 비밀번호를 변경하지 않으려면 비워두세요. *</p>

          <div className="flex-row">
            <p className="ttl">현재 비밀번호</p>
            <TextField
              variant="outlined"
              value={curPassword}
              type="password"
              onChange={e => setCurPassword(e.target.value)}
            />
          </div>

          <div className="flex-row">
            <p className="ttl">새 비밀번호</p>
            <TextField
              variant="outlined"
              value={newPassword}
              type="password"
              onChange={e => setNewPassword(e.target.value)}
            />
          </div>

          <div className="flex-row">
            <p className="ttl">새 비밀번호 확인</p>
            <TextField
              variant="outlined"
              value={newConfirmPassword}
              type="password"
              onChange={e => setNewConfirmPassword(e.target.value)}
            />
          </div>
          {/* 불일치할 경우 클래스명 incorrect 추가, 일치할 경우 클래스명 incoreect 제거 */}
          {newPassword !== '' && <p className={`alert-txt ${newPassword !== newConfirmPassword && 'incorrect'}`}>비밀번호가 일치{newPassword === newConfirmPassword ? '합니다' : '하지 않습니다'}.</p>}

          <Button className="contained" onClick={e => editPassword()}>수정 완료</Button>

          <div className="btn-bx">
            <Button className="with-drawal" onClick={() => setShowWithDrawal(true)}>회원탈퇴</Button>
          </div>
        </div>
      </div>
      {showWithdrawal && (
          <WithDrawalView
            onClose={() => setShowWithDrawal(false)}
            onCompleteDrop={() => completeDrop()}
          />
        )}
    </div>
  )
}
