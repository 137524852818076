import { Person } from "../store/modules/app"

export type Bond = {
  id: number
  law: string
  price: number
  balance: number
  lendDate: string

  //대여금
  //fix: 정함, condition: 조건, reminder: 독촉, none: 정하지않음
  //매매대금
  //fix, division, none
  willPaybackDateType: string
  
  willPaybackDate: string
  interestRate: number
  realInterestRate: number
  deferInterestRate: number
  realDeferInterestRate: number
  isInterestRate: boolean
  interestPrice: number
  interestBalance: number
  isDeferInterestRate: boolean
  deferInterestPrice: number
  deferInterestBalance: number
  delayPrice: number
  isMerchant: boolean
  isRelativeWork: boolean //원고의 업무와 관련된 채권(대여금)
  jointGuarantorList: JointGuarantor[]
  
  //양수금
  assignmentDateType: number //채권양도 통지나 승낙
  assignmentDate1: string
  assignmentDate2: string
  assignmentDate3: string
  assignmentDate: string
  realAssignmentDate: string
  assignmentName: string //양도계약체결자
  isRelativeAssignment1: boolean
  isRelativeAssignment2: boolean

  //대여금, 약정금
  fixDate: string
  conditionDate: string
  reminderDate: string

  //임금
  joinDate: string
  leaveDate: string
  isLeave: boolean

  //매매대금
  tradingPriceContractDate: string
  //willPaybackDateType: 'none', //매매대금 지금 날짜 타입 fix, division, none
  //willPaybackDate: '2020-01-01', //단일 지급
  willPaybackDateList: any[] // 분할 지급인 경우
  tradingPrice: number //매매대금 총액
  stuffList: any[] //팔기로 한 물건, stuff(name, count, unit)
  willDeliveryDateType: string //물건을 인도하기로한 날짜 타입 fix, division, none
  willDeliveryDate: string //고정 인도약정일인 경우
  willDeliveryDateList: any[] // 분할 인도약정일인 경우, date, stuff(name, count, unit)
  didDeliveryDateType: string //all, part, none
  didDeliveryDate: string //물건을 인도한 날짜
  didPaybackDateList: any[]

  //매매대금-부동산
  landList: any[] //토지
  buildingList: any[] //건물
  didDeliveryDate01: string //소유권이전등기 경료일
  didDeliveryDate02: string //소유권이전등기에 필요한 서류를 계속 제공하고 있는 날짜

  //용역대금
  serviceTitle: string
  serviceType: string
  

  //임대차보증금
  isChangedMaster: boolean
  changedMasterDate: string
  changedMasterName: string
  depositPriceType: string
  depositPrice: number
  prevDepositPrice: number
  nextDepositPrice: number
  didDepositDate: string //보증금을 모두 지급한 날짜
  rentType: string
  monthRentPrice: number
  yearRentPrice: number
  contractDateType: string
  startContractDate: string
  endContractDate: string
  delayStartContractDate:  string
  delayEndContractDate: string
  delayFinalContractDate: string

  //계약종료
  contractExitReason: string //expiration: 만료  midway: 해지
  cancelContractDate: string

  //공제
  isDeduction: boolean
  isDeductionDeposit: boolean 
  deductionDeposit: number
  isDeductionRent: boolean 
  deductionRent: number
  isDeductionFix: boolean 
  deductionFix: number
  isDeductionEtc: boolean 
  deductionEtcReason: string
  deductionEtc: number

  //임대차 목적
  depositPurpose: string

  /**
   * 계약금반환(매매대금)
   */
   contractTradingPriceClaimReason: number

   //독촉관련 필드
   dunningType: number
   dunning1Date1: string
   dunning1Date2: string
   dunning1Date3: string
   dunning2Date1: string
   dunning2Date2: string
   dunning3Date1: string
   dunning3Date2: string
   dunning4Date1: string

   //해제 의사표시 관련 필드
   expressionType: number
   expression1Date1: string
   expression1Date2: string
   expression2Date1: string
}

export type Payback = {
  isChecked: boolean
  id: number
  defendant?: Person
  price: number
  balance: number
  date: string
}

export type JointGuarantor = {
  id: number
  defendant?: Person,
  isLimit: boolean
  price: number
  balance: number
}

export const initBond = (id: number, type: string = ''): Bond => {
  return {
    id: id,
    law: '',
    price: 10000,
    balance:  0,
    lendDate: '2019.01.01',

    willPaybackDateType: 'fix',
    
    willPaybackDate: '2020.03.01',
    interestRate: 0.0,
    realInterestRate: 0.0,
    deferInterestRate: 0.0,
    realDeferInterestRate: 0.0,
    isInterestRate: false,
    interestPrice: 0,
    interestBalance: 0,
    isDeferInterestRate: false,
    deferInterestPrice: 0,
    deferInterestBalance: 0,
    delayPrice: 0,
    isMerchant: false,
    isRelativeWork: false,
    jointGuarantorList: [],
    
    assignmentDateType: 0,
    assignmentDate1: '2019.01.01',
    assignmentDate2: '2019.01.01',
    assignmentDate3: '2019.01.01',
    assignmentDate: '2019.01.01',
    realAssignmentDate: '2019.01.01',
    assignmentName: '',
    isRelativeAssignment1: false,
    isRelativeAssignment2: false,

    fixDate: '2020.01.01',
    conditionDate: '2020.01.01',
    reminderDate: '2020.01.01',

    joinDate: '2022.01.01',
    leaveDate: '',
    isLeave: false,

    tradingPriceContractDate: '2020.01.01',
    willPaybackDateList: [],
    tradingPrice: 0,
    stuffList: [], //[{name: '사과', count: '100', unit: '개'}]
    willDeliveryDateType: 'none',
    willDeliveryDate: '2020-03-01',
    willDeliveryDateList: [], //{date: '2020-03-02', stuff: {name: '사과', count: 10, unit: '개'}}, {date: '2020-03-02', stuff: {name: '오렌지', count: 20, unit: '개'}}
    didDeliveryDateType: 'none',
    didDeliveryDate: type === '임대차보증금' ? '2020.12.31' : '2020.03.01',
    didPaybackDateList: [],

    landList: [], //{address: '서울 서초구 서초중앙로 198', category: '대', area: '300'}
    buildingList: [{address: '', detail: ''}],
    didDeliveryDate01: '2020.03.01',
    didDeliveryDate02: '2020.03.01',

    serviceTitle: '',
    serviceType: 'complete',
    

    isChangedMaster: false,
    changedMasterDate: '2019.01.01',
    changedMasterName: '',
    depositPriceType: 'fix',
    depositPrice: 100000,
    prevDepositPrice: 0,
    nextDepositPrice: 0,
    didDepositDate: '2018.02.01',
    rentType: 'none',
    monthRentPrice: 0,
    yearRentPrice: 0,
    contractDateType: 'none',
    startContractDate: '2018.01.01',
    endContractDate: '2020.12.31',
    delayStartContractDate:  '2018.01.01',
    delayEndContractDate: '2020.12.31',
    delayFinalContractDate: '2022.12.31',

    contractExitReason: 'midway',
    cancelContractDate: '2020.12.31',

    isDeduction: true,
    isDeductionDeposit: false,
    deductionDeposit: 0,
    isDeductionRent: false,
    deductionRent: 0,
    isDeductionFix: false,
    deductionFix: 0,
    isDeductionEtc: false,
    deductionEtcReason: '기타',
    deductionEtc: 0,

    depositPurpose: 'home',

    contractTradingPriceClaimReason: 0,

    dunningType: 0,
    dunning1Date1: '2020.04.01',
    dunning1Date2: '2020.04.15',
    dunning1Date3: '2020.04.05',
    dunning2Date1: '2020.04.01',
    dunning2Date2: '2020.04.05',
    dunning3Date1: '2020.04.01',
    dunning3Date2: '2020.04.15',
    dunning4Date1: '2020.04.01',

    expressionType: 0,
    expression1Date1: '2020.05.01',
    expression1Date2: '2020.05.15',
    expression2Date1: '2020.05.01',
  }
}

export const initJointGuarantor = (): JointGuarantor => {
  return {
    id: 0,
    defendant: undefined,
    isLimit: false,
    price: 0,
    balance: 0,
  }
}

export const initPayback = (id: number, isChecked: boolean): Payback => {
  return {
    isChecked: isChecked,
    id: id,
    price: 0,
    balance: 0,
    date: '2020.01.01'
  }
}