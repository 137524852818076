import { useCallback } from 'react';
import { connect, useDispatch} from 'react-redux'
import { refreshExecution, refreshApplicationCourt, refreshDeptText } from '../../store/modules/app';

import { TextField } from '@mui/material';

import Execution from '../components/application/Execution';
import SelectCourt from '../components/application/SelectCourt';
import { useAppSelector } from '../../store';

const Court = [
  '서울중앙지방법원', '서울동부지방법원', '서울남부지방법원', '서울북부지방법원', '서울서부지방법원', '의정부지방법원', '인천지방법원', '수원지방법원', '춘천지방법원', '대전지방법원', '청주지방법원', '대구지방법원', '부산지방법원', '울산지방법원', '창원지방법원', '광주지방법원', '전주지방법원', '제주지방법원'
]


//재산명시신청서
export default function AssetDisplay() {

  const dispatch = useDispatch()

  const execution = useAppSelector((state) => state.app.application.execution);
  const debt = useAppSelector((state) => state.app.application.deptText);
  const court = useAppSelector((state) => state.app.application.court);

  return (
    <div className="asset-display">
      {/* <p className="head">재산명시 신청서</p> */}

      <div className="cont-bx">
        <p className="ttl">1) 집행권원을 입력해 주세요.</p>
        <Execution 
          execution={execution}
          onData={execution => dispatch(refreshExecution(execution))}/>
        <hr />

        <p className="ttl">2) 채무자가 이행하지 아니하는 금전채무액을 입력해 주세요.</p>
        <div className="answer-area textarea-wrap">
          <TextField
            value={debt}
            onChange={e => dispatch(refreshDeptText(e.target.value))}
            multiline
            rows={2}
            placeholder="금 10,000원 및 이에 대한 2020. 1. 1.부터 다 갚는 날까지 연 20%의 비율에 의한 이자 및 지연손해금"
          />
        </div>

        <hr />

        <p className="ttl">3) 관할법원을 선택해 주세요.</p>
        <SelectCourt
          court={court}
          onSelectCourt={court => dispatch(refreshApplicationCourt(court))}
        />
      </div>
    </div>
  )
}

