
import { useEffect } from 'react';
import { connect } from 'react-redux';
import SJDatePicker from './SJDatePicker';
import { TextField, Radio, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app';

import _ from 'lodash';
import { useAppSelector } from '../../../store';

const REASON_LIST = [
  "상대방이 인도한 물건에 중요한 하자가 있습니다.",
  "상대방이 물건을 전혀 인도하지 아니하고 있습니다.",
  "상대방이 물건을 일부 인도하지 아니하고 있습니다.",
  "상대방이 물건을 인도할 수 없게 되었습니다.",
  "상대방이 물건을 인도하지 아니하겠다고 말합니다.",
  "제가 착오로 매매계약을 체결하였습니다.",
  "제가 상대방에게 속아 매매계약을 체결하였습니다.",
  "제가 상대방의 강압 때문에 매매계약을 체결하였습니다."
]

function ContractTradingPrice(props) {

  let complaint = useAppSelector((state) => state.app.complaint);

  useEffect(() => {
    if(complaint.bondList[0].didPaybackDateList.length === 0) {
      addBondDidPaybackDate(0);
    }
  }, []);

  const changeTradingPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPrice = price;
    props.refreshComplaint(c);
  }


  const changeStuffName = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].name = e.target.value;

    props.refreshComplaint(c);
  }

  const changeStuffCount = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].count = parseInt(e.target.value);

    props.refreshComplaint(c);
  }

  const changeStuffUnit = (e, bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList[stuffIndex].unit = e.target.value;

    props.refreshComplaint(c);
  }

  const addStuff = (bondIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList.push({name: '', count: '', unit: ''})

    props.refreshComplaint(c);
  }

  const removeStuff = (bondIndex, stuffIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.stuffList.splice(stuffIndex, 1);

    props.refreshComplaint(c);
  }


  const changeWillDeliveryDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willDeliveryDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillDeliveryDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.willDeliveryDate = date;

    props.refreshComplaint(c);
  }

  const addWillDeliveryDate = (bondIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    if(bond.willDeliveryDateList.length === 0) {
      bond.willDeliveryDateList = [{date: '2020-03-01', stuff: {name: '사과', count: 10, unit: '개'}}, {date: '2020-03-01', stuff: {name: '오렌지', count: 10, unit: '개'}}];
    }
    else {
      bond.willDeliveryDateList.push({date: '2020-03-01', stuff: {name: '사과', count: 10, unit: '개'}});
    }

    props.refreshComplaint(c);
  }

  const removeWillDeliveryDate = (bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList.splice(willDeliveryDateIndex, 1);

    props.refreshComplaint(c);
  }

  const changeWillDeliveryListDate = (date, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].date = date;

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffName = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.name = e.target.value;

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffCount = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.count = parseInt(e.target.value);

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateStuffUnit = (e, bondIndex, willDeliveryDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[bondIndex];

    bond.willDeliveryDateList[willDeliveryDateIndex].stuff.unit = e.target.value;

    props.refreshComplaint(c);
  }

  const changeClaimReason = (index, rIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.contractTradingPriceClaimReason = rIndex;

    props.refreshComplaint(c);
  }

  const changeDunningType = (index, type) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.dunningType = type;

    props.refreshComplaint(c);
  }

  const addBondDidPaybackDate = (index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.didPaybackDateList.push({price: 0, date: '2020.01.01'})

    props.refreshComplaint(c);
  }

  const removeBondDidPaybackDate = (index, dIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.didPaybackDateList.splice(dIndex, 1);

    props.refreshComplaint(c);
  }

  const changeBondDidPaybackDate = (date, index, didPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];
    bond.didPaybackDateList[didPaybackDateIndex].date = date;
    props.refreshComplaint(c);
  }

  const changeBondDidPaybackDatePrice = (e, index, didPaybackDateIndex) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.bondList[index].didPaybackDateList[didPaybackDateIndex].price = price;
    props.refreshComplaint(c);
  }

  const changeBond = (index, bond) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index] = bond;
    props.refreshComplaint(c);
  }

  return (
    <div className="contract-trading-price">
      <p className="head">계약금반환(매매대금)</p>
      {_.cloneDeep(complaint.bondList).map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          <Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon/></Button>
          <p className="ttl">1) 매매계약을 체결한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker 
              date={bond.tradingPriceContractDate}
              onChangeDate={date => changeBond(index, {...bond, tradingPriceContractDate: date})}
            />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />
          
          <p className="ttl">2) 매매대금 총액이 얼마인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              value={bond.tradingPrice === 0 ? '' : bond.tradingPrice}
              onChange={e => changeTradingPrice(e, index)}
              placeholder="1,000,000"
              className="input-price"
            />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />

          <div className="ttl-bx">
            <p className="ttl">3) 매매대금을 지급한 날짜가 언제인가요?</p>
            <Button className="add-btn btn-24" onClick={e => addBondDidPaybackDate(index)}><AddIcon /></Button>
          </div>
          {bond.didPaybackDateList.map((didPaybackDate, dIndex) => (
            <div className="answer-area flex-row item4" key={`didPaybackDate${index}${dIndex}`}>
              <SJDatePicker
                date={didPaybackDate.date}
                onChangeDate={date => changeBondDidPaybackDate(date, index, dIndex)}
              />
              <span className="mr_10">에</span>
              <TextField
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => changeBondDidPaybackDatePrice(e, index, dIndex)}
                placeholder="1,000,000"
                className="input-price"
              />
              <span className="mr_10">원입니다.</span>
              {dIndex !== 0 && <Button className="remove-btn btn-24" onClick={e => removeBondDidPaybackDate(index, dIndex)}><RemoveIcon /></Button>}
            </div>
          ))}

          <hr />

          <div className="ttl-bx">
            <p className="ttl">4) 사기로 한 물건이 무엇인가요?</p>
            <Button className="add-btn btn-24" onClick={e => addStuff(index)}><AddIcon /></Button>
          </div>
          {bond.stuffList.map((stuff, sIndex) => (
            <div className="answer-area flex-row item5" key={`stuff${index}${sIndex}`}>
              <TextField
                variant="outlined"
                value={stuff.name}
                onChange={e => changeStuffName(e, index, sIndex)}
                placeholder="물건명"
                className="input-price"
              />
              <TextField
                variant="outlined"
                value={stuff.count}
                onChange={e => changeStuffCount(e, index, sIndex)}
                placeholder="수량"
                className="input-percentage"
              />
              <TextField
                variant="outlined"
                value={stuff.unit}
                onChange={e => changeStuffUnit(e, index, sIndex)}
                placeholder="개"
                className="input-percentage"
              />
              <span>입니다.</span>
              {sIndex !== 0 && <Button className="remove-btn btn-24" onClick={e => removeStuff(index, sIndex)}><RemoveIcon /></Button>}
            </div>
          ))}

          <hr />

          <p className="ttl">5) 물건을 인도받기로 한 날짜가 언제인가요?</p>
          <div className="answer-area item6">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willDeliveryDateType === 'fix'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker
                date={bond.willDeliveryDate}
                onChangeDate={date => changeWillDeliveryDate(date, index)}
              />
              <span className="ml_4">입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='division'
                checked={bond.willDeliveryDateType === 'division'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span className="mr_10">분할하여 인도받기로 정하였습니다.</span>
              <Button className="add-btn btn-24" onClick={e => addWillDeliveryDate(index)}><AddIcon /></Button>
            </label>
            {bond.willDeliveryDateList.map((willDeliveryDate, wIndex) => (
              <div className="radio-sub-bx flex-row" key={`willDeliveryDate${index}${wIndex}`}>
                <div className="flex-row">
                  <SJDatePicker
                    date={willDeliveryDate.date}
                    onChangeDate={date => changeWillDeliveryListDate(date, index, wIndex)}
                  />
                  <span className="mr_10">에</span>
                  <TextField
                    variant="outlined"
                    value={willDeliveryDate.stuff.name}
                    onChange={e => changeWillDeliveryDateStuffName(e, index, wIndex)}
                    placeholder="물건명"
                    className="input-price"
                  />
                  <TextField
                    variant="outlined"
                    value={willDeliveryDate.stuff.count}
                    onChange={e => changeWillDeliveryDateStuffCount(e, index, wIndex)}
                    placeholder="수량"
                    className="input-percentage"
                  />
                  <TextField
                    variant="outlined"
                    value={willDeliveryDate.stuff.unit}
                    onChange={e => changeWillDeliveryDateStuffUnit(e, index, wIndex)}
                    placeholder="개"
                    className="input-percentage"
                  />
                </div>
                {wIndex !== 0 && wIndex !== 1 && <Button className="remove-btn btn-24" onClick={e => removeWillDeliveryDate(index, wIndex)}><RemoveIcon/></Button>}
              </div>
            ))}
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willDeliveryDateType === 'none'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">6) 매매대금 반환을 청구하는 이유가 무엇인가요?</p>
          {REASON_LIST.map((reason, rIndex) => (
            <label className="radio-item" key={`reason${index}${rIndex}`}>
              <Radio
                value={rIndex}
                checked={bond.contractTradingPriceClaimReason === rIndex}
                onChange={e => changeClaimReason(index, rIndex)}
              />
              <span>{reason}</span>
            </label>
          ))}

          {(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) && (
            <div>
              <hr />
              <p className="ttl">6-1) 상대방에게 물건을 인도하라고 독촉하였나요?</p>
              <div className="answer-area item7-1">
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 0}
                    onChange={e => changeBond(index, {...bond, dunningType: 0})}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning1Date1}
                    onChangeDate={date => {
                      bond.dunning1Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에</span>
                  <SJDatePicker
                    date={bond.dunning1Date2}
                    onChangeDate={date => {
                      bond.dunning1Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>까지 인도하라고</span>
                  <span className="mr_10">의사표시를 발송하였고, 상대방이</span>
                  <SJDatePicker
                    date={bond.dunning1Date3}
                    onChangeDate={date => {
                      bond.dunning1Date3 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 받았습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    //value={1}
                    checked={bond.dunningType === 1}
                    onChange={e => changeBond(index, {...bond, dunningType: 1})}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning2Date1}
                    onChangeDate={date => {
                      bond.dunning2Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에 지체없이 인도하라고 의사표시를</span>
                  <span className="mr_10">발송하였고, 상대방이</span>
                  <SJDatePicker
                    date={bond.dunning2Date2}
                    onChangeDate={date => {
                      bond.dunning2Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 받았습니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 2}
                    onChange={e => changeBond(index, {...bond, dunningType: 2})}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning3Date1}
                    onChangeDate={date => {
                      bond.dunning3Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에</span>
                  <SJDatePicker
                    date={bond.dunning3Date2}
                    onChangeDate={date => {
                      bond.dunning3Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>까지 인도하라고 </span>
                  <span>의사표시를 발송하였으나, 상대방이 받은 일시를 모릅니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 3}
                    onChange={e => changeBond(index, {...bond, dunningType: 3})}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning4Date1}
                    onChangeDate={date => {
                      bond.dunning4Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 지체없이 인도하라고 의사표시를 발송하였으</span>
                  <span className="mr_10">나, 상대방이 받은 일시를 모릅니다.</span>
                </label>
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 4}
                    onChange={e => {
                      let c = _.cloneDeep(complaint);

                      if(bond.serviceType === 'complete' && bond.contractTradingPriceClaimReason === 0) {
                        c.documentType = '소장';
                      }
                      else if(bond.serviceType === 'delivery' && (bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2)) {
                        c.documentType = '소장';
                      }

                      c.bondList[index].dunningType = 4;
                      props.refreshComplaint(c);
                    }}
                  />
                  <span>독촉하지 아니하였습니다.</span>
                  <span style={{fontSize: '10px'}}> (이 경우 지급명령 신청서는 선택할 수 없고, 소장을 선택해야 합니다)</span>
                </label>
              </div>
            </div>
          )}
          <hr />
          <p className="ttl">7) 상대방에게 매매계약을 해제한다는 의사표시를 하였나요?</p>
          <div className="answer-area item8">
            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 0}
                onChange={e => changeBond(index, {...bond, expressionType: 0})}
              />
              <span className="mr_10">제가</span>
              <SJDatePicker
                date={bond.expression1Date1}
                onChangeDate={date => {
                  bond.expression1Date1 = date;
                  changeBond(index, bond);
                }}
              />
              <span>에 매매계약을 해제한다는 의사표시를 발송하였</span>
              <span className="mr_10">고, 상대방이</span>
              <SJDatePicker
                date={bond.expression1Date2}
                onChangeDate={date => {
                  bond.expression1Date2 = date;
                  changeBond(index, bond);
                }}
              />
              <span>에 받았습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 1}
                onChange={e => changeBond(index, {...bond, expressionType: 1})}
              />
              <span className="mr_10">제가</span>
              <SJDatePicker
                date={bond.expression2Date1}
                onChangeDate={date => {
                  bond.expression2Date1 = date;
                  changeBond(index, bond);
                }}
              />
              <span>에 매매계약을 해제한다는 의사표시를 발송하였</span>
              <span>고, 상대방이 받은 일시를 모릅니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 2}
                onChange={e => {
                  let c = _.cloneDeep(complaint);

                  c.documentType = '소장';

                  c.bondList[index].expressionType = 2;
                  props.refreshComplaint(c);
                }}
              />
              <span>아직 아니하였습니다.</span>
              <span style={{fontSize: '10px'}}> (이 경우 지급명령 신청서는 선택할 수 없고, 소장을 선택해야 합니다)</span>
            </label>
          </div>
        </div>
      ))}
      {/* <Button className="contained">채권 추가</Button> */}
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractTradingPrice)