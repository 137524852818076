import josa from 'josa-js';
import moment from 'moment'

import {getText, wage, getTradingServiceText, deposit,
  contractTradingPriceText, contractServicePriceText,
  getLossText
} from './documents'
import { getServicePriceText } from './ServicePriceDocuments';
import { deliveryCharge } from './Const'
import _ from 'lodash';
import { addComma } from './common';


const civilLawRate = 5.0;
const commercialLawRate = 6.0;

const HANGUEL_NUMBER = ['일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십']


const diffDate = (startDate, endDate) => {
  
  startDate.add(-1, 'days');

  const years = endDate.diff(startDate, 'years');

  startDate.add(1, 'days');

  let isYoondal = false;

  if(years !== 0) {

    startDate.add(years, 'years');

    const curYear = startDate.year();

    if(moment([curYear]).isLeapYear()) {

      if((startDate.isSame(`${curYear}-02-29`) || moment(`${curYear}-02-29`).isAfter(startDate)) 
      && (endDate.isSame(`${curYear}-02-29`) || moment(`${curYear}-02-29`).isBefore(endDate))) {
        isYoondal = true;
      }

    }

  }
  else {
    const tempDate = moment(startDate);

    tempDate.add(365, 'days');

    for(let i = startDate.year(); i <= tempDate.year(); i++) {
      if(moment([i]).isLeapYear()) {
        if((startDate.isSame(`${i}-02-29`) || moment(`${i}-02-29`).isAfter(startDate)) && 
        (tempDate.isSame(`${i}-02-29`) || moment(`${i}-02-29`).isBefore(tempDate))) {
          isYoondal = true;
          break;
        }
      }
    }
  }

  return {
    yearCount: years,
    dateCount: endDate.diff(startDate, 'days') + 1,
    isYoondal: isYoondal
  }
}

const calcDelayPrice = (startDate, endDate, price, rate) => { //이자 계산

  console.log(startDate, endDate, price, rate)
  const dateInfo = diffDate(moment(startDate), moment(endDate));

  let delayPrice = 0;
  console.log('dateInfo',dateInfo)

  delayPrice = price * dateInfo.yearCount * +(rate * 0.01).toFixed(5);

  delayPrice += price * (dateInfo.dateCount / (dateInfo.isYoondal ? 366 : 365)) * +(rate * 0.01).toFixed(5);

  // return {
  //   ...dateInfo,
  //   delayPrice: Math.floor(delayPrice)
  // }

  console.log('calcDelayPrice', delayPrice)

  return Math.floor(delayPrice);
};

const calcDelayPriceInfo = (startDate, endDate, price, rate) => { //이자 계산

  const dateInfo = diffDate(moment(startDate), moment(endDate));

  let delayPrice = 0;

  delayPrice = price * dateInfo.yearCount * +(rate * 0.01).toFixed(5);

  delayPrice += price * (dateInfo.dateCount / (dateInfo.isYoondal ? 366 : 365)) * +(rate * 0.01).toFixed(5);

  return {
    price: Math.floor(delayPrice),
    dateCount: dateInfo.dateCount,
    yearCount: dateInfo.yearCount,
    dateOfYear: dateInfo.isYoondal ? 366 : 365
  }
};

function calcPrice(price, rate, startDate, endDate) {
  const dateInfo = diffDate(moment(startDate), moment(endDate));

  let delayPrice = 0;

  delayPrice = price * dateInfo.yearCount * +(rate * 0.01).toFixed(5);

  delayPrice += price * (dateInfo.dateCount / (dateInfo.isYoondal ? 366 : 365)) * +(rate * 0.01).toFixed(5);

  // return {
  //   ...dateInfo,
  //   delayPrice: Math.floor(delayPrice)
  // }

  return Math.floor(delayPrice);
}

const calcDeferInterestPrice = (B, C, D, E, F, balance, deferInterestRate, isLast, N, L, didPaybackDate, willDeliveryDate, didDeliveryDate) => {

  let deferInterestPrice = 0;

  if(!B && !C && !D && !E && !F) { //1
    
  }
  else if(!B && !C && !D && !E && F) { //2
    
  }
  else if(!B && !C && !D && E && !F) { //3
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && !D && E && F) { //4
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && !E && !F) { //5
    
  }
  else if(!B && !C && D && !E && F) { //6
    
  }
  else if(!B && !C && D && E && !F) { //7
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && E && F) { //8
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && C && !D && !E && !F) { //9
    
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < didDeliveryDate) {
      if(didDeliveryDate < didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365')

        //U열 : 인도일
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      console.log('원금*(P-U열+1)*지손금율/365')

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
      
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && !E && !F) { //13
    
  }
  else if(!B && C && D && !E && F) { //14
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && E && F) { //16
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      /**
       * N열: 변제기 다음날
       * P열: 잔금 변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {

    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) && nextDate(N) < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < didDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
        else {
          if(nextDate(N) <= didPaybackDate) {
            console.log('원금*(P-N열+1)*지손금율/365');

            deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
          }
        }
      }
      else {
        /**
         * N열: 
         */
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열:
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < L) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(L <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //58행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      /**
       * N열: 
       * P열: 잔금변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {
      
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) &&
      (nextDate(N) < willDeliveryDate)) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //77행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  
  return parseInt(deferInterestPrice);
}

const calcAppropriationForPerformance  = (_complaint, _plaintiff, _defendantList) => {
  
  let result;
  
  let complaint = {..._complaint}
  let plaintiff = {..._plaintiff}
  let defendantList = [..._defendantList]
  let bondList = complaint.bondList;
  let didPaybackDateList = complaint.didPaybackDateList;
  
  let textList = [];

  complaint.courtList = [];

  //주소 표기 변경
  if(plaintiff.address != '') {
    const address = plaintiff.address;

    const addressList = address.split(' ');
    
    if(addressList[0] == '경기' && addressList[1].charAt(addressList[1].length - 1) == '시') {
      plaintiff.displayAddress = plaintiff.address.replace('경기 ', '');
    }
    else if(addressList[0] == '강원' && addressList[1].charAt(addressList[1].length - 1) == '시') {
      plaintiff.displayAddress = plaintiff.address.replace('강원 ', '');
    }
    else if(addressList[0] == '제주특별자치도') {
      plaintiff.displayAddress = plaintiff.address.replace('제주특별자치도 ', '');
    }
    else {
      if(addressList[1].charAt(addressList[1].length - 1) == '시') {
        plaintiff.displayAddress = plaintiff.address.replace(addressList[0] + ' ', '');  
      }
      else {
        plaintiff.displayAddress = plaintiff.address
      }
    }
  }

  defendantList = defendantList.map((v) => {

    const defendant = {...v}

    if(defendant.address != '') {
      const address = defendant.address;
      
      const addressList = address.split(' ');
      if(addressList[0] == '경기' && addressList[1].charAt(addressList[1].length - 1) == '시') {
        defendant.displayAddress = defendant.address.replace('경기 ', '');
      }
      else if(addressList[0] == '강원' && addressList[1].charAt(addressList[1].length - 1) == '시') {
        defendant.displayAddress = defendant.address.replace('강원 ', '');
      }
      else if(addressList[0] == '제주특별자치도') {
        defendant.displayAddress = defendant.address.replace('제주특별자치도 ', '');
      }
      else {
        if(addressList[1].charAt(addressList[1].length - 1) == '시') {
          defendant.displayAddress = defendant.address.replace(addressList[0] + ' ', '');  
        }
        else {
          defendant.displayAddress = defendant.address;
        }
      }
    }

    return defendant
  })

  complaint = findCourt(complaint, plaintiff, defendantList);
  
  if(complaint.type === '임금') {
    complaint.claimPrice = complaint.bondList[0].price;
    complaint = calcEtc(complaint, defendantList);
    result = wage(complaint, defendantList, textList);
    return {
      complaint, 
      textList: result.textList,
      claimPurpose: result.claimPurpose,
      claimCauseList: result.claimCauseList,
      plaintiff: plaintiff,
      defendantList: defendantList
    };
  }
  else if(complaint.type === '임대차보증금') {
    result = deposit(complaint, defendantList, textList);
    return {
      complaint, 
      textList: result.textList,
      claimPurpose: result.claimPurpose,
      claimCauseList: result.claimCauseList,
      plaintiff: plaintiff,
      defendantList: defendantList
    };
  }
  else if(complaint.type === '매매대금반환') {
    bondList = checkLaw(complaint, bondList, plaintiff, defendantList);
    complaint.claimPrice = complaint.bondList.map(b => b.tradingPrice).reduce((a, c) => a + c, 0);
    complaint = calcEtc(complaint, defendantList);
    result = contractTradingPriceText(complaint, defendantList, textList);
    return {
      complaint, 
      textList: result.textList,
      claimPurpose: result.claimPurpose,
      claimCauseList: result.claimCauseList,
      plaintiff: plaintiff,
      defendantList: defendantList
    };
  }
  else if(complaint.type === '용역대금반환') {
    bondList = checkLaw(complaint, bondList, plaintiff, defendantList);
    complaint.claimPrice = complaint.bondList.map(b => b.tradingPrice).reduce((a, c) => a + c, 0);
    complaint = calcEtc(complaint, defendantList);
    result = contractServicePriceText(complaint, defendantList, textList);
    return {
      complaint, 
      textList: result.textList,
      claimPurpose: result.claimPurpose,
      claimCauseList: result.claimCauseList,
      plaintiff: plaintiff,
      defendantList: defendantList
    };
  }
  else if(complaint.type === '손해배상') {
    result = getLossText(complaint, defendantList, textList);
    complaint.claimPrice = complaint.lossList.map((loss => loss.priceList.filter(p => p.isSelected).map(p => p.price).reduce((a, c) => a + c, 0))).reduce((a, c) => a + c, 0);
    complaint = calcEtc(complaint, defendantList);
    return {
      complaint, 
      hurtTextList: result.hurtTextList,
      lossPriceTextList: result.lossPriceTextList,
      claimPurpose: result.claimPurpose,
      claimCauseList: result.claimCauseList,
      plaintiff: plaintiff,
      defendantList: defendantList
    };
  }

  bondList = bondList.map(v => {

    const bond = {...v}
    
    bond.balance = bond.price;
    bond.gauranteeBalance = bond.price;
    bond.interestPrice = 0;
    bond.interestBalance = 0;
    bond.deferInterestPrice = 0;
    bond.deferInterestBalance = 0;

    console.log(complaint.type, bond.fixDate)

    if(complaint.type === '대여금' || complaint.type === '약정금' || complaint.type === '양수금') {
      if(bond.willPaybackDateType === 'fix') bond.willPaybackDate = bond.fixDate;
      else if(bond.willPaybackDateType === 'condition') bond.willPaybackDate = bond.conditionDate;
      else if(bond.willPaybackDateType === 'reminder') bond.willPaybackDate = bond.reminderDate;
      else bond.willPaybackDate = ''
    }

    return bond
  });

  didPaybackDateList = didPaybackDateList.map(v =>  {
    const didPaybackDate = {...v}
    didPaybackDate.balance = didPaybackDate.price
    return didPaybackDate
  })
  

  bondList = bondList.map(v => {
    const bond = {...v}
    bond.jointGuarantorList = bond.jointGuarantorList.map(j => {
      if(j.isLimit) {
        j.balance = j.price
      }
    });

    bond.realWillPaybackDate = bond.willPaybackDate;

    //소유권이전등기에 필요한 서류를 계속 제공하는 날짜
    if(complaint.type === '매매대금' && complaint.tradingPriceType === '부동산') {
      if(bond.didDeliveryDateType === 'all') {
        bond.didDeliveryDate = bond.didDeliveryDate01; //소유권 이전 등기 경료날짜
      }
      else if(bond.didDeliveryDateType === 'part') {
        bond.didDeliveryDate = bond.didDeliveryDate02;
      }
    }

    if((complaint.type === '매매대금' || complaint.type === '용역대금') && bond.willPaybackDate === '') {
      if(bond.didDeliveryDateType === 'all') {
        bond.realWillPaybackDate = bond.didDeliveryDate;
      }
    }

    return bond;

  })

  bondList = checkLaw(complaint, bondList, plaintiff, defendantList);

  bondList = checkRealRate(bondList);

  //연대보증 처리
  calcJointGuarantor(didPaybackDateList, bondList);

  if(complaint.type === '매매대금' || complaint.type === '용역대금') {
    /**
       * 마지막 변제기, 인도약정일
       */
    bondList = bondList.map((v, index) => {
      const bond = {...v}

      bond.lastWillPaybackDate = bond.willPaybackDate; //마지막 변제기
      
      if(bond.willPaybackDateType === 'division') {
        const lastWillPaybackDateArray = bond.willPaybackDateList.map(d => d).sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0).map(p => p.date).slice(0, 1);
        bond.lastWillPaybackDate = lastWillPaybackDateArray.length !== 0 ? lastWillPaybackDateArray[0] : '';
      }

      bond.finalWillDeliveryDate = ''; //인도약정일
      if(bond.willDeliveryDateType === 'fix') {
        bond.finalWillDeliveryDate = bond.willDeliveryDate;
      }
      else if(bond.willDeliveryDateType === 'division') {
        
        const lastWillDeliveryDateArray =  bond.willDeliveryDateList.map(d => d).sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0).map(p => p.date).slice(0, 1);
        bond.finalWillDeliveryDate = lastWillDeliveryDateArray.length !== 0 ? lastWillDeliveryDateArray[0] : '';
      }

      console.log(`채권 ${index + 1}, 마지막 변제기 = ${bond.lastWillPaybackDate}, 인도약정일 = ${bond.finalWillDeliveryDate}`);

      return bond
    })
  }

  let virtualBondList = [];
  if(complaint.type === '매매대금' || complaint.type === '용역대금') {
    bondList.forEach((bond, bondIndex) => {
      const mainPigo = defendantList[0].typeIndex !== 0 ? defendantList[0].company : defendantList[0].name;
      let pigo = '';

      if(defendantList.length === 1) {
        pigo = '피고는 ';
      }
      else {
        if(bond.jointGuarantorList.length === 0) {
          pigo = `피고 ${josa.r(mainPigo, '은/는')} `;
        }
        else {
          if(defendantList.length - 1 <= bond.jointGuarantorList.length) {
            pigo = '피고들은 연대하여 ';
          }
          else {
            const pigoList = [mainPigo].concat(bond.jointGuarantorList.map(j => j.defendant.typeIndex !== 0 ? j.defendant.company : j.defendant.name));
            pigo = `피고 ${pigoList.join(', ')}${josa.c(pigoList[pigoList.length - 1], '은/는')} 연대하여 `;
          }
        }
      }

      if(bond.willPaybackDateType === 'none') { //변제기가 없는 경우
        virtualBondList.push({
          id: virtualBondList.length,
          bondIndex: bondIndex,
          tradingPricePaybackIndex: -1,
          lendDate: bond.tradingPriceContractDate,
          unit: '',
          isLast: false,
          lastN: '',
          law: bond.law,
          realInterestRate: bond.realInterestRate,
          isDeferInterestRate: bond.isDeferInterestRate,
          realDeferInterestRate: bond.realDeferInterestRate,
          price: parseInt(bond.tradingPrice),
          balance: parseInt(bond.tradingPrice),
          tradingPriceBalance: parseInt(bond.tradingPrice),

          interestRate: bond.realInterestRate,
          deferInterestRate: bond.realDeferInterestRate,

          interestBalance: 0,
          deferInterestBalance: 0,
  
          willPaybackDate: '',
          lastWillPaybackDate: bond.lastWillPaybackDate,
          willDeliveryDate: bond.finalWillDeliveryDate,
          didDeliveryDate: bond.didDeliveryDateType === 'all' ? bond.didDeliveryDate : '',
  
          compareRate: bond.realInterestRate,
  
          isPayment: false,
          pigo: pigo,
          defendant: bond.defendant,

          serviceType: bond.serviceType,
          
          jointGuarantorList: bond.jointGuarantorList
        })
  
      }
      else if(bond.willPaybackDateType === 'fix') { //변제기가 1개
        const virtualBond = {
          id: virtualBondList.length,
          bondIndex: bondIndex,
          tradingPricePaybackIndex: -1,
          lendDate: bond.tradingPriceContractDate,
          unit: '',
          isLast: false,
          lastN: '',
          law: bond.law,
          realInterestRate: bond.realInterestRate,
          isDeferInterestRate: bond.isDeferInterestRate,
          realDeferInterestRate: bond.realDeferInterestRate,

          interestRate: bond.realInterestRate,
            deferInterestRate: bond.realDeferInterestRate,
  
          price: parseInt(bond.tradingPrice),
          balance: parseInt(bond.tradingPrice),
          tradingPriceBalance: parseInt(bond.tradingPrice),

          interestBalance: 0,
          deferInterestBalance: 0,
  
          willPaybackDate: bond.willPaybackDate,
          lastWillPaybackDate: bond.lastWillPaybackDate,
          willDeliveryDate: bond.finalWillDeliveryDate,
          didDeliveryDate: bond.didDeliveryDateType === 'all' ? bond.didDeliveryDate : '',
  
          isPayment: false,
          pigo: pigo,
          defendant: bond.defendant,

          serviceType: bond.serviceType,

          jointGuarantorList: bond.jointGuarantorList
        }
  
        virtualBondList.push(virtualBond);
      }
      else { //분할 변제
        bond.willPaybackDateList.forEach((willPaybackDate, tradingPricePaybackIndex) => {
          const isLast = bond.willPaybackDateList.length - 1 === tradingPricePaybackIndex;
          let lastN = '';
          if(isLast && bond.willPaybackDateList.length !== 1) {
            lastN = bond.willPaybackDateList[tradingPricePaybackIndex - 1].date;
          }
          const virtualBond = {
            id: virtualBondList.length,
            bondIndex: bondIndex,
            tradingPricePaybackIndex: tradingPricePaybackIndex,
            lendDate: bond.tradingPriceContractDate,
            unit: willPaybackDate.unit,
            isLast: bond.willPaybackDateList.length - 1 === tradingPricePaybackIndex,
            law: bond.law,
            realInterestRate: bond.realInterestRate,
            isDeferInterestRate: bond.isDeferInterestRate,
            realDeferInterestRate: bond.realDeferInterestRate,

            interestRate: bond.realInterestRate,
            deferInterestRate: bond.realDeferInterestRate,
  
            lastN: lastN, //L상황인 경우 마지막 N
  
            price: parseInt(willPaybackDate.price),
            //balance: bond.didPaybackDateList.filter(p => p.isChecked).map(p => p.unit).includes(willPaybackDate.unit) ? 0 : parseInt(willPaybackDate.price),
            balance: bond.didPaybackDateList[tradingPricePaybackIndex].isChecked ? 0 : parseInt(willPaybackDate.price),
            tradingPriceBalance: parseInt(willPaybackDate.price),
  
            interestBalance: 0,
            deferInterestBalance: 0,

            willPaybackDate: willPaybackDate.date,
            lastWillPaybackDate: bond.lastWillPaybackDate,
            willDeliveryDate: bond.finalWillDeliveryDate,
            didDeliveryDate: bond.didDeliveryDateType === 'all' ? bond.didDeliveryDate : '',
  
            ///isPayment: bond.didPaybackDateList.filter(p => p.isChecked).map(p => p.unit).includes(willPaybackDate.unit),
            isPayment: bond.didPaybackDateList[tradingPricePaybackIndex].isChecked,
            pigo: pigo,
            defendant: bond.defendant,

            serviceType: bond.serviceType,

            jointGuarantorList: bond.jointGuarantorList
          }

          virtualBondList.push(virtualBond);
        })
      }
    });
  }

  
  let paymentTableList = [];

  if(complaint.type === '매매대금') {
    const {bList, pList} = calcAll(complaint.type, didPaybackDateList, virtualBondList);
    virtualBondList = bList
    paymentTableList = pList
  }
  else {
    const {bList, pList} = calcAll(complaint.type, didPaybackDateList, bondList);
    bondList = bList
    paymentTableList = pList
  }

  bondList.forEach((bond, index) => {
    textList.push(`${index + 1}번 채권, ${bond.law}, 적용이율:${bond.realInterestRate}, 적용지손율:${bond.realDeferInterestRate}`)
    bond.jointGuarantorList.forEach(j => {
      textList.push(`연대보증인 ${j.defendant?.name}, 보증한도${j.isLimit ? `: ${j.price}, 남은 보증한도: ${j.balance}` : ' 정하지 않음'}`)
    })
    textList.push('==========================')
  })

  paymentTableList.forEach(paymentTable => {
    textList.push(`원금 : ${paymentTable.beforePrice}, 이자: ${paymentTable.beforeInterestPrice}, 지손금: ${paymentTable.beforeDeferInterestPrice} / 원금 잔액 : ${paymentTable.afterPrice}
      이자 잔액 : ${paymentTable.afterInterestPrice}, 지손금잔액 : ${paymentTable.afterDeferInterestPrice}`)
    // textList.push(`원금 : ${bond.price}, 이자: ${bond.interestPrice}, 지손금: ${bond.deferInterestPrice}, 이자합계: ${bond.interestPrice + bond.deferInterestPrice}, 원금 잔액 : ${bond.balance}
    //   이자 잔액 : ${bond.interestBalance}, 지손금잔액 : ${bond.deferInterestBalance}`)
  });

  if(paymentTableList.length !== 0) {
    textList.push('==========================')
  }

  complaint.didPaybackDateList.sort((p1, p2) => p1.id < p2.id ? -1 : 1)

  complaint.virtualBondList = virtualBondList;

  if(complaint.type === '매매대금' || complaint.type === '용역대금') {
    complaint.claimPrice = virtualBondList.map(b => b.balance).reduce((a, c) => a + c, 0);
  }
  else {
    complaint.claimPrice = bondList.map(b => b.balance).reduce((a, c) => a + c, 0);
  }
  
  calcEtc(complaint, defendantList);

  //props.setTextList(textList);
  //props.refreshComplaint(complaint);

  complaint.didPaybackDateList = didPaybackDateList
  complaint.bondList = bondList
  complaint.virtualBondList = virtualBondList
  complaint.paymentTableList = paymentTableList;

  if(complaint.type === '매매대금') {
    result = getTradingServiceText(complaint, defendantList, textList);
  }
  else if(complaint.type === '용역대금') {
    result = getServicePriceText(complaint, defendantList, textList);
  }
  else {
    result = getText(complaint, plaintiff, defendantList, textList);
  }

  
  return {
    complaint, 
    textList: result.textList,
    claimPurpose: result.claimPurpose,
    claimCauseList: result.claimCauseList,
    plaintiff: plaintiff,
    defendantList: defendantList
  };
}

const calcPaymentOrder = (bondList) => { //변제충당 순서 정렬

  let orderIdArray = [];

  if(bondList.length !== 0) {
    const rateArray = Array.from(new Set(bondList.map(b => b.rate))).sort((a, b) => a > b ? -1 : 1);
    if(bondList.length === rateArray.length) { //지연손해금율이 모두 다른 경우
      bondList = bondList.sort((b1, b2) => b1.rate > b2.rate ? -1 : 1)
      orderIdArray = orderIdArray.concat(bondList.map(b => [b.id]));
    }
    else { //지연손해금율 중복 있음
      rateArray.forEach(rate => {
        const equalRateBondList = bondList.filter(b => b.rate === rate);
        const willDateArray = Array.from(new Set(equalRateBondList.map(b => b.date))).sort((a, b) => a > b ? 1 : -1);

        if(willDateArray.length === equalRateBondList.length) { //변제기가 모두 다른 경우
          orderIdArray = orderIdArray.concat(
            equalRateBondList
            .filter(b => b.rate === rate)
            .sort((b1, b2) => b1.date > b2.date ? 1 : -1)
            .map(b => [b.id])
          );
        }
        else if(willDateArray.length === 1) { //변제기가 모두 같은 경우(안분배당))
          orderIdArray = orderIdArray.concat(
            [
              equalRateBondList
              .filter(b => b.rate === rate)
              .sort((b1, b2) => b1.date > b2.date ? 1 : -1)
              .map(b => b.id)
            ]
          );
        }
        else { //변제기가 같은 것도 있고 아닌것도 있고
          willDateArray.forEach(willDate => {
            orderIdArray = orderIdArray.concat(
              [
                equalRateBondList
                .filter(b => b.date === willDate)
                .map(b => b.id)
              ]
            )
          })
        }
        
      })
    }
  }

  return orderIdArray;
}

const checkRealRate = (bondList) => { //실제 적용 이율 계산
  console.log('checkRealRate', bondList);
  return bondList.map(value => {
    const bond = {...value}
    const I = bond.isInterestRate;
    const W = bond.realWillPaybackDate !== '';
    const D = bond.isDeferInterestRate;

    bond.realInterestRate = bond.isInterestRate ? bond.interestRate : 0.0

    if(W && D) {
      bond.realDeferInterestRate = bond.deferInterestRate;
    }
    else if(W && !D) {
      if(I) {
        if(bond.law === '민법' && bond.realInterestRate < civilLawRate) {
          bond.realDeferInterestRate = civilLawRate;
        }
        else if(bond.law === '상법' && bond.realInterestRate < commercialLawRate) {
          bond.realDeferInterestRate = commercialLawRate;
        }
        else {
          bond.realDeferInterestRate = bond.interestRate;
        }
        
      }
      else {
        //민법은 5%, 상법은 6%
        if(bond.law === '민법') 
          bond.realDeferInterestRate = civilLawRate;
        else if(bond.law === '상법') 
          bond.realDeferInterestRate = commercialLawRate;
        else bond.realDeferInterestRate = 0;
      }
    }
    else if(!W && D) {
      if(I) bond.realDeferInterestRate = bond.interestRate;
      else {
        //상법인 경우 6% 아닌경우 0
        if(bond.law === '상법')
          bond.realDeferInterestRate = commercialLawRate;
        else
          bond.realDeferInterestRate = 0.0;
      }
    }
    else {
      if(I) bond.realDeferInterestRate = bond.interestRate;
      else {
        //상법인 경우 6% 아닌경우 0
        if(bond.law === '상법')
          bond.realDeferInterestRate = commercialLawRate;
        else
          bond.realDeferInterestRate = 0.0;
      }
    }

    return bond
  })
}

const checkLaw = (complaint, bondList, plaintiff, defendantList) => { //상법, 민법 체크

  return bondList.map(value => {
    const bond = {...value}
    if(complaint.type === '대여금') {
      if(plaintiff.isMerchant && bond.isRelativeWork) {
        bond.law = '상법';
      }
      else {
        bond.law = '민법';
      }
    }
    else if(complaint.type === '약정금' || complaint.type === '매매대금' || complaint.type === '용역대금') {
      if(plaintiff.isMerchant) {
        bond.law = '상법';
      }
      else {
        if(defendantList.filter(d => d.isMerchant).length !== 0) {
          bond.law = '상법';
        }
        else {
          bond.law = '민법';
        }
      }
    }
    else if(complaint.type === '임금') {
      if(defendantList.filter(d => d.isMerchant).length !== 0) {
        bond.law = '상법';
      }
      else {
        bond.law = '민법';
      }
    }
    else if(complaint.type === '양수금') {
      if(plaintiff.isMerchant && bond.isRelativeAssignment1 && bond.isRelativeAssignment2) {
        bond.law = '상법';
      }
      else {
        bond.law = '민법';
      }
    }
    // else if(complaint.type === '매매대금') {
      
    // }
    // else if(complaint.type === '용역대금') {
      
    // }
    else if(complaint.type === '매매대금반환' || complaint.type === '용역대금반환') {
      if(plaintiff.isMerchant || defendantList.filter(d => d.isMerchant).length !== 0) {
        bond.law = '상법';
      }
      else {
        bond.law = '민법';
      }
    }

    return bond;
  })
}

const checkLimitRate = (bondList) => { //이자제한법

  bondList.forEach(bond => {

    const date = bond.lendDate;

    if(moment(date).isAfter('1962-01-15') && moment(date).isBefore('1965-09-23')) {
      //bond.realInterestRate
    }
    else if(moment(date).isAfter('1965-09-24') && moment(date).isBefore('1972-08-02')) {

    }
  })

  // if(this.checkBeforeAndAfter(startDate, '1962-1-15') == 'after' && this.checkBeforeAndAfter(startDate, '1965-9-23') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1965-9-23') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 20 ? 20 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1965-9-23')),
  //       interestRate: interestRate > 20 ? 20 : interestRate
  //     });
  //   }
  //   startDate = new Date('1965-9-24')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1965-9-24') == 'after' && this.checkBeforeAndAfter(startDate, '1972-8-2') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1972-8-2') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 35.5 ? 35.5 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1972-8-2')),
  //       interestRate: interestRate > 35.5 ? 35.5 : interestRate
  //     });
  //   }
  //   startDate = new Date('1972-8-3')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1972-8-3') == 'after' && this.checkBeforeAndAfter(startDate, '1980-1-11') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1980-1-11') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 25.0 ? 25.0 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1980-1-11')),
  //       interestRate: interestRate > 25.0 ? 25.0 : interestRate
  //     });
  //   }
  //   startDate = new Date('1980-1-12')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1980-1-12') == 'after' && this.checkBeforeAndAfter(startDate, '1983-12-15') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1983-12-15') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 40.0 ? 40.0 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1983-12-15')),
  //       interestRate: interestRate > 40.0 ? 40.0 : interestRate
  //     });
  //   }
  //   startDate = new Date('1983-12-16')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1983-12-16') == 'after' && this.checkBeforeAndAfter(startDate, '1997-12-21') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1997-12-21') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 25.0 ? 25.0 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1997-12-21')),
  //       interestRate: interestRate > 25.0 ? 25.0 : interestRate
  //     });
  //   }
  //   startDate = new Date('1997-12-22')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1997-12-22') == 'after' && this.checkBeforeAndAfter(startDate, '1998-1-12') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '1998-1-12') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 40.0 ? 40.0 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('1998-1-12')),
  //       interestRate: interestRate > 40.0 ? 40.0 : interestRate
  //     });
  //   }
  //   startDate = new Date('1998-1-13')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '1998-1-13') == 'after' && this.checkBeforeAndAfter(startDate, '2007-6-29') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '2007-6-29') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('2007-6-29')),
  //       interestRate: interestRate
  //     });
  //   }
  //   startDate = new Date('2007-6-30')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '2007-6-30') == 'after' && this.checkBeforeAndAfter(startDate, '2014-7-14') == 'before') {
  //   if(this.checkBeforeAndAfter(date2, '2014-7-14') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 30.0 ? 30.0 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('2014-7-14')),
  //       interestRate: interestRate > 30.0 ? 30.0 : interestRate
  //     });
  //   }
  //   startDate = new Date('2014-7-15')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '2014-7-15') == 'after' && this.checkBeforeAndAfter(startDate, '2018-2-7') == 'before') {
  //   //console.log('2014-7-15 ~ 2018-2-7 사이')
  //   if(this.checkBeforeAndAfter(date2, '2018-2-7') == 'before') {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, date2),
  //       interestRate: interestRate > 25 ? 25 : interestRate
  //     });
  //   }
  //   else {
  //     countInfoList.push({
  //       countInfo: this.dateDiff('이자', startDate, new Date('2018-2-7')),
  //       interestRate: interestRate > 25 ? 25 : interestRate
  //     });
  //   }
  //   startDate = new Date('2018-2-8')
  // }
  // else if(this.checkBeforeAndAfter(startDate, '2018-2-8') == 'after') {
  //   //console.log('2014-2-8 이후')
  //   countInfoList.push({
  //     countInfo: this.dateDiff('이자', startDate, date2),
  //     interestRate: interestRate > 24 ? 24 : interestRate
  //   });
  //   startDate = new Date(date2);
  // }
}

const calcJointGuarantor = (didPaybackDateList, bondList) => {
  didPaybackDateList.forEach(didPaybackDate => {
    bondList.forEach(bond => {
      if(bond.jointGuarantorList.length !== 0) {
        if(didPaybackDate.defendant?.id === 0) { //주채무자가 변제
          const balance = bond.gauranteeBalance - didPaybackDate.price < 0 ? 0 : bond.gauranteeBalance - didPaybackDate.price;
          bond.gauranteeBalance = balance;
  
          bond.jointGuarantorList.filter((j, i) => balance < j.balance).forEach(jointGuarantor => {
            jointGuarantor.balance = jointGuarantor.balance - didPaybackDate.price < balance ? balance : jointGuarantor.balance - didPaybackDate.price;
          });
        }
        else {
          const index = bond.jointGuarantorList.map(j => j.defendant?.id).indexOf(didPaybackDate.defendant?.id);
        
          if(index !== -1) {
            const jointGuarantor = bond.jointGuarantorList[index];
  
            const balance = jointGuarantor.balance - didPaybackDate.price < 0 ? 0 : jointGuarantor.balance - didPaybackDate.price;
            jointGuarantor.balance = balance;
  
            bond.jointGuarantorList.filter((j, i) => i !== index && balance < j.balance).forEach(jointGuarantor => {
              jointGuarantor.balance = jointGuarantor.balance - didPaybackDate.price < balance ? balance : jointGuarantor.balance - didPaybackDate.price;
            })
          }
        }
      }
    })
  });
}

const calcAll = (complaintType, _didPaybackDateList, _bondList) => {

  let paymentTableList = []
  let bondList = [..._bondList]
  console.log('bondList', bondList)

  let interestStandardDate = '';

  let didPaybackDateList = [..._didPaybackDateList].filter(d => d.isChecked).sort((p1, p2) => moment(p1.date).isAfter(p2.date) ? 1 : -1)

  didPaybackDateList.forEach((didPaybackDate, didPaybackDateIndex) => {

    let didPaybackDateBalance = didPaybackDate.balance;
    let orderIdsArray = [];

    let beforeBondList = bondList.filter(bond => moment(bond.willPaybackDate).isBefore(didPaybackDate.date));

    let calcDelayPriceStartDate = ''

    orderIdsArray = orderIdsArray.concat(calcPaymentOrder(beforeBondList.map(b => {
      console.log(b.id, b.realDeferInterestRate, b.willPaybackDate)
      return {
        id: b.id,
        rate: b.realDeferInterestRate,
        date: b.willPaybackDate
      }
    })))

    const afterBondList = bondList.filter(b => !beforeBondList.map(b => b.id).includes(b.id));

    orderIdsArray = orderIdsArray.concat(calcPaymentOrder(afterBondList.map(b => {
      console.log(b.id, b.realDeferInterestRate, b.willPaybackDate)
      return {
        id: b.id,
        rate: b.realInterestRate,
        date: b.willPaybackDate
      }
    })));

    orderIdsArray.forEach(orderIds => {
      orderIds.sort((a, b) => a > b ? 1 : -1).forEach(orderId => {

        bondList = bondList.map(value => {
          const bond = {...value}
        
          if(complaintType === '매매대금') {
            bond.deferInterestPrice = calcDeferInterestPrice(
              bond.tradingPricePaybackIndex !== -1, 
              bond.willPaybackDate !== '',
              bond.isDeferInterestRate,
              bond.didDeliveryDate !== '',
              bond.willDeliveryDate !== '',
              bond.balance,
              bond.deferInterestRate,
              bond.isLast,
              bond.willPaybackDate,
              bond.lastWillPaybackDate,
              didPaybackDate.date,
              bond.willDeliveryDate,
              bond.didDeliveryDate,
            );

            bond.deferInterestPrice = bond.deferInterestPrice < 0 ? 0 : bond.deferInterestPrice;
      
            bond.isPayment = true;
      
            const bondTableData = {
              seq: bond.index,
              beforePrincipalPrice: addComma(bond.balance),
              //beforeInterestPrice: addComma(bond.interestPrice),
              beforeDeferInterestPrice: addComma(bond.deferInterestPrice),
              afterPrincipalPrice: 0,
              afterInterestPrice: 0,
              afterDeferInterestPrice: 0
            }
      
            //인지대, 송달료 계산을 위한 매매잔금 
            const tempBalance = didPaybackDateBalance - bond.deferInterestPrice < 0 ? 0 : didPaybackDateBalance - bond.deferInterestPrice;
            bond.tradingPriceBalance -= tempBalance;
      
            bond.balance += bond.deferInterestPrice;
      
            const afterDeferInterestPrice = bond.deferInterestPrice - didPaybackDateBalance > 0 ? bond.deferInterestPrice - didPaybackDateBalance : 0;
            bondTableData.afterDeferInterestPrice = addComma(afterDeferInterestPrice);
      
            const bondBalance = bond.balance - didPaybackDateBalance > 0 ? bond.balance - didPaybackDateBalance : 0;
            const paybackBalance = didPaybackDateBalance - bond.balance > 0 ? didPaybackDateBalance - bond.balance : 0;
      
            bond.balance = bondBalance;
            didPaybackDateBalance = paybackBalance;
      
            bondTableData.afterPrincipalPrice = addComma(bond.balance);
      
            bond.balance = parseInt(bond.balance);
            didPaybackDateBalance = parseInt(didPaybackDateBalance);
      
            bond.delayPrice = bond.deferInterestPrice;

            //tableData.bondList.push(bondTableData);

            console.log('aaa', _.cloneDeep(bond));

            console.log('bbb', bond.id, didPaybackDate.id, bond.balance)

            if(bondTableData.beforePrincipalPrice !== '0') {
              console.log('paymentTableList push')
              paymentTableList.push({
                bondId: bond.id,
                didPaybackDateId: didPaybackDate.id,
                beforePrice: bondTableData.beforePrincipalPrice,
                beforeInterestPrice: addComma(bond.interestBalance),
                beforeDeferInterestPrice: bondTableData.beforeDeferInterestPrice,
                afterPrice: bondTableData.afterPrincipalPrice,
                afterInterestPrice: 0,
                afterDeferInterestPrice: bondTableData.afterDeferInterestPrice,
              })
            }
          }
          else {
            if(moment(bond.willPaybackDate).isBefore(didPaybackDate.date)) {
              bond.interestPrice = calcDelayPrice(bond.lendDate, bond.willPaybackDate, bond.balance, bond.realInterestRate);
              bond.deferInterestPrice = calcDelayPrice(moment(bond.willPaybackDate).add(1, 'days').format('YYYY-MM-DD'), didPaybackDate.date, bond.balance, bond.realDeferInterestRate);
              bond.interestBalance = bond.interestPrice;
              bond.deferInterestBalance = bond.deferInterestPrice;
              bond.delayPrice = bond.interestPrice + bond.deferInterestPrice;
            }
            else {
              console.log('변제기 이전에 충당됨')
              if(didPaybackDateIndex === 0) calcDelayPriceStartDate = bond.lendDate
              else calcDelayPriceStartDate = moment(didPaybackDateList[didPaybackDateIndex - 1].date).add(1, 'days').format('YYYY-MM-DD')
              bond.interestPrice = calcDelayPrice(calcDelayPriceStartDate, didPaybackDate.date, bond.balance, bond.realInterestRate);
              bond.interestBalance = bond.interestPrice;
    
              bond.delayPrice = bond.interestPrice + bond.deferInterestPrice;
            }
    
            console.log('aaa', _.cloneDeep(bond));
            console.log('bbb', bond.id, didPaybackDate.id, bond.balance)

            if(!paymentTableList.find(v => v.bondId === bond.id && v.didPaybackDateId === didPaybackDate.id)) {
              paymentTableList.push({
                bondId: bond.id,
                didPaybackDateId: didPaybackDate.id,
                beforePrice: addComma(bond.balance),
                beforeInterestPrice: addComma(bond.interestBalance),
                beforeDeferInterestPrice: addComma(bond.deferInterestPrice)
              })
            }
            // paymentTableList.push({
            //   bondId: bond.id,
            //   didPaybackDateId: didPaybackDate.id,
            //   beforePrice: addComma(bond.balance),
            //   beforeInterestPrice: addComma(bond.interestBalance),
            //   beforeDeferInterestPrice: addComma(bond.deferInterestPrice)
            // })

            console.log(`이자: ${bond.interestPrice}, 지손금: ${bond.deferInterestPrice}, 이자합계: ${bond.delayPrice}`);
          }

          return bond;
        })

        

        
      })
      
    });

    orderIdsArray.forEach(orderIds => {
      if(orderIds.length !== 1) { //안분배당
        console.log('안분배당')
        const multipleBondList = bondList.filter(b => orderIds.includes(b.id));
        const totalDelayPrice = multipleBondList.map(b => b.delayPrice).reduce((a, c) => a + c, 0);
        
        console.log(totalDelayPrice, didPaybackDateBalance)

        if(totalDelayPrice < didPaybackDateBalance) { //이자 합계를 모두 갚는 경우(원금 안분배당)
        //if(totalDelayPrice === 0) { //이자 합계를 모두 갚는 경우(원금 안분배당)
          const totalBalance = multipleBondList.map(b => b.balance).reduce((a, c) => a + c, 0);
          
          bondList.forEach(bond => {
            const index = multipleBondList.map(b => b.id).indexOf(bond.id)
            if(index !== -1) {
              bondList[index].delayPrice = 0;
              bondList[index].interestBalance = 0;
              bondList[index].deferInterestBalance = 0;
            }
          })
          // multipleBondList.map(b => {
          //   b.delayPrice = 0;
          //   b.interestBalance = 0;
          //   b.deferInterestBalance = 0;
          //   return b;
          // });
          didPaybackDateBalance -= totalDelayPrice;

          multipleBondList.forEach(b => {
            const paymentPrice = Math.round(didPaybackDateBalance * (b.balance / totalBalance));

            const index = bondList.map(a => a.id).indexOf(b.id)
            if(index !== -1) {
              bondList[index].balance -= paymentPrice;
            }

            //b.balance -= paymentPrice;
          });

          // multipleBondList.forEach(b => {
          //   const paymentPrice = Math.round(didPaybackDateBalance * (b.balance / totalBalance));
          //   b.balance -= paymentPrice;
          // });

        }
        else { //이자 안분배당 (비율은 이자+지손금이지만 갚는건 지손금 먼저)
          multipleBondList.forEach(b => {

            const index = bondList.map(a => a.id).indexOf(b.id)

            let paymentPrice = Math.round(didPaybackDateBalance * (b.delayPrice / totalDelayPrice));
            console.log('이자 or 지손금 안분배당', paymentPrice)
            
            //b.delayPrice -= paymentPrice;
            bondList[index].delayPrice -= paymentPrice;

            const deferInterestPaymentPrice = b.deferInterestBalance - paymentPrice > 0 ? paymentPrice : b.deferInterestBalance;
            //b.deferInterestBalance -= deferInterestPaymentPrice;
            bondList[index].deferInterestBalance -= deferInterestPaymentPrice;
            paymentPrice -= deferInterestPaymentPrice;

            if(paymentPrice !== 0) {
              const interestPaymentPrice = b.interestBalance - paymentPrice ? paymentPrice : b.interestBalance;
              //b.interestBalance -= interestPaymentPrice;
              bondList[index].interestBalance -= interestPaymentPrice;
              paymentPrice -= deferInterestPaymentPrice;
            }
            
          });
          didPaybackDateBalance = 0;
        }
      }
      else {
        console.log('안안분배당')
        const bondIndex = bondList.map(b => b.id).indexOf(orderIds[0])
        const bond = bondList[bondList.map(b => b.id).indexOf(orderIds[0])];
        // console.log(bond.delayPrice, didPaybackDateBalance);
        // console.log(didPaybackDate)
        let paymentPrice = didPaybackDateBalance - bond.delayPrice < 0 ? didPaybackDateBalance : bond.delayPrice;
        console.log('이자 충당',  paymentPrice)
        bond.delayPrice -= paymentPrice;
        //bondList[bondIndex].delayPrice -= paymentPrice;
        didPaybackDateBalance -= paymentPrice;

        const deferInterestPaymentPrice = bond.deferInterestBalance - paymentPrice > 0 ? paymentPrice : bond.deferInterestBalance;
        bond.deferInterestBalance -= deferInterestPaymentPrice;
        //bondList[bondIndex].deferInterestBalance -= deferInterestPaymentPrice;
        paymentPrice -= deferInterestPaymentPrice;

        if(paymentPrice !== 0) {
          const interestPaymentPrice = bond.interestBalance - paymentPrice > 0 ? paymentPrice : bond.interestBalance;
          bond.interestBalance -= interestPaymentPrice;
          //bondList[bondIndex].interestBalance -= interestPaymentPrice;
          paymentPrice -= deferInterestPaymentPrice;
        }

        bondList[bondIndex] = bond;

      }
    });

    console.log('남은 충당 잔액 : ', didPaybackDateBalance)

    if(didPaybackDateBalance !== 0) {
      orderIdsArray.forEach(orderIds => {
        if(orderIds.length !== 1) { //안분배당
  
        }
        else {
          const bondIndex = bondList.map(b => b.id).indexOf(orderIds[0])
          const bond = bondList[bondList.map(b => b.id).indexOf(orderIds[0])];
          const paymentPrice = didPaybackDateBalance - bond.balance < 0 ? didPaybackDateBalance : bond.balance;
          bond.balance -= paymentPrice;
          didPaybackDateBalance -= paymentPrice;

          bondList[bondIndex] = bond
        }
      });
    }
    
    if(complaintType !== '매매대금') {
      bondList.forEach(bond => {
      
        paymentTableList = paymentTableList.map((paymentTable, index) => {

          if(paymentTable.didPaybackDateId === didPaybackDate.id && paymentTable.bondId === bond.id) {
            paymentTable.afterPrice = addComma(bond.balance);
            paymentTable.afterInterestPrice = addComma(bond.interestBalance);
            paymentTable.afterDeferInterestPrice = addComma(bond.deferInterestBalance);
          }

          return paymentTable
        })

        const filterPaymentTableList = paymentTableList.filter(t => t.didPaybackDateId === didPaybackDate.id);
        //console.log(paymentTableList.filter(t => t.didPaybackDateId === didPaybackDate.id));
        const tableIndex = filterPaymentTableList.map(t => t.bondId).indexOf(bond.id);
        //console.log(tableIndex, paymentTableList[tableIndex])
        filterPaymentTableList[tableIndex].afterPrice = addComma(bond.balance);
        filterPaymentTableList[tableIndex].afterInterestPrice = addComma(bond.interestBalance);
        filterPaymentTableList[tableIndex].afterDeferInterestPrice = addComma(bond.deferInterestBalance);
      })
    }
  });

  // paymentTableList.forEach((table) => {
  //   console.log('table', table)
  // })

  return {
    bList: bondList, 
    pList: paymentTableList
  }
  
}

//인지대, 송달료 계산
const calcEtc = (_complaint, defendantList) => {

  const complaint = {..._complaint}

  complaint.deliveryCharge1 = 0;
  complaint.deliveryCharge2 = (1 + defendantList.length) * deliveryCharge * 6;

  if(complaint.claimPrice <= 30000000) {
    complaint.deliveryCharge1 = (1 + defendantList.length) * deliveryCharge * 10;
  }
  else {
    complaint.deliveryCharge1 = (1 + defendantList.length) * deliveryCharge * 15;
  }

  if(complaint.claimPrice < 10000000) {
    complaint.recognition1 = complaint.claimPrice * 50 / 10000
  }
  else if(10000000 <= complaint.claimPrice && complaint.claimPrice < 100000000) {
    complaint.recognition1 = complaint.claimPrice * 45 / 10000 + 5000
  }
  else if(10000000 <= complaint.claimPrice && complaint.claimPrice < 1000000000) {
    complaint.recognition1 = complaint.claimPrice * 40 / 10000 + 55000
  }
  else {
    complaint.recognition1 = complaint.claimPrice * 35 / 10000 + 555000
  }

  complaint.recognition2 = parseInt(complaint.recognition1 / 10)
  
  complaint.recognition1 = parseInt(complaint.recognition1);
  
  if(complaint.recognition1 < 1000) {
    complaint.recognition1 = 1000;
  }
  else {
    complaint.recognition1 = (parseInt(complaint.recognition1 / 100)) * 100;
  }

  if(complaint.recognition2 < 1000) {
    complaint.recognition2 = 1000;
  }
  else {
    complaint.recognition2 = (parseInt(complaint.recognition2 / 100)) * 100;
  }

  return complaint
}

const findCourt = (_complaint, plaintiff, defendantList) => {

  let complaint = {..._complaint}


  let court = getCourt(complaint.claimPrice, plaintiff, '원고');
  court.id = complaint.courtList.length;
  complaint.courtList.push(court)

  defendantList.forEach(defendant => {
    //console.log(getCourt(complaint.claimPrice, defendant, '피고'));
    let court = getCourt(complaint.claimPrice, defendant, '피고');
    court.id = complaint.courtList.length;
    complaint.courtList.push(court)
  });

  return complaint
  
}

const getCourt = (totalBond, person, type) => {
  let court = '';
  
  const address = person.address.split(' ');
  const originAddress = person.address;
  const standartTotalBond = 30000000;
  const isDownCourt = totalBond <= standartTotalBond;
  
  const gu = address[1];
  if(address[0] === '서울') {
    if(gu === '종로구' || gu === '중구' || gu === '강남구' ||  gu === '서초구' ||  gu === '관악구' ||  gu === '동작구') {
      court = '서울중앙지방법원';
    }
    else if(gu === '성동구' || gu === '광진구' || gu === '강동구' || gu === '송파구') {
      court = '서울동부지방법원';
    }
    else if(gu === '영등포구' || gu === '강서구' || gu === '양천구' || gu === '구로구' || gu === '금천구') {
      court = '서울남부지방법원';
    }
    else if(gu === '동대문구' || gu === '중랑구' || gu === '성북구' || gu === '도봉구' || gu === '강북구' || gu === '노원구') {
      court = '서울북부지방법원';
    }
    else if(gu === '서대문구' || gu === '마포구' || gu === '은평구' || gu === '용산구') {
      court = '서울서부지방법원';
    }
  }
  else if(address[0] === '부산') {
    if(gu === '중구' || gu === '동구' || gu === '영도구' || gu === '부산진구' || gu === '동래구' || gu === '연제구' || gu === '금정구') {            
      court = '부산지방법원';
    }
    else if(gu === '해운대구' || gu === '남구' || gu === '수영구' || gu === '기장군') {
      court = '부산지방법원 동부지원';
    }
    else if(gu === '서구' || gu === '북구' || gu === '사상구' || gu === '사하구' || gu === '강서구') {
      court = '부산지방법원 서부지원';
    }
  }
  else if(address[0] === '울산') {
    court = '울산지방법원';
  }
  else if(address[0] === '광주') {
    court = '광주지방법원';
  }
  else if(address[0] === '대전' || address[0] === '세종특별자치시') {
    court = '대전지방법원';
    if(address[0] === '세종특별자치시' && isDownCourt) {
      court += ' ' + address[0] + '법원';
    }
  }
  else if(address[0] === '대구') {
    if(gu === '중구' || gu === '동구' || gu === '남구' || gu === '북구' || gu === '수성구') {
      court = '대구지방법원';
    }
    else if(gu === '서구' || gu === '달서구' || gu === '달성군') {
      court = '대구지방법원 서부지원';
    }
  }
  else if(address[0] === '인천') {
    court = '인천지방법원'
    if(gu === '강화군' || isDownCourt) {
      court += ' 강화군법원';
    }
  }
  else if(address[0] === '경기') {
    if(gu === '의정부시' || gu === '동두천시' || gu === '양주시' || gu === '연천군' || gu === '포천시') {
      court = '의정부지방법원';
      if((gu === '연천군' || gu === '포천시' || gu === '동두천시') && isDownCourt) {
        court = ' ' + gu + '법원';
      }
    }
    else if(gu === '고양시' || gu === '파주시') {
      court = '의정부지방법원 고양지원';
      
      if(gu === '파주시' && isDownCourt) {
        court += ' 파주시법원';
      }
    }
    else if(gu === '남양주시' || gu === '구리시' || gu === '가평군') {
      court = '의정부지방법원';
      if(isDownCourt) {
        if(gu === '가평군') {
          court += ' 가평군법원';
        }
        else {
          court += ' 남양주시법원';
        }
      }
    }
    else if(gu === '부천시' || gu === '김포시') {
      court = '인천지방법원 부천지원';

      if(gu === '김포시' && isDownCourt) {
        court += ' 김포시법원';
      }
    }
    else if(gu === '수원시' || gu === '오산시' || gu === '용인시' || gu === '화성시') {
      court = '수원지방법원';

      if(gu === '용인시' && isDownCourt) {
        court += ' 용인시법원';
      }
      else if((gu === '오산시' || gu === '화성시') && isDownCourt) {
        court += ' 오산시법원';
      }
    }
    else if(gu === '성남시' || gu === '하남시' || gu === '광주시') {
      court = '수원지방법원 성남지원';

      if((gu === '하남시' || gu === '광주시') && isDownCourt) {
        court += ' 광주시법원';
      }
    }
    else if(gu === '이천시' || gu === '여주시' || gu === '양평군') {

      court = '수원지방법원 여주지원';

      if((gu === '이천시' || gu === '양평군') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '평택시' || gu === '안성시') {
      court = '수원지방법원 평택지원';

      if(gu === '안성시' && isDownCourt) {
        court += ' 안성시법원';
      }
    }
    else if(gu === '안산시' || gu === '광명시' || gu === '시흥시') {
      court = '수원지방법원 안산지원';

      if(gu === '광명시' && isDownCourt) {
        court += ' 광명시법원';
      }
    }
    else if(gu === '안양시' || gu === '과천시' || gu === '의왕시' || gu === '군포시') {
      court = '수원지방법원 안양지원';
    }
  }
  else if(address[0] === '강원') {
    court = '의정부지방법원';
    if(gu === '철원군') {
      if(isDownCourt) {
        court += ' 철원군법원';
      }
    }
    else if(gu === '춘천시' || gu === '화천군' || gu === '양구군' || gu === '인제군' || gu === '홍천군') {
      court = '춘천지방법원';

      if((gu === '인제군' || gu === '홍천군' || gu === '양구군' || gu === '화천군') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '강릉시' || gu === '동해시' || gu === '삼척시') {
      court = '춘천지방법원 강릉지원';
      if((gu === '삼척시' || gu === '동해시') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '원주시' || gu === '횡성군') {
      court = '춘천지방법원 원주지원';
      if(court === '횡성군' && isDownCourt) {
        court += ' 횡성군법원';
      }
    }
    else if(gu === '속초시' || gu === '양양군' || gu === '고성군') {
      court = '춘천지방법원 속초지원';
      if((gu === '양양군' || gu === '고성군') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '태백시' || gu === '영월군' || gu === '정선군' || gu === '평창군') {
      court = '춘천지방법원 영월지원';
      if(gu !== '영원군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '충북') {
    if(gu === '청주시' || gu === '진천군' || gu === '보은군' || gu === '괴산군' || gu === '증평군') {
      court = '청주지방법원';
      if(gu !== '청주시' && isDownCourt) {
        if(gu === '증평군') {
          court += ' 괴산군법원';
        }
        else {
          court += ' ' + gu + '법원';
        }
      }
    }
    else if(gu === '충주시' || gu === '음성군') {
      court = '청주지방법원 충주지원';
      if(gu === '음성군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '제천시' || gu === '단양군') {
      court = '청주지방법원 제천지원';
      if(gu === '단양군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '영동군' || gu === '옥천군') {
      court = '청주지방법원 영동지원';
      if(gu === '옥천군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '충남') {
    if(gu === '보령시' || gu === '홍성군' || gu === '예산군' || gu === '서천군') {
      court = '대전지방법원 홍성지원';
      if(gu !== '홍성군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '공주시' || gu === '청양군') {
      court = '대전지방법원 공주지원';
      if(gu === '쳥양군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '논산시' || gu === '계룡시' || gu === '부여군') {
      court = '대전지방법원 논산지원';
      if(gu === '부여군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '서산시' || gu === '당진시' || gu === '태안군') {
      court = '대전지방법원 서산지원';
      if(gu !== '서산시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '천안시' || gu === '아산시') {
      court = '대전지방법원 천안지원';
      if(gu === '아산시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '금산군') {
      court = '대전지방법원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '경북') {
    if(gu === '영천시' || gu === '경산시' || gu === '칠곡군' || gu === '청도군') {
      court = '대구지방법원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '성주군' || gu === '고령군') {
      court = '대구지방법원 서부지원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '안동시' || gu === '영주시' || gu === '봉화군') {
      court = '대구지방법원 안동지원';
      if(gu !== '안동시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '경주시') {
      court = '대구지방법원 경주지원';
    }
    else if(gu === '포항시' || gu === '울릉군') {
      court = '대구지방법원 포항지원';
    }
    else if(gu === '김천시' || gu === '구미시') {
      court = '대구지방법원 김천지원';
      if(gu === '구미시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '상주시' || gu === '문경시' || gu === '예천군') {
      court = '대구지방법원 상주지원';
      if(gu !== '상주시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '의성군' || gu === '군위군' || gu === '청송군') {
      court = '대구지방법원 의성지원';
      if(gu !== '의성군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '영덕군' || gu === '영양군' || gu === '울진군') {
      court = '대구지방법원 영덕지원';
      if(gu !== '영덕군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '경남') {
    if(gu === '양산시') {
      court = '울산지방법원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '창원시') {
      if(address[2] === '의창구' || address[2] === '성산구' || address[2] === '진해구') {
        court = '창원지방법원';
        if(address[2] === '진해구' && isDownCourt) {
          court += ' 창원남부법원';
        }
      }
      else if(address[2] === '마산합포구' || address[2] === '마산회원구' || address[2]) {
        court = '창원지방법원 마산지원';
      }
    }
    else if(gu === '김해시') {
      court = '창원지방법원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '함안군' || gu === '의령군') {
      court = '창원지방법원 마산지원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '통영시' || gu === '거제시' || gu === '고성군') {
      court = '창원지방법원 통영지원';
      if(gu !== '통영시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '밀양시' || gu === '창녕군') {
      court = '창원지방법원 밀양지원';
      if(gu === '창녕군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '거창군' || gu === '함양군' || gu === '합천군') {
      court = '창원지방법원 거창지원';
      if(gu !== '거창군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '진주시' || gu === '사천시' || gu === '남해군' || gu === '하동군' || gu === '산청군') {
      court = '창원지방법원 진주지원';
      if(gu !== '진주시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '전북' || address[0] === '전남') {
    if(gu === '나주시' || gu === '화순군' || gu === '장성군' || gu === '담양군' || gu === '곡성군' || gu === '영광군') {
      court = '광주지방법원';
      if(isDownCourt) {
        court += ' ' + gu + '법원';
      }
    } 
    else if(gu === '목포시' || gu === '무안군' || gu === '신안군' || gu === '함평군' || gu === '영암군'){
      court = '광주지방법원 목포지원';
      if((gu === '무안군' || gu === '함평군' || gu === '영암군') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '장홍군' || gu === '강진군') {
      court = '광주지방법원 장홍지원';
      if(gu === '강진군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '순천시' || gu === '여수시' || gu === '광양시' || gu === '구례군' || gu === '고흥군' || gu === '보성군') {
      court = '광주지방법원 순천지원';
      if(gu !== '순천시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '해남군' || gu === '완도군' || gu === '진도군') {
      court = '광주지방법원 해남지원';
      if(gu !== '해남군' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '전주시' || gu === '김제시' || gu === '완주군' || gu === '임실군' || gu === '진안군' || gu === '무주군') {
      court = '전주지방법원';
      if((gu !== '전주시' && gu !== '완주군') && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '군산시' || gu === '익산시'){
      court = '전주지방법원 군산지원';
      if(gu === '익산시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '정읍시' || gu === '부안군' || gu === '고창군') {
      court = '전주지방법원 정읍지원';
      if(gu !== '정읍시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
    else if(gu === '남원시' || gu === '장수군' || gu === '순창군') {
      court = '전주지방법원 남원지원';
      if(gu !== '남원시' && isDownCourt) {
        court += ' ' + gu + '법원';
      }
    }
  }
  else if(address[0] === '제주특별자치도') {
    if(gu === '제주시' || gu === '서귀포시')
    court = '제주지방법원';
    if(gu === '서귀포시' && isDownCourt) {
      court += ' ' + gu + '법원';
    }
  }

  return {
    //id: courtList.length,
    type: type,
    displayName: '[' + court + '] (' + type + ' 주소지) ' + originAddress,
    name: court
  };
}

const getName = (person) => {
  let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : person?.name;
  if(!name || name === '') name = '';

  if(person?.type === 0 || person?.type === 1) {
    if(person?.isEmptyName === true) {
      name = `${person.id + 1}`;
    }
  }
  else {
    if(person?.isEmptyCompany === true) {
      name = `${person.id + 1}`;
    }
  }

  return name;
}

const getPhone = (person) => {

  if(person.phone1 != '' && person.phone2 != '' && person.phone3 != '') {
    return `${person.phone1}-${person.phone2}-${person.phone3}`;
  }

  return null;
}

const getRRNumber = (person) => {
  if(person.RRNumber1 !== '' && person.RRNumber2 !== '') {
    return `${person.RRNumber1}-${person.RRNumber2}`
  }

  return null;
}

const getNameWithJosa = (person, format) => {
  let name = person?.type !== 0 ? person?.company : person?.name;
  if(!name || name === '') name = '';

  if(person?.type === 0 || person?.type === 1) {
    if(person?.isEmptyName === true) {
      name = `${person.id + 1}`;
      return `${name}${josa.c(HANGUEL_NUMBER[person.id], format)}`
    }
  }
  else {
    if(person?.isEmptyCompany === true) {
      name = `${person.id + 1}`;
      return `${name}${josa.c(HANGUEL_NUMBER[person.id], format)}`
    }
  }
 
  
  return josa.r(name, format);
}

const nextDate = (date) => {
  return moment(date).add(1, 'd').format('YYYY-MM-DD');
}



export {
  calcDelayPrice, calcAppropriationForPerformance, addComma, nextDate, diffDate,
  getName, getNameWithJosa, getPhone, getRRNumber,
  calcDelayPriceInfo
}