import LossTitle from './LossTitle';
import LossContent from './LossContent';
import { useAppSelector } from '../../../../store';

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아', '자', '차', '카', '타', '파', '하'];

type LossPreviewProps = {
  hurtTextList: any[]
}

function LossPreview() {

  //let defendantCount = props.complaint.defendantList.length;
  const documentType = useAppSelector(state => state.app.complaint.documentType)
  const defendantList = useAppSelector(state => state.app.defendantList)
  const hurtTextList = useAppSelector(state => state.app.hurtTextList)
  const lossPriceTextList = useAppSelector(state => state.app.lossPriceTextList)
  const claimCauseList = useAppSelector(state => state.app.claimCauseList)
  const claimPurpose = useAppSelector(state => state.app.claimPurpose)
  
  let wongo = documentType === '소장' ? '원고' : '채권자';
  let pigo = documentType === '소장' ? '피고' : '채무자';
  let so = documentType === '소장' ? '소를' : '지급명령신청을';

  return (
    <div className="loss-preview">
      <LossTitle
        title="1."
        contents={`${wongo}의 ${pigo}${defendantList.length === 1 ? '에' : '들에'} 대한 손해배상청구권의 발생`}
      />

      <p className="h40"></p>

      <p>{pigo}{defendantList.length === 1 ? '는' : '들은'} {wongo}에게 아래와 같은 내용의 가해행위를 하였습니다.</p>
      <p className="h40"></p>
      {hurtTextList.length === 1 && (
        <div>
          <LossContent
            contentLeft="가. 일 시 : "
            contentRight={hurtTextList[0][0]}
          />
          <LossContent
            contentLeft="나. 장 소 : "
            contentRight={hurtTextList[0][1]}
          />
          <LossContent
            contentLeft="다. 가해행위 :"
            contentRight={hurtTextList[0][2]}
          />
          <LossContent
            contentLeft="라. 손 해 : "
            contentRight={hurtTextList[0][3]}
          />
          <p className="h40"></p>
        </div>
      )}
      {hurtTextList.length !== 1 && (
        hurtTextList.map((hurtText, index) => (
          <div key={`hurtText${index}`}>
            <LossTitle
              title={`${HANGEUL_INDEX[index]}.`}
              contents={`제${index + 1}차 가해행위의 내용`}
            />
            <LossContent
              contentLeft="1) 일 시 : "
              contentRight={hurtText[0]}
            />
            <LossContent
              contentLeft="2) 장 소 : "
              contentRight={hurtText[1]}
            />
            <LossContent
              contentLeft="3) 가해행위 :"
              contentRight={hurtText[2]}
            />
            <LossContent
              contentLeft="4) 손 해 : "
              contentRight={hurtText[3]}
            />
            <p className="h40"></p>
          </div>
        ))
      )}
      
      <p>이러한 {pigo}{defendantList.length === 1 ? '' : '들'}의 가해행위는 {wongo}에 대한 불법행위를 구성하는바, {pigo}{defendantList.length === 1 ? '' : '들'} {wongo}에게 불법행위로 인한 손해를 배상할 책임이 있습니다.</p>
      <p className="h40"></p>
      <LossTitle
        title="2."
        contents="손해배상의 범위"
      />

      <p className="h40"></p>

      {lossPriceTextList.length === 1 && (
        lossPriceTextList[0].map((lossPrice, index) => (
        <LossContent
          key={`lossPrice${index}`}
          contentLeft={`${HANGEUL_INDEX[index]}. ${lossPrice.name} : `}
          contentRight={lossPrice.price}
        />
        ))
      )}
      {lossPriceTextList.length !== 1 && (
        lossPriceTextList.map((lossPriceText, index) => (
          <div key={`lossPriceText${index}`}>
            <LossTitle
              title={`${HANGEUL_INDEX[index]}.`}
              contents={`제${index + 1}차 가해행위 손해배상의 범위`}
            />
            {lossPriceText.map((lossPrice, pIndex) => (
              <LossContent
                key={`lossPrice${index}${pIndex}`}
                contentLeft={`${pIndex + 1}) ${lossPrice.name} : `}
                contentRight={lossPrice.price}
              />
              ))}
            <p className="h40"></p>
          </div>
        ))
      )}
      
      {claimCauseList.length !== 0 && (
        <p>{claimCauseList[0]}</p>
      )}
      <p className="h40"></p>
      <LossTitle
        title="3."
        contents="결 론"
      />

      <p className="h40"></p>

      <p>이에 {wongo}는 {pigo}{defendantList.length === 1 ? '' : '들'}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 {so} 제기하기에 이르렀습니다.</p>
    </div>
  )
}

export default LossPreview