//대여금, 약정금
import Execution from '../application/Execution';

import '../writeDocument/Step.scss';
import EditJointGuarantor from '../EditJointGuarantor';
import { Button, TextField, Radio, FormControl, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import DateFnsUtils from '@date-io/date-fns';


import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { getName } from '../../../utils/utils';
import SJDatePicker from './SJDatePicker';

import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';


function LendPrice(props) {

  let complaint = useAppSelector((state) => state.app.complaint);
  const defendantList = useAppSelector(state => state.app.defendantList)

  const refreshJointGuarantorList = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].jointGuarantorList = e;
    props.refreshComplaint(c);
  }

  const changePrice = (e, index) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint)
    c.bondList[index].price = price;
    props.refreshComplaint(c);
  }

  const changeLendDate = (date, index) => {
    let c = _.cloneDeep(complaint)
    c.bondList[index].lendDate = date;
    props.refreshComplaint(c);
  }

  const changeFixDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].fixDate = date;
    props.refreshComplaint(c);
  }

  const changeConditionDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].conditionDate = date;
    props.refreshComplaint(c);
  }

  const changeReminderDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].reminderDate = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].interestRate = rate;
    props.refreshComplaint(c);
  }

  const changeDeferInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeferInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDeferInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].deferInterestRate = rate;
    props.refreshComplaint(c);
  }

  const changeIsRelativeWork = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isRelativeWork = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDefendant = (e, index) => {
    let c = _.cloneDeep(complaint);
    const defendantIndex = defendantList.map(d => d.id).indexOf(parseInt(e.target.value));
    c.didPaybackDateList[index].defendant = defendantList[defendantIndex];
    props.refreshComplaint(c);
  }
  

  const changeDidPaybackDatePrice = (e, index) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].price = price;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].date = date;
    props.refreshComplaint(c);
  }

  return (
    <div className="lend-price">
      <p className="head">채권내역</p>

      {complaint.bondList.map((bond, index) => (
        <div className="cont-bx bg-gray" key={`bond${index}`}>
          {index !== 0 && <Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon /></Button>}
          <p className="ttl">1) 주채무자</p>
          <div className="answer-area">
            <span>{getName(defendantList[0])} 입니다.</span>
          </div>

          <hr />
          
          <EditJointGuarantor
          bondIndex={index}
          jointGuarantorList={bond.jointGuarantorList} 
          defendantList={defendantList} 
          onAdd={e => props.addJointGuarantor(index)}
          onRemove={jIndex => props.removeJointGuarantor({bondIndex: index, index: jIndex})}
          refresh={e => refreshJointGuarantorList(e, index)}/>
          
          <hr />
          <p className="ttl">3) {complaint.type === '대여금' ? '언제 얼마를 빌려주었나요?' : '지급 약정을 한 날이 언제이고, 얼마를 지급받기로 했나요?'}</p>
          <div className="answer-area flex-row">
            <SJDatePicker
              date={bond.lendDate}
              onChangeDate={(date) => changeLendDate(date, index)}/>
            {complaint.type === '대여금' && <span className="mr_10">에</span>}
            {complaint.type === '약정금' && <span className="mr_10">에 약정하였고, 약정금액은 </span>}
            <TextField
              variant="outlined"
              value={bond.price === 0 ? '' : bond.price}
              onChange={e => changePrice(e, index)}
              placeholder="1,000,000"
              className="input-price"
            />
            {complaint.type === '대여금' && <span>원을 빌려주었습니다.</span>}
            {complaint.type === '약정금' && <span>입니다.</span>}

          </div>
          <hr />
          <p className="ttl">4) {complaint.type === '대여금' ? '갚기로 약속한 날짜가 언제인가요?' : '돈을 받기로 한 날이 언제인가요?'}</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willPaybackDateType === 'fix'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker 
                date={bond.fixDate}
                onChangeDate={(date) => changeFixDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='condition'
                checked={bond.willPaybackDateType === 'condition'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">조건(예:보험금 받은 날)을 정하였으며,</span>
              <SJDatePicker 
                date={bond.conditionDate}
                onChangeDate={(date) => changeConditionDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='reminder'
                checked={bond.willPaybackDateType === 'reminder'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">최초 독촉한 날짜는</span>
              <SJDatePicker 
                date={bond.reminderDate}
                onChangeDate={(date) => changeReminderDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willPaybackDateType === 'none'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">5) 이자율을 얼마로 정하였나요?</p>
          <div className="answer-area item5">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isInterestRate}
                onChange={e => changeInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" variant="outlined" autoComplete='off'
                value={bond.interestRate === 0 ? '' : bond.interestRate}
                onChange={e => changeInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isInterestRate}
                onChange={e => changeInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">6) 지연이자율을 얼마로 정하였나요? <span>(갚기로 약속한 날짜 이후 이자)</span></p>
          <div className="answer-area item6">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" variant="outlined" autoComplete='off'
                value={bond.deferInterestRate === 0 ? '' : bond.deferInterestRate}
                onChange={e => changeDeferInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          
          {complaint.type === '대여금' && (
            <div>
              <hr />
              <p className="ttl">7) 원고가 업무와 관련해 빌려준 것인가요? <span>(상사법정이자 적용 여부)</span></p>
              <div className="answer-area item7">
                <label className="radio-item">
                  <Radio
                    value='on'
                    checked={bond.isRelativeWork}
                    onChange={e => changeIsRelativeWork(e, index)}
                  />
                  <span>예</span>
                </label>

                <label className="radio-item">
                  <Radio
                    value='off'
                    checked={!bond.isRelativeWork}
                    onChange={e => changeIsRelativeWork(e, index)}
                  />
                  <span>아니요</span>
                </label>
              </div>
            </div>
          )}
        </div>
      ))}
      <Button className="contained" onClick={e => props.addBond()}>채권 추가</Button>
      <div className="head-bx">
        <p className="head">피고가 빌린 돈을 일부 갚았나요?</p>
        <Button className="add-btn" onClick={e => props.addDidPaybackDate(true)}><AddIcon /></Button>
      </div>
      <div className="cont-bx">
        <p className="sub-txt">* 일부 갚은 돈이 있는 경우, 우측의 +버튼을 눌러 추가하세요.</p>
        <p className="sub-txt">* 갚은 돈이 없는 경우, 다음 단계로 넘어가세요.</p>
        {complaint.didPaybackDateList.map((didPaybackDate, index) => (
          <div className="border-bx flex" key={`didPaybackDate${index}`}>
            <div className="input-txt-bx flex-row wrap">
              <FormControl variant="outlined" className="select">
                <Select
                  value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1} 
                  onChange={e => changeDidPaybackDateDefendant(e, index)}
                >
                  <MenuItem value="-1" disabled>선택</MenuItem>
                  {defendantList.map((defendant, defendantIndex) => (
                    <MenuItem value={defendant.id} key={`didPaybackDate${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                  ))}
                  
                </Select>
              </FormControl>
              <span className="ml_4 mr_10">이(가)</span>
              <SJDatePicker 
              date={didPaybackDate.date}
              onChangeDate={date => changeDidPaybackDateDate(date, index)}/>
              <span className="mr_10">에</span>
              <TextField
                type="number"
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => changeDidPaybackDatePrice(e, index)}
                placeholder="1,000,000"
                className="input-price"
              />
              <span className="mr_10">원을</span>
              <span>갚았습니다.</span>
            </div>

            <Button className="add-btn btn-24" onClick={e => props.removeDidPaybackDate(index)}><RemoveIcon /></Button>
          </div>
        ))}
      </div>
    </div>
  )
}


let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LendPrice)
