import { TextField, Button } from '@mui/material';
import { useState } from 'react';
import EditFloor from './EditFloor';


//건물 추가, 수정
export default function EditBuilding(props) {

  const [address, setAddress] = useState(props.building?.address || '');
  const [history, setHistory] = useState(props.building?.history || '');
  const [floorList, setFloorList] = useState(props.building?.floorList || []);

  return (
    <div className="modal-container edit-building">
      <div className="modal">
        <p className="top-area">건물</p>

        <div className="middle-area">
          <div className="item">
            <p className="ttl">소재지번</p>
            <div className="textarea-wrap">
              <TextField
                value={address}
                onChange={e => setAddress(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>

          <div className="item">
            <p className="ttl">건물내역</p>
            <div className="textarea-wrap">
              <TextField
                value={history}
                onChange={e => setHistory(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>

          <EditFloor 
            floorList={floorList}
            onRefreshData={floorList => setFloorList(floorList)}
          />
        </div>

        <div className="bottom-area">
          <Button className="outlined" onClick={e => props.onClose()}>취소</Button>
          <Button className="contained" onClick={e => props.building ? props.onEdit({address, history, floorList}) : props.onAdd({address, history, floorList})}>추가</Button>
        </div>
      </div>
    </div>
  )
}
