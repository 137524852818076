
import { Radio } from '@mui/material';
import { useAppSelector } from '../../../store';
import './Step.scss';

type Step3CountProps = {
  onSelectCourt: (court: any) => void
}

export default function Step3Court(props: Step3CountProps) {

  const complaint = useAppSelector(state => state.app.complaint)
  //const courtList = useAppSelector(state => state.app.complaint.courtList)

  return(
    <div className="cont-bx step3-court">
      <div className="bg-gray">
        {complaint.courtList.filter(court => court.type === '원고').map((court, index) => (
          <label className="radio-item" key={`court${index}`}>
            <Radio 
              value={court.id}
              checked={complaint.selectedCourt?.id === court.id}
              onChange={e => props.onSelectCourt(court)}
            />
            <span>{court.displayName}</span>
          </label>
        ))}
        {complaint.documentType === '소장' && complaint.courtList.filter(court => court.type === '피고').map((court, index) => (
          <label className="radio-item" key={`court${index}`}>
            <Radio 
              value={court.id}
              checked={complaint.selectedCourt?.id === court.id}
              onChange={e => props.onSelectCourt(court)}
            />
            <span>{court.displayName}</span>
          </label>
        ))}
        {complaint.documentType === '지급명령' && [...Array(new Set(complaint.courtList.filter(court => court.type === '피고').map(court => court.name)))].length === 1 && complaint.courtList.filter(court => court.type === '피고').map((court, index) => (
          <label className="radio-item" key={`court${index}`}>
            <Radio 
              value={court.id}
              checked={complaint.selectedCourt?.id === court.id}
              onChange={e => props.onSelectCourt(court)}
            />
            <span>{court.displayName}</span>
          </label>
        ))}
      </div>
   </div>
  )
}