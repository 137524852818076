
import moment from "moment";
import { useState, useEffect } from "react";

import { get } from "../../utils/Api";

function Article(props) {

  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    getArticleList();
  }, []);

  const getArticleList = () => {
    get('article')
    .then(response => {
      if(response.data.success) {
        setArticleList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="article page-view">
      <div className="h-top"></div>
      <div className="inner mw1280">
        <div className="head-area">
          <span className="head">언론보도</span>
        </div>

        <div className="cont-area">
          <div className="txt-card-view-group flex">
            {articleList.map((article, index) => (
              <a className="card-wrap" key={`article${index}`} onClick={e => window.open(article.link, '_blank')}>
                <div className="card">
                  <p className="color-6bbaff">언론보도</p>
                  <b className="ttl color-222">{article.title}</b>
                  <p className="txt">{article.contents}</p>
                  <p className="date">{moment(article.writing_date).format('YYYY-M-D')}</p>
                </div>
              </a>
            ))}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Article;
