import { Button, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { get, post, put } from '../../utils/Api';
import { useState } from 'react';

let user = null;

export default function ResetPwView(props) {

  const [email, setEmail] = useState('');

  const [isCorrectEmail, setIsCorrectEmail] = useState(false);
  const [isCertificate, setIsCertificate] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const checkUser = () => {
    get(`user/email`, {
      email: email
    }).then(response => {
      if(response.data.success) {
        setIsCorrectEmail(true); 
      }
      else {
        if(response.data.errorCode === 1) {
          alert('입력된 정보로 가입된 계정이 없습니다. 회원가입을 진행해주세요.');
        }
      }
    }).catch(error => console.log(error))
  }

  const clickedCertificate = () => {
    const { IMP } = window;
      IMP.init('imp84428497');
      //IMP.init('imp10391932');
      

      /* 2. 본인인증 데이터 정의하기 */
      const data = {
        // merchant_uid: `mid_${new Date().getTime()}`,
        // company: '아임포트',
        // carrier: 'SKT',
        // name: '홍길동',
        // phone: '01012341234'
      };

      /* 4. 본인인증 창 호출하기 */
      IMP.certification(data, response => {
        const {
        success,
        merchant_uid,
        error_msg
      } = response;

      if (response.success) {
        getUserInfo(response.imp_uid, email);
        
      } else {
        alert(`본인인증 실패: ${error_msg}`);
      }
      });
  }

  const getUserInfo = (imp_uid, email) => {
    post(`certifications/findpw`, {
      imp_uid: imp_uid,
      email: email
    }).then(response => {
      if(response.data.success) {
        user = response.data.data;
        setIsCertificate(true);
      }
      else {
        if(response.data.errorCode === 1) {
          alert('입력된 정보로 가입된 계정이 없습니다. 회원가입을 진행해주세요.');
        }
      }
    }).catch(error => console.log(error))
  }

  const editPassword = () => {

    if(!user) return;

    if(password === '') return;

    if(password !== confirmPassword) return;

    put(`user/${user.id}/resetpw`, {
      pw: password
    }).then(response => {
      if(response.data.success) {
        alert('비밀번호가 재설정되었습니다.');
        props.onClose();
      }
      else {
        
      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="reset-pw user user-modal">
      <div className="card">
        <Button className="clear-btn" onClick={e => props.onClose()}><ClearIcon /></Button>

        <div className="head-area">
          <span className="head">비밀번호 재설정</span>
        </div>

        {!isCorrectEmail && (
          <div>
            <p className="sub-head">비밀번호 재설정을 위해 사용자 확인을 진행합니다.</p>

            <div className="flex-row">
              <p className="ttl">ID</p>
              <TextField
                variant="outlined"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>

            <Button className="contained check-btn" onClick={e => checkUser()}>다음</Button>
          </div>
        )}

        {isCorrectEmail && !isCertificate && (
          <div>
            <div className="flex-row">
              <p className="ttl">본인인증</p>
              {true && (
                <Button className="contained certified-btn" onClick={e => clickedCertificate()}>인증하기</Button>
              )}
              {false && (
                <p className="outlined certified">인증완료</p>
              )}
            </div>

            <Button className="contained check-btn">다음</Button>
          </div>
        )}

        {isCertificate && (
          <div>
            <p className="sub-head">재설정 할 비밀번호를 입력해주세요.</p>

            <div className="flex-row">
              <p className="ttl">비밀번호</p>
              <TextField
                variant="outlined"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>

            <div className="flex-row">
              <p className="ttl">비밀번호 확인</p>
              <TextField
                variant="outlined"
                type="password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
              />
            </div>

            <Button className="contained check-btn" onClick={e => editPassword()}>확인</Button>
          </div>
        )}
      </div>
    </div>
  )
}