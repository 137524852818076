import React, { useState, useEffect } from 'react';
import { TextField, FormControlLabel, Checkbox, Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { post } from '../../utils/Api';


export default function SignUpView(props) {

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [confirmPw, setConfirmPw] = useState('');

  const [isCertificate, setIsCertificate] = useState(false);

  const [isAgree, setIsAgree] = useState(false);

  const clickedCertificate = () => {
    const { IMP } = window;
      IMP.init('imp84428497');
      //IMP.init('imp10391932');
      

      /* 2. 본인인증 데이터 정의하기 */
      const data = {
        // merchant_uid: `mid_${new Date().getTime()}`,
        // company: '아임포트',
        // carrier: 'SKT',
        // name: '홍길동',
        // phone: '01012341234'
      };

      /* 4. 본인인증 창 호출하기 */
      IMP.certification(data, response => {
        const {
        success,
        merchant_uid,
        error_msg
      } = response;

      if (response.success) {
        getUserInfo(response.imp_uid);
        
      } else {
        alert(`본인인증 실패: ${error_msg}`);
      }
      });
  }

  const getUserInfo = (imp_uid) => {
    post(`certifications/signup`, {
      imp_uid: imp_uid
    }).then(response => {
      if(response.data.success) {
        setPhone(response.data.data.phone)
        setName(response.data.data.name)
        setIsCertificate(true);
      }
      else {
        if(response.data.errorCode === 4) {
          alert('이미 가입된 계정이 있습니다. 아이디 찾기를 진행해주세요.');
        }
      }
    }).catch(error => console.log(error))
  }

  const clickedRegister = () => {

    if(id === '') return alert('아이디를 입력해 주세요.');

    if(pw === '') return alert('비밀번호를 입력해 주세요.');

    if(pw.length < 8 || 20 < pw.length) return alert('8 ~ 20자리로 입력해 주세요.');

    if(pw !== confirmPw) return alert('비밀번호가 일치하지 않습니다.');

    if(!isAgree) return alert('필수 동의에 체크해주세요.');

    post(`auth/signup`, {
      email: id,
      pw: pw,
      name: name,
      phone: phone
    }).then(response => {
      if(response.data.success) {
        alert('회원가입이 완료되었습니다.');
        props.onCompleteSignUp();
      }
      else {
        if(response.data.errorCode === 2) {
          alert('이미 존재하는 아이디입니다.');
        }
      }
    }).catch(error => console.log(error))
  }
 
  return (
    <div className="sign-up user user-modal">
      <div className="card">
        <Button className="clear-btn" onClick={e => props.onClose()}><ClearIcon /></Button>

        <div className="head-area">
          <span className="head">회원가입</span>
        </div>

        {/* <div className="flex-row">
          <p className="ttl">이름</p>
          <TextField
            variant="outlined"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div> */}
        
        <div className="flex-row">
          <p className="ttl">본인인증</p>
          {!isCertificate && (
            <Button className="contained" onClick={e => clickedCertificate()}>인증하기</Button>
          )}
          {isCertificate && (
            <p className="outlined certified">인증완료</p>
          )}
        </div>

        <div className="flex-row">
          <p className="ttl">아이디</p>
          <TextField
            variant="outlined"
            value={id}
            onChange={e => setId(e.target.value)}
          />
        </div>

        <div className="flex-row">
          <p className="ttl">비밀번호</p>
          <TextField
            variant="outlined"
            type="password"
            value={pw}
            onChange={e => setPw(e.target.value)}
          />
        </div>

        <div className="flex-row">
          <p className="ttl">비밀번호 확인</p>
          <TextField
            variant="outlined"
            type="password"
            value={confirmPw}
            onChange={e => setConfirmPw(e.target.value)}
          />
        </div>

        {/* 불일치할 경우 클래스명 incorrect 추가, 일치할 경우 클래스명 incoreect 제거 */}
        {pw !== '' && <p className={`alert-txt ${pw !== confirmPw && 'incorrect'}`}>비밀번호가 일치{pw === confirmPw ? '합니다' : '하지 않습니다'}.</p>}

        <div className="checkbox-item">
          <FormControlLabel control={<Checkbox checked={isAgree} onChange={e => setIsAgree(e.target.checked)}/>} label="개인정보 수집 이용 안내 및 약관에 동의합니다." />
        </div>

        <Button className="signup-btn contained" onClick={e => clickedRegister()}>회원가입 하기</Button>
      </div>
    </div>
  )
}