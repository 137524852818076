import React from 'react';
import '../Scss/Introduce.scss';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default class Introduce extends React.Component{
  state = {
    cardList: [
      { img: '', ttl: '', txt1: '민사소송 절차는 소장이나 지급명령신청서를', txt2: '법원에 제출함으로써 시작됩니다.', txt3: '그러나 소송에 익숙하지 않은 일반인이', txt4: '소장이나 지급명령신청서를', txt5: '정확히 작성하기는 쉽지 않습니다.' },

      { img: '', ttl: '', txt1: '소송을 통해 받고자 하는 금액이 크지않으면,', txt2: '현실적으로 변호사를 선임하여 소송을 진행하는것은', txt3: '어렵습니다. [소장이지] 일반인도 쉽고, 빠르고, 완벽하게', txt4: '소장이나 지급명령신청서를', txt5: '작성할 수 있도록 도와드립니다.' },

      { img: '', ttl: '', txt1: '이용자가 간단한 질문에 답을 입력하면 [소장이지]가', txt2: '소장이나 지급명령신청서를 자동으로 완성해드립니다.', txt3: '무료로 작성한 소장이나 지급명령신청서를 법원에', txt4: '직접 제출할 수 있고, 합리적인 가격으로', txt5: '변호사 검토 및 제출대행 서비스를 이용할 수도 있습니다.' }
    ],
    useStepList: [
      { no: 'step1', ttl: '1. 사실관계 입력', txt1: '당사자 인적사항', txt2: '및 사실관계 입력',  img: '../../Image/use-step1.png' },
      { no: 'step2', ttl: '2. 증거 업로드', txt1: '사실관계 인증을 위한', txt2: '증거자료 업로드', img: '../../Image/use-step2.png' },
      { no: 'step3', ttl: '3. 문서 형식 선택', txt1: '소장 또는', txt2: '지급명령신청서 선택', img: '../../Image/use-step3.png' },
      { no: 'step4', ttl: '4. 다운로드 및 제출', txt1: '완성한 문서를 PDF로 다운로드', txt2: '관할 법원에 제출', img: '../../Image/use-step4.png' }
    ],
    civilLitigationProcedureList: [
      { img: '../../Image/procedure1.png', ttl: '(원고) 소장 작성', txt1: '[소장이지]에서', txt2: '소장 작성 및 제출' },
      { img: '../../Image/procedure2.png', ttl: '(법원) 심사 및 송달', txt1: '소장 심사 후', txt2: '피고에게 소장 송달' },
      { img: '../../Image/procedure3.png', ttl: '(피고) 답변서 제출', txt1: '장기간 미 제출시', txt2: '원고 승소' },
      { img: '../../Image/procedure2.png', ttl: '(원고+피고) 법원 출석', txt1: '준비서면 제출', txt2: '변론기일 출석' },
      { img: '../../Image/procedure4.png', ttl: '(법원) 판결', txt1: '변론종결 후', txt2: '판결선고' }
    ],
    paymentOrderProcedureList: [
      { img: '../../Image/procedure1.png', ttl: '(채권자) 지급명령신청서 작성', txt1: '[소장이지]에서', txt2: '지급명령신청서 작성 및 제출' },
      { img: '../../Image/procedure2.png', ttl: '(법원) 심사 및 송달', txt1: '소장 심사 후', txt2: '피고에게 소장 송달' },
      { img: '../../Image/procedure3.png', ttl: '(채무자) 이의신청서 제출', txt1: '장기간 미 제출시', txt2: '원고 승소' },
      { img: '../../Image/procedure4.png', ttl: '(법원) 소송 진행', txt1: '준비서면 제출', txt2: '변론기일 출석' }
    ]
  }


  render() {
    return(
      <div className="introduce">
        <div className="top-img-area flex-center">
          <div className="contents-bx">
            <p className="ta-center">소장 자동 완성으로 나홀로 소송을 더 쉽게</p>
            <img src="../../Image/logo-white.png" alt="소장이지" />
          </div>
        </div>

        <p className="sj-head">소개</p>

        <div className="img-txt-area mw1280">
          <div className="img-txt-group flex-row">
            <div className="img-bx">
              <div className="back-square"></div>
              <img src="../../Image/introduce-sub-img.png" alt="sj-ez" />
            </div>
            <div className="txt-bx">
              <b className="first-line">소액 소송을 진행할 때, 많은 분이 고민에 빠집니다.</b>
              <p className="second-line">변호사에게 맡기자니 비용이 부담스럽고,<br/>혼자 진행하려니 법을 몰라 막막하고,</p>
              <b className="third-line">법무법인 강남이 만든 소장이지를 소개합니다.</b>
              <p className="fourth-line">변호사들의 전문 노하우로 탄생한 소장이지는<br/>간단한 질문에 답을 하면 소장 및 지급명령신청서가 자동으로 작성되어<br/>마치 변호사의 도움을 받은 듯, 혼자서도 빈틈없는<br/>소송 진행이 가능합니다.</p>
            </div>
          </div>
          
        </div>

        <div className="card-view-area mw1280">
          <div className="logo-bx">
            <img src="../../Image/logo-circle.png" alt="sj-ez" />
          </div>
          
          <div className="card-group flex">
            <div className="card-wrap">
              <div className="card ta-center">
                <img src="../../Image/intro-card1.png" alt="나홀로 소송의 어려움" />
                <b className="card-ttl color-6bbaff">나홀로 소송의 어려움</b>
                <p>민사소송 절차는 소장이나 지급명령신청서를<br/>법원에 제출함으로써 시작됩니다.<br/>그러나 소송에 익숙하지 않은 일반인이<br/>소장이나 지급명령신청서를<br/>정확히 작성하기는 쉽지 않습니다.</p>
              </div>
            </div>
            <div className="card-wrap">
              <div className="card ta-center">
                <img src="../../Image/intro-card2.png" alt="[소장이지] 개발취지" />
                <b className="card-ttl color-6bbaff">[소장이지] 개발취지</b>
                <p>소송을 통해 받고자 하는 금액이 크지않으면,<br/>현실적으로 변호사를 선임하여 소송을 진행하는것은<br/>어렵습니다. [소장이지]일반인도 쉽고,빠르고,<br/> 완벽하게 소장이나 지급명령신청서를<br/>작성할 수 있도록 도와드립니다. </p>
              </div>
            </div>
            <div className="card-wrap">
              <div className="card ta-center">
                <img src="../../Image/intro-card3.png" alt="[소장이지] 서비스" />
                <b className="card-ttl color-6bbaff">[소장이지] 서비스</b>
                <p>이용자가 간단한 질문에 답을 입력하면 [소장이지]가<br/>소장이나 지급명령신청서를 자동으로 완성해드립니다.<br/>무료로 작성한 소장이나 지급명령신청서를 법원에<br/>직접 제출할 수 있고, 합리적인 가격으로<br/>변호사 검토 및 제출대행 서비스를<br/>이용할 수도 있습니다.</p>
              </div>
            </div>
          </div>
        </div>

        <p className="sj-head">서비스 이용 방법</p>

        <div className="txt-area ta-center">
          <div className="txt-cont">
            <FormatQuoteIcon />
            <p className="ta-center">자동으로 안내됩니다. 쉽게 따라 해 보세요</p>
            <FormatQuoteIcon />
          </div>
        </div>

        <div className="use-step-area mw1280">
          <div className="use-group flex">
            {this.state.useStepList.map((useStepItem, index) => (
              <div className="card-wrap" key={`useStepItem${index}`}>
                <div className="card ta-center">
                  <b className="use-step-no flex-center">{useStepItem.no}</b>
                  <div className="use-step-bx">
                    <b className="ttl color-6bbaff">{useStepItem.ttl}</b>
                    <img src={useStepItem.img} alt={useStepItem.ttl} />
                    <p>{useStepItem.txt1}</p>
                    <p>{useStepItem.txt2}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="civil-litigation-area ta-center">
          <p className="sj-head">소송 및 지급명령 진행 절차</p>
          <b className="procedure-ttl color-6bbaff">민사소송 진행 절차</b>
          <div className="mw1280">
            <div className="procedure-group flex">
              {this.state.civilLitigationProcedureList.map((civilLitigationItem, index) => (
                <div className="card-wrap" key={`civilLitigationItem${index}`}>
                  <div className="card">
                    <div className="img-circle flex-center">
                      <img src={civilLitigationItem.img} alt={civilLitigationItem.ttl} />
                    </div>
                    <b className="ttl color-6bbaff">{civilLitigationItem.ttl}</b>
                    <p>{civilLitigationItem.txt1}</p>
                    <p>{civilLitigationItem.txt2}</p>
                  </div>
                  <ArrowForwardIosIcon />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="paymentOrder-area mw1280 ta-center">
          <b className="procedure-ttl color-6bbaff">지급명령 진행 절차</b>
          <div className="procedure-group flex">
            {this.state.paymentOrderProcedureList.map((paymentOrderItem, index) => (
              <div className="card-wrap" key={`paymentOrderItem${index}`}>
                <div className="card">
                  <div className="img-circle flex-center">
                    <img src={paymentOrderItem.img} alt={paymentOrderItem.ttl} />
                  </div>
                  <b className="ttl color-6bbaff">{paymentOrderItem.ttl}</b>
                  <p>{paymentOrderItem.txt1}</p>
                  <p>{paymentOrderItem.txt2}</p>
                </div>
                <ArrowForwardIosIcon />
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}