
import './Step.scss';
import { Button, TextField } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';

import _ from 'lodash'

export default function Step4Evidence(props) {
  
  const changeName = (e) => {
    let evidence = _.cloneDeep(props.evidence);
    evidence.name = e.target.value;
    props.onRefreshEvidence(evidence);
  }

  const changeFile = (file) => {
    let evidence = _.cloneDeep(props.evidence);
    evidence.file = file;
    props.onRefreshEvidence(evidence);
  }

  const getFile = async (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve({ file: file, url: reader.result, name: file.name, format: file.name.split('.')[file.name.split('.').length - 1] });
      };
      reader.readAsDataURL(file);
    })  
  }

  const clickedFile = () => {

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.onchange = async function() {
      
      const file = input.files[0];
      const result = await getFile(file);

      changeFile(result);

      
    }.bind(this);

    input.click();

  }

  return(
    <div className="cont-bx step4-evidence">
      <Button className="remove-btn" onClick={e => props.onClickRemove()}><RemoveIcon/></Button>
      <div className="item-bx flex-row">
        <p className="ttl">호증번호</p>
        <TextField
          variant="outlined"
          disabled
          value={props.evidence.no}
        />
      </div>

      <div className="item-bx flex-row">
        <p className="ttl">서류명(필수)</p>
        <TextField
          variant="outlined"
          value={props.evidence.name}
          onChange={changeName}
          placeholder="차용증"
        />
      </div>

      <div className="item-bx file-row-bx flex-row">
        <p className="ttl">파일</p>
        <TextField
          variant="outlined"
          className="file-name"
          disabled
          value={props.evidence.file?.name || ''}
        />
        <label className="flex ai-center" onClick={e => clickedFile()}>파일선택</label>
        {/* <input type="file" id="add-file" className="hide-input"/> */}
      </div>
   </div>
  )
}