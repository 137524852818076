
import { connect, useSelector } from 'react-redux'
import EditJointGuarantor from "../EditJointGuarantor";
import SJDatePicker from './SJDatePicker';
import { TextField, Radio, Button, Checkbox, Select, MenuItem, FormControl } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import { getName } from '../../../utils/utils'

import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'

import _ from 'lodash'
import { useAppSelector } from '../../../store';

const ServicePrice = (props) => {

  let complaint = useAppSelector((state) => state.app.complaint)
  let defendantList = useAppSelector((state) => state.app.defendantList)

  const changeContractDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPriceContractDate = date;
    props.refreshComplaint(c);
  }

  const changeTradingPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPrice = price;
    props.refreshComplaint(c);
  }

  const changeDeferInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeferInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDeferInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.bondList[index].deferInterestRate = rate;
    props.refreshComplaint(c);
  }
  
  const changeServiceTitle = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].serviceTitle = e.target.value;
    props.refreshComplaint(c);
  }

  const changeServiceType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].serviceType = e.target.value;
    props.refreshComplaint(c);
  }

  const refreshJointGuarantorList = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].jointGuarantorList = e;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDate = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackListDate = (date, index, willPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].date = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateUnit = (e, index, willPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].unit = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDatePrice = (e, index, willPaybackDateIndex) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateList[willPaybackDateIndex].price = price;
    props.refreshComplaint(c);
  }

  const addWillPaybackDate = (bondIndex) => {
      
    let c = _.cloneDeep(complaint);

    const bond = c.bondList[bondIndex];

    if(bond.willPaybackDateList.length === 0) {
      bond.willPaybackDateList = [{unitPlaceHolder: '계약금', unit: '계약금', price: 0, date: '2020-01-01'}, {unitPlaceHolder: '잔금', unit: '잔금', price: 0, date: '2020-03-01'}];
      bond.didPaybackDateList = [{ unit: '계약금', price: 0, date: '', defendant: null}, {unit: '잔금', price: 0, date: '', defendant: null}];
    }
    else {

      const insertIndex = bond.willPaybackDateList.length - 1;
      let unit = '';

      if(bond.willPaybackDateList.length === 2) {
        unit = '중도금';
      }
      else {
        if(bond.willPaybackDateList.length === 3) {
          bond.willPaybackDateList[1].unitPlaceHolder = '제1차 중도금';
          bond.willPaybackDateList[1].unit = '제1차 중도금';
          bond.didPaybackDateList[1].unit = '제1차 중도금';
        }
        unit = `제${bond.willPaybackDateList.length - 1}차 중도금`;
      }

      bond.willPaybackDateList.splice(insertIndex, 0, {unitPlaceHolder: unit, unit: unit, date: '2020-02-01', price: 0})
      bond.didPaybackDateList.splice(insertIndex, 0, {unit: unit, price: 0, date: '', defendant: null})
    }

    
    props.refreshComplaint(c);
  }

  const removeWillPaybackDate = (bondIndex, willPaybackDateIndex) => {

    let c = _.cloneDeep(complaint);

    const bond = c.bondList[bondIndex];

    bond.willPaybackDateList.splice(willPaybackDateIndex, 1);
    bond.didPaybackDateList.splice(willPaybackDateIndex, 1);

    if(bond.willPaybackDateList.length === 3) {
      bond.willPaybackDateList[1].unitPlaceHolder = '중도금';
    }

    props.refreshComplaint(c);
  }

  const changeWillDeliveryDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willDeliveryDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeWillDeliveryDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.willDeliveryDate = date;

    props.refreshComplaint(c);
  }


  const changeDidDeliveryDateType = (e, index) => {

    let c = _.cloneDeep(complaint);
    c.bondList[index].didDeliveryDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDidDeliveryDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.didDeliveryDate = date;

    props.refreshComplaint(c);
  }

  const changeBondDidPaybackDate = (e, index, didPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];
    bond.didPaybackDateList[didPaybackDateIndex].isChecked = e.target.checked;
    props.refreshComplaint(c);
  }

  const changeBondDidPaybackDateDefendant = (e, index, didPaybackDateIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];
    bond.didPaybackDateList[didPaybackDateIndex].defendant = e.target.value;
    props.refreshComplaint(c);
  }
  
  const checkDidPaybackDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.didPaybackDateList[index].isChecked = e.target.checked;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDefendant = (e, index) => {
    let c = _.cloneDeep(complaint);

    if(e.target.value === -1) {
      c.didPaybackDateList[index].defendant = null;
    }
    else {
      const dIndex = defendantList.map(d => d.id).indexOf(e.target.value);
      if(dIndex !== -1) {
        c.didPaybackDateList[index].defendant = defendantList[dIndex];
      }
      else {
        c.didPaybackDateList[index].defendant = null;
      }
    }

    
    props.refreshComplaint(c);
  }

  const changeDidPaybackDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].date = date;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDatePrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].price = price;
    props.refreshComplaint(c);
  }

  return(
    <div className="service-price">
      <p className="head">용역대금</p>
      {complaint.bondList.map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          {index !== 0 && (<Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon/></Button>)}
          <p className="ttl">1) 주채무자</p>
          <div className="answer-area">
            <span>{getName(defendantList[0])} 입니다.</span>
          </div>
          <hr />
          <EditJointGuarantor 
            bondIndex={index}
            jointGuarantorList={bond.jointGuarantorList} 
            defendantList={defendantList} 
            onAdd={e => props.addJointGuarantor(index)}
            onRemove={jIndex => props.removeJointGuarantor({bondIndex: index, index: jIndex})}
            refresh={e => refreshJointGuarantorList(e, index)}/>
          <hr />
          <p className="ttl">3) 용역계약을 체결한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker 
              date={bond.tradingPriceContractDate}
              onChangeDate={date => changeContractDate(date, index)}
              />
            <span className="ml_4">입니다.</span>
          </div>
          <hr />
          <p className="ttl">4) 용역 내용이 무엇인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              value={bond.serviceTitle}
              onChange={e => changeServiceTitle(e, index)}
              placeholder="프로그램 개발"
              className="w240"
            />
            <span className="ml_4">입니다.</span>
          </div>
          <hr />
          <p className="ttl">5) 일의 완료 이외에 목적물 인도가 필요한가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='delivery'
                checked={bond.serviceType === 'delivery'}
                onChange={e => changeServiceType(e, index)}
              />
              <span className="mr_10">일의 완료 이외에 목적물 인도가 필요합니다.</span>
              <span className="sub-txt">예) 설계, 개발, 디자인, 건설</span>
            </label>

            <div className="radio-item">
              <label className="radio-item">
                <Radio
                  value='complete'
                  checked={bond.serviceType === 'complete'}
                  onChange={e => changeServiceType(e, index)}
                />
                <span className="mr_10">일의 완료만 하면 됩니다.</span>
                <span className="sub-txt">예) 경비, 청소</span>
              </label>
            </div>
          </div>
          <hr />
          <p className="ttl">6) 일을 완료하기로 한 날짜가 언제인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willDeliveryDateType === 'fix'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker
                date={bond.willDeliveryDate}
                onChangeDate={date => changeWillDeliveryDate(date, index)}
                />
              <span className="ml_4">입니다.</span>
            </label>

            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willDeliveryDateType === 'none'}
                onChange={e => changeWillDeliveryDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">7) 일을 언제 완료하였나요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='all'
                checked={bond.didDeliveryDateType === 'all'}
                onChange={e => changeDidDeliveryDateType(e, index)}
              />
              <SJDatePicker
                date={bond.didDeliveryDate}
                onChangeDate={date => changeDidDeliveryDate(date, index)}
                />
              <span className="ml_4">에 모두 완료하였습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.didDeliveryDateType === 'none'}
                onChange={e => changeDidDeliveryDateType(e, index)}
              />
              <span>일을 완료하지 않았습니다.</span>
              <p className="sub-txt radio-item-sub-txt">* 일을 완료하지 않았다면, 용역대금을 청구할 수 없습니다.</p>
            </label>
          </div>
          <hr />
          <p className="ttl">8) 용역대금 총액이 얼마인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              type="number"
              value={bond.tradingPrice === 0 ? '' : bond.tradingPrice}
              onChange={e => changeTradingPrice(e, index)}
              placeholder="1,000,000"
              className="input-price"
            />
            <span className="ml_4">원 입니다.</span>
          </div>
          <hr />
          <p className="ttl">9) 용역대금을 지급하기로 한 날짜가 언제인가요?</p>
          <div className="answer-area item9">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willPaybackDateType === 'fix'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker 
                date={bond.willPaybackDate}
                onChangeDate={date => changeWillPaybackDate(date, index)}/>
              <span className="ml_4">입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='division'
                checked={bond.willPaybackDateType === 'division'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">분할하여 지급하기로 정하였습니다.</span>
              <Button className="add-btn btn-24" onClick={e => addWillPaybackDate(index)}><AddIcon/></Button>
            </label>
            {bond.willPaybackDateList.map((willPaybackDate, wIndex) => (
              <div className="radio-sub-bx flex-row jc-sb">
                <div className="flex-row">
                  <SJDatePicker
                    date={willPaybackDate.date}
                    onChangeDate={date => changeWillPaybackListDate(date, index, wIndex)}
                    />
                  <span className="mr_10">에</span>
                  <TextField
                    variant="outlined"
                    value={willPaybackDate.unit}
                    onChange={e => changeWillPaybackDateUnit(e, index, wIndex)}
                    placeholder={willPaybackDate.unitPlaceHolder}
                    className="input-text"
                  />
                  <TextField
                    variant="outlined"
                    value={willPaybackDate.price === 0 ? '' : willPaybackDate.price}
                    onChange={e => changeWillPaybackDatePrice(e, index, wIndex)}
                    placeholder="1,000,000"
                    className="input-price"
                  />
                  <span>원</span>
                </div>
                {(wIndex !== 0 && wIndex !== bond.willPaybackDateList.length - 1) && (
                  <Button className="add-btn btn-24" onClick={e => removeWillPaybackDate(index, wIndex)}><RemoveIcon /></Button>
                )}
                
              </div>
            ))}
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willPaybackDateType === 'none'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">10) 지연이자율을 얼마로 정하였나요? <span>(갚기로 약속한 날짜 이후 이자)</span></p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" variant="outlined" autoComplete='off'
                value={bond.deferInterestRate === 0 ? '' : bond.deferInterestRate}
                onChange={e => changeDeferInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>

            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
        </div>
      ))}
      <Button className="contained" onClick={e => props.addBond()}>채권 추가</Button>

      <div className="head-bx">
        <p className="head">피고가 용역대금을 일부 지급했나요?</p>
        <Button className="remove-btn" onClick={e => props.addDidPaybackDate()}><AddIcon/></Button>
      </div>
      <div className="cont-bx">
        <p className="sub-txt">* 일부 지급했다면, 우측의 +버튼을 눌러 추가하세요.</p>
        <p className="sub-txt">* 지급하지 않았다면, 다음 단계로 넘어가세요.</p>
        <div className="border-bx">
          {complaint.bondList.map((bond, index) => (
            <div key={`bond-didpayback${index}`}>
              {complaint.bondList.length > 1 && <p>[{index + 1}번 채권]</p>}
              {bond.didPaybackDateList.filter((d, i) => bond.didPaybackDateList.length - 1 !== i).map((didPaybackDate, dIndex) => (
                <div className="checkbox-item" key={`bond-didpayback${index}${dIndex}`}>
                <Checkbox
                  checked={didPaybackDate.isChecked}
                  onChange={e => changeBondDidPaybackDate(e, index, dIndex)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <FormControl variant="outlined" className="select">
                  <Select
                    value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1}
                    onChange={e => changeBondDidPaybackDate(e, index, dIndex)}
                  >
                    <MenuItem value={-1} >===</MenuItem>
                    {defendantList.map((defendant, defendantIndex) => (
                      <MenuItem value={defendant.id} key={`bond-didPayback-defendant${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <span className="ml_4">이(가) {didPaybackDate.unit}을 지급하였습니다.</span>
              </div>
              ))}
            </div>
          ))}
          {complaint.bondList.length > 1 && complaint.didPaybackDateList.length !== 0 && <p>[모든 채권]</p>}
          {complaint.didPaybackDateList.map((didPaybackDate, index) => (
            <div className="checkbox-item" key={`didPaybackDate${index}`}>
              <Checkbox
                checked={didPaybackDate.isChecked}
                onChange={e => checkDidPaybackDate(e, index)}
              />
              <span className="mr_10">그밖에</span>
              <FormControl variant="outlined" className="select">
                <Select
                  value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1} 
                  onChange={e => changeDidPaybackDateDefendant(e, index)}
                >
                  <MenuItem value={-1}>===</MenuItem>
                  {defendantList.map((defendant, defendantIndex) => (
                    <MenuItem value={defendant.id} key={`didPayback-defendant${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <span className="ml_4 mr_10">이(가)</span>
              <SJDatePicker 
                date={didPaybackDate.date}
                onChangeDate={date => changeDidPaybackDate(date, index)}
              />
              <span className="ml_4">에</span>
              <TextField
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => changeDidPaybackDatePrice(e, index)}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원을 지급하였습니다.</span>
              <Button className="remove-btn btn-24" onClick={e => props.removeDidPaybackDate(index)}><RemoveIcon/></Button>
            </div>
          ))}
          
        </div>
      </div>
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePrice)