import { Button, TextField } from '@mui/material';
import { useState } from 'react';


//전유부분의 건물의 표시 
export default function EditSetBuildingSub2(props) {

  const [contents1, setContents1] = useState(props.info2?.contents1 || '');
  const [contents2, setContents2] = useState(props.info2?.contents2 || '');
  const [area, setArea] = useState(props.info2?.area || '');

  return (
    <div className="modal-container edit-set-building-sub">
      <div className="modal">
        <p className="top-area">전유부분의 건물의 표시</p>

        <div className="middle-area">
          <div className="item">
            <p className="ttl">건물번호</p>
            <div className="textarea-wrap">
              <TextField
                value={contents1}
                onChange={e => setContents1(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>
          <div className="item">
            <p className="ttl">건물내역</p>
            <div className="textarea-wrap">
              <TextField
                value={contents2}
                onChange={e => setContents2(e.target.value)}
                multiline
                rows={3}
              />
            </div>
          </div>

          <div className="item">
            <p className="ttl">면적</p>
            <TextField
              value={area}
              onChange={e => setArea(e.target.value)}
              style={{width: '282px'}}
            />
            <span className="ml_4">㎡</span>
          </div>
        </div>

        <div className="bottom-area">
          <Button className="outlined" onClick={e => props.onClose()}>취소</Button>
          <Button className="contained" onClick={e => props.info2 ? props.onEdit({contents1, contents2, area}) : props.onAdd({contents1, contents2, area})}>추가</Button>
        </div>
      </div>
    </div>
  )
}
