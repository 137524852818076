
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import reducer, { RootStates } from './modules';
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";

const store = configureStore({ 
  reducer: reducer,
  middleware: (getDefaultMiddleware) => process.env.NEXT_PUBLIC_MODE !== 'production' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware(),
  devTools: process.env.NEXT_PUBLIC_MODE !== 'production',
});

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector