import '../Preview.scss';

import { connect } from "react-redux";

import josa from 'josa-js';
import josajs from 'josa-js';

import LossTitle from './LossTitle';
import { useAppSelector } from '../../../../store';

const HANGEUL_INDEX = ['가', '나', '다', '라', '마', '바', '사', '아'];

const Spacer = () => {
  return (
    <p style={{height: '20px'}}></p>
  )
}

export default function NameRegisterPreview(props) {

  const execution = useAppSelector((state) => state.app.application.execution);
  const deptText = useAppSelector((state) => state.app.application.deptText);

  const getExecutionText = (execution) => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}` 
    } 
    else if(execution.type === '기타') {
      return execution.execution3;
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${execution.type}`  
    }
  }

  const getExecutionText2 = (execution) => {
    if(execution.type === '집행력있는 공정증서 정본') {
      const {lawFirm, year, subName} = execution.execution2;
      return `공증인가 ${lawFirm} 증서 ${year}년 제${subName}호 ${execution.type}이` 
    } 
    else if(execution.type === '기타') {
      return josajs.r(execution.execution3, '이/가');
    }
    else {
      const { court, year, code, subName, name } = execution.execution1;
      return `${court} ${year}${code}${subName} ${name} 사건의 ${josa.r(execution.type, '이/가')}`.replace('확정 ', '').replace('확정된 ', '')
    }
  }

  const getSpacingWord = (word) => {
    return word.split('').join(' ')
  }

  return (
    <div className="inner-content">
      
      <Spacer/>

      <LossTitle
        title="집행권원의 표시 : "
        contents={getExecutionText(execution)}
        bold={true}
      />

      <LossTitle
        title="채무자가 이행하지 아니하는 금전채무액 :"
        contents={deptText}
        bold={true}
      />

      <Spacer/>

      <p className="ta-center bold">신 청 취 지</p>

      <Spacer/>

      <p>채무자를 채무불이행자명부에 등재한다.</p>
      <p>라는 재판을 구합니다.</p>

      <Spacer/>

      <p className="ta-center bold">신 청 이 유</p>

      <Spacer/>

      <LossTitle
        title="1. "
        //contents={`채권자는 채무자에 대하여 위와 같은 집행권원을 가지고 있으며, ${incident.court} ${incident.year}${incident.code}${incident.subName}호 ${incident.name} 사건의 판결이 확정된 후 6개월이 지나도록 채무자는 위 채 무를 이행하지 아니하고 있습니다.`}
        contents={`채권자는 채무자에 대하여 위와 같은 집행권원을 가지고 있으며, ${getExecutionText2(execution)} 확정된 후 6개월이 지나도록 채무자는 위 채무를 이행하지 아니하고 있습니다.`}
      />

      <LossTitle
        title="2. "
        contents="이에 채권자는 민사집행법 제 70조 제 1항 제1호에 의하여 채무자에 대한 채무불이행자명부 등재를 신청합니다."
      />

      <Spacer/>

      {/* {props.attachmentList.length !== 0 && (
        <div className="attachment-area">
          <b className="ttl">첨 부 서 류</b>
          {props.attachmentList.map((attachment, index) => (
            <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
          ))}
        </div>
      )} */}
    </div>
  )
}

