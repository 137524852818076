import moment from "moment";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { get } from "../../utils/Api";

function Notice(props) {

  const navigate = useNavigate()

  const [noticeList, setNoticeList] = useState([])

  useEffect(() => {
    getNoticeList();
  }, [])

  const getNoticeList = () => {
    get('notice')
    .then(response => {
      if(response.data.success) {
        setNoticeList(response.data.data);
      }
      else {

      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="notice page-view">
      <div className="h-top"></div>
      <div className="inner mw1280">
        <div className="head-area">
          <span className="head">공지사항</span>
        </div>

        <div className="cont-area">
          <div className="txt-card-view-group flex">
            {noticeList.map((notice, index) => (
              <a className="card-wrap" onClick={e => navigate(`/notice/${notice.id}`)}>
                <div className="card">
                  <p className="color-6bbaff">공지사항</p>
                  <b className="ttl color-222">{notice.title}</b>
                  <p className="txt">{notice.contents}</p>
                  <p className="date">{moment(notice.created_at).format('YYYY-M-D')}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notice;
