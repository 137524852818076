import { FormControl, Select, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';

const Court = [
  '서울중앙지방법원', '서울동부지방법원', '서울남부지방법원', '서울북부지방법원', '서울서부지방법원', '의정부지방법원', '인천지방법원', '수원지방법원', '춘천지방법원', '대전지방법원', '청주지방법원', '대구지방법원', '부산지방법원', '울산지방법원', '창원지방법원', '광주지방법원', '전주지방법원', '제주지방법원'
]

type SelectCourtProps = {
  court: any
  courtList?: any[]
  onSelectCourt: (court: any) => void
}

export default function SelectCourt(props: SelectCourtProps) {

  const [courtList, setCourtList] = useState(props.courtList ? props.courtList : Court)

  useEffect(() => {
    setCourtList(props.courtList || Court);
  }, [props.courtList])


  return (
    <div className="answer-area">
      <FormControl className='select'>
        <Select
          value={props.court}
          onChange={e => props.onSelectCourt(e.target.value)}
          className="w180"
        >
          {courtList.map((item, index) => (
            <MenuItem value={item} key={`item${index}`}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}