import { Button,FormControl, Select, MenuItem, Radio, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import _ from 'lodash'

import { getName } from '../../utils/utils'
import { useEffect, useState } from 'react';

function EditJointGuarantor(props) {

  const [isShowAnswer, setIsShowAnswer] = useState(true);

  const defendantList = props.defendantList;
  const jointGuarantorList = props.jointGuarantorList;

  useEffect(() => {
    setIsShowAnswer(props.isShowAnswer);
  }, [props.isShowAnswer]);

  useEffect(() => {
  }, [isShowAnswer])

  const changeJointGuarantorDefendant = (e, index) => {
    let jointGuarantorList = _.cloneDeep(props.jointGuarantorList);
    const defendantIndex = defendantList.map(d => d.id).indexOf(parseInt(e.target.value));
    jointGuarantorList[index].defendant = defendantList[defendantIndex];
    props.refresh(jointGuarantorList);
  }

  const changeJointGuarantorIsLimit = (e, index) => {
    let jointGuarantorList = _.cloneDeep(props.jointGuarantorList);
    jointGuarantorList[index].isLimit = e.target.value === 'on';
    props.refresh(jointGuarantorList);
  }

  const changeJointGuarantorPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0

    let jointGuarantorList = _.cloneDeep(props.jointGuarantorList);
    jointGuarantorList[index].price = price;
    props.refresh(jointGuarantorList);
  }

  return (
    <div className="joint-guarantor">
      <Button className="add-btn" onClick={e => props.onAdd()}><AddIcon/></Button>
      <div className="ttl-bx">
        <p className="ttl">2) 연대보증인이 있나요?</p>
      </div>
      <div className="answer-area item2">
        <p className="sub-txt">* 연대보증인이 있는 경우, 우측의 +버튼을 누르세요.</p>
        <p className="sub-txt">* 연대보증인이 없는 경우, 3) 단계로 넘어가세요.</p>
      </div>
      
      {jointGuarantorList.map((jointGuarantor, index) => (
        <div className="border-bx" key={`jointGuarantor${index}`}>
          <Button className="remove-btn btn-24" onClick={e => props.onRemove(index)}><RemoveIcon /></Button>
          <FormControl variant="outlined" className="select">
            <Select
              value={jointGuarantor.defendant !== null ? jointGuarantor.defendant.id : -1} 
              onChange={e => changeJointGuarantorDefendant(e, index)}
            >
              {defendantList.filter((d, i) => i !== 0).map((defendant, defendantIndex) => (
                <MenuItem value={defendant.id} key={`didPaybackDate${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
              ))}
              
            </Select>
          </FormControl>
          {isShowAnswer && (
            <div>
              <label className="radio-item">
                <Radio
                  value='on'
                  checked={jointGuarantor.isLimit}
                  onChange={e => changeJointGuarantorIsLimit(e, index)}
                />
                <span className="mr_10">보증한도는</span>
                <TextField type="number" variant="outlined" autoComplete='off'
                  value={jointGuarantor.price === 0 ? '' : jointGuarantor.price}
                  onChange={e => changeJointGuarantorPrice(e, index)}
                  className="input-price" />
                <span>원 입니다.</span>
              </label>
              <label className="radio-item">
                <Radio
                  value='off'
                  checked={!jointGuarantor.isLimit}
                  onChange={e => changeJointGuarantorIsLimit(e, index)}
                />
                <span>보증한도는 정하지 않았습니다.</span>
              </label>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

EditJointGuarantor.defaultProps = {
  isShowAnswer: true
}

export default EditJointGuarantor