import React from 'react';
import './Step.scss';
// import { Button } from '@material-ui/core';

import { getName } from '../../../utils/utils'
import { useAppSelector } from '../../../store';

type Step5Props = {
  evidenceList: any[]  
  attachmentList: any[]
  normalAttachmentList: any[]
}

export default function Step5(props: Step5Props) {
  
  const type = useAppSelector((state) => state.app.type);

  const complaint = useAppSelector((state) => state.app.complaint);
  const plaintiff = useAppSelector((state) => state.app.plaintiff);
  const defendantList = useAppSelector((state) => state.app.defendantList);


  return(
    <div className="cont-bx step5-check">
      <div className="item-bx flex">
        <div className="icon-ttl">
          <img src="../../Image/step5-1.png" alt="소장" />
          <p className="ttl ta-center">{type === '신청서' ? '신청서' : complaint.documentType === '소장' ? '소장' : '지급명령신청서'}</p>
        </div>

        <div className="contents flex1 column">
          <div className="row-bx">
            <p>원고: {getName(plaintiff)}</p>
          </div>
          <div className="row-bx">
            <p>피고: {defendantList.map(d => getName(d)).join(',')}</p>
          </div>
        </div>
      </div>

      {type === '소장' && (
        <div className="item-bx flex">
          <div className="icon-ttl">
            <img src="../../Image/step5-2.png" alt="소장" />
            <p className="ttl ta-center">증거서류</p>
          </div>

          <div className="contents flex1 column">
            {props.evidenceList.map((evidence, index) => (
              <p key={`evidence${index}`}>{index + 1}. {evidence.name}</p>
            ))}
          </div>
        </div>
      )}

      <div className="item-bx flex">
        <div className="icon-ttl">
          <img src="../../Image/step5-2.png" alt="소장" />
          <p className="ttl ta-center">첨부서류</p>
        </div>

        <div className="contents flex1 column">
        {props.normalAttachmentList.map((attachment, index) => (
          <p key={`normal-attachment${index}`}>{index + 1}. {attachment.name}</p>
        ))}
        {props.attachmentList.map((attachment, index) => (
            <p key={`attachment${index}`}>{index + 1}. {attachment.name}</p>
          ))}
        </div>
      </div>
   </div>
  )
}
