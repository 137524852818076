import { useState } from 'react';

import { TextField, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditSetBuildingSub1 from './EditSetBuildingSub1';
import EditSetBuildingSub2 from './EditSetBuildingSub2';
import EditSetBuildingSub3 from './EditSetBuildingSub3';
import EditFloor from './EditFloor';

//집합건물 추가, 수정
export default function EditSetBuilding(props) {

  const [showEditSetBuildingInfo1, setShowEditSetBuildingInfo1] = useState(false);
  const [showEditSetBuildingInfo2, setShowEditSetBuildingInfo2] = useState(false);
  const [showEditSetBuildingInfo3, setShowEditSetBuildingInfo3] = useState(false);

  const [address, setAddress] = useState(props.setBuilding?.address || '');
  const [history, setHistory] = useState(props.setBuilding?.history || '');
  const [floorList, setFloorList] = useState(props.setBuilding?.floorList || []);
  const [info1List, setInfo1List] = useState(props.setBuilding?.info1List || []);
  const [info2List, setInfo2List] = useState(props.setBuilding?.info2List || []);
  const [info3List, setInfo3List] = useState(props.setBuilding?.info3List || []);

  const [selectedInfo1Index, setSelectedInfo1Index] = useState(-1);
  const [selectedInfo2Index, setSelectedInfo2Index] = useState(-1);
  const [selectedInfo3Index, setSelectedInfo3Index] = useState(-1);

  return (
    <div className="modal-container edit-set-building">
      <div className="modal">
        <p className="top-area">집합건물</p>

        <div className="middle-area flex">
          <div className="left">
            <div className="item">
              <p className="ttl">소재지번</p>
              <div className="textarea-wrap">
                <TextField
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                  multiline
                  rows={3}
                />
              </div>
            </div>
            
            <div className="row-item">
              <div className="flex-row">
                <p className="ttl">대지권의 목적인 토지의 표시</p>
                <Button className="add-btn btn-24" onClick={e => setShowEditSetBuildingInfo1(true)}><AddIcon/></Button>
              </div>
              {info1List.map((info1, index) => (
                <div className="list-item" key={`info1${index}`}>
                  <p>{info1.address}</p>
                  <div>
                    <Button className="outlined" onClick={e => {
                      setSelectedInfo1Index(index);
                      setShowEditSetBuildingInfo1(true);
                    }}>수정</Button>
                    <Button className="outlined" onClick={e => setInfo1List([...info1List.slice(0, index), ...info1List.slice(index + 1)])}>삭제</Button>
                  </div>
                </div>
              ))}
            </div>
            

            <div className="row-item">
              <div className="flex-row">
                <p className="ttl">전유부분의 건물의 표시</p>
                <Button className="add-btn btn-24" onClick={e => setShowEditSetBuildingInfo2(true)}><AddIcon/></Button>
              </div>
                {info2List.map((info2, index) => (
                <div className="list-item" key={`info2${index}`}>
                  <p>{info2.contents1}</p>
                  <div>
                    <Button className="outlined" onClick={e => {
                      setSelectedInfo2Index(index);
                      setShowEditSetBuildingInfo2(true);
                    }}>수정</Button>
                    <Button className="outlined" onClick={e => setInfo2List([...info2List.slice(0, index), ...info2List.slice(index + 1)])}>삭제</Button>
                  </div>
                </div>
              ))}
            </div>
            

            <div className="row-item">
              <div className="flex-row">
                <p className="ttl">대지권의 표시</p>
                <Button className="add-btn btn-24" onClick={e => setShowEditSetBuildingInfo3(true)}><AddIcon/></Button>
              </div>
              {info3List.map((info3, index) => (
                <div className="list-item" key={`info3${index}`}>
                  <p>{info3.contents1}</p>
                  <div>
                    <Button className="outlined" onClick={e => {
                      setSelectedInfo3Index(index);
                      setShowEditSetBuildingInfo3(true);
                    }}>수정</Button>
                    <Button className="outlined" onClick={e => setInfo3List([...info3List.slice(0, index), ...info3List.slice(index + 1)])}>삭제</Button>
                  </div>
                </div>
              ))}
            </div>
            
          </div>

          <div className="right">
            <div className="item">
              <p className="ttl">건물내역</p>
              <div className="textarea-wrap">
                <TextField
                  value={history}
                  onChange={e => setHistory(e.target.value)}
                  multiline
                  rows={3}
                />
              </div>
            </div>

            <EditFloor 
              floorList={floorList}
              onRefreshData={floorList => setFloorList(floorList)}
            />
          </div>
        </div>

        <div className="bottom-area">
          <Button className="outlined" onClick={e => props.onClose()}>취소</Button>
          <Button className="contained" onClick={e => props.setBuilding ? props.onEdit({address, history, floorList, info1List, info2List, info3List}) : props.onAdd({address, history, floorList, info1List, info2List, info3List})}>추가</Button>
        </div>
      </div>

      {showEditSetBuildingInfo1 && (
        <EditSetBuildingSub1
          info1={info1List[selectedInfo1Index]}
          onAdd={(info1) => {
            setInfo1List([...info1List, info1])
            setShowEditSetBuildingInfo1(false);
          }}
          onEdit={(info1) => {
            setInfo1List([...info1List.slice(0, selectedInfo1Index), info1, ...info1List.slice(selectedInfo1Index + 1) ])
            setShowEditSetBuildingInfo1(false);
          }}
          onClose={() => setShowEditSetBuildingInfo1(false)}/>
      )}

      {showEditSetBuildingInfo2 && (
        <EditSetBuildingSub2
          info2={info2List[selectedInfo2Index]}
          onAdd={(info2) => {
            setInfo2List([...info2List, info2])
            setShowEditSetBuildingInfo2(false);
          }}
          onEdit={(info2) => {
            setInfo2List([...info2List.slice(0, selectedInfo2Index), info2, ...info1List.slice(selectedInfo2Index + 1) ])
            setShowEditSetBuildingInfo2(false);
          }}
          onClose={() => setShowEditSetBuildingInfo2(false)}
        />
      )}

      {showEditSetBuildingInfo3 && (
        <EditSetBuildingSub3
          info3={info3List[selectedInfo3Index]}
          onAdd={(info3) => {
            setInfo3List([...info3List, info3])
            setShowEditSetBuildingInfo3(false);
          }}
          onEdit={(info3) => {
            setInfo3List([...info3List.slice(0, selectedInfo3Index), info3, ...info3List.slice(selectedInfo3Index + 1) ])
            setShowEditSetBuildingInfo3(false);
          }}
          onClose={() => setShowEditSetBuildingInfo3(false)}
        />
      )}

    </div>
  )
}
