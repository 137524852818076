import moment from "moment"
import { useSelector } from "react-redux";
import { useAppSelector } from "../../../store";
import { Person } from "../../../store/modules/app";
import { addComma } from "../../../utils/utils"

export default function PreviewBondTable() {

  const complaint = useAppSelector((state) => state.app.complaint);
  const defendantList = useAppSelector((state) => state.app.defendantList)

  const getName = (person: Person|undefined) => {
    let name = (person?.type !== 0 && person?.type !== 1) ? person?.company : person?.name;
    if(!name || name === '') name = 'O O O';

    if(person?.type === 0 || person?.type === 1) {
      if(person?.isEmptyName === true) {
        name = '성명 불상';
      }
    }
    else {
      if(person?.isEmptyCompany === true) {
        name = '성명 불상';
      }
    }

    return name;
  }

  return (
    <div className="bond-table" id="bond-table" style={{fontSize: 'textnode-font-size', lineHeight: 'textnode-font-size', whiteSpace: 'nowrap', letterSpacing: '0px'}}>
      <p style={{}}>- 채권 내역</p>
      <table>
        <thead>
          {complaint.type !== '매매대금' && (
            <tr>
              <th>순번</th>
              <th style={{minWidth: '100px'}}>대여시기</th>
              <th style={{minWidth: '90px'}}>주채무자</th>
              <th>연대보증인</th>
              <th>대여원금</th>
              <th>이자율(연)</th>
              <th style={{minWidth: '100px'}}>변제기</th>
              <th>지연손해금율(연)</th>
            </tr>
          )}
          {complaint.type === '매매대금' && (
            <tr>
              <th>순번</th>
              {complaint.type === '매매대금' && (
                <th>분류</th>
              )}
              <th style={{minWidth: '100px'}}>발생시기</th>
              <th style={{minWidth: '90px'}}>주채무자</th>
              <th>연대보증인</th>
              <th>원금</th>
              <th style={{minWidth: '100px'}}>변제기</th>
              <th>지연손해금율(연)</th>
            </tr>
          )}
        </thead>

        <tbody>
          {complaint.type !== '매매대금' && complaint.bondList.map((bond, index) => (
            <tr key={`bond${index}`}>
              <td>{index + 1}</td>
              <td>{moment(bond.lendDate).format('YYYY. M. D.')}</td>
              <td>{getName(defendantList[0])}</td>
              <td>{bond.jointGuarantorList.map(j => getName(j.defendant)).join(",") || '-'}</td>
              <td>{addComma(bond.price)}</td>
              <td>{bond.isInterestRate ? `${bond.interestRate}%` : '-'}</td>
              <td>{bond.willPaybackDate !== '' ? moment(bond.willPaybackDate).format('YYYY. M. D.') : '-'}</td>
              <td>{bond.isDeferInterestRate ? `${bond.deferInterestRate}%` : '-'}</td>
            </tr>
          ))}
          {complaint.type == '매매대금' && complaint.virtualBondList.map((bond, index) => (
            <tr key={`bond${index}`}>
              <td>{index + 1}</td>
              <td>{bond.unit}</td>
              <td>{moment(bond.willPaybackDate).format('YYYY. M. D.')}</td>
              <td>{getName(defendantList[0])}</td>
              <td>{bond.jointGuarantorList.map((j: any) => getName(j.defendant)).join(",") || '-'}</td>
              <td>{addComma(bond.price)}</td>
              <td>{bond.lendDate !== '' ? moment(bond.lendDate).format('YYYY. M. D.') : '-'}</td>
              <td>{bond.isDeferInterestRate ? `${bond.deferInterestRate}%` : '-'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}