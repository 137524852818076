
import { useCallback, useEffect } from 'react';

import {
  addDefendant, removeDefendant,
  refreshComplaint,
} from '../../../store/modules/app'

import _ from 'lodash';

import {Button} from '@mui/material'
import AssetFindPerson from './AssetFindPerson';
import { useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { Person, refreshDefendant, refreshPlaintiff } from '../../../store/modules/app';
import InputPerson from './InputPerson';


export default function Step1(props: any) {

  const dispatch = useDispatch()

  const type = useAppSelector((state) => state.app.type);
  const complaintType = useAppSelector((state) => state.app.complaint.type);
  const applicationType = useAppSelector((state) => state.app.application.type);
  const plaintiff = useAppSelector((state) => state.app.plaintiff);
  const defendantList = useAppSelector((state) => state.app.defendantList);

  useEffect(() => {
    console.log(plaintiff, defendantList)
  }, [plaintiff, defendantList])

  const isShowAddDefendantButton = () => {
    if(type === '소장') {
      if(complaintType.includes('반환')) {
        return false
      }

      return true;
    }


    return false;
  }

  const onRemovePerson = (index: number) => {
    if(type === '소장') {
      dispatch(removeDefendant(index))
      //props.removeDefendant(index);
    }
    else {
      dispatch(removeDefendant(index))
      //props.removeApplicationDefendant(index);
    }
  }

  return (
    <div className="step1">
      <p className="head">{type === '소장' ? '원고' : '채권자'} 정보(본인 정보)</p>
      {(type === '신청서' && applicationType === '재산조회 신청서') ? (
        <AssetFindPerson
          type={0}
          person={plaintiff}
          onChange={person => dispatch(refreshPlaintiff(person))}
        />
      ) : (
        <InputPerson 
          type={0}
          person={plaintiff}
          onChange={person => dispatch(refreshPlaintiff(person))}
          />
      )}
      <hr />
      <p className="head">{type === '소장' ? '피고' : '채무자'} 정보(상대방 정보)</p>
      {defendantList.map((defendant, index) => {
        return (type === '신청서' && applicationType === '재산조회 신청서') ? (
          <AssetFindPerson 
            type={1}
            key={`defendant${index}`} 
            person={defendant}
            onChange={person => dispatch(refreshDefendant(person))}
          />
        ) : (
          <InputPerson 
            type={1}
            key={`defendant${index}`} 
            person={defendant}
            onRemovePerson={(index === 0 || isShowAddDefendantButton()) ? undefined : () => onRemovePerson(index)}
            onChange={person => dispatch(refreshDefendant(person))}
          />
        )
      })}
      {isShowAddDefendantButton() && <Button className="contained defendant-add-btn" onClick={e => type === '소장' ? dispatch(addDefendant()) : props.addApplicationDefendant()}>{type === '소장' ? '피고' : '채무자'} 추가</Button>}
    </div>
  )
}
