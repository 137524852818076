import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { TextField, Select, MenuItem, Button, FormControl } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import Execution from '../components/application/Execution';
import AddressPicker from '../components/bond/AddressPicker';
import EditLand from '../components/application/EditLand';
import EditBuilding from '../components/application/EditBuilding';
import EditSetBuilding from '../components/application/EditSetBuilding';

import { 
  refreshApplicationCourt, refreshExecution, refreshDeptText,
  addRelation, removeRelation, refreshRelation,
  refreshApplicationLandList, refreshApplicationBuildingList, refreshApplicationSetBuildingList
 } from '../../store/modules/app';
import SelectCourt from '../components/application/SelectCourt';
import { useAppSelector } from '../../store';

const Court = [
  '서울중앙지방법원', '서울동부지방법원', '서울남부지방법원', '서울북부지방법원', '서울서부지방법원', '의정부지방법원', '인천지방법원', '수원지방법원', '춘천지방법원', '대전지방법원', '청주지방법원', '대구지방법원', '부산지방법원', '울산지방법원', '창원지방법원', '광주지방법원', '전주지방법원', '제주지방법원'
]
const Interested = [
  '채무자 및 소유자', '채무자', '소유자', '공유자', '압류채권자', '압류권자', '근저당권자', '인도와 주민등록을 마친 주택임차인', '인도와 사업자등록신청을 마친 상가 건물임차인', '소액임차권자', '전세권자', '지상권자', '법정지상권자', '임대차 등기를 한 임차권자', '건물등기 있는 토지임차인', '유치권자', '점유권자', '가등기담보권자', '가등기권리자', '집행력 있는 정본에 의한 배당요구채권자', '근저당채권에 대한 질권자', '직접입력'
]

// 부동산강제경매신청서
function Auction(props) {

  const execution = useAppSelector((state) => state.app.application.execution);
  const debt = useAppSelector((state) => state.app.application.deptText);
  const court = useAppSelector((state) => state.app.application.court);
  const relationList = useAppSelector((state) => state.app.application.relationList);

  const landList = useAppSelector((state) => state.app.application.landList);
  const buildingList = useAppSelector((state) => state.app.application.buildingList);
  const setBuildingList = useAppSelector((state) => state.app.application.setBuildingList);
  
  const [selectedLandIndex, setSelectedLandIndex] = useState(-1);
  const [selectedBuildingIndex, setSelectedBuildingIndex] = useState(-1);
  const [selectedSetBuildingIndex, setSelectedSetBuildingIndex] = useState(-1);

  const [relation, setRelation] = useState('');
  const [showEditLand, setShowEditLand] = useState(false);
  const [showEditBuilding, setShowEditBuilding] = useState(false);
  const [showEditSetBuilding, setShowEditSetBuilding] = useState(false);

  useEffect(() => {
    if(!showEditLand) setSelectedLandIndex(-1);
  }, [showEditLand]);

  useEffect(() => {
    if(!showEditBuilding) setSelectedBuildingIndex(-1);
  }, [showEditBuilding]);

  useEffect(() => {
    if(!showEditSetBuilding) setSelectedSetBuildingIndex(-1);
  }, [showEditSetBuilding]);

  const onExecutionData = useCallback(execution => {
    props.refreshExecution(execution);

  }, [])

  return (
    <div className="auction">
      <p className="head">부동산 강제경매 신청서</p>

      <div className="cont-bx">
        <p className="ttl">1) 집행권원을 입력해 주세요.</p>
        <Execution 
          execution={execution}
          onData={onExecutionData} />

        <hr />

        <p className="ttl">2) 청구금액을 입력해 주세요.</p>
        <div className="answer-area textarea-wrap">
          <TextField
            value={debt}
            onChange={e => props.refreshDeptText(e.target.value)}
            multiline
            rows={2}
            placeholder="금 10,000원 및 이에 대한 2020. 1. 1.부터 다 갚는 날까지 연 20%의 비율에 의한 이자 및 지연손해금"
          />
        </div>

        <hr />

        <p className="ttl">3) 경매신청할 부동산 정보를 입력해 주세요.</p>
        <div className="answer-area item3">
          <div className="item-ttl-row flex-row">
            <span className="mr_10">토지</span>
            <Button className="add-btn btn-24" onClick={e => setShowEditLand(true)}><AddIcon /></Button>
          </div>
          {landList.map((land, index) => (
            <div className="list-item" key={`land${index}`}>
              <p>{land.address}</p>
              <div>
                <Button className="outlined" onClick={e => {
                  setSelectedLandIndex(index);
                  setShowEditLand(true);
                }}>수정</Button>
                <Button className="outlined" onClick={e => {
                  props.refreshApplicationLandList([...landList.slice(0, index), ...landList.slice(index + 1)])
                }}>삭제</Button>
              </div>
            </div>
          ))}
          

          <div className="item-ttl-row flex-row">
            <span className="mr_10">건물</span>
            <Button className="add-btn btn-24" onClick={e => setShowEditBuilding(true)}><AddIcon /></Button>
          </div>
          {buildingList.map((building, index) => (
            <div className="list-item" key={`building${index}`}>
              <p>{building.address}</p>
              <div>
                <Button className="outlined" onClick={e => {
                  setSelectedBuildingIndex(index);
                  setShowEditBuilding(true);
                }}>수정</Button>
                <Button className="outlined" onClick={e => {
                  props.refreshApplicationBuildingList([...buildingList.slice(0, index), ...buildingList.slice(index + 1)])
                }}>삭제</Button>
              </div>
            </div>
          ))}

          <div className="item-ttl-row flex-row">
            <span className="mr_10">집합건물</span>
            <Button className="add-btn btn-24" onClick={e => setShowEditSetBuilding(true)}><AddIcon /></Button>
          </div>
          {setBuildingList.map((setBuilding, index) => (
            <div className="list-item" key={`setBuilding${index}`}>
              <p>{setBuilding.address}</p>
              <div>
                <Button className="outlined" onClick={e => {
                  setSelectedSetBuildingIndex(index);
                  setShowEditSetBuilding(true);
                }}>수정</Button>
                <Button className="outlined" onClick={e => {
                  props.refreshApplicationSetBuildingList([...setBuildingList.slice(0, index), ...setBuildingList.slice(index + 1)])
                }}>삭제</Button>
              </div>
            </div>
          ))}
        </div>

        <hr />

        <p className="ttl">4) 이해관계인 정보를 입력해 주세요. <span>(중복선택 가능)</span></p>
        <div className="answer-area item4">
          <FormControl className='select'>
            <Select
              value={relation}
              onChange={e => {
                setRelation(e.target.value);
                props.addRelation(e.target.value);
              }}
            >
              {Interested.map((item, index) => (
                <MenuItem value={item} key={`item${index}`}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {relationList.map((relation, index) => {
            if(!relation.idEditable) {
              return (
                <div className="border-bx" key={`relation${index}`}>
                  <div className="flex-row">
                    <p>{relation.type}</p>
                    <Button className="btn-24" onClick={e => props.removeRelation(index)}><RemoveIcon/></Button>
                  </div>
                  <div className="flex-row">
                    <p className="item-ttl">성명</p>
                    <TextField
                      value={relation.name}
                      onChange={e => props.refreshRelation({index, relation: {...relation, name: e.target.value}})}
                      className="w180"
                    />
                  </div>
                  <div className="flex-row address-row">
                    <p className="item-ttl">주소</p>
                    <AddressPicker 
                      address={relation.address}
                      onSelectAddress={address => props.refreshRelation({index, relation: {...relation, address: address.address, zoneCode: address.zonecode}})}
                    />
                    <TextField
                      value={relation.detailAddress}
                      onChange={e => props.refreshRelation({index, relation: {...relation, detailAddress: e.target.value}})}
                      placeholder="상세주소"
                    />
                  </div>
                </div>
              )
            }
            else {
              return (
                <div className="border-bx" key={`relation${index}`}>
                  <div className="flex-row">
                    <TextField
                      value={relation.type}
                      onChange={e => props.refreshRelation({index, relation: {...relation, type: e.target.value}})}
                      className="w200"
                      placeholder="직접입력"
                    />
                    <Button className="btn-24" onClick={e => props.removeRelation(index)}><RemoveIcon/></Button>
                  </div>
                  <div className="flex-row">
                    <p className="item-ttl">성명</p>
                    <TextField
                      value={relation.name}
                      onChange={e => props.refreshRelation({index, relation: {...relation, name: e.target.value}})}
                      className="w180"
                    />
                  </div>
                  <div className="flex-row address-row">
                    <p className="item-ttl">주소</p>
                    <AddressPicker 
                      address={relation.address}
                      onSelectAddress={address => props.refreshRelation({index, relation: {...relation, address: address.address, zoneCode: address.zonecode}})}
                    />
                    <TextField
                      value={relation.detailAddress}
                      onChange={e => props.refreshRelation({index, relation: {...relation, detailAddress: e.target.value}})}
                      placeholder="상세주소"
                    />
                  </div>
                </div>
              )
            }
          })}          
        </div>

        <hr />

        <p className="ttl">5) 관할법원을 선택해 주세요.</p>
        <SelectCourt
          court={court}
          onSelectCourt={court => props.refreshApplicationCourt(court)}
        />
      </div>

      {showEditLand && (
        <EditLand
          land={landList[selectedLandIndex]}
          onAdd={(land) => {
            props.refreshApplicationLandList([...landList, land]);
            setShowEditLand(false);
          }}
          onEdit={(land) => {
            props.refreshApplicationLandList([...landList.slice(0, selectedLandIndex), land, ...landList.slice(selectedLandIndex + 1) ]);
            setShowEditLand(false);
          }}
          onClose={() => setShowEditLand(false)}
        />
      )}

      {showEditBuilding && (
        <EditBuilding
          building={buildingList[selectedBuildingIndex]}
          onAdd={(building) => {
            props.refreshApplicationBuildingList([...buildingList, building]);
            setShowEditBuilding(false);
          }}
          onEdit={(building) => {
            props.refreshApplicationBuildingList([...buildingList.slice(0, selectedBuildingIndex), building, ...buildingList.slice(selectedBuildingIndex + 1) ]);
            setShowEditBuilding(false);
          }}
          onClose={() => setShowEditBuilding(false)}
        />
      )}

      {showEditSetBuilding && (
        <EditSetBuilding
          setBuilding={setBuildingList[selectedSetBuildingIndex]}
          onAdd={(setBuilding) => {
            props.refreshApplicationSetBuildingList([...setBuildingList, setBuilding]);
            setShowEditSetBuilding(false);
          }}
          onEdit={(setBuilding) => {
            props.refreshApplicationSetBuildingList([...setBuildingList.slice(0, selectedSetBuildingIndex), setBuilding, ...setBuildingList.slice(selectedSetBuildingIndex + 1) ]);
            setShowEditSetBuilding(false);
          }}
          onClose={() => setShowEditSetBuilding(false)}
        />
      )}
      
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      application: state.app.application,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      refreshApplicationCourt: (data) => dispatch(refreshApplicationCourt(data)),
      refreshExecution: (data) => dispatch(refreshExecution(data)),
      refreshDeptText: (data) => dispatch(refreshDeptText(data)),
      addRelation: (type) => dispatch(addRelation(type)),
      removeRelation: (index) => dispatch(removeRelation(index)),
      refreshRelation: (data) => dispatch(refreshRelation(data)),
      refreshApplicationLandList: (list) => dispatch(refreshApplicationLandList(list)),
      refreshApplicationBuildingList: (list) => dispatch(refreshApplicationBuildingList(list)),
      refreshApplicationSetBuildingList: (list) => dispatch(refreshApplicationSetBuildingList(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auction)