//react
import { connect } from 'react-redux';

//ui
import { TextField, Radio, Checkbox, Button, Select, MenuItem, FormControl } from '@mui/material';
import SJDatePicker from './SJDatePicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AddressPicker from './AddressPicker';

//etc
import { 
  refreshComplaint
} from '../../../store/modules/app'

import _ from 'lodash'
import { useAppSelector } from '../../../store';



const CATEGORY = [
  '대', '전', '답', '임야', '도로', '과수원',
  '창고용지', '공장용지', '잡종지', '하천', '제방',
  '주차장', '주유소용지', '목장용지', '학교용지', '광천지', 
  '염전', '철도용지', '구거', '유지', '양어장', '수도용지', 
  '공원', '체육용지', '유원지', '종교용지', '사적지', '묘지'
]

const Deposit = (props) => {

  let complaint = useAppSelector((state) => state.app.complaint)


  const changeContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPriceContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeTradingPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPrice = price;
    props.refreshComplaint(c);
  }

  const changeLandAddress = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].address = e.target.value;

    props.refreshComplaint(c);
  }

  const changeLandCategory = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].category = e.target.value;

    props.refreshComplaint(c);
  }

  const changeLandArea = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList[index].area = e.target.value;

    props.refreshComplaint(c);
  }

  const addLand = (bondIndex) => {
    let c = _.cloneDeep(complaint);

    c.bondList[bondIndex].landList.push({address: '', category: '', area: ''})

    props.refreshComplaint(c);
  }

  const removeLand = (bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].landList.splice(index, 1);

    props.refreshComplaint(c);
  }

  const changeBuildingAddress = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].buildingList[index].address = e.target.value;

    props.refreshComplaint(c);
  }

  const changeBuildingDetail = (e, bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].buildingList[index].detail = e.target.value;

    props.refreshComplaint(c);
  }

  const addBuilding = (bondIndex) => {
    let c = _.cloneDeep(complaint);

    c.bondList[bondIndex].buildingList.push({address: '', detail: ''})

    props.refreshComplaint(c);
  }

  const removeBuilding = (bondIndex, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[bondIndex].buildingList.splice(index, 1);

    props.refreshComplaint(c);
  }

  const changeMaster = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[index].isChangedMaster = e.target.value === 'true' ? true : false;

    props.refreshComplaint(c);
  }

  const changeMasterDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[index].changedMasterDate = e.target.value;

    props.refreshComplaint(c);
  }

  const changeMasterName = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[index].changedMasterName = e.target.value;

    props.refreshComplaint(c);
  }

  const changeDepositPriceType = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[index].depositPriceType = e.target.value;

    props.refreshComplaint(c);
  }

  const changeDepositPrice = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    const price = parseInt(e.target.value) ? parseInt(e.target.value) : 0;

    c.bondList[index].depositPrice = price;

    props.refreshComplaint(c);
  }

  const changePrevDepositPrice = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    c.bondList[index].prevDepositPrice = parseInt(e.target.value) ? parseInt(e.target.value) : 0;

    props.refreshComplaint(c);
  }

  const changeNextDepositPrice = (e, index) => {
    let c = _.cloneDeep(complaint);
    
    const price = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    c.bondList[index].nextDepositPrice = price;

    props.refreshComplaint(c);
  }

  const changeDidDepositDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].didDepositDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeRentType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].rentType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeMonthRentPrice = (e, index) => {
    let c = _.cloneDeep(complaint);
    const price = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    c.bondList[index].monthRentPrice = e.target.value;
    props.refreshComplaint(c);
  }

  const changeYearRentPrice = (e, index) => {
    let c = _.cloneDeep(complaint);
    const price = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    c.bondList[index].yearRentPrice = e.target.value;
    props.refreshComplaint(c);
  }

  const changeContractDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].contractDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeStartContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].startContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeEndContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].endContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDelayStartContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].delayStartContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDelayEndContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].delayEndContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDelayFinalContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].delayFinalContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeContractExitReason = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].contractExitReason = e.target.value;
    props.refreshComplaint(c);
  }

  const changeCancelContractDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].cancelContractDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDidDeliveryDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].didDeliveryDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDidDeliveryDate = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].didDeliveryDate = e.target.value;
    props.refreshComplaint(c);
  }

  const changeIsDeduction = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeduction = e.target.checked;

    if(e.target.checked) {
      c.bondList[index].isDeductionDeposit = false;
      c.bondList[index].isDeductionRent = false;
      c.bondList[index].isDeductionFix = false;
      c.bondList[index].isDeductionEtc = false;
    }

    props.refreshComplaint(c);
  }

  const changeIsDeductionDeposit = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeductionDeposit = e.target.checked;

    if(c.bondList[index].isDeductionDeposit || c.bondList[index].isDeductionRent || c.bondList[index].isDeductionFix || c.bondList[index].isDeductionEtc) {
      c.bondList[index].isDeduction = false;
    }

    props.refreshComplaint(c);
  }

  const changeDeductionDeposit = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].deductionDeposit = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    props.refreshComplaint(c);
  }

  const changeIsDeductionRent = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeductionRent = e.target.checked;

    if(c.bondList[index].isDeductionDeposit || c.bondList[index].isDeductionRent || c.bondList[index].isDeductionFix || c.bondList[index].isDeductionEtc) {
      c.bondList[index].isDeduction = false;
    }
    props.refreshComplaint(c);
  }

  const changeDeductionRent = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].deductionRent = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    props.refreshComplaint(c);
  }

  const changeIsDeductionFix = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeductionFix = e.target.checked;

    if(c.bondList[index].isDeductionDeposit || c.bondList[index].isDeductionRent || c.bondList[index].isDeductionFix || c.bondList[index].isDeductionEtc) {
      c.bondList[index].isDeduction = false;
    }
    props.refreshComplaint(c);
  }

  const changeDeductionFix = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].deductionFix = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    props.refreshComplaint(c);
  }

  const changeIsDeductionEtc = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeductionEtc = e.target.checked;

    if(c.bondList[index].isDeductionDeposit || c.bondList[index].isDeductionRent || c.bondList[index].isDeductionFix || c.bondList[index].isDeductionEtc) {
      c.bondList[index].isDeduction = false;
    }
    props.refreshComplaint(c);
  }

  const changeDeductionEtc = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].deductionEtc = parseInt(e.target.value) ? parseInt(e.target.value) : 0;
    props.refreshComplaint(c);
  }

  const changeDeductionEtcReason = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].deductionEtcReason = e.target.value;
    props.refreshComplaint(c);
  }

  const changeDepositPurpose = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].depositPurpose = e.target.value;
    props.refreshComplaint(c);
  }

  const changeBond = (index, bond) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index] = bond;
    props.refreshComplaint(c);
  }

  return (
    <div className="deposit">
      <p className="head">임대차보증금</p>
      {_.cloneDeep(complaint.bondList).map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          <Button className="remove-btn"><RemoveIcon/></Button>
          
          <p className="ttl">1) 임대차계약을 체결한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker 
              date={bond.tradingPriceContractDate}
              onChangeDate={date => {
                changeBond(index, {...bond, tradingPriceContractDate: date})
              }}
            />
            <span className="ml_4">입니다.</span>
          </div>
          
          <hr />

          <p className="ttl">2) 임대하기로 한 부동산이 무엇인가요? (부동산등기부를 확인하세요)</p>
          <div className="answer-area">
            <div className="answer-area__ttl flex-row land">
              <span className="mr_10">토지</span>
              <Button className="add-btn btn-24" onClick={e => addLand(index)}><AddIcon /></Button>
            </div>
            {bond.landList.map((land, lIndex) => (
              <div className="answer-area__land flex-row" key={`land${index}${lIndex}`}>
                <AddressPicker
                  address={land.address}
                  onSelectAddress={address => {
                    bond.landList[lIndex].address = address.address;
                    changeBond(index, bond)
                  }}
                />
                <FormControl variant="outlined" className="select">
              <Select
                value={land.category} 
                onChange={e => {
                  bond.landList[lIndex].category = e.target.value;
                  changeBond(index, bond)
                }}
              >
                <MenuItem value=''>선택</MenuItem>
                {CATEGORY.map((category, index) => (
                  <MenuItem value={category} key={`category${index}`}>{category}</MenuItem>
                ))}
              </Select>
                </FormControl>
                <TextField
                  variant="outlined"
                  value={land.area}
                  onChange={e => {
                    bond.landList[lIndex].area = e.target.value;
                    changeBond(index, bond)
                  }}
                  placeholder="면적"
                  className="mr_10"
                />
                <span className="mr_10 ml_4">㎡</span>
                <Button className="remove-btn btn-24" onClick={e => removeLand(index, lIndex)}><RemoveIcon /></Button>
              </div>
            ))}

            <div className="answer-area__ttl flex-row building">
              <span className="mr_10">건물</span>
              <Button className="add-btn btn-24" onClick={e => addBuilding(index)}><AddIcon /></Button>
            </div>
            {bond.buildingList.map((building, bIndex) => (
              <div className="answer-area__building flex-row" key={`building${index}${bIndex}`}>
                <AddressPicker
                  address={building.address}
                  onSelectAddress={address => {
                    bond.buildingList[bIndex].address = address.address;
                    changeBond(index, bond)
                  }}
                />
                <TextField
                  variant="outlined"
                  value={building.detail}
                  onChange={e => {
                    bond.buildingList[bIndex].detail = e.target.value;
                    changeBond(index, bond);
                  }}
                  placeholder="상세주소"
                  className="mr_10"
                />
                <Button className="remove-btn btn-24" onClick={e => removeBuilding(index, bIndex)}><RemoveIcon /></Button>
              </div>
            ))}
          </div>

          <hr />

          <p className="ttl">3) 임대차계약 체결 이후 부동산 소유자가 바뀌었나요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={!bond.isChangedMaster}
                onChange={e => changeBond(index, {...bond, isChangedMaster: false})}
              />
              <span>바뀌지 않았습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.isChangedMaster}
                onChange={e => changeBond(index, {...bond, isChangedMaster: true})}
              />
              <SJDatePicker
                date={bond.changedMasterDate}
                onChangeDate={date => changeBond(index, {...bond, changedMasterDate: date})}/>
              <span>에 바뀌었으며, 임대차계약 체결 당시 임대인은</span>
              <TextField
                variant="outlined"
                value={bond.changedMasterName}
                onChange={e => changeBond(index, {...bond, changedMasterName: e.target.value})}
                className="w100 mt_4"
              />
              <span>입니다.</span>
            </label>
          </div>
          
          <hr />
          
          <p className="ttl">4) 임대차보증금이 얼마인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.depositPriceType === 'fix'}
                onChange={e => changeBond(index, {...bond, depositPriceType: 'fix'})}
              />
              <TextField
                variant="outlined"
                value={bond.depositPrice === 0 ? '' : bond.depositPrice}
                onChange={e => changeBond(index, {...bond, depositPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.depositPriceType === 'increase'}
                onChange={e => changeBond(index, {...bond, depositPriceType: 'increase'})}
              />
              <span className="mr_10">처음 임대차계약 체결시</span>
              <TextField
                variant="outlined"
                value={bond.prevDepositPrice === 0 ? '' : bond.prevDepositPrice}
                onChange={e => changeBond(index, {...bond, prevDepositPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원이었으나, 증액되어 현재</span>
              <TextField
                variant="outlined"
                value={bond.nextDepositPrice === 0 ? '' : bond.nextDepositPrice}
                onChange={e => changeBond(index, {...bond, nextDepositPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
          </div>
          
          <hr />
          
          <p className="ttl">5) 임대차보증금을 모두 지급한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker
              date={bond.didDepositDate}
              onChangeDate={date => changeBond(index, {...bond, didDepositDate: date})}/>
            <span>입니다.</span>
          </div>
          
          <hr />

          <p className="ttl">6) 임대료가 얼마인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.rentType === 'month'}
                onChange={e => changeBond(index, {...bond, rentType: 'month'})}
              />
              <span className="mr_10">월</span>
              <TextField
                variant="outlined"
                value={bond.monthRentPrice === 0 ? '' : bond.monthRentPrice}
                onChange={e => changeBond(index, {...bond, monthRentPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.rentType === 'year'}
                onChange={e => changeBond(index, {...bond, rentType: 'year'})}
              />
              <span className="mr_10">연</span>
              <TextField
                variant="outlined"
                value={bond.yearRentPrice === 0 ? '' : bond.yearRentPrice}
                onChange={e => changeBond(index, {...bond, yearRentPrice: parseInt(e.target.value) ? parseInt(e.target.value) : 0})}
                placeholder="1,000,000"
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.rentType === 'none'}
                onChange={e => changeBond(index, {...bond, rentType: 'none'})}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">7) 계약기간이 언제인가요?</p>
          <div className="answer-area answer7">
            <label className="radio-item">
              <Radio
                checked={bond.contractDateType === 'fix'}
                onChange={e => changeBond(index, {...bond, contractDateType: 'fix'})}
              />
              <SJDatePicker
                date={bond.startContractDate}
                onChangeDate={date => changeBond(index, {...bond, startContractDate: date})}
              />
              <span className="mr_10 ml_4">부터</span>
              <SJDatePicker
                date={bond.endContractDate}
                onChangeDate={date => changeBond(index, {...bond, endContractDate: date})}
              />
              <span className="ml_4">까지입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.contractDateType === 'delay'}
                onChange={e => changeBond(index, {...bond, contractDateType: 'delay'})}
              />
              <span className="mr_10">처음 계약기간은</span>
              <SJDatePicker
                date={bond.delayStartContractDate}
                onChangeDate={date => changeBond(index, {...bond, delayStartContractDate: date})}
              />
              <span className="mr_10 ml_4">부터</span>
              <SJDatePicker
                date={bond.delayEndContractDate}
                onChangeDate={date => changeBond(index, {...bond, delayEndContractDate: date})}
              />
              <span className="ml_4">까지이</span>
              <span className="mr_10">나, 마지막으로</span>
              <SJDatePicker
                date={bond.delayFinalContractDate}
                onChangeDate={date => changeBond(index, {...bond, delayFinalContractDate: date})}
              />
              <span className="ml_4">까지 연장되었습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.contractDateType === 'none'}
                onChange={e => changeBond(index, {...bond, contractDateType: 'none', contractExitReason: 'midway'})}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">8) 임대차계약 종료사유가 무엇인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                disabled={bond.contractDateType === 'none'}
                checked={bond.contractExitReason === 'expiration'}
                onChange={e => changeBond(index, {...bond, contractExitReason: 'expiration'})}
              />
              <span>계약기간 만료입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.contractExitReason === 'midway'}
                onChange={e => changeBond(index, {...bond, contractExitReason: 'midway'})}
              />
              <span className="mr_10">계약해지이고,</span>
              <SJDatePicker
                date={bond.cancelContractDate}
                onChangeDate={date => changeBond(index, {...bond, cancelContractDate: date})}
              />
              <span>에 해지되었습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">9) 임대한 부동산을 언제 인도하였나요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.didDeliveryDateType === 'all'}
                onChange={e => changeBond(index, {...bond, didDeliveryDateType: 'all'})}
              />
              <SJDatePicker
                date={bond.didDeliveryDate}
                onChangeDate={date => changeBond(index, {...bond, didDeliveryDate: date})}
              />
              <span>에 인도하였습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.didDeliveryDateType === 'none'}
                onChange={e => changeBond(index, {...bond, didDeliveryDateType: 'none'})}
              />
              <span>아직 인도하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">10) 임대차보증금 중 공제할 금액이 있나요? <span>(복수선택 가능)</span></p>
          <div className="answer-area">
            <label className="checkbox-item">
              <Checkbox
                checked={bond.isDeduction}
                onChange={e => changeIsDeduction(e, index)}
              />
              <span>임대차보증금 전액을 받아야 합니다.</span>
            </label>
            <label className="checkbox-item">
              <Checkbox
                checked={bond.isDeductionDeposit}
                onChange={e => changeIsDeductionDeposit(e, index)}
              />
              <span className="mr_10">임대차보증금 중</span>
              <TextField
                variant="outlined"
                type="number"
                value={bond.deductionDeposit === 0 ? '': bond.deductionDeposit} 
                onChange={e => changeDeductionDeposit(e, index)}
                className="input-price"
              />
              <span>원을 반환받았습니다.</span>
            </label>
            <label className="checkbox-item">
              <Checkbox
                checked={bond.isDeductionRent} 
                onChange={e => changeIsDeductionRent(e, index)}
              />
              <span className="mr_10">밀린 임대료</span>
              <TextField
                variant="outlined"
                type="number" 
                value={bond.deductionRent === 0 ? '' : bond.deductionRent} 
                onChange={e => changeDeductionRent(e, index)}
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
            <label className="checkbox-item">
              <Checkbox
                checked={bond.isDeductionFix} 
                onChange={e => changeIsDeductionFix(e, index)}
              />
              <span className="mr_10">수리비</span>
              <TextField
                variant="outlined"
                type="number" 
                value={bond.deductionFix === 0 ? '' : bond.deductionFix} 
                onChange={e => changeDeductionFix(e, index)}
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
            <label className="checkbox-item">
              <Checkbox
                checked={bond.isDeductionEtc} 
                onChange={e => changeIsDeductionEtc(e, index)}
              />
              <TextField
                variant="outlined"
                value={bond.deductionEtcReason} 
                onChange={e => changeDeductionEtcReason(e, index)}
                placeholder="기타"
                className="w200"
              />
              <span className="mr_10">사유로</span>
              <TextField
                variant="outlined"
                type="number" 
                value={bond.deductionEtc === 0 ? '' : bond.deductionEtc} 
                onChange={e => changeDeductionEtc(e, index)}
                className="input-price"
              />
              <span>원입니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">11) 임대차 목적이 무엇인가요? <span>(상사 이율)</span></p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.depositPurpose === 'home'}
                onChange={e => changeBond(index, {...bond, depositPurpose: 'home'})}
              />
              <span>주거 목적입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.depositPurpose === 'store'}
                onChange={e => changeBond(index, {...bond, depositPurpose: 'store'})}
              />
              <span>상가 목적입니다.</span>
            </label>
          </div>
        </div>
      ))}

      {/* <Button className="contained" onClick={}>채권 추가</Button> */}
      





      {/* {complaint.bondList.map((bond, index) => (
        <div>
          <div>
            <input placeholder='임대차계약 체결일' value={bond.tradingPriceContractDate} onChange={e => changeContractDate(e, index)} />
            <input placeholder='보증금을 모두 지급한 날짜' value={bond.didDepositDate} onChange={e => changeDidDepositDate(e, index)}/>
          </div>
          토지<button onClick={e => addLand(index)}>추가</button>
          {bond.landList.map((land, realtyIndex) => (
            <div key={`land${index}${realtyIndex}`}>
              <input type="text" className="input-form" placeholder="주소" value={land.address} onChange={e => changeLandAddress(e, index, realtyIndex)} />
              <select onChange={e => changeLandCategory(e, index, realtyIndex)}>
              <option value=''>선택</option>
                {CATEGORY.map((category, index) => (
                  <option value={category} key={`category${index}`}>{category}</option>
                ))}
              </select>
              <input type="text" className="input-form" placeholder="면적" value={land.area} onChange={e => changeLandArea(e, index, realtyIndex)}/>
              <button className="pm-btn" onClick={e => removeLand(index, realtyIndex)}>삭제</button>
            </div>
          ))}
          건물<button onClick={e => addBuilding(index)}>추가</button>
          {bond.buildingList.map((building, buildingIndex) => (
            <div key={`building${index}${buildingIndex}`}>
              <input type="text" className="input-form" placeholder="주소" value={building.address} onChange={e => changeBuildingAddress(e, index, buildingIndex)} />
              <input type="text" className="input-form" placeholder="상세주소" value={building.detail} onChange={e => changeBuildingDetail(e, index, buildingIndex)}/>
              <button className="pm-btn" onClick={e => removeBuilding(index, buildingIndex)}>삭제</button>
            </div>
          ))}
          <div>
            <p>============================</p>
            임대차계약 체결 이후 부동산 소유자가 바뀌었나요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`changedMaster${index}`} value={false} checked={!bond.isChangedMaster} onChange={e => changeMaster(e, index)}/>
                <span>바뀌지 않았습니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`changedMaster${index}`} value={true} checked={bond.isChangedMaster} onChange={e => changeMaster(e, index)}/>
                <input value={bond.changedMasterDate} onChange={e => changeMasterDate(e, index)}/>
                <span>에 바뀌였으며, 임대차계약 체결 당시 임대인은</span>
                <input value={bond.changedMasterName} onChange={e => changeMasterName(e, index)}/>
                <span>입니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대차보증금이 얼마인가요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`depostiPrice${index}`} value='fix' checked={bond.depositPriceType === 'fix'} onChange={e => changeDepositPriceType(e, index)}/>
                <input type="number" value={bond.depositPrice === 0 ? '' : bond.depositPrice} onChange={e => changeDepositPrice(e, index)}/>
                <span>원입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`depostiPrice${index}`} value='increase' checked={bond.depositPriceType === 'increase'} onChange={e => changeDepositPriceType(e, index)}/>
                <span>처음 임대차계약 체결시</span>
                <input type="number" value={bond.prevDepositPrice === 0 ? '' : bond.prevDepositPrice} onChange={e => changePrevDepositPrice(e, index)}/>
                <span>원이었으나, 증액되어 현재</span>
                <input type="number" value={bond.nextDepositPrice === 0 ? '' : bond.nextDepositPrice} onChange={e => changeNextDepositPrice(e, index)}/>
                <span>원입니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대료가 얼마인가요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`rentType${index}`} value={'month'} checked={bond.rentType === 'month'} onChange={e => changeRentType(e, index)}/>
                <span>월</span>
                <input type="number" value={bond.monthRentPrice === 0 ? '' : bond.monthRentPrice} onChange={e => changeMonthRentPrice(e, index)}/>
                <span>원입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`rentType${index}`} value={'year'} checked={bond.rentType === 'year'} onChange={e => changeRentType(e, index)}/>
                <span>연</span>
                <input type="number" value={bond.yearRentPrice === 0 ? '' : bond.yearRentPrice} onChange={e => changeYearRentPrice(e, index)}/>
                <span>원입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`rentType${index}`} value={'none'} checked={bond.rentType === 'none'} onChange={e => changeRentType(e, index)}/>
                <span>정하지 않았습니다.</span>
              </label>
            </div>
            <p>============================</p>
            계약기간이 언제인가요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`contractDate${index}`} value='fix' checked={bond.contractDateType === 'fix'} onChange={e => changeContractDateType(e, index)}/>
                <input value={bond.startContractDate} onChange={e => changeStartContractDate(e, index)}/>
                <span>부터</span>
                <input value={bond.endContractDate} onChange={e => changeEndContractDate(e, index)}/>
                <span>까지입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`contractDate${index}`} value='delay' checked={bond.contractDateType === 'delay'} onChange={e => changeContractDateType(e, index)}/>
                <span>처음 계약기간은</span>
                <input value={bond.delayStartContractDate} onChange={e => changeDelayStartContractDate(e, index)}/>
                <span>부터</span>
                <input value={bond.delayEndContractDate} onChange={e => changeDelayEndContractDate(e, index)}/>
                <span>까지이나 마지막으로</span>
                <input value={bond.delayFinalContractDate} onChange={e => changeDelayFinalContractDate(e, index)}/>
                <span>까지 연장되었습니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`contractDate${index}`} value='none' checked={bond.contractDateType === 'none'} onChange={e => changeContractDateType(e, index)}/>
                <span>정하지 않았습니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대차계약 종료사유가 무엇인가요?
            <div>
              <label>
                <input type="radio" className="ty-radio" disabled={bond.contractDateType === 'none'} name={`contractExitReason${index}`} value='expiration' checked={bond.contractExitReason === 'expiration'} onChange={e => changeContractExitReason(e, index)}/>
                <span>계약기간 만료입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`contractExitReason${index}`} value='midway' checked={bond.contractExitReason === 'midway'} onChange={e => changeContractExitReason(e, index)}/>
                <span>계약해지이고,</span>
                <input value={bond.cancelContractDate} onChange={e => changeCancelContractDate(e, index)}/>
                <span>에 해지되었습니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대한 부동산을 언제 인도하였나요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`didDelivery${index}`} value='all' checked={bond.didDeliveryDateType === 'all'} onChange={e => changeDidDeliveryDateType(e, index)}/>
                <input value={bond.didDeliveryDate} onChange={e => changeDidDeliveryDate(e, index)}/>
                <span>에 인도하였습니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`didDelivery${index}`} value='none' checked={bond.didDeliveryDateType === 'none'} onChange={e => changeDidDeliveryDateType(e, index)}/>
                <span>아직 인도하지 않았습니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대차보증금 중 공제할 금액이 있나요?
            <div>
              <label>
                <input type="checkbox" className="ty-radio" name={`deduction${index}`} checked={bond.isDeduction} onChange={e => changeIsDeduction(e, index)}/>
                <span>임대차보증금 전액을 받아야 합니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" className="ty-radio" name={`deduction${index}`} checked={bond.isDeductionDeposit} onChange={e => changeIsDeductionDeposit(e, index)}/>
                <span>임대차보증금 중</span>
                <input type="number" value={bond.deductionDeposit === 0 ? '': bond.deductionDeposit} onChange={e => changeDeductionDeposit(e, index)}/>
                <span>원을 반환받았습니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" className="ty-radio" name={`deduction${index}`} checked={bond.isDeductionRent} onChange={e => changeIsDeductionRent(e, index)}/>
                <span>밀린 임대료</span>
                <input type="number" value={bond.deductionRent === 0 ? '' : bond.deductionRent} onChange={e => changeDeductionRent(e, index)}/>
                <span>원 입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" className="ty-radio" name={`deduction${index}`} checked={bond.isDeductionFix} onChange={e => changeIsDeductionFix(e, index)}/>
                <span>수리비</span>
                <input type="number" value={bond.deductionFix === 0 ? '' : bond.deductionFix} onChange={e => changeDeductionFix(e, index)}/>
                <span>원 입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="checkbox" className="ty-radio" name={`deduction${index}`} checked={bond.isDeductionEtc} onChange={e => changeIsDeductionEtc(e, index)}/>
                <input value={bond.deductionEtcReason} onChange={e => changeDeductionEtcReason(e, index)}/>
                <span>사유로</span>
                <input type="number" value={bond.deductionEtc === 0 ? '' : bond.deductionEtc} onChange={e => changeDeductionEtc(e, index)}/>
                <span>원 입니다.</span>
              </label>
            </div>
            <p>============================</p>
            임대차 목적이 무엇인가요?
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`depositPurpose${index}`} value='home' checked={bond.depositPurpose === 'home'} onChange={e => changeDepositPurpose(e, index)}/>
                <span>주거 목적입니다.</span>
              </label>
            </div>
            <div>
              <label>
                <input type="radio" className="ty-radio" name={`depositPurpose${index}`} value='store' checked={bond.depositPurpose === 'store'} onChange={e => changeDepositPurpose(e, index)}/>
                <span>상가 목적입니다.</span>
              </label>
            </div>
          </div>
        </div>
      ))} */}
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deposit)