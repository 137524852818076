

import moment from 'moment';

import { addComma, nextDate, diffDate, getName, getNameWithJosa } from './utils'

import _ from 'lodash';

const numberType = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'];
const dateFormat =  "YYYY. M. D.";


function calcPrice(price, rate, startDate, endDate) {
  // const countInfo = dateDiff('지연손해금', new Date(startDate), new Date(endDate));

  // let price = 0;

  // price += balance * (parseFloat(rate) * 0.01) * countInfo[0];
    
  // if(countInfo[2]) { //잔여일에 윤달이 포함된 경우
  //   price += balance * (parseFloat(rate) * 0.01) * (parseFloat(countInfo[1]) / 366.0);
  // }
  // else {
  //   price += balance * (parseFloat(rate) * 0.01) * (parseFloat(countInfo[1]) / 365.0);
  // }

  // return price;

  const dateInfo = diffDate(moment(startDate), moment(endDate));

  let delayPrice = 0;

  delayPrice = price * dateInfo.yearCount * +(rate * 0.01).toFixed(5);

  delayPrice += price * (dateInfo.dateCount / (dateInfo.isYoondal ? 366 : 365)) * +(rate * 0.01).toFixed(5);

  // return {
  //   ...dateInfo,
  //   delayPrice: Math.floor(delayPrice)
  // }

  return Math.floor(delayPrice);
}

const calcDeferInterestPrice = (B, C, D, E, F, balance, deferInterestRate, isLast, N, L, didPaybackDate, willDeliveryDate, didDeliveryDate) => {

  console.log(B, C, D, E, F, balance, deferInterestRate, isLast, N, L, didPaybackDate, willDeliveryDate, didDeliveryDate);

  let deferInterestPrice = 0;

  if(!B && !C && !D && !E && !F) { //1
    
  }
  else if(!B && !C && !D && !E && F) { //2
    
  }
  else if(!B && !C && !D && E && !F) { //3
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && !D && E && F) { //4
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && !E && !F) { //5
    
  }
  else if(!B && !C && D && !E && F) { //6
    
  }
  else if(!B && !C && D && E && !F) { //7
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && E && F) { //8
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && C && !D && !E && !F) { //9
    
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < didDeliveryDate) {
      if(didDeliveryDate < didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365')

        //U열 : 인도일
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      console.log('원금*(P-U열+1)*지손금율/365')

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
      
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && !E && !F) { //13
    
  }
  else if(!B && C && D && !E && F) { //14
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && E && F) { //16
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      /**
       * N열: 변제기 다음날
       * P열: 잔금 변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {

    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) && nextDate(N) < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < didDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
        else {
          if(nextDate(N) <= didPaybackDate) {
            console.log('원금*(P-N열+1)*지손금율/365');

            deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
          }
        }
      }
      else {
        /**
         * N열: 
         */
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열:
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < L) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(L <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //58행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      /**
       * N열: 
       * P열: 잔금변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {
      
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) &&
      (nextDate(N) < willDeliveryDate)) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //77행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  
  return parseInt(deferInterestPrice);
}

const checkBeforeAfterAffectPrincipal = (B, C, D, E, F, bond, lastDidPaybackDate) => {

  const willDeliveryDate = bond.willDeliveryDate;
  const didDeliveryDate = bond.didDeliveryDate;

  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format('YYYY-MM-DD');
  const lastWillPaybackDate = bond.lastWillPaybackDate;

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  console.log(nextWillPaybackDate);

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, P = ${lastDidPaybackDate}, LastN = ${bond.lastN}`);

  let status = 'before';

  if(!B && !C && !D && E && !F) { //3
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && !C && !D && E && F) { //4
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && !C && D && E && !F) { //7
    
  }
  else if(!B && !C && D && E && F) { //8
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && D && E && F) { //16
    console.log('16')
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      console.log(nextWillPaybackDate, lastDidPaybackDate);
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      console.log(nextWillPaybackDate, lastDidPaybackDate);
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if(L < didDeliveryDate) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //58행
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        if(lastWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //77행
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        if(lastWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if(L < willDeliveryDate) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  
  return status;
}

const getClaimPurpose = (B, C, D, E, F, bond, isAffectPrincipal) => { //기본형

  if(bond.balance === 0) return '';

  console.log(B, C, D, E, F);

  let claimPurpose = '';

  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  const willDeliveryTitle = bond.serviceType === 'delivery' ? '인도약정일' : '완료예정일';
  const didDeliveryTitle = bond.serviceType === 'delivery' ? '인도일' : '용역완료일';

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  if(!B && !C && !D && E && !F) { //3
    claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
    else {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && D && E && F) { //16
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
    else {
      claimPurpose = `용역대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if(L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(((bond.willDeliveryDate < N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate)) || 
      ((bond.willDeliveryDate === N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate))) { //58행
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((bond.willDeliveryDate < N && N < L && L === bond.didDeliveryDate) ||
      (bond.willDeliveryDate === N && N < bond.didDeliveryDate && bond.didDeliveryDate === L)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((bond.willDeliveryDate <= N && N < L && L < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate <= L && L < bond.didDeliveryDate)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(((bond.willDeliveryDate < N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate)) || 
      ((bond.willDeliveryDate === N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate))) { //77행
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((bond.willDeliveryDate < N && N < L && L === bond.didDeliveryDate) ||
      (bond.willDeliveryDate === N && N < bond.didDeliveryDate && bond.didDeliveryDate === L)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((bond.willDeliveryDate <= N && N < L && L < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate <= L && L < bond.didDeliveryDate)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }

  if(isAffectPrincipal) claimPurpose = `나머지 ${claimPurpose}`

  return claimPurpose;
}

const getClaimPurpose1 = (B, C, D, E, F, bond, P) => { //지손금 영향

  if(bond.balance === 0) return '';

  console.log(B, C, D, E, F);

  let claimPurpose = '';

  const tradingPrice = addComma(bond.price);
  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  const nextLastDidPaybackDate = moment(P).add(1, 'd').format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  const willDeliveryTitle = bond.serviceType === 'delivery' ? '인도약정일' : '완료예정일';
  const didDeliveryTitle = bond.serviceType === 'delivery' ? '인도일' : '용역완료일';

  if(isLast) N = bond.lastN;

  console.log(`지손금 영향, N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}의`;
  if(!B && !C && !D && E && !F) { //3
    claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else  if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && E && F) { //16
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `용역대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        } 
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        } 
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }

  return claimPurpose;
}

const getClaimPurpose3 = (B, C, D, E, F, bond, P) => { //원금영향+후

  if(bond.balance === 0) return '';

  console.log('원금영향+후', B, C, D, E, F);

  let claimPurpose = '';

  const tradingPrice = addComma(bond.price);
  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  const nextLastDidPaybackDate = moment(P).add(1, 'd').format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  const willDeliveryTitle = bond.serviceType === 'delivery' ? '인도약정일' : '완료예정일';
  const didDeliveryTitle = bond.serviceType === 'delivery' ? '인도일' : '용역완료일';

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, P = ${P}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}의`;
  if(!B && !C && !D && E && !F) { //3
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && E && !F) { //11
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && E && F) { //12
    if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && E && !F) { //15
    claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && D && E && F) { //16
    if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 용역대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if((N < L && L < bond.didDeliveryDate) && (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((N < L && L < bond.didDeliveryDate) && (L <= P && P < bond.didDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) { //54
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) { //55
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XLawText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) { //54
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) { //55
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 ${willDeliveryTitle}인 ${willDeliveryDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if((N < L && L < bond.didDeliveryDate) && (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((N < L && L < bond.didDeliveryDate) && (L <= P && P < bond.didDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 ${didDeliveryTitle}인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }

  return claimPurpose;
}

const getServicePriceText = (complaint, defendantList, textList) => {

  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const documentType = complaint.documentType;
  const virtualBondList = complaint.virtualBondList;
  
  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';

  let didPaybackDateCount = 0;
  didPaybackDateCount += complaint.didPaybackDateList.filter(d => d.isChecked).length;
  didPaybackDateCount += complaint.bondList.map(b => b.didPaybackDateList.filter(d => d.isChecked).length).reduce((a, c) => a + c, 0);

  let totalPaybackPrice = complaint.didPaybackDateList.map(p => p.price).reduce((a, c) => a + c, 0);
  const latestPaybackDateArray = complaint.didPaybackDateList.filter(d => d.isChecked).map(p => p).sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0).map(p => p.date).slice(0, 1);
  let latestPaybackDate = latestPaybackDateArray.length !== 0 ? latestPaybackDateArray[0] : '' //최종변제일
  let nextLatestPaybackDate = latestPaybackDate !== '' ? moment(latestPaybackDate).add(1, 'd').format('YYYY-MM-DD') : ''; //최종변제일 다음날
 
  console.log(`총 변제 매매대금 = ${totalPaybackPrice}, 최종변제일 = ${latestPaybackDate}, 최종변제일 다음날 = ${nextLatestPaybackDate}`)

  let contents = '';
  //청구원인 (처음)
  complaint.bondList.forEach((bond, i) => {

    var mainDefendant = '원고는 ';
    var causeOfAction = '원고는 ';

    
    causeOfAction += `${moment(bond.tradingPriceContractDate).format(dateFormat)} `;
    causeOfAction += `${defendantList.length === 1 ? '피고와 ' : `피고 ${getName(defendantList[0])}과 `}`

    causeOfAction += `용역대금 ${addComma(bond.tradingPrice)}원`;

    const isWillDeliveryDate = bond.willDeliveryDateType !== 'none';
    const isDeferInterestRate = bond.isDeferInterestRate;
    const isWillPaybackDate = bond.willPaybackDateType !== 'none';

    const serviceType = bond.serviceType === 'delivery' ? '인도약정일' : '완료예정일';

    const chooseList = [];
    const notChooseList = [];
    
    isWillDeliveryDate ? chooseList.push(`${serviceType} ${moment(bond.willDeliveryDate).format(dateFormat)}로 정하고`) : notChooseList.push(serviceType);
    if(isWillPaybackDate) {
      if(bond.willPaybackDateType === 'fix') {
        chooseList.push(`변제기 ${moment(bond.willPaybackDate).format(dateFormat)}로 정하고`)
      }
      else {
        chooseList.splice(0, 0, ` 중 ${bond.willPaybackDateList.map((d, index) => `${d.unit} ${addComma(d.price)}원은 ${moment(d.date).format(dateFormat)}에`).join(', ')} 각 지급하기로 정하고`);
        //chooseList.push(` 중 ${bond.willPaybackDateList.map((d, index) => `${d.unit} ${addComma(d.price)}원은 ${moment(d.date).format(dateFormat)}에`).join(', ')} 각 지급하기로 정하고`)
      }
      
    }
    else {
      notChooseList.push('변제기');
    }
    
    isDeferInterestRate ? chooseList.push(`지연손해금율 연 ${bond.deferInterestRate}%로 정하${chooseList.length === 2 ? '여' : '고'}`) : notChooseList.push('지연손해금율');
    
    if(chooseList.length !== 0) {
      if(bond.willPaybackDateType === 'division') {
        causeOfAction += ` ${chooseList.join(', ')}`;
      }
      else {
        if(isWillDeliveryDate) {
          causeOfAction += `으로, ${chooseList.join(', ')}`;
        }
        else {
          causeOfAction += `으로 정하고, ${chooseList.join(', ')}`;
        }
      }
      
      
    }

    if(notChooseList.length !== 0) {
      if(chooseList.length !== 0) {
        causeOfAction += `, ${notChooseList.join(', ')}의 정함이 없이 `;
      }
      else {
        causeOfAction += ` 정하고, ${notChooseList.join(', ')}의 정함이 없이 `;
      }
    }

    causeOfAction += `${bond.serviceTitle} 계약을 체결하였습니다.`;

    //console.log(causeOfAction);

    if(complaint.documentType === '지급명령') {
      causeOfAction = causeOfAction.replace('원고', '채권자').replace('피고', '채무자');
    }

    claimCauseList.push(causeOfAction);
    textList.push(causeOfAction);
  });

  //연대보증인 문장
  contents = '';
  complaint.bondList.forEach((bond, index) => {
    bond.jointGuarantorList.forEach(jointGuarantor => {
      if(contents !== '') contents += '하였고, ';

      contents += `피고 ${getNameWithJosa(jointGuarantor.defendant, '은/는')} 위 ${index + 1}.항의 용역대금 반환채무를 전액 연대보증`;

    })
  });
  if(contents !== '') contents += '하였습니다.';
  //console.log(contents);
  if(contents !== '') {
    claimCauseList.push(contents);
    textList.push(contents);
  }

  //물건 인도 문장

  complaint.bondList.forEach((bond, index) => bond.index = index);
  contents = '';
  const deliveryCount =complaint.bondList.filter(b => b.didDeliveryDateType === 'all').length;

  if(deliveryCount === 1) {
    const bond = complaint.bondList.filter(b => b.didDeliveryDateType === 'all')[0];
    if(bond.serviceType === 'delivery') {
      contents += `${moment(bond.didDeliveryDate).format(dateFormat)} ${defendantList.length === 1 ? '피고' : `피고 ${getName(defendantList[0])}`}에게 위 ${bond.index + 1}.항의 목적물을 인도하였`;
    }
    else if(bond.serviceType === 'complete') {
      contents += `${moment(bond.didDeliveryDate).format(dateFormat)} 위 ${bond.index + 1}.항의 일을 완료하였`;
    }
  }
  else if(deliveryCount > 1) {
    complaint.bondList.filter(b => b.didDeliveryDateType === 'all').forEach(bond => {
    
      if(contents !== '') contents += '고, ';
  
      if(bond.serviceType === 'delivery') {
        contents += `${moment(bond.didDeliveryDate).format(dateFormat)}  위 ${bond.index + 1}.항의 목적물을 인도하였`;
      }
      else if(bond.serviceType === 'complete') {
        contents += `${moment(bond.didDeliveryDate).format(dateFormat)} 위 ${bond.index + 1}.항의 일을 완료하였`;
      }
    })
  }
  

  if(contents !== '') {
    if(complaint.bondList.filter(b => b.didDeliveryDateType === 'all').length === 1) {
      if(contents.includes('목적물')) {
        contents = `원고는 ${contents}습니다.`;
      }
      else {
        contents = `원고는 ${contents}습니다.`;
      }
    }
    else if(complaint.bondList.filter(b => b.didDeliveryDateType === 'all').length >= 2) {
      if(contents.includes('목적물')) {
        contents = `원고는 ${defendantList.length === 1 ? '피고' : `피고 ${getName(defendantList[0])}`}에게 ${contents}습니다.`;
      }
      else {
        contents = `원고는 ${contents}습니다.`;
      }
    }
    //contents = `원고는 ${`${defendantList.length === 1 ? '피고에게 ' : `피고 ${defendantList[0].typeIndex !== 0 ? defendantList[0].company : defendantList[0].name}에게 `}`} 목적물을 인도하였습니다.`;
    if(complaint.documentType === '지급명령') {
      contents = contents.replace('원고', '채권자').replace('피고', '채무자');
    }

    claimCauseList.push(contents);
    textList.push(contents);
  }
  
  //일부변제 문장
  contents = '';
  if(defendantList.length === 1) {
    complaint.bondList.forEach((bond, index) => {
      const temp = bond.didPaybackDateList.filter(d => d.isChecked).map(d => d.unit).join(', ');
      
      if(bond.didPaybackDateList.filter(d => d.isChecked).map(d => d.unit).join(', ') !== '') {
        if(contents !== '') {
          contents += `하였고, 위 ${index + 1}.항의 ${temp}을 지급`;
        }
        else {
          contents += `위 ${index + 1}.항의 ${temp}을 지급`;
        }
      }
    });

    
    complaint.didPaybackDateList.filter(d => d.isChecked).forEach(didPaybackDate => {
      if(contents !== '') {
        contents += `하였고, ${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
      }
      else {
        contents += `${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
      }
    });
  
    if(contents !== '') contents = `피고는 ${contents}하였습니다.`;
  }
  else { //피고가 2명 이상
    defendantList.forEach(defendant => {
      let a = '';
      complaint.bondList.forEach((bond, index) => {
        const temp = bond.didPaybackDateList.filter(d => d.isChecked && d.defendant.seq === defendant.seq).map(d => d.unit).join(', ');
        if(temp !== '') {
          if(a !== '') {
            a += `하였고, 위 ${index + 1}.항의 ${temp}을 지급`;
          }
          else {
            a += `위 ${index + 1}.항의 ${temp}을 지급`;
          }
        }
      });

      complaint.didPaybackDateList.filter(d => d.isChecked && d.defendant.seq === defendant.seq).forEach(didPaybackDate => {
        
        if(a !== '') {
          a += `하였고, ${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
        }
        else {
          a += `${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
        }
      });
      if(a !== '') {
        if(contents !== '') {
          contents += `하였고, 피고 ${getNameWithJosa(defendant, '은/는')} ${a}`;
        }
        else {
          contents += `피고 ${getNameWithJosa(defendant, '은/는')} ${a}`;
        }
      }
    });

    if(contents !== '') {
      if(complaint.documentType === '지급명령') {
        contents = contents.replace('원고', '채권자').replace('피고', '채무자');
      }
      contents = `한편, ${contents}`;
      contents += '하였습니다.';
    }
  }
  //console.log('일부변제문장', contents);

  if(contents !== '') {
    claimCauseList.push(contents);
    textList.push(contents);
  }

  contents = '';
  if(defendantList.length === 1) {
    contents = `그러나 피고는 현재까지 용역대금을 지급하지 아니하고 있습니다.`;
  }
  else {
    if(complaint.bondList.map(b => b.jointGuarantorList.length).reduce((a, c) => a + c, 0) !== 0) {
      contents = `그러나 피고들은 현재까지 용역대금을 지급하지 아니하고 있습니다.`;
    }
    else {
      contents = `그러나 피고 ${getNameWithJosa(defendantList[0], '은/는')} 현재까지 용역대금을 지급하지 아니하고 있습니다.`;
    } 
  }
  
  if(didPaybackDateCount !== 0) contents = contents.replace('용역대금', '나머지 용역대금');

  if(complaint.documentType === '지급명령') {
    contents = contents.replace('원고', '채권자').replace('피고', '채무자');
  }
  
  claimCauseList.push(contents);
  textList.push(contents);

  if(complaint.isShowComment && complaint.comment !== '') {
    claimCauseList.push(complaint.comment);
  }

  const bondCount = virtualBondList.filter(b => b.balance !== 0).length;

  let singleClaimPurposeList = [];
  let multipleClaimPurposeList = [];
  //결구
  contents = '';
  virtualBondList.filter(b => b.balance !== 0).forEach((bond, index) => {

    console.log('최종 원리금', bond.balance)

    if(contents !== '') contents += '하여야 하고, ';
    contents += bondCount >= 2 ? `${numberType[index]} ` : '';

    let claimPurpose = `${bond.pigo}원고에게 `;

    if(!bond.isPayment) { //일부변제가 없는 경우 (기본형)
      claimPurpose += getClaimPurpose(
        bond.tradingPricePaybackIndex !== -1, 
        bond.willPaybackDate !== '',
        bond.isDeferInterestRate,
        bond.didDeliveryDate !== '',
        bond.willDeliveryDate !== '', 
        bond,
        false
      )
    }
    else {
      console.log(bond.price, bond.balance);
      if(bond.price <= bond.balance) { //지손금 영향
        claimPurpose += getClaimPurpose1(
          bond.tradingPricePaybackIndex !== -1, 
          bond.willPaybackDate !== '',
          bond.isDeferInterestRate,
          bond.didDeliveryDate !== '',
          bond.willDeliveryDate !== '', 
          bond,
          latestPaybackDate
        )
      }
      else {
        const status = checkBeforeAfterAffectPrincipal(
          bond.tradingPricePaybackIndex !== -1, 
          bond.willPaybackDate !== '',
          bond.isDeferInterestRate,
          bond.didDeliveryDate !== '',
          bond.willDeliveryDate !== '', 
          bond,
          latestPaybackDate
        )

        console.log('status', status);

        if(status === 'before') {
          claimPurpose += getClaimPurpose(
            bond.tradingPricePaybackIndex !== -1, 
            bond.willPaybackDate !== '',
            bond.isDeferInterestRate,
            bond.didDeliveryDate !== '',
            bond.willDeliveryDate !== '', 
            bond,
            true
          )
        }
        else {
          claimPurpose += getClaimPurpose3(
            bond.tradingPricePaybackIndex !== -1, 
            bond.willPaybackDate !== '',
            bond.isDeferInterestRate,
            bond.didDeliveryDate !== '',
            bond.willDeliveryDate !== '', 
            bond,
            latestPaybackDate
          )
        }
      }
    }

    contents += `${claimPurpose}`;

    let c = claimPurpose;

    c = c.replace('용역대금 및 지연손해금 합계인 ', '').replace('용역대금 ', '').replace('나머지 ', '');
    
    console.log(`${bond.unit} 및 지연손해금 합계인 `);
    if(bond.unit !== '') c = c.replace(`${bond.unit} 및 지연손해금 합계인 `, '').replace(`${bond.unit} `, '');

    c = c.replace('변제기 다음날인 ', '').replace('인도약정일인 ', '').replace('인도일인 ', '').replace('완료예정일인 ', '').replace('용역완료일인 ', '').replace('최종 변제일 다음날인 ', '');
    c = c.replace('잔금 변제기인 ', '');
    c = c.replace('소송촉진 등에 관한 특례법이 정한 ', '');
    c = c.replace(/약정지연손해금율인 /gi, '').replace(/민법이 정한 /gi, '').replace(/상법이 정한 /gi, '');

    if(virtualBondList.filter(b => b.balance !== 0).length !== 1) {
      
      c = c.replace(' 지급', ',');

      //state.contents.claimPurpose.secondList.push(c);
      multipleClaimPurposeList.push(c);
      
      //textList.push(c);
    }
    else {

      if(complaint.documentType === '지급명령') {
        c = c.replace('원고', '채권자').replace('피고', '채무자');
      }

      c += '하라.'
      //state.contents.claimPurpose.firstList.push(c);
      singleClaimPurposeList.push(c);
    }
  });

  if(contents !== '') {
    contents = `따라서, ${contents}하여야 합니다.`;
  }

  if(complaint.documentType === '지급명령') {
    contents = contents.replace('원고', '채권자').replace('피고', '채무자');
  }

  claimCauseList.push(contents);
  textList.push(contents);

  contents = '';
  if(defendantList.length === 1) {
    contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  else {
    contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  
  textList.push(contents)
  claimCauseList.push(contents);

  textList.push('======================')

  singleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose1List.push(t);
  })

  claimPurposeHeader = '';
  
  const showBondList = virtualBondList.filter(b => b.balance !== 0);
  if(showBondList.length !== 1) {
    if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}는 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}는 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}는 ${wongo}에게 `, ''));
    }
    else if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}들은 연대하여 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}들은 연대하여 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}들은 연대하여 ${wongo}에게 `, ''));
    }
    else {
      claimPurposeHeader = `${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${wongo}에게 `, ''))
    }
  };

  if(claimPurposeHeader !== '') {
    textList.push(claimPurposeHeader);
  }
  multipleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose2List.push(t);
  });

  if(multipleClaimPurposeList.length !== 0) {
    textList.push('각 지급하라.')
  }

  if(documentType === '소장') {
    claimPurpose1List.push(`소송비용은 ${pigo}가 부담한다.`);
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
    textList.push(`소송비용은 ${pigo}가 부담한다.`);
    textList.push('제1항은 가집행할 수 있다.')
  }
  else {
    claimPurpose1List.push(`독촉절차비용은 ${pigo}가 부담한다.`);
    textList.push(`독촉절차비용은 ${pigo}가 부담한다.`);
  }

  textList.push('라는 판결을 구합니다.')
  

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: claimPurpose2List
  }
  return {textList, claimCauseList, claimPurpose};
}

export {
  getServicePriceText
}