
import {Person} from './person.model'
import moment from 'moment'

export type Application = {
  type: string


  name: string

  incident: {
    court: string
    year: string
    code: string
    subName: string
    name: string
  }

  //집행권원
  execution: {
    type: string
    execution1: {
      court: string
      year: string
      code: string
      subName: string
      name: string
    },
    execution2: {
      lawFirm: string
      year: string
      subName: string
    },
    execution3: string
  }

  deptText: string //채무액

  //이해관계인
  relationList: Relation[]

  court: string

  //청구금액
  billingAmountList: BillingAmount[]
  // billingAmountList: {
  //   price: number
  //   startDate: string
  //   endDate: string
  //   rate: number
  // }[]

  billingAmount: {
    type: string //all, part
    price: number
  }

  thirdDebtor: any

  bankDebtorList: BankDebtor[]; //예금
  cardDebtorList: CardDebtor[]; //카드
  depositDebtorList: DepositDebtor[]; //임대차보증금

  //채권가압류결정
  decision: {
    isOn: boolean,
    incident: {
      court: string,
      year: string,
      code: string,
      subName: string,
      name: string
    },
    price: number
  }

  assetFindReasonArray: any[];
  account: {
    no: string,
    name: string,
    bank: string
  };

  telecomList: any[];
  telecom: any;

  landList: any[];
  buildingList: any[];
  setBuildingList: any[];
  
  createdAt: string;
}

export type BankDebtor = {
  bank: any
  price: number
}

export type CardDebtor = {
  card: any
  price: number
}

export type DepositDebtor = {
  name: string
  address: string
  detailAddress: string
  contractDate: string
  dontKnowContractDate: string
  price: number
}

export type Relation = {
  type: string
  name: string
  zoneCode: string
  address: string
  detailAddress: string

  idEditable: boolean
}

export const initRelation = (type: string): Relation => {
  return {
    type: type === '직접입력' ? '' : type,
    name: '',
    zoneCode: '',
    address: '',
    detailAddress: '',

    idEditable: type === '직접입력',
  }
}

export type BillingAmount = {
  price: number
  startDate: string
  endDate: string
  rate: number
}

export const initBillingAmount = (): BillingAmount => {
  return {
    price: 0,
    startDate: '2020-01-01',
    endDate: moment().format('YYYY-MM-DD'),
    rate: 0,
  }
}

export const newApplication = (): Application => {
  console.log('newApplication')
  return {
    type: '',
    name: '',

    incident: {
      court: '대법원',
      year: '',
      code: '',
      subName: '',
      name: ''
    },

    //집행권원
    execution: {
      type: '',
      execution1: {
        court: '',
        year: '',
        code: '',
        subName: '',
        name: ''
      },
      execution2: {
        lawFirm: '',
        year: '',
        subName: '',
      },
      execution3: ''
    },

    deptText: '', //채무액

    //이해관계인
    relationList: [],

    court: '',

    //청구금액
    //billingAmountList: [new BillingAmount()],
    billingAmountList: [
      {
        price: 0,
        startDate: '2020-01-01',
        endDate: moment().format('YYYY-MM-DD'),
        rate: 0
      }
    ],

    billingAmount: {
      type: 'all', //all, part
      price: 0
    },

    thirdDebtor: {},

    bankDebtorList: [], //예금
    cardDebtorList: [], //카드
    depositDebtorList: [], //임대차보증금

    //채권가압류결정
    decision: {
      isOn: false,
      incident: {
        court: '대법원',
        year: '',
        code: '',
        subName: '',
        name: ''
      },
      price: 0,
    },

    assetFindReasonArray: [],
    account: {
      no: '',
      name: '',
      bank: ''
    },

    telecomList: [],
    telecom: null,

    landList: [],
    buildingList: [],
    setBuildingList: [],
    
    createdAt: moment().format('YYYY-MM-DD'),
  }
}