import { useEffect, useState }  from 'react'

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DatePicker from '@mui/lab/DatePicker';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import { TimePicker, DateTimePicker } from '@mui/lab';
// import koLocale from 'date-fns/locale/ko';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';


function SJDateTimePicker(props) {

  const changeDate = (date) => {
    if(props.onChangeDate)
      props.onChangeDate(moment(new Date(date)).format('YYYY-MM-DD HH:mm'));
  }

  return (
    <div className="date-time-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}
        // locale={koLocale}
      >
        <div>
          <DateTimePicker
          inputFormat={'YYYY.MM.DD HH:mm'}
          mask={'____.__.__ __:__'}
            value={props.date}
            onChange={changeDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>
    </div>
  )
}

export default SJDateTimePicker