import { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import SJDatePicker from './SJDatePicker';
import { TextField, Radio, Button, Checkbox, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'

import _ from 'lodash'
import { useAppSelector } from '../../../store';

const DELIVERY_REASON_LIST = [
  "상대방이 인도한 용역목적물에 중요한 하자가 있습니다.",
  "상대방이 용역목적물을 전혀 인도하지 아니하고 있습니다.",
  "상대방이 용역목적물을 일부 인도하지 아니하고 있습니다.",
  "상대방이 용역목적물을 인도할 수 없게 되었습니다.",
  "상대방이 용역목적물을 인도하지 아니하겠다고 말합니다.",
  "제가 착오로 용역계약을 체결하였습니다.",
  "제가 상대방에게 속아 용역계약을 체결하였습니다.",
  "제가 상대방의 강압 때문에 용역계약을 체결하였습니다."
]

const COMPLETE_REASON_LIST = [
  "상대방이 일을 완료하지 아니하고 있습니다.",
  "상대방이 일을 완료할 수 없게 되었습니다.",
  "상대방이 일을 완료하지 아니하겠다고 말합니다.",
  "제가 착오로 용역계약을 체결하였습니다.",
  "제가 상대방에게 속아 용역계약을 체결하였습니다.",
  "제가 상대방의 강압 때문에 용역계약을 체결하였습니다."
]



function ContractServicePrice(props) {

  let complaint = useAppSelector((state) => state.app.complaint)

  useEffect(() => {
    if(complaint.bondList[0].didPaybackDateList.length === 0) {
      addBondDidPaybackDate(0);
    }
  }, []);

  const changeContractDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPriceContractDate = date;
    props.refreshComplaint(c);
  }

  const changeTradingPrice = (e, index) => {

    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].tradingPrice = price;
    props.refreshComplaint(c);
  }

  const addBondDidPaybackDate = (index) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.didPaybackDateList.push({price: 0, date: '2020.01.01'})

    props.refreshComplaint(c);
  }

  const removeBondDidPaybackDate = (index, dIndex) => {
    let c = _.cloneDeep(complaint);
    const bond = c.bondList[index];

    bond.didPaybackDateList.splice(dIndex, 1);

    props.refreshComplaint(c);
  }

  const changeBond = (index, bond) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index] = bond;
    props.refreshComplaint(c);
  }

  const getTitle1 = (bond) => { //7번 문항 해제, 취소
    const reason = bond.contractTradingPriceClaimReason;
    return (reason === 3 || reason === 4 || reason === 5) ? '취소' : '해제'
  }

  const isDisplaySubQuestion = (bond) => {
    if(bond.serviceType === 'delivery') {
      if(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) {
        return true;
      }
    }
    else {
      if(bond.contractTradingPriceClaimReason === 0) {
        return true;
      }
    }

    return false;
  }

  return (
    <div className="contract-service-price">
      <p className="head">계약금반환(용역대금)</p>
      {_.cloneDeep(complaint.bondList).map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          {index !== 0 && (<Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon/></Button>)}
          <p className="ttl">1) 용역계약을 체결한 날짜가 언제인가요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker 
              date={bond.tradingPriceContractDate}
              onChangeDate={date => {
                bond.tradingPriceContractDate = date;
                changeBond(index, bond)
              }}
              />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />
          
          <p className="ttl">2) 용역 내용이 무엇인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              value={bond.serviceTitle}
              onChange={e => {
                bond.serviceTitle = e.target.value;
                changeBond(index, bond);
              }}
              placeholder="프로그램 개발"
              className="w240"
            />
            <span className="ml_4">입니다.</span>
          </div>

          <hr />

          <p className="ttl">3) 용역대금 총액이 얼마인가요?</p>
          <div className="answer-area flex-row">
            <TextField
              variant="outlined"
              type="number"
              value={bond.tradingPrice === 0 ? '' : bond.tradingPrice}
              onChange={e => changeTradingPrice(e, index)}
              placeholder="1,000,000"
              className="input-price"
            />
            <span>원입니다.</span>
          </div>

          <hr />

          <div className="ttl-bx">
            <p className="ttl">4) 용역대금을 지급한 날짜가 언제인가요?</p>
            <Button className="add-btn btn-24" onClick={e => addBondDidPaybackDate(index)}><AddIcon /></Button>
          </div>
          <div className='answer-area answer4'>
          {bond.didPaybackDateList.map((didPaybackDate, dIndex) => (
            <div className="row-bx flex-row" key={`didPaybackDate${index}${dIndex}`}>
              <SJDatePicker
                date={didPaybackDate.date}
                onChangeDate={date => {
                  bond.didPaybackDateList[dIndex].date = date;
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">에</span>
              <TextField
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => {
                  const price = e.target.value ? parseInt(e.target.value) : 0;
                  bond.didPaybackDateList[dIndex].price = price;
                  changeBond(index, bond);
                }}
                placeholder="1,000,000"
                className="input-price"
              />
              <span className="mr_10">원입니다.</span>
              {dIndex !== 0 && <Button className="remove-btn btn-24" onClick={e => removeBondDidPaybackDate(index, dIndex)}><RemoveIcon /></Button>}
            </div>
          ))}
          </div>
          <hr />

          <p className="ttl">5) 일의 완료 이외에 용역목적물 인도가 필요한가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.serviceType === 'delivery'}
                onChange={e => {
                  bond.serviceType = 'delivery';
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">일의 완료 이외에 용역목적물 인도가 필요합니다.</span>
              <span className="sub-txt">예) 설계, 개발, 디자인, 건설</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.serviceType === 'complete'}
                onChange={e => {
                  bond.serviceType = 'complete';
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">일의 완료만 하면 됩니다.</span>
              <span className="sub-txt">예) 경비, 청소</span>
            </label>
          </div>

          <hr />

          <p className="ttl">6) {bond.serviceType === 'delivery' ? '용역목적물을 인도하기로' : '일을 완료하기로'} 한 날짜가 언제인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                checked={bond.willDeliveryDateType === 'fix'}
                onChange={e => {
                  bond.willDeliveryDateType = 'fix';
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker 
                date={bond.willDeliveryDate}
                onChangeDate={date => {
                  bond.willDeliveryDate = date;
                  changeBond(index, bond);
                }}
              />
              <span className="ml_4">입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                checked={bond.willDeliveryDateType === 'none'}
                onChange={e => {
                  bond.willDeliveryDateType = 'none';
                  changeBond(index, bond);
                }}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>

          <hr />

          <p className="ttl">7) 용역대금 반환을 청구하는 이유가 무엇인가요?</p>
          {(bond.serviceType === 'delivery' ? DELIVERY_REASON_LIST : COMPLETE_REASON_LIST).map((reason, rIndex) => (
            <label className="radio-item" key={`reason${index}${rIndex}`}>
              <Radio
                value={rIndex}
                checked={bond.contractTradingPriceClaimReason === rIndex}
                onChange={e => {
                  bond.contractTradingPriceClaimReason = rIndex;
                  changeBond(index, bond);
                }}
              />
              <span>{reason}</span>
            </label>
          ))}
          <hr />

          {isDisplaySubQuestion(bond) && (
            <div>
              <p className="ttl">7-1) 상대방에게 {bond.serviceType === 'delivery' ? '용역목적물을 인도' : '일을 완료'}하라고 독촉하였나요?</p>
              <div className="answer-area answer7-1">
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 0}
                    onChange={e => {
                      bond.dunningType = 0;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning1Date1}
                    onChangeDate={date => {
                      bond.dunning1Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에</span>
                  <SJDatePicker
                    date={bond.dunning1Date2}
                    onChangeDate={date => {
                      bond.dunning1Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>까지 인도하라고 의사</span>
                  <span className="mr_10">표시를 발송하였고, 상대방이</span>
                  <SJDatePicker
                    date={bond.dunning1Date3}
                    onChangeDate={date => {
                      bond.dunning1Date3 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 받았습니다.</span>
                </label>
                
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 1}
                    onChange={e => {
                      bond.dunningType = 1;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning2Date1}
                    onChangeDate={date => {
                      bond.dunning2Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에 지체없이 인도하라고 의사표시를 발송하였</span>
                  <span className="mr_10">고, 상대방이</span>
                  <SJDatePicker
                    date={bond.dunning2Date2}
                    onChangeDate={date => {
                      bond.dunning2Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 받았습니다.</span>
                </label>
                
                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 2}
                    onChange={e => {
                      bond.dunningType = 2;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning3Date1}
                    onChangeDate={date => {
                      bond.dunning3Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">에</span>
                  <SJDatePicker
                    date={bond.dunning3Date2}
                    onChangeDate={date => {
                      bond.dunning3Date2 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>까지 인도하라고 의사</span>
                  <span>표시를 발송하였으나, 상대방이 받은 일시를 모릅니다.</span>
                </label>

                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 3}
                    onChange={e => {
                      bond.dunningType = 3;
                      changeBond(index, bond);
                    }}
                  />
                  <span className="mr_10">제가</span>
                  <SJDatePicker
                    date={bond.dunning4Date1}
                    onChangeDate={date => {
                      bond.dunning4Date1 = date;
                      changeBond(index, bond);
                    }}
                  />
                  <span>에 지체없이 인도하라고 의사표시를 발송하였으</span>
                  <span>나, 상대방이 받은 일시를 모릅니다.</span>
                </label>

                <label className="radio-item">
                  <Radio
                    checked={bond.dunningType === 4}
                    onChange={e => {
                      let c = _.cloneDeep(complaint);

                      if(bond.serviceType === 'complete' && bond.contractTradingPriceClaimReason === 0) {
                        c.documentType = '소장';
                      }
                      else if(bond.serviceType === 'delivery' && (bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2)) {
                        c.documentType = '소장';
                      }

                      c.bondList[index].dunningType = 4;
                      props.refreshComplaint(c);
                    }}
                  />
                  <span>독촉하지 아니하였습니다.</span>
                  <span style={{fontSize: '10px'}}> (이 경우 지급명령 신청서는 선택할 수 없고, 소장을 선택해야 합니다)</span>
                  
                </label>
              </div>
            </div>
          )}
          

          <hr />

          <p className="ttl">8) 상대방에게 용역계약을 {getTitle1(bond)}한다는 의사표시를 하였나요?</p>
          <div className="answer-area answer8">
            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 0}
                onChange={e => {
                  bond.expressionType = 0;
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">제가</span>
              <SJDatePicker
                date={bond.expression1Date1}
                onChangeDate={date => {
                  bond.expression1Date1 = date;
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">에 용역계약을 {getTitle1(bond)}한다는 의사표시를 발송하</span>
              <span className="mr_10">였고, 상대방이</span>
              <SJDatePicker
                date={bond.expression1Date2}
                onChangeDate={date => {
                  bond.expression1Date2 = date;
                  changeBond(index, bond);
                }}
              />
              <span>에 받았습니다.</span>
            </label>

            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 1}
                onChange={e => {
                  bond.expressionType = 1;
                  changeBond(index, bond);
                }}
              />
              <span className="mr_10">제가</span>
              <SJDatePicker/>
              <span>에 용역계약을 {getTitle1(bond)}한다는 의사표시를 발송하였</span>
              <span>고, 상대방이 받은 일시를 모릅니다.</span>
            </label>

            <label className="radio-item">
              <Radio
                checked={bond.expressionType === 2}
                onChange={e => {
                  
                  let c = _.cloneDeep(complaint);

                  c.documentType = '소장';

                  c.bondList[index].expressionType = 2;
                  props.refreshComplaint(c);
                }}
              />
              <span>아직 아니하였습니다.</span>
              <span style={{fontSize: '10px'}}> (이 경우 지급명령 신청서는 선택할 수 없고, 소장을 선택해야 합니다)</span>
            </label>
          </div>
        </div>
      ))}
      {/* <Button className="contained" onClick={e => props.addBond()}>채권 추가</Button> */}
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractServicePrice)
