import React, { useEffect, useState } from 'react';
import './Step.scss';
import InfoBx from '../InfoBx';
import { FormControlLabel, FormControl, Select, MenuItem, TextField, FormGroup, Checkbox, Button } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';

import _ from 'lodash';
import AddressPicker from '../bond/AddressPicker';
import { Person } from '../../../store/modules/app';

type PersonProps = {
  type: number
  person: Person
  onChange: (person: Person) => void
  onRemovePerson?: () => void
}

export default function InputPerson(props: PersonProps) {

  const [showInfoBx, setShowInfoBx] = useState(false);

  useEffect(() => {
    console.log('person', props.person.type)
  }, [props.person])

  return(
    <div className="cont-bx bg-gray">
      <div className="ttl-bx flex-row">
        <p className="ttl">1) 귀하의 형태를 선택해주세요.</p>
        {/* <Button className={`info-btn active ${showInfoBx ? 'active' : ''}`} onClick={clickedInfoBtn}><InfoIcon /></Button> */}
        {/* {false && (
          <InfoBx>
            <Button className="close-btn" onClick={clickedInfoBtn}><CloseIcon /></Button>
            <p># 작성 가이드</p>
            <p>- 등기를 하여 법인을 설립한 경우에는<br/>법인을 선택하시고 개인이나 개인사업자인<br/>경우에는 개인을 선택하세요.</p>
            <p>- 지급 명령 당사자를 선택하면, 알맞은<br/>입증 방법을 자동 작성 해 드립니다.</p>
          </InfoBx>
        )} */}
      </div>
      {props.onRemovePerson && (
        <Button className="remove-btn" onClick={props.onRemovePerson}><RemoveIcon /></Button>
      )}
      <FormControl variant="outlined" className="select">
        <Select
          id="demo-simple-select-outlined"
          value={props.person.type}
          onChange={e => {
            if(typeof(e.target.value) === 'number') {
              const type = e.target.value

              props.onChange({...props.person, 
                type: type, 
                isEmptyName: false, 
                isEmptyCompany: false,
                isEmptyPhone: false,
                isEmptyAddress: false,
                isEmptyRRNumber: false,
                isMerchant: type !== 0 && type !== 4
              })
            }
          }}
        >
          <MenuItem value={0}>개인</MenuItem>
          <MenuItem value={1}>개인사업자</MenuItem>
          <MenuItem value={2}>주식회사</MenuItem>
          <MenuItem value={3}>기타 회사</MenuItem>
          <MenuItem value={4}>회사 외 법인</MenuItem>
        </Select>
      </FormControl>
      <p className="sub-txt">* 원고/피고 중 일부가 법인인 경우, 법인등기부등본 제출</p>
      <hr />
      <p className="ttl">2) 귀하(법인)의 정보를 입력해주세요.</p>
      {(props.person.type === 2 || props.person.type === 3 || props.person.type === 4) && (
        <div>
          <div className="info-item-bx flex-row">
            <p className="ttl">회사명</p>
            <TextField
              variant="outlined"
              value={props.person.company}
              onChange={e => props.onChange({...props.person, company: e.target.value})}
              placeholder={`${props.person.type === 2 ? '주식회사' : props.person.type === 4 ? '사단법인' : '유한회사'} OOO`}
            />
            {props.type === 1 && (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="모름"  />}
                  label="모름"
                  checked={props.person.isEmptyCompany}
                  onChange={(e, checked) => props.onChange({...props.person, isEmptyCompany: checked})}
                />
              </FormGroup>
            )}
          </div>

          <div className="info-item-bx flex-row corporationNum-item-bx">
            <p className="ttl">법인번호</p>
            <TextField
              variant="outlined"
              value={props.person.RRNumber1}
              onChange={e => props.onChange({...props.person, RRNumber1: e.target.value})}
              placeholder="000000"
            />
            <p className="hyphen">-</p>
            <TextField
              variant="outlined"
              value={props.person.RRNumber2}
              onChange={e => props.onChange({...props.person, RRNumber2: e.target.value})}
              placeholder="0000000"
            />
            {props.type === 1 && (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox name="모름"  />}
                  label="모름"
                  checked={props.person.isEmptyRRNumber}
                  onChange={(e, checked) => props.onChange({...props.person, isEmptyRRNumber: checked})}
                />
              </FormGroup>
            )}
          </div>

          <div className="info-item-bx flex-row">
            <p className="ttl">직위</p>
            <TextField
              variant="outlined"
              value={props.person.spot}
              onChange={e => props.onChange({...props.person, spot: e.target.value})}
              placeholder={`${props.person.type === 4 ? '이사장' : '대표이사'}`}
            />
          </div>
        </div>
      )}

      <div className="info-item-bx flex-row">
        <p className="ttl">이름</p>
        <TextField
          variant="outlined"
          value={props.person.name}
          onChange={e => props.onChange({...props.person, name: e.target.value})}
          placeholder="OOO"
        />
        {(props.type === 1 && (props.person.type === 0 || props.person.type === 1)) && (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox name="모름"/>
              }
              checked={props.person.isEmptyName}
              onChange={(e, checked) => props.onChange({...props.person, isEmptyName: checked})}
              label="모름"
            />
          </FormGroup>
        )}
      </div>

      <div className="info-item-bx flex-row tel-item-bx">
        <p className="ttl">전화번호</p>
        <TextField
          variant="outlined"
          value={props.person.phone1}
          onChange={e => props.onChange({...props.person, phone1: e.target.value})}
          placeholder="010"
        />
        <p className="hyphen">-</p>
        <TextField
          variant="outlined"
          value={props.person.phone2}
          onChange={e => props.onChange({...props.person, phone2: e.target.value})}
          placeholder="0000"
        />
        <p className="hyphen">-</p>
        <TextField
          variant="outlined"
          value={props.person.phone3}
          onChange={e => props.onChange({...props.person, phone3: e.target.value})}
          placeholder="0000"
        />
        {props.type === 1 && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="모름"  />}
              label="모름"
              checked={props.person.isEmptyPhone}
              onChange={(e, checked) => props.onChange({...props.person, isEmptyPhone: checked})}
            />
          </FormGroup>
        )}
      </div>
      
      {(props.person.type === 0 || props.person.type === 1) && (
        <div className="info-item-bx flex-row rrNum-item-bx">
          <p className="ttl">주민등록번호</p>
          <TextField
            variant="outlined"
            value={props.person.RRNumber1}
            onChange={e => props.onChange({...props.person, RRNumber1: e.target.value})}
            placeholder="000000"
          />
          <p className="hyphen">-</p>
          <TextField
            variant="outlined"
            value={props.person.RRNumber2}
            onChange={e => props.onChange({...props.person, RRNumber2: e.target.value})}
            placeholder="0000000"
          />
          {props.type === 1 && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="모름"  />}
                label="모름"
                checked={props.person.isEmptyRRNumber}
                onChange={(e, checked) => props.onChange({...props.person, isEmptyRRNumber: checked})}
              />
            </FormGroup>
          )}
        </div>
      )}

      <div className="info-item-bx flex-row">
        <p className="ttl">주소</p>
        <AddressPicker
          address={props.person.address}
          onSelectAddress={(address: any) => {
            props.onChange({...props.person, address: address.address, displayAddress: address.displayAddress})
          }}
        />
        {props.type === 1 && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox name="모름"  />}
              label="모름"
              checked={props.person.isEmptyAddress}
              onChange={(e, checked) => props.onChange({...props.person, isEmptyAddress: checked})}
            />
          </FormGroup>
        )}
      </div>

      <div className="info-item-bx flex-row">
        <p className="ttl">상세주소</p>
        <TextField
          variant="outlined"
          value={props.person.detailAddress}
          onChange={e => props.onChange({...props.person, detailAddress: e.target.value})}
        />
      </div>

      {props.type === 0 && (
        <div className="info-item-bx flex ai-center">
          <p className="ttl">이메일(선택)</p>
          <TextField
            variant="outlined"
            value={props.person.email}
            onChange={e => props.onChange({...props.person, email: e.target.value})}
            placeholder="sj-ez@gmail.com"
          />
        </div>
      )}
   </div>
  )
}