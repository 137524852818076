import React from 'react';
import '../Scss/Expense.scss';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';


export default class Expense extends React.Component{
  state = {
  }


  render() {
    return(
      <div className="expense">
        <div className="top-img-area flex-center">
          <div className="contents-bx ta-center">
            <b>0원으로 소장 작성</b>
            <p>어렵고 복잡한 사건도 도와드립니다.</p>
          </div>
        </div>

        <p className="sj-head">비용</p>

        <div className="card-view-area mw1280">
          <div className="card-group flex jc-center">
            <div className="card-wrap">
              <div className="card basic-card">
                <p className="card-ttl-bx">베이직</p>
                <img src="../../Image/expense-basic.png" alt="basic" />
                <p className="price color-222"><b>0</b>원</p>
                <p className="sub-txt">소장·지급명령신청서 자동완성</p>
                <p className="sub-txt">증거자료 포함, PDF 문서 인쇄</p>
              </div>
            </div>

            <div className="card-wrap">
              <div className="card premium-card">
                <p className="card-ttl-bx">프리미엄</p>
                <img src="../../Image/expense-premium.png" alt="premium" />
                <p className="price color-222"><b>200,000</b>원</p>
                <p className="sub-txt">베이직 서비스</p>
                <p className="color-6bbaff">+ 변호사 검토 및 제출대행 서비스</p>
              </div>
            </div>
          </div>
        </div>

        <div className="txt-area ta-center">
          <div className="txt-cont">
            <FormatQuoteIcon />
            <p className="color-222 ta-center">보통 사건은 베이직 서비스로 충분하지만,</p>
            <p className="color-222 ta-center">사건내용과 증거가 복잡하면 프리미엄 서비스를 추천합니다.</p>
            <FormatQuoteIcon />
          </div>
        </div>

      </div>
    )
  }
}