

import moment from 'moment';

import { addComma, nextDate, diffDate } from './utils'

const dateFormat =  "YYYY. M. D.";

function calcPrice(price, rate, startDate, endDate) {

  const dateInfo = diffDate(moment(startDate), moment(endDate));

  let delayPrice = 0;

  delayPrice = price * dateInfo.yearCount * +(rate * 0.01).toFixed(5);

  delayPrice += price * (dateInfo.dateCount / (dateInfo.isYoondal ? 366 : 365)) * +(rate * 0.01).toFixed(5);

  return Math.floor(delayPrice);
}

const calcDeferInterestPrice = (B, C, D, E, F, balance, deferInterestRate, isLast, N, L, didPaybackDate, willDeliveryDate, didDeliveryDate) => {

  console.log(B, C, D, E, F, balance, deferInterestRate, isLast, N, L, didPaybackDate, willDeliveryDate, didDeliveryDate);

  let deferInterestPrice = 0;

  if(!B && !C && !D && !E && !F) { //1
    
  }
  else if(!B && !C && !D && !E && F) { //2
    
  }
  else if(!B && !C && !D && E && !F) { //3
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && !D && E && F) { //4
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && !E && !F) { //5
    
  }
  else if(!B && !C && D && !E && F) { //6
    
  }
  else if(!B && !C && D && E && !F) { //7
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');
      
      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && !C && D && E && F) { //8
    if(didDeliveryDate <= didPaybackDate) {
      console.log('원금*(P-U열+1)*지손금율/365');

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
  }
  else if(!B && C && !D && !E && !F) { //9
    
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < didDeliveryDate) {
      if(didDeliveryDate < didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365')

        //U열 : 인도일
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      console.log('원금*(P-U열+1)*지손금율/365')

      //U열 : 인도일
      deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
      
    }
    else {
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && !E && !F) { //13
    
  }
  else if(!B && C && D && !E && F) { //14
    if(N < willDeliveryDate) {
      //N열 : 변제기 다음날
      //P열 : 인도약정일
      if(N <= didPaybackDate && didPaybackDate < willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365')
        
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(!B && C && D && E && F) { //16
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      /**
       * U열: 인도일
       */
      if(didDeliveryDate <= didPaybackDate) {
        console.log('원금*(P-U열+1)*지손금율/365');
        deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      /**
       * N열: 변제기 다음날
       * P열: 인도약정일
       * U열: 인도일
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
      }
      else if(didDeliveryDate <= didPaybackDate) {
        console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
      }
    }
    else {
      /**
       * N열: 변제기 다음날
       */
      if(nextDate(N) <= didPaybackDate) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      /**
       * N열: 변제기 다음날
       * P열: 잔금 변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {

    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) && nextDate(N) < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {

      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < didDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
        else {
          if(nextDate(N) <= didPaybackDate) {
            console.log('원금*(P-N열+1)*지손금율/365');

            deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
          }
        }
      }
      else {
        /**
         * N열: 
         */
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열:
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');
          
          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < L) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(L <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //58행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      /**
       * N열: 
       * P열: 잔금변제기
       */
      if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
        console.log('원금*(P-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
      }
      else if(L < didPaybackDate) {
        console.log('원금*(P열-N열+1)*지손금율/365');

        deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
      }
    }
    else {
      
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
      }
      else if((N < L && L !== willDeliveryDate) &&
      (nextDate(N) < willDeliveryDate)) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
      }
      else {
        
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 잔금변제기
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= willDeliveryDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(willDeliveryDate < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //77행
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        /**
         * U열: 잔금변제기
         */
        if(L <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, L, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        /**
         * N열: 
         * P열: 인도약정일
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), willDeliveryDate);
          deferInterestPrice += calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        /**
         * N열: 
         * P열: 잔금변제기
         * U열: 인도일
         */
        if(nextDate(N) <= didPaybackDate && didPaybackDate <= L) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
        else if(L < didPaybackDate && didPaybackDate < didDeliveryDate) {
          console.log('원금*(P열-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
        }
        else if(didDeliveryDate <= didPaybackDate) {
          console.log('[원금*(P열-N열+1)*지손금율/365]+[원금*(P-U열+1)*지손금율/365]');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), L);
          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
    else {
      if(L < willDeliveryDate) {
        /**
         * U열: 인도일
         */
        if(didDeliveryDate <= didPaybackDate) {
          console.log('원금*(P-U열+1)*지손금율/365');

          deferInterestPrice = calcPrice(balance, deferInterestRate, didDeliveryDate, didPaybackDate);
        }
      }
      else {
        /**
         * N열: 
         */
        if(nextDate(N) <= didPaybackDate) {
          console.log('원금*(P-N열+1)*지손금율/365')

          deferInterestPrice = calcPrice(balance, deferInterestRate, nextDate(N), didPaybackDate);
        }
      }
    }
  }
  
  return parseInt(deferInterestPrice);
}

const checkBeforeAfterAffectPrincipal = (B, C, D, E, F, bond, lastDidPaybackDate) => {

  const willDeliveryDate = bond.willDeliveryDate;
  const didDeliveryDate = bond.didDeliveryDate;

  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format('YYYY-MM-DD');
  const lastWillPaybackDate = bond.lastWillPaybackDate;

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  console.log(nextWillPaybackDate);

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, P = ${lastDidPaybackDate}, LastN = ${bond.lastN}`);

  let status = 'before';

  if(!B && !C && !D && !E && !F) { //1
    
  }
  else if(!B && !C && !D && !E && F) { //2
    
  }
  else if(!B && !C && !D && E && !F) { //3
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && !C && !D && E && F) { //4
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && !C && D && !E && !F) { //5
    
  }
  else if(!B && !C && D && !E && F) { //6
    
  }
  else if(!B && !C && D && E && !F) { //7
    
  }
  else if(!B && !C && D && E && F) { //8
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && C && !D && !E && !F) { //9
    if(didDeliveryDate <= lastDidPaybackDate) {
      status = 'after';
    }
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < willDeliveryDate) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {

    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && D && !E && !F) { //13
    
  }
  else if(!B && C && D && !E && F) { //14
    if(N < willDeliveryDate) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {

    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(!B && C && D && E && F) { //16
    console.log('16')
    if(willDeliveryDate <= N && N < didDeliveryDate) {
      if(didDeliveryDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else if(N < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
      console.log(nextWillPaybackDate, lastDidPaybackDate);
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      console.log(nextWillPaybackDate, lastDidPaybackDate);
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {

    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((N < L && L !== willDeliveryDate) && nextDate(N) < willDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {

      }
    }
    else {
      if(L < willDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {

      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if(L < didDeliveryDate) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //58행
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        if(lastWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      if(nextWillPaybackDate <= lastDidPaybackDate) {
        status = 'after';
      }
    }
    else {
      
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === willDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((N < L && L !== willDeliveryDate) && (nextDate(N) < willDeliveryDate)) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        
      }
    }
    else {
      if(L < willDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < willDeliveryDate && willDeliveryDate < L && L < didDeliveryDate) ||
      (N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate < didDeliveryDate && didDeliveryDate < L )) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L === willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < willDeliveryDate && willDeliveryDate < L && L === didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(((willDeliveryDate < N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate)) || 
      ((willDeliveryDate === N && N < L && L !== didDeliveryDate) && (nextDate(N) < willDeliveryDate))) { //77행
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if((willDeliveryDate < N && N < L && L === didDeliveryDate) ||
      (willDeliveryDate === N && N < didDeliveryDate && didDeliveryDate === L)) {
        if(lastWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if((willDeliveryDate <= N && N < L && L < didDeliveryDate) ||
      (N < willDeliveryDate && willDeliveryDate <= L && L < didDeliveryDate)) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else if(N < L && L < willDeliveryDate && willDeliveryDate < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < didDeliveryDate) {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
    else {
      if(L < willDeliveryDate) {
        if(didDeliveryDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
      else {
        if(nextWillPaybackDate <= lastDidPaybackDate) {
          status = 'after';
        }
      }
    }
  }
  
  return status;
}

const getClaimPurpose = (B, C, D, E, F, bond, isAffectPrincipal) => { //기본형

  if(bond.balance === 0) return '';

  console.log("status", B, C, D, E, F);

  let claimPurpose = '';

  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  if(!B && !C && !D && !E && !F) { //1
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && !E && F) { //2
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && E && !F) { //3
    claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && !E && !F) { //5
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && !E && F) { //6
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && !E && !F) { //9
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < bond.willDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
    else {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && D && !E && !F) { //13
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && D && !E && F) { //14
    if(N < bond.willDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(!B && C && D && E && F) { //16
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
    else {
      claimPurpose = `매매대금 ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else if((N < L && L !== bond.willDeliveryDate) && nextDate(N) < bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if(L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}. 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(((bond.willDeliveryDate < N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate)) || 
      ((bond.willDeliveryDate === N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate))) { //58행
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((bond.willDeliveryDate < N && N < L && L === bond.didDeliveryDate) ||
      (bond.willDeliveryDate === N && N < bond.didDeliveryDate && bond.didDeliveryDate === L)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((bond.willDeliveryDate <= N && N < L && L < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate <= L && L < bond.didDeliveryDate)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else if((N < L && L !== bond.willDeliveryDate) && (nextDate(N) < bond.willDeliveryDate)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}. 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(((bond.willDeliveryDate < N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate)) || 
      ((bond.willDeliveryDate === N && N < L && L !== bond.didDeliveryDate) && (nextDate(N) < bond.willDeliveryDate))) { //77행
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((bond.willDeliveryDate < N && N < L && L === bond.didDeliveryDate) ||
      (bond.willDeliveryDate === N && N < bond.didDeliveryDate && bond.didDeliveryDate === L)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((bond.willDeliveryDate <= N && N < L && L < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate <= L && L < bond.didDeliveryDate)) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if(N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}. 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원 및 이에 대한 변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }

  if(isAffectPrincipal) claimPurpose = `나머지 ${claimPurpose}`

  return claimPurpose;
}

const getClaimPurpose1 = (B, C, D, E, F, bond, P) => { //지손금 영향

  if(bond.balance === 0) return '';

  console.log(B, C, D, E, F);

  let claimPurpose = '';

  const tradingPrice = addComma(bond.price);
  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  const nextLastDidPaybackDate = moment(P).add(1, 'd').format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}의`;
  if(!B && !C && !D && !E && !F) { //1
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && !E && F) { //2
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && E && !F) { //3
    claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && !E && !F) { //5
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && !E && F) { //6
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && !E && !F) { //9
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < P && P < bond.willDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText} 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate <= P) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && !D && E && !F) { //11
    if(N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && !D && E && F) { //12
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else  if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && !E && !F) { //13
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && D && !E && F) { //14
    if(N < P && P < bond.willDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
    }
    else if(N < bond.willDeliveryDate && bond.willDeliveryDate <= P) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원을 지급`;
    }
    else {
      claimPurpose = `매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && D && E && !F) { //15
    if(N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && E && F) { //16)
    if(bond.willDeliveryDate <= N && N < bond.didDeliveryDate) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `매매대금 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      if(N < P && P < L) {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      } 
    }
    else {
      claimPurpose = `${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if(N < L && L === bond.willDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else if((N < L && L !== bond.willDeliveryDate)) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        } 
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      if(N < P && P < L) {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
      }
    }
    else {
      claimPurpose = `${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if(N < L && L === bond.willDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else if((N < L && L !== bond.willDeliveryDate)) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        } 
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if(N < L && L < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `${bond.unit} 및 지연손해금 합계인 ${balance}원 및 그 중 ${tradingPrice}원에 대하여 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }

  return claimPurpose;
}

const getClaimPurpose3 = (B, C, D, E, F, bond, P) => { //원금영향+후

  if(bond.balance === 0) return '';

  console.log('원금영향+후', B, C, D, E, F);

  let claimPurpose = '';

  const tradingPrice = addComma(bond.price);
  const balance = addComma(bond.balance);
  const nextWillPaybackDate = moment(bond.willPaybackDate).add(1, 'd').format(dateFormat);
  const lastWillPaybackDate = moment(bond.lastWillPaybackDate).format(dateFormat);
  const willDeliveryDate = moment(bond.willDeliveryDate).format(dateFormat);
  const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);

  const nextLastDidPaybackDate = moment(P).add(1, 'd').format(dateFormat);

  let N = bond.willPaybackDate;
  const L = bond.lastWillPaybackDate;
  const isLast = bond.isLast;

  if(isLast) N = bond.lastN;

  console.log(`N = ${N}, L = ${L}, 약 = ${bond.willDeliveryDate}, 인 = ${bond.didDeliveryDate}, P = ${P}, LastN = ${bond.lastN}`);

  const XLawText = bond.law === '민법' ? '민법이 정한 연 5%의' : '상법이 정한 연 6%의';
  const XDeferInterestText = `약정지연손해금율인 연 ${bond.deferInterestRate}%의`;
  const AC = !bond.isDeferInterestRate || bond.deferInterestRate < 12 ? '소송촉진 등에 관한 특례법이 정한 연 12%의' : `약정지연손해금율인 연 ${bond.deferInterestRate}의`;
  if(!B && !C && !D && !E && !F) { //1
    claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && !E && F) { //2
    claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && !D && E && !F) { //3
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && !D && E && F) { //4
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && !E && !F) { //5
    claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && !E && F) { //6
    claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
  }
  else if(!B && !C && D && E && !F) { //7
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && !C && D && E && F) { //8
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && !E && !F) { //9
    claimPurpose = `매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && !D && !E && F) { //10
    if(N < P && P < bond.willDeliveryDate) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && !D && E && !F) { //11
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && !D && E && F) { //12
    if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(!B && C && D && !E && !F) { //13
    claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
  }
  else if(!B && C && D && !E && F) { //14
    if(N < P && P < bond.willDeliveryDate) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 매매대금 ${balance}원을 지급`;
    }
  }
  else if(!B && C && D && E && !F) { //15
    claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
  }
  else if(!B && C && D && E && F) { //16
    if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (N < P && P < bond.willDeliveryDate)) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else if((N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) && 
    (bond.willDeliveryDate <= P && P < bond.didDeliveryDate)) {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
    else {
      claimPurpose = `나머지 매매대금 ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && !E && !F) { //25
    if(!isLast) {
      if(N < P && P < L) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      } 
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && !D && !E && F) { //26
    if(!isLast) {
      if((N < L && L === bond.willDeliveryDate) &&
      (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else if((N < L && L !== bond.willDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate && (L < P && P < bond.willDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XLawText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && !D && E && !F) { //27
    if(!isLast) {
      if((N < L && L < bond.didDeliveryDate) && (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((N < L && L < bond.didDeliveryDate) && (L <= P && P < bond.didDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }
  else if(B && C && !D && E && F) { //28
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) { //54
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) { //55
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XLawText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XLawText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && !E && !F) { //29
    if(!isLast) {
      if(N < P && P < L) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
    }
  }
  else if(B && C && D && !E && F) { //30
    if(!isLast) {
      if((N < L && L === bond.willDeliveryDate) && (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else if((N < L && L !== bond.willDeliveryDate) && (N < P && P < bond.willDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      }
    }
    else {
      if(L < bond.willDeliveryDate && (L < P && P < bond.willDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지 ${XDeferInterestText} 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원을 지급`;
      }
    }
  }
  else if(B && C && D && E && F) { //31
    if(!isLast) {
      if((N < bond.willDeliveryDate && bond.willDeliveryDate < L && L < bond.didDeliveryDate) ||
      (N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) ||
      (N < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate && bond.didDeliveryDate < L )) {
        if((N < P && P < bond.willDeliveryDate)) { //54
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) { //55
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < L && L === bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate) {
        if(N < P && P < L) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(L <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else if(N < bond.willDeliveryDate && bond.willDeliveryDate < L && L === bond.didDeliveryDate) {
        if(N < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 잔금 변제기인 ${lastWillPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else { //60
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      if((N < L && L < bond.willDeliveryDate && bond.willDeliveryDate < bond.didDeliveryDate)) {
        if(L < P && P < bond.willDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 인도약정일인 ${willDeliveryDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else if(bond.willDeliveryDate <= P && P < bond.didDeliveryDate) {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
        else {
          claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
        }
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
  }
  else if(B && C && D && E && !F) { //32
    if(!isLast) {
      if((N < L && L < bond.didDeliveryDate) && (N < P && P < L)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 잔금 변제기인 ${lastWillPaybackDate}까지는 ${XDeferInterestText}, 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else if((N < L && L < bond.didDeliveryDate) && (L <= P && P < bond.didDeliveryDate)) {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 인도일인 ${didDeliveryDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
      else {
        claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
      }
    }
    else {
      claimPurpose = `나머지 ${bond.unit} ${balance}원 및 이에 대한 최종 변제일 다음날인 ${nextLastDidPaybackDate}부터 이 사건 소장부본 송달일까지는 ${XDeferInterestText}, 그 다음날부터 다 갚는 날까지는 ${AC} 각 비율에 의한 금원을 지급`;
    }
  }

  return claimPurpose;
}

export {
  getClaimPurpose, getClaimPurpose1, getClaimPurpose3, checkBeforeAfterAffectPrincipal
}