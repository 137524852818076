import React from 'react';
import './Step.scss';
import { Button } from '@mui/material';

type Step6ServiceProps = {
  service: string,
  onClickDownload: () => void
  onClickOrder: () => void
}

export default function Step6Service(props: Step6ServiceProps) {
  
  return(
    <div className="cont-bx step6-service flex jc-sb">
      <div className="item-bx basic-item-bx flex column ta-center">
        <div className="card">
          <p className="card-ttl-bx flex-center">베이직</p>
          <img src="../../Image/expense-basic.png" alt="basic" />
          <p className="price color-222"><b>0</b>원</p>
          <p className="sub-txt">소장·지급명령신청서 자동완성</p>
          <p className="sub-txt">증거자료 포함, PDF 문서 인쇄</p>
        </div>
        <Button className={props.service === 'basic' ? 'contained' : ''} onClick={e => props.onClickDownload()}>선택</Button>
      </div>

      <div className="item-bx premium-item-bx flex column ta-center">
        <div className="card">
          <p className="card-ttl-bx flex-center">프리미엄</p>
          <img src="../../Image/expense-premium.png" alt="premium" />
          <p className="price color-222"><b>200,000</b>원</p>
          <p className="sub-txt">베이직 서비스</p>
          <p className="color-6bbaff">+ 변호사 검토 및 제출대행 서비스</p>
        </div>
        <Button className={props.service === 'premium' ? 'contained' : ''} onClick={e => props.onClickOrder()}>선택</Button>
      </div>
   </div>
  )
}