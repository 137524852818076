import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { FormControl, Select, MenuItem, TextField, Radio, FormControlLabel, Checkbox, Button, CircularProgress } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import './Order.scss';
import { post } from "../../utils/Api";

function OrderView(props) {

  const paymentTypeList = [
    {name: '신용카드', type: 'card'},
    {name: '계좌이체', type:  'trans'},
  ]

  const bankArray = [ "KB국민", "IBK기업", "NH농협", "신한", "시티", "SC제일", "우리", "카카오뱅크", "케이뱅크", "하나", "경남", "광주", "대구", "부산", "KDB산업", "수협", "우체국", "전북", "제주", "새마을금고", "신협", "SBI저축", "저축", "미래에셋대우", "삼성", "SK", "유안타", "유진투자", "한국투자" ];

  const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);
  const [bank, setBank] = useState('');
  const [accountName, setAccountNmae] = useState('');
  const [accountNo, setAccountNo] = useState('');

  const [showterm, setShowTerm] = useState(false);

  const [isAgree, setIsAgree] = useState(true);
  const [isAgreeService, setIsAgreeService] = useState(true);

  const [isShowLoading, setShowLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate()


  const clickedPayment = () => {

    if(bank === '' || accountName === '' || accountNo === '') {
      return alert('환불계좌정보를 입력해주세요.');
    }

    if(!isAgree || !isAgreeService) return alert('필수 동의항목이 있습니다.');

    const user = JSON.parse(sessionStorage.getItem('user'));

    // if(user) {
    //   this.payment('imp_432484333878', 'sjez_1612862483816');
    //   return;
    // }

    const merchant_uid = 'sjez_' + new Date().getTime();

    const { IMP } = window;
    IMP.init("imp84428497");

    IMP.request_pay({
      pg: 'danal_tpay',
      pay_method : paymentTypeList[selectedTypeIndex].type,
      merchant_uid : merchant_uid,
      name : '프리미엄 서비스',
      amount : user.email === 'test' ? 500 : 200000,
      buyer_email : '',
      buyer_name : user.name,
      buyer_tel : user.phone,
      buyer_addr : '',
      buyer_postcode : '',
    }, (response) => {
      let msg = '';
      if(response.success) {
        payment(response.imp_uid, response.merchant_uid);
      }
      else {
        console.log(response.error_msg);
      }
    });
  }

  const payment = (imp_uid, merchant_uid) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    
    const { fileName, complaint, plaintiff, defendantList } = location.state;



    setShowLoading(true);
    post(`payment`, {
      imp_uid: imp_uid,
      merchant_uid: merchant_uid,
      userId: user.id,
      complaint: {...complaint, plaintiff: plaintiff, defendantList: defendantList},
      file: fileName,
      bank: bank,
      account: accountNo,
      name: accountName
    }).then(response => {
      setShowLoading(false);
      if(response.data.success) {
        if(response.data.data === 'paid') {
          alert('결제가 완료되었습니다.');
          navigate('/order-complete', {
            state: {
              fileName: fileName
            }
          })
        }
        else if(response.data.data === 'ready') {

        }
      }
      else {

      }
    }).catch(error => {
      setShowLoading(false);
      console.log(error);
    })
  }

  return (
    <div className="order">
      <div className="h-top"></div>

      <div className="inner">
        <div className="head-area">
          <span className="head">주문 정보</span>
        </div>

        <div className="card">
          <div className="card-head">상품명</div>
          <div className="card-body color-6bbaff">
            <b className="fs18">프리미엄 서비스</b>            
          </div>
        </div>

        <div className="card price-card">
          <div className="card-head">결제금액</div>
          <div className="card-body">
            <b className="color-6bbaff fs18">200,000</b>
            <span>원</span>
          </div>
        </div>

        <div className="card account-card">
          <div className="card-head">
            <b>환불계좌등록</b>
            <span>인지대, 송달료 등을 환불하는 데에 필요합니다.</span>
          </div>
          <div className="card-body">
            <FormControl>
              <Select
                value={bank}
                onChange={e => setBank(e.target.value)}
              >
                {bankArray.map((bank, index) => (
                  <MenuItem key={`bank${index}`} value={bank}>{bank}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              value={accountName}
              onChange={e => setAccountNmae(e.target.value)}
              placeholder="예금주"
            />

            <TextField
              variant="outlined"
              value={accountNo}
              onChange={e => setAccountNo(e.target.value)}
              placeholder="계좌번호"
            />
          </div>
        </div>

        <div className="card">
          <div className="card-head">결제방법</div>
          {paymentTypeList.map((paymentType, index) => (
            <div className="card-body" key={`paymentType${index}`}>
              <label className="radio-item flex-row">
                <Radio
                  value='off'
                  checked={selectedTypeIndex === index}
                  onChange={e => setSelectedTypeIndex(index)}
                />
                <span>{paymentType.name}</span>
              </label>
            </div>
          ))}
        </div>

        <div className="checkbox-item">
          <FormControlLabel control={<Checkbox checked={isAgree} onChange={e => setIsAgree(e.target.checked)}/>} label="취소/환불 규정에 동의합니다." />
          <Button className="term-btn" onClick={e => setShowTerm(true)}>규정보기</Button>
        </div>

        <div className="checkbox-item">
          <FormControlLabel control={<Checkbox checked={isAgreeService} onChange={e => setIsAgreeService(e.target.checked)} />} label="프리미엄 서비스는 이용자가 입력한 정보에 전적으로 의존하며, 소송 결과에 책임을 지지 아니함을 확인합니다." />
        </div>

        <Button className="contained" onClick={e => clickedPayment()}>결제하기</Button>

        {showterm && (
          <div className="term-modal">
            <div className="card">
              <Button className="clear-btn" onClick={e => setShowTerm(false)}><ClearIcon /></Button>
              <p className="head">소장이지 취소/환불 규정</p>
              <p>"프리미엄 서비스" 이용료 반환(취소/환불) 규정</p>
              <table>
                <thead>
                  <tr>
                    <th>이용료 경우</th>
                    <th>이용료 반환 규정</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="refund100">
                    <td>
                      <p>프리미엄 서비스 이용료 결제 후</p>
                      <ol>
                        <li>소장 등을 다운로드 하지 않고,</li>
                        <li>변호사가 검토 서비스를 개시하기 전 결제취소</li>
                      </ol>
                    </td>
                    <td>100% 환불</td>
                  </tr>
                  <tr className="refund50">
                    <td>
                      <p>프리미엄 서비스 이용료 결제 후</p>
                      <ol>
                        <li>소장 등을 다운로드 하고,</li>
                        <li>변호사가 검토 서비스를 개시하기 전 결제취소</li>
                      </ol>
                    </td>
                    <td>50% 환불</td>
                  </tr>
                  <tr className="refund0">
                    <td>
                      <p>프리미엄 서비스 이용료 결제 후</p>
                      <p>변호사가 검토 서비스를 개시한 후 결제취소</p>
                    </td>
                    <td>0% 환불</td>
                  </tr>
                </tbody>
              </table>
              {/* <p>1. 변호사가 검토 서비스를 개시한 시점은 “소장이지” 관리자 페이지에서 검토 요청 서면을 처음 열어본 때로 봅니다.</p>
              <p>2. 이용료 반환 결과는 별도로 통보하지 않습니다.</p>
              <p>3. 이용료 반환은 결제 취소 방법에 따라 3~7일 소요될 수 있습니다(신용카드, 계좌이체).</p>
              <p>4. 휴대폰 결제의 경우 당월은 결제 취소만 가능하며, 익월 이후 휴대폰결제 환불 건은 입금 확인 후 결제자 본인 계좌로만 환불 가능합니다.</p> */}
              <ol className="explanation">
                <li>변호사가 검토 서비스를 개시한 시점은 “소장이지” 관리자 페이지에서 검토 요청 서면을 처음 열어본 때로 봅니다.</li>
                <li>이용료 반환 결과는 별도로 통보하지 않습니다.</li>
                <li>이용료 반환은 결제 취소 방법에 따라 3~7일 소요될 수 있습니다. (신용카드, 계좌이체)</li>
                <li>휴대폰 결제의 경우 당월은 결제 취소만 가능하며, 익월 이후 휴대폰결제 환불 건은 입금 확인 후 결제자 본인 계좌로만 환불 가능합니다.</li>
              </ol>
            </div>
          </div>
        )}
      </div>

      {isShowLoading && (
        <div className='loading-bx'>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default OrderView;