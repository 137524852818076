
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { changeUser, clear, init } from '../store/modules/app';

import '../Scss/Main.scss';
import '../Scss/User.scss';
import WriteDocument from './WriteDocument';
import SignInView from './user/SignInView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';
import { useEffect, useState } from "react";
import { useAppSelector } from "../store";




export default function Main(props: any) {

  const dispatch = useDispatch()

  let user = useAppSelector((state) => state.app.user);


  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const [navList, setNavList] = useState([
    { to: '/introduce', name: '소개' },
    { to: '/expense', name: '비용' },
    { to: '/notice', name: '공지사항' },
    { to: '/article', name: '언론보도' },
    { to: '/faq', name: 'FAQ' },
    { to: '', name: '블로그' }
  ]);
  const [selectedNavIndex, setSelectedNavIndex] = useState(-1);

  const [showWriteDocument, setShowWriteDocument] = useState(false);

  const [showSignIn, setShowSignIn] = useState(false);

  const [showDropdownMenu, setShowDropdownMenu] = useState(false);

  const [selectedComplaintType, setSelectedComplaintType] = useState('');

  useEffect(() => {
    const writeType = searchParams.get('writeType')

    console.log('writeType', writeType)

    if(writeType !== null) {
      setShowWriteDocument(true)
      setSelectedComplaintType(writeType)
    }
    else {
      setShowWriteDocument(false)
      setSelectedComplaintType('')
    }

  }, [searchParams])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if(window.scrollY > 1) {
        document.getElementById('header')?.classList.add('header-scroll')
      } else {
        document.getElementById('header')?.classList.remove('header-scroll')
      }
    })

    const user = sessionStorage.getItem('user')
    if(user !== null) {
      dispatch(changeUser(JSON.parse(user)))
    }

    //onSelectComplaint('매매대금(동산)');
    //onSelectComplaint('임대차보증금');

    // window.addEventListener('scroll', () => {
    //   if(window.scrollY > 1) {
    //     document.getElementById('header').classList.add('header-scroll')
    //   } else {
    //     document.getElementById('header').classList.remove('header-scroll')
    //   }
    // })

    // window.addEventListener('scroll', () => {
    //   if(window.scrollY < 674) {
    //     this.setState(
    //       { seletedNav: 0 }
    //     )
    //   } else if(window.scrollY < 1511) {
    //     this.setState(
    //       { seletedNav: 1 }
    //     )
    //   } else if(window.scrollY < 2068) {
    //     this.setState(
    //       { seletedNav: 2 }
    //     )
    //   } else {
    //     this.setState(
    //       { seletedNav: 3 }
    //     )
    //   }
    // })
  }, [])

  const onSelectComplaint = (type: string) => {
    setSelectedComplaintType(type);
    setShowWriteDocument(true);
  }

  const completeSignIn = (user: any) => {
    dispatch(changeUser(user))
    //props.changeUser(user);
    setShowSignIn(false);
  }

  const logout = () => {
    dispatch(changeUser(null))
    //props.changeUser(null);
    sessionStorage.removeItem('isLogin');
    sessionStorage.removeItem('user');
    setShowDropdownMenu(false);
    //history.push('/');
    navigate('/')
  }

  return(
    <div className="main flex column">
      <header id="header">
        <div className="mw1280 flex-row jc-sb">
          <Link className="logo-bx flex-row" to="/" >
            <img src="../../Image/logo-white.png" alt="sj-ez" />
          </Link>
          <nav className="flex">
            {navList.map((navItem, index) => (
              <Link
                to={navItem.to}
                key={`navItem${index}`}
                className={`flex-row ${selectedNavIndex === index ? 'selected' : ''}`}
                onClick={e => setSelectedNavIndex(index)}
              >
                <span data-hover={navItem.name}>{navItem.name}</span>
              </Link>
            ))}
            {!user && (
              <Button onClick={() => setShowSignIn(true)} className="sign-in-btn">
                <span data-hover="로그인">로그인</span>
              </Button>
            )}
            {user && (
              <div className="account flex-row">
                {/* <PersonIcon /> */}
                <Button onClick={() => setShowDropdownMenu(!showDropdownMenu)} className="account-btn">
                  <p>{user?.name}님</p>
                  <ArrowDropDownIcon className={`${showDropdownMenu === true ? 'rotate' : ''}`} />
                </Button>

                {showDropdownMenu && (
                  <ul>
                    <li>
                      {/* <Link to="./myinfo">내정보</Link> */}
                      <Link to="/myinfo">내정보</Link>
                    </li>
                    <li>
                      <Link to="./payment-list">결제내역</Link>
                    </li>
                    <li>
                      <Button onClick={e => logout()}>로그아웃</Button>
                    </li>
                  </ul>
                )}
              </div>
            )}
          </nav>
        </div>
      </header>


      <main>
        <Outlet/>
      </main>


      <footer>
        <div className="mw1280">
          <div className="term-area flex color-999">
            <Link to="#">이용약관</Link>
            <Link to="#">면책공고</Link>
            <Link to="#">개인정보 처리방침</Link>
          </div>
          <div className="info-area flex">
            <div className="logo-bx">
              <img src="../../Image/logo-white.png" alt="sj-ez" />
              <img src="../../Image/logo-gn-white.png" alt="법무법인(유한) 강남" />
            </div>
            <div className="txt-bx color-999">
              <div className="row-bx flex">
                <p>법무법인(유한) 강남</p>
                <p>주소 : 서울 서초구 서초중앙로 199, 4, 5층 (반포동)</p>
                <p>대표자 : 구본민외 5명</p>
              </div>
              <div className="row-bx flex">
                <p>이메일 : sojangez@gmail.com</p>
                <p>전화 : 02-6745-3621 (평일 10:00~18:00)</p>
              </div>
              <div className="row-bx flex">
                <p>개인정보책임자 : 박종인</p>
                <p>사업자등록번호 : 114-87-06003</p>
                <p>통신판매업신고 : 2020-서울서초-3754</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {showWriteDocument && (
        <WriteDocument
          complaintType={selectedComplaintType}
          onClose={() => {
            setSearchParams({})
            dispatch(clear())
          }}
        />
      )}

      {showSignIn && (
        <SignInView
          onClose={() => setShowSignIn(false)}
          onCompleteSignIn={(user: any) => completeSignIn(user)}
        />
      )}

    </div>
  )
}
