import { Button } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { useState } from 'react';

import { post } from '../../utils/Api';


export default function FindIdView(props) {

  const [id, setId] = useState(null);

  const clickedCertificate = () => {
    const { IMP } = window;
      IMP.init('imp84428497');
      //IMP.init('imp10391932');
      

      /* 2. 본인인증 데이터 정의하기 */
      const data = {
        // merchant_uid: `mid_${new Date().getTime()}`,
        // company: '아임포트',
        // carrier: 'SKT',
        // name: '홍길동',
        // phone: '01012341234'
      };

      /* 4. 본인인증 창 호출하기 */
      IMP.certification(data, response => {
        const {
        success,
        merchant_uid,
        error_msg
      } = response;

      if (response.success) {
        getUserInfo(response.imp_uid);
        
      } else {
        alert(`본인인증 실패: ${error_msg}`);
      }
      });
  }

  const getUserInfo = (imp_uid) => {
    post(`certifications/findid`, {
      imp_uid: imp_uid
    }).then(response => {
      if(response.data.success) {
        setId(response.data.data.email)
      }
      else {
        if(response.data.errorCode === 1) {
          alert('입력된 정보로 가입된 계정이 없습니다. 회원가입을 진행해주세요.');
        }
      }
    }).catch(error => console.log(error))
  }

  return (
    <div className="find-id user user-modal">
      <div className="card">
        <Button className="clear-btn" onClick={e => props.onClose()}><ClearIcon /></Button>

        <div className="head-area">
          <span className="head">아이디 찾기</span>
        </div>

        {!id && (
          <div>
            <p className="sub-head">소장이지 계정을 찾기 위해 사용자 확인을 진행합니다.</p>

            <div className="flex-row">
              <p className="ttl">본인인증</p>
              {true && (
                <Button className="contained certified-btn" onClick={e => clickedCertificate()}>인증하기</Button>
              )}
              {false && (
                <p className="outlined certified">인증완료</p>
              )}
            </div>
          </div>
        )}

        {id && (
          <div className="flex-row">
            <p className="ttl">아이디</p>
            <p className="txt">{id}</p>
          </div>
        )}
      </div>
    </div>
  )
}