import { Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useState } from 'react';


//층수 추가
export default function EditFloor(props) {

  const [floorList, setFloorList] = useState(props.floorList || []);

  useEffect(() => {
    
    if(props.onRefreshData)
      props.onRefreshData(floorList);

  }, [floorList])

  const clickedAddFloor = () => {

    let floor = '';
    let area = '';

    if(floorList.length !== 0) {
      const f = floorList[floorList.length - 1];
      
      if(parseInt(f.floor)) {
        floor = parseInt(f.floor) + 1;
        area = f.area;
      }

    }

    setFloorList([...floorList, {floor: floor, area: area}])
  }

  return (
    <div className="edit-floor">
      <div className="item">
        <p className="ttl">층수</p>
        <Button className="add-btn btn-24" onClick={e => clickedAddFloor()}><AddIcon/></Button>
      </div>
      {floorList.map((floor, index) => (
        <div className="floor-row flex-row" key={`floor${index}`}>
          <TextField
            className="w100"
            value={floor.floor}
            onChange={e => setFloorList([...floorList.slice(0, index), {...floor, floor: e.target.value}, ...floorList.slice(index + 1)])}
          />
          <span>층</span>
          <TextField
            className="w100"
            value={floor.area}
            onChange={e => setFloorList([...floorList.slice(0, index), {...floor, area: e.target.value}, ...floorList.slice(index + 1)])}
          />
          <span>㎡</span>
          <Button className="remove-btn btn-24" onClick={e => setFloorList([...floorList.slice(0, index), ...floorList.slice(index + 1)])}><RemoveIcon /></Button>
        </div>
      ))}
    </div>
  )
}
