import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router"

export default function CompleteOrderView(props) {

  const location = useLocation();
  const navigate = useNavigate()

  useEffect(() => {
    const state = location.state;

    if(!state) return;

    if(state.fileName) {
      const link = document.createElement('a')
      link.href = `https://sjez.s3.ap-northeast-2.amazonaws.com/pdf/${state.fileName}`
      link.target = '_blank'
      link.setAttribute('download', state.fileName)
      document.body.appendChild(link);
      link.click();
    }
  }, [])

  const clickedShowPaymentHistory = () => {
    navigate('payment-list')
  }

  return (
    <div className="order complete-order">
      <div className="h-top"></div>

      <div className="inner">
        <div className="head-area">
          <span className="head">결제 완료</span>
        </div>
        
        <div className="cont-area">
          <div className="wrap-bx">
            <p className="">결제가 완료되었습니다.</p>
            <p className="">담당 변호사가 확인 후 2일 이내로 연락 예정입니다.</p>

            <button className="show-payment-btn contained" onClick={e => clickedShowPaymentHistory()}>결제내역보기</button>
          </div>
        </div>


      </div>
    </div>
  )
}