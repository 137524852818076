import { useState, useEffect } from "react";
import { useAppSelector } from "../../../../store";

export default function RealEstateTable(props) {

  const landList = useAppSelector((state) => state.app.application.landList);
  const buildingList = useAppSelector((state) => state.app.application.buildingList);
  const setBuildingList = useAppSelector((state) => state.app.application.setBuildingList);

  const [totalCount, setTotalCount] = useState(landList.length + buildingList.length + setBuildingList.length);

  useEffect(() => {
    setTotalCount(landList.length + buildingList.length + setBuildingList.length);
  }, [landList, buildingList, setBuildingList])

  return (
    <div className="real-estate-table" style={{marginTop: '40px'}}>
      <p className="bold">부동산 목록</p>

      {landList.map((land, index) => (
        <p key={`land${index}`}>{totalCount < 2 ? '' : `${index + 1}. `}{land.address} {land.category} {land.area}m²</p>
      ))}

      {buildingList.map((building, index) => (
        <div key={`building${index}`}>
          <p>{totalCount < 2 ? '' : `${landList.length + index + 1}. `}{building.address} 지상 {building.history}</p>
          {building.floorList.map((floor, fIndex) => (
            <p key={`bfloor${index}${fIndex}`} style={{marginLeft: '10px'}}>{floor.floor}층 {floor.area}m²</p>
          ))}
        </div>
      ))}

      {setBuildingList.map((setBuilding, index) => (
        <div key={`setBuilding${index}`}>
          <p>{`${landList.length + buildingList.length + index + 1}. 1동의 건물의 표시`}</p>
          <div style={{marginLeft: '10px'}}>
            <p>{setBuilding.address}</p>
            <p>{setBuilding.history}</p>
            {setBuilding.floorList.map((floor, fIndex) => (
              <p key={`sbfloor${index}${fIndex}`}>{floor.floor}층 {floor.area}m²</p>
            ))}
          </div>
          {setBuilding.info1List.length !== 0 && (
            <div>
              <p>대지권의 목적인 토지의 표시</p>
              {setBuilding.info1List.map((info1, info1Index) => (
                <p key={`info1${index}${info1Index}`} style={{marginLeft: '10px'}}>{info1.address} {info1.category} {info1.area}m²</p>
              ))}
            </div>
          )}
          {setBuilding.info2List.length !== 0 && (
            <div>
              <p>전유부분의 건물의 표시</p>
              {setBuilding.info2List.map((info2, info2Index) => (
                <div key={`info2${index}${info2Index}`} style={{marginLeft: '10px'}}>
                  <p>건물번호 : {info2.contents1}</p>
                  <p>구 조 : {info2.contents2}</p>
                  <p>면 적 : {info2.area}m²</p>
                </div>
              ))}
            </div>
          )}
          {setBuilding.info3List.length !== 0 && (
            <div>
              <p>대지권의 표시</p>
              {setBuilding.info3List.map((info3, info3Index) => (
                <div key={`info3${index}${info3Index}`} style={{marginLeft: '10px'}}>
                  <p>대지권종류 : {info3.contents1}</p>
                  <p>대지권비율 : {info3.contents2}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}

      <p>끝.</p>

    </div>
  )
}