import moment from 'moment'
import josajs from 'josa-js'
import {josa} from 'josa'

import { getName, getNameWithJosa } from './utils'

import { checkBeforeAfterAffectPrincipal, getClaimPurpose, getClaimPurpose1, getClaimPurpose3 } from './TradingPriceDocuments'
import { deliveryCharge } from './Const'
const civilLawRate = 5.0;
const commercialLawRate = 6.0;

const numberType = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'];

const dateFormat = 'YYYY. M. D.'

const getText = (complaint, plaintiff, defendantList, textList) => {

  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const complaintType = complaint.type;
  const documentType = complaint.documentType;

  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';


  let singleClaimPurposeList = [];
  let multipleClaimPurposeList = [];

  textList.push(`${complaintType} 청구의 ${documentType === '소장' ? '소' : '독촉사건'}`);

  //청구원인 첫번째
  complaint.bondList.forEach(bond => {

    const price = addComma(bond.price);
    const lendDate = moment(new Date(bond.lendDate)).format(dateFormat);
    const willPaybackDate = moment(new Date(bond.willPaybackDate)).format(dateFormat);
    const interestRate = bond.interestRate;
    const deferInterestRate = bond.deferInterestRate;

    let causeOfAction = '';

    if(complaintType === '대여금') {
      causeOfAction += `원고는 ${lendDate} `;
      if(defendantList.length === 1) {
        causeOfAction += '피고에게 ';
      }
      else {
        causeOfAction += `피고 ${getName(defendantList[0])}에게 `;
        //causeOfAction = '피고 ' + defendantList[0].name + '에게 ';
      }
      causeOfAction += `${price}원을 `;
    }
    else if(complaintType === '약정금') {
      if(defendantList.length === 1) {
        causeOfAction += '피고는 ';
      }
      else {
        causeOfAction += `피고 ${getNameWithJosa(defendantList[0], '은/는')} `;
      }
      causeOfAction += `${lendDate}에 원고에게 ${price}원을 `
    }
    else if(complaintType === '양수금') {
      causeOfAction += `소외 ${josajs.r(bond.assignmentName, '은/는')} ${lendDate}에 피고에게 ${price}원을 `
    }

    const I = bond.isInterestRate;
    const W = bond.willPaybackDate !== '';
    const D = bond.isDeferInterestRate;

    if(!I && !D && !W) {
      if(complaintType === '대여금' || complaintType === '양수금') {
        causeOfAction += '이자율, 변제기, 지연손해금율의 정함이 없이 ';
      }
      else if(complaintType === '약정금') {
        causeOfAction += '변제기, 지연손해금율의 정함이 없이 ';
      }
    }
    else if(!I && !D && W) {
      if(complaintType === '대여금' || complaintType === '양수금') {
        causeOfAction += '변제기 ' + willPaybackDate + '로 정하고, 이자율, 지연손해금율의 정함이 없이 ';
      }
      else if(complaintType === '약정금') {
        causeOfAction += '변제기 ' + willPaybackDate + '로 정하고, 지연손해금율의 정함이 없이 ';
      }
    }
    else if((!I && D && !W)) {
      if(complaintType === '대여금' || complaintType === '양수금') {
        causeOfAction += '지연손해금율 ' + deferInterestRate + '%로 정하고, 이자율, 변제기의 정함이 없이 ';
      }
      else if(complaintType === '약정금') {
        causeOfAction += '지연손해금율 ' + deferInterestRate + '%로 정하고, 변제기의 정함이 없이 ';
      }
      
    }
    else if((!I && D && W)) {
      if(complaintType === '대여금' || complaintType === '양수금') {
        causeOfAction += '변제기 ' + willPaybackDate + '로 정하고, 지연손해금율 ' + deferInterestRate + '%로 정하고, 이자율의 정함이 없이 ';
      }
      else if(complaintType === '약정금') {
        causeOfAction += '변제기 ' + willPaybackDate + '로 정하고, 지연손해금율 ' + deferInterestRate + '%로 정하여 ';
      }
      
    }
    else if((I && !D && !W)) {
      causeOfAction += '이자율 ' + interestRate + '%로 정하고, 변제기, 지연손해금율의 정함이 없이 ';
    }
    else if((I && !D && W)) {
      causeOfAction += '이자율 ' + interestRate + '%로 정하고, 변제기 ' + willPaybackDate + '로 정하고, 지연손해금율의 정함이 없이 ';
    }
    else if((I && D && !W)) {
      causeOfAction += '이자율 ' + interestRate + '%로 정하고, 지연손해금율 ' + deferInterestRate + '%로 정하고, 변제기의 정함이 없이 ';
    }
    else if((I && D && W)) {
      causeOfAction += '이자율 ' + interestRate + '%로 정하고, 변제기 ' + willPaybackDate + '로 정하고, 지연손해금율 ' + deferInterestRate + '%로 정하여 ';
    }

    if(complaintType === '대여금' || complaintType === '양수금') {
      causeOfAction += '대여하였습니다.';
    }
    else if(complaintType) {
      causeOfAction += '지급하기로 약정하였습니다.';
    }

    if(documentType === '지급명령') {
      causeOfAction = causeOfAction.replace('원고', '채권자').replace('피고', '채무자')
    }
    
    textList.push(causeOfAction);
    claimCauseList.push(causeOfAction);
  });

  let contents = '';

  //양수금인 경우
  if(complaintType === '양수금') {
    complaint.bondList.forEach((bond, index) => {
      
      
      const realAssignmentDate = moment(new Date(bond.realAssignmentDate)).format(dateFormat);
      let assignmentDate = ''

      if(bond.assignmentDateType === 0) assignmentDate = moment(new Date(bond.assignmentDate1)).format(dateFormat);
      else if(bond.assignmentDateType === 1) assignmentDate = moment(new Date(bond.assignmentDate2)).format(dateFormat);
      else if(bond.assignmentDateType === 2) assignmentDate = moment(new Date(bond.assignmentDate3)).format(dateFormat);

      contents = `소외 ${josajs.r(bond.assignmentName, '은/는')} ${realAssignmentDate} 원고에게 위 ${index + 1}.항 기재 채권을 `;

      if(bond.assignmentDateType === 0) {
        contents += `양도하고, 소외 ${josajs.r(bond.assignmentName, '이/가')} ${assignmentDate} 위 채권양도사실을 통지하여 그 무렵 피고에게 도달하였습니다.`;
      }
      else if(bond.assignmentDateType === 1) {
        contents += `양도하고, 원고가 ${assignmentDate} 소외 ${bond.assignmentName}의 위임을 받아 위 채권양도사실을 통지하여 그 무렵 피고에게 도달하였습니다.`;
      }
      else if(bond.assignmentDateType === 2) {
        contents += `양도하고, 피고가 ${assignmentDate} 위 채권양도를 이의를 보류하지 아니하고 승낙하였습니다.`;
      }
      else if(bond.assignmentDateType === 3) {
        contents += '양도하였으며, 이 사건 소정부본 송달로써 피고에게 위 채권양도사실을 통지합니다.';
      }

      if(documentType === '지급명령') {
        contents = contents.replace('원고', '채권자').replace('피고', '채무자');
      }
      textList.push(contents);
      claimCauseList.push(contents);
    })
  }

  let finalDidPaybackDateDate = '';
  let nextFinalDidPaybackDateDate = '';

  if(complaint.didPaybackDateList.length !== 0) {
    finalDidPaybackDateDate = complaint.didPaybackDateList.map(p => p.date).sort((p1, p2) => moment(p1).isAfter(p2) ? -1 : 11)[0];
    nextFinalDidPaybackDateDate = moment(new Date(finalDidPaybackDateDate)).add(1, 'days').format(dateFormat);
  }

  

  //연대보증인 내용
  contents = '';
  complaint.bondList.forEach((bond, index) => {
    bond.jointGuarantorList.forEach(jointGuarantor => {
      if(contents !== '') contents += '하였고, ';

      if(jointGuarantor.isLimit) {
        contents += `피고 ${getNameWithJosa(jointGuarantor.defendant, '은/는')} 위 ${index + 1}.항의 대여금 반환채무를 ${addComma(jointGuarantor.price)}원 한도 내에서 연대보증`;
      }
      else {
        contents += `피고 ${getNameWithJosa(jointGuarantor.defendant, '은/는')} 위 ${index + 1}.항의 대여금 반환채무를 전액 연대보증`;
      }

      if(complaintType === '약정금') {
        contents = contents.replace('대여금 반환채무', '약정금 지급채무');
      }

    });
  });

  if(contents !== '') contents += '하였습니다.';

  if(documentType === '지급명령') contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

  if(contents !== '') {
    textList.push(contents);
    claimCauseList.push(contents);
  }

  //일부변제 내용
  contents = '';
  complaint.didPaybackDateList.forEach(didPaybackDate => {

    if(contents !== '') contents += '하였고, ';
    
    if(defendantList.length === 1) {
      contents += `피고는 ${moment(new Date(didPaybackDate.date)).format(dateFormat)}에 원고에게 ${addComma(didPaybackDate.price)}원을 변제`;
    }
    else {
      contents += `피고 ${getNameWithJosa(didPaybackDate.defendant, '은/는')} ${moment(new Date(didPaybackDate.date)).format(dateFormat)}에 원고에게 ${addComma(didPaybackDate.price)}원을 변제`;
    }
  });

  if(documentType === '지급명령') contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

  if(contents !== '') contents = `한편, ${contents}하였습니다.`;

  if(contents !== '') {
    textList.push(contents);
    claimCauseList.push(contents);
  }

  if(complaint.didPaybackDateList.length !== 0) {
    if(defendantList.length === 1) {
      contents = '피고가 변제한 금원은 별지 표 기재와 같이 민법 제477조에 따라 법정변제충당되었습니다.';

      if(documentType === '지급명령') 
        contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

      claimCauseList.push(contents);

      if(complaintType === '약정금') {
        contents = '그러나 피고는 현재까지 나머지 약정원리금을 변제하지 아니하고 있습니다.';
      }
      else {
        contents = '그러나 피고는 현재까지 나머지 대여원리금을 변제하지 아니하고 있습니다.';
      }
      
      if(documentType === '지급명령') 
        contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

      claimCauseList.push(contents);
    }
    else {
      contents = '피고들이 변제한 금원은 별지 표 기재와 같이 민법 제477조에 따라 법정변제충당되었습니다.';
      
      if(documentType === '지급명령') 
        contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

      claimCauseList.push(contents);

      if(complaintType === '약정금') {
        contents = '그러나 피고들은 현재까지 나머지 약정원리금을 변제하지 아니하고 있습니다.';
      }
      else {
        contents = '그러나 피고들은 현재까지 나머지 대여원리금을 변제하지 아니하고 있습니다.';
      }
      
      if(documentType === '지급명령') 
        contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

      textList.push(contents);
      claimCauseList.push(contents);
    }
  }
  else {
    if(defendantList.length === 1) {
      contents = '그러나 피고는 현재까지 대여금을 변제하지 아니하고 있습니다.';
    }
    else {
      contents = '그러나 피고들은 현재까지 대여금을 변제하지 아니하고 있습니다.';
    }
    
    if(complaintType === '약정금') {
      contents = contents.replace('대여금', '약정금')
    }

    if(documentType === '지급명령') 
      contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

    textList.push(contents);
    claimCauseList.push(contents);
  }

  if(complaint.isShowComment && complaint.comment !== '') {
    claimCauseList.push(complaint.comment);
  }

  let claimCause = '';

  const bondCount = complaint.bondList.filter(bond => bond.balance !== 0).length;

  //결구 및 청구취지
  contents = '';
  complaint.bondList.filter(bond => bond.balance !== 0).forEach((bond, index) => {

    const I = bond.isInterestRate;
    const W = bond.willPaybackDate !== '';
    const D = bond.isDeferInterestRate;

    const price = addComma(bond.price);
    const balance = addComma(bond.balance);
    const totalBalance = addComma(bond.balance + bond.interestBalance + bond.deferInterestBalance);
    const lendDate = moment(new Date(bond.lendDate)).format(dateFormat);
    const willPaybackDate = moment(new Date(bond.willPaybackDate)).format(dateFormat);
    const nextWillPaybackDate = moment(new Date(bond.willPaybackDate)).add(1, 'days').format(dateFormat)
    const interestRate = bond.realInterestRate;
    const deferInterestRate = bond.realDeferInterestRate;
    const law = bond.law;

    let isS = true;
    let isX = true;
    let lawRate = 0;
    let textW = '';

    if(claimCause !== '') claimCause += '하여야 하고, '
    claimCause += bondCount >= 2 ? `${numberType[index]} ` : '';
    
    if(defendantList.length === 1) {
      contents += '피고는 ';
    }
    else {
      if(bond.jointGuarantorList.length === 0) {
        contents += `피고 ${getNameWithJosa(defendantList[0], '은/는')} `;
      }
      else {
        if(defendantList.length === bond.jointGuarantorList.length + 1) { //채무자 및 연대보증인과 피고 수가 같은 경우
          contents += '피고들은 연대하여 ';
        }
        else {
          let pigo = '';
          bond.jointGuarantorList.forEach((j, i) => {
            if(pigo !== '') pigo += ', ';
            pigo += bond.jointGuarantorList.length -1 === i ? getNameWithJosa(j.defendant, '은/는') : getName(j.defendant);
          });

          if(pigo !== '') pigo = `피고 ${getName(defendantList[0])}, ${pigo} 연대하여 `;
          
          contents += pigo;
        }
        
      }
    }

    contents += '원고에게 ';
    if(complaint.didPaybackDateList.length === 0) { //변제충당이 전혀 안된 경우
      
      contents += `대여원금 ${balance}원 및 이에 대한 `;

      if(I && W && D) {

        lawRate = deferInterestRate;
        textW = '약정지연손해금율인 ';

        contents += `대여시기인 ${lendDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, `;

        if(lawRate < 12) {
          contents += `그 다음날부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
          //isX = false;
        }
        else {
          contents += `그 다음날부터 `;
          isX = false;
        }
      }
      else if(I && W && !D) {

        contents += `대여시기인 ${lendDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터 `;

        contents += '이 사건 송달부본 송달일까지는 ';

        if(law === '민법' && interestRate < civilLawRate) {
          contents += `민법이 정한 연 ${civilLawRate}%의, `
          lawRate = civilLawRate;
          textW = '민법이 정한 ';
        }
        else if(law === '상법' && interestRate < commercialLawRate) {
          contents += `상법이 정한 연 ${commercialLawRate}%의, `
          lawRate = commercialLawRate;
          textW = '상법이 정한 ';
        }
        else {
          contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
          lawRate = interestRate;
          textW = '약정이율에 의한 지연손해금율인 ';
        }
        contents += `그 다음날부터 `;
      }
      else if(I && !W && D) {
        isS = false;

        contents += `대여시기인 ${lendDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터 `;

        lawRate = interestRate;
        textW = '약정이율인';

      }
      else if(I && !W && !D) {
        isS = false;

        contents += `대여시기인 ${lendDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터 `;

        lawRate = interestRate;
        textW = '약정이율인';
      }
      else if(!I && W && D) {
        if(law === '상법') {
          contents += `대여시기인 ${lendDate}부터 변제기인 ${willPaybackDate}까지는 상법이 정한 연 ${interestRate}%의, 그 다음날부터 변제기 다음날인 ${nextWillPaybackDate}부터 `;
        }
        else {
          contents += `변제기 다음날인 ${nextWillPaybackDate}부터 `
          isS = false;
        }

        lawRate = deferInterestRate;
        textW = '약정지연손해금율인 ';
        contents += `이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의 그 다음날부터 `
      }
      else if(!I && W && !D) {
        isS = false;

        if(law === '상법') {
          contents += `대여시기인 ${lendDate}부터 이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의 그 다음날부터 `;
          lawRate = commercialLawRate;
          textW = '상법이 정한 ';
        }
        else {
          contents += `변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 민법이 정한 연 ${civilLawRate}%의 그 다음날부터 `;
          lawRate = civilLawRate;
          textW = '민법이 정한 ';
        }

        
      }
      else if(!I && !W && D) {
        isS = false;

        if(law === '상법') {
          contents += `대여시기인 ${lendDate}부터 이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의 그 다음날부터 `;
          lawRate = commercialLawRate;
          textW = '상법이 정한 ';
        }
        else {
          isX = false;
          contents += '이 사건 소장부본 송달일 다음날부터 ';
        }
      }
      else if(!I && !W && !D) {
        isS = false;

        if(law === '상법') {
          contents += `대여시기인 ${lendDate}부터 이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의 그 다음날부터 `;
          lawRate = commercialLawRate;
          textW = '상법이 정한 ';
        }
        else {
          isX = false;
          contents += '이 사건 소장부본 송달일 다음날부터 ';
        }
      }
      if(complaintType === '약정금') {
        contents = contents.replace('대여원금', '약정금');
      }
    }
    else { //변제충당이 된 경우
      if(bond.interestBalance === 0 && bond.deferInterestBalance === 0) {//원금만 남은 경우

        if(bond.price === bond.balance) {
          contents += `대여원금 ${balance}원 및 이에 대한 `;
        }
        else {
          contents += `대여잔금 ${balance}원 및 이에 대한 `;
        }

        if(moment(new Date(finalDidPaybackDateDate)).isBefore(new Date(bond.willPaybackDate))) { //최종변제일이 변제기 이전인 경우

          finalDidPaybackDateDate = moment(new Date(finalDidPaybackDateDate)).format(dateFormat);
          if(I && W && D) {
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, 
            그 다음날부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
          }
          else if(I && W && !D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, 
            그 다음날부터 이 사건 소장부본 송달일까지는 `;

            if(law === '민법' && interestRate < civilLawRate) {
              contents += `민법이 정한 연 ${civilLawRate}%의, `
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            else if(law === '상법' && interestRate < commercialLawRate) {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
              lawRate = interestRate;
              textW = '약정이율에 의한 지연손해금율인 ';
            }
            contents += `그 다음날부터 `;

          }
          else if(I && !W && D) {
            isS = false;
            lawRate = interestRate;
            textW = '약정이율인 ';

            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터`; 
          }
          else if(I && !W && !D) {
            isS = false;
            lawRate = interestRate;
            textW = '약정이율인 ';

            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터`; 
          }
          else if(!I && W && D) {

            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';

            if(law === '상법') {
              contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 변제기인 ${willPaybackDate}까지는 연 ${commercialLawRate}%의, `;
            }
            else {
              isS = false;
              contents += `변제기 다음날인 ${nextWillPaybackDate}부터 `
            }

            contents += `이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
          }
          else if(!I && W && !D) {
            isS = false;
            if(law === '상법') {
              contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의, `;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `변제기 다음날인 ${nextWillPaybackDate}부터 이 사건 소장부본 송달일까지는 민법이 정한 연 ${civilLawRate}%의, `;
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            contents += '그 다음날부터 ';
          }
          else if(!I && !W && D) {
            isS = false;
            if(law === '상법') {
              contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 연 ${commercialLawRate}%의, 그 다음날부터 `;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += '이 사건 소장부본 송달일 다음날부터 ';
            }
          }
          else if(!I && !W && !D) {
            isS = false;
            if(law === '상법') {
              contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 연 ${commercialLawRate}%의, 그 다음날부터 `;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += '이 사건 소장부본 송달일 다음날부터 ';
            }
          }
        }
        else { //최종변제일이 변제기와 같거나 이후인 경우
          finalDidPaybackDateDate = moment(new Date(finalDidPaybackDateDate)).format(dateFormat);

          isS = false;

          if(I && W && D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(I && W && !D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는`;

            if(law === '민법' && interestRate < civilLawRate) {
              contents += `민법이 정한 연 ${civilLawRate}%의, `
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            else if(law === '상법' && interestRate < commercialLawRate) {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
              lawRate = interestRate;
              textW = '약정이율에 의한 지연손해금율인 ';
            }
            contents += `그 다음날부터 `;
          }
          else if(I && !W && D) {
            
          }
          else if(I && !W && !D) {
    
          }
          else if(!I && W && D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(!I && W && !D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 `;

            if(law === '상법') {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `;
              lawRate = commercialLawRate;
              textW = '상법이 정한';
            }
            else {
              contents += `민법이 정한 연 ${civilLawRate}%의, `;
              lawRate = civilLawRate;
              textW = '민법이 정한';
            }

            contents += '그 다음날부터 ';
          }
          else if(!I && !W && D) {
    
          }
          else if(!I && !W && !D) {
    
          }
        }
      }
      else if(bond.interestBalance !== 0 && bond.deferInterestBalance === 0){ //이자가 남고 지연손해금은 없음
        
        if(moment(new Date(finalDidPaybackDateDate)).isBefore(new Date(bond.willPaybackDate))) { //최종변제일이 변제기 이전인 경우
          contents += `대여원리금 합계인 ${totalBalance}원 및 그 중 대여원금 ${price}원에 대하여 `;
          if(I && W && D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, 
            그 다음날부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터`;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(I && W && !D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 변제기인 ${willPaybackDate}까지는 약정이율인 연 ${interestRate}%의, 
            그 다음날부터 이 사건 소장부본 송달일까지는 `;

            if(law === '민법' && interestRate < civilLawRate) {
              contents += `민법이 정한 연 ${civilLawRate}%의, `
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            else if(law === '상법' && interestRate < commercialLawRate) {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
              lawRate = interestRate;
              textW = '약정이율에 의한 지연손해금율인 ';
            }
            contents += `그 다음날부터 `;
          }
          else if(I && !W && D) {
            isS = false;

            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터`;
            lawRate = interestRate;
            textW = '약정이율인 ';
          }
          else if(I && !W && !D) {
            isS = false;

            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정이율인 연 ${interestRate}%의, 그 다음날부터`;
            lawRate = interestRate;
            textW = '약정이율인 ';
          }
          
          if(law === '상법') {
            contents = `원고에게 대여원리금 합계인 ${totalBalance}원 및 그 중 대여원금 ${price}원에 대하여 최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 `;
            if(!I && W && D) {
              contents += `변제기인 ${willPaybackDate}까지는 상법이 정한 연 ${commercialLawRate}%의, 변제기 다음날인 ${nextWillPaybackDate}부터 
              이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
              lawRate = deferInterestRate;
              textW = '약정지연손해금율인 ';
            }
            else if(!I && W && !D) {
              isS = false;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';

              contents += `이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의, 그 다음날부터 `;
            }
            else if(!I && !W && D) {
              isS = false;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';

              contents += `이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의, 그 다음날부터 `;
            }
            else if(!I && !W && !D) {
              isS = false;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';

              contents += `이 사건 소장부본 송달일까지는 상법이 정한 연 ${commercialLawRate}%의, 그 다음날부터 `;
            }
          }
        }
        else { //최종변제일이 변제기와 같거나 이후인 경우
          isS = false;
          contents += `대여원리금 합계인 ${totalBalance}원 및 그 중 대여원금 ${price}원에 대하여 `;
          if(I && W && D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(I && W && !D) {
            contents += `최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 `;

            if(law === '민법' && interestRate < civilLawRate) {
              contents += `민법이 정한 연 ${civilLawRate}%의, `
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            else if(law === '상법' && interestRate < commercialLawRate) {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
              lawRate = interestRate;
              textW = '약정이율에 의한 지연손해금율인 ';
            }
            contents += `그 다음날부터 `;
          }
          else if(I && !W && D) {
            contents = '';
          }
          else if(I && !W && !D) {
            contents = '';
          }
          
          if(law === '상법') {
            contents = `원고에게 대여원리금 합계인 ${totalBalance}원 및 그 중 대여원금 ${price}원에 대하여 최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 `;
            if(!I && W && D) {
              contents = `약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
              lawRate = deferInterestRate;
              textW = '약정지연손해금율인 ';
            }
            else if(!I && W && !D) {
              contents = `상법이 정한 연 ${commercialLawRate}%의, 그 다음날부터 `;
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else if(!I && !W && D) {
              contents = '';
            }
            else if(!I && !W && !D) {
              contents = '';
            }
          }
        }
      }
      else if(bond.interestBalance !== 0 && bond.deferInterestBalance !== 0) { //이자 남고 자연손해금도 남고
        if(moment(new Date(finalDidPaybackDateDate)).isBefore(new Date(bond.willPaybackDate))) { //최종변제일이 변제기 이전인 경우
          contents = '';
          // if(I && W && D) {

          // }
          // else if(I && W && !D) {
    
          // }
          // else if(I && !W && D) {
            
          // }
          // else if(I && !W && !D) {
    
          // }
          // else if(!I && W && D) {
    
          // }
          // else if(!I && W && !D) {
    
          // }
          // else if(!I && !W && D) {
    
          // }
          // else if(!I && !W && !D) {
    
          // }
        }
        else { //최종변제일이 변제기와 같거나 이후인 경우
          isS = false;
          contents = `원고에게 대여원리금 합계인 ${totalBalance}원 및 그 중 대여원금 ${price}원에 대하여 최종 변제일 다음날인 ${nextFinalDidPaybackDateDate}부터 이 사건 소장부본 송달일까지는 `;
          if(I && W && D) {
            contents += `약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(I && W && !D) {
            if(law === '민법' && interestRate < civilLawRate) {
              contents += `민법이 정한 연 ${civilLawRate}%의, `
              lawRate = civilLawRate;
              textW = '민법이 정한 ';
            }
            else if(law === '상법' && interestRate < commercialLawRate) {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `
              lawRate = commercialLawRate;
              textW = '상법이 정한 ';
            }
            else {
              contents += `약정이율에 의한 지연손해금율인 연 ${interestRate}%의, `
              lawRate = interestRate;
              textW = '약정이율에 의한 지연손해금율인 ';
            }
            contents += `그 다음날부터 `;
          }
          else if(I && !W && D) {
            contents = '';
          }
          else if(I && !W && !D) {
            contents = '';
          }
          else if(!I && W && D) {
            contents += `약정지연손해금율인 연 ${deferInterestRate}%의, 그 다음날부터 `;
            lawRate = deferInterestRate;
            textW = '약정지연손해금율인 ';
          }
          else if(!I && W && !D) {
            if(law === '상법') {
              contents += `상법이 정한 연 ${commercialLawRate}%의, `;
              lawRate = commercialLawRate;
              textW = '상법이 정한';
            }
            else {
              contents += `민법이 정한 연 ${civilLawRate}%의, `;
              lawRate = civilLawRate;
              textW = '민법이 정한';
            }

            contents += '그 다음날부터 ';
          }
          else if(!I && !W && D) {
            contents = '';
          }
          else if(!I && !W && !D) {
            contents = '';
          }
        }
      }
    }

    contents += '다 갚는 날까지';

    if(isS || isX) contents += '는 ';
    else contents += ' ';

    if(lawRate < 12) contents += '소송촉진 등에 관한 특례법이 정한 연 12%의 ';
    else contents += `${textW} 연${lawRate}%의 `;

    if(isS || isX) contents += '각 ';

    contents += '비율에 의한 금원을 지급';

    let guaranteeText = '';
    bond.jointGuarantorList.filter(j => j.isLimit).forEach(jointGuarantor => {
      if(guaranteeText !== '') guaranteeText += ', ';
      

      guaranteeText += `피고 ${getNameWithJosa(jointGuarantor.defendant, '은/는')} ${addComma(jointGuarantor.balance)}원`;
    })

    if(guaranteeText !== '') contents = `${contents}하되, ${guaranteeText}의 ${bond.jointGuarantorList.length !== 1 ? '각 ' : ''}한도 내에서 이를 지급`;

    if(complaint.type === '약정금') {
      contents = contents.replace('대여원금', '약정원금').replace('대여잔금', '약정잔금').replace('대여원리금', '약정원리금');
      contents = contents.replace('대여금 반환채무', '약정금 지급채무');
    }

    if(documentType === '지급명령') contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

    claimCause += contents;

    let c = contents;

    c = c.replace('대여시기인 ', '').replace('변제기 다음날인 ', '').replace('최종 변제일 다음날인 ', '').replace('변제기인 ', '').replace('대여원리금 합계인 ', '').replace('대여잔금 ', '').replace('대여원금 ', '');
    c = c.replace(/약정이율인 /gi, '').replace(/약정지연손해금율인 /gi, '').replace('약정이율에 의한 지연손해금율인 ', '').replace('상법이 정한 ', '').replace('민법이 정한 ', '');
    c = c.replace('소송촉진 등에 관한 특례법이 정한 ', '');
    c = c.replace('약정원금 ', '').replace('약정잔금 ', '');

    if(complaint.bondList.filter(b => b.balance !== 0).length !== 1) {
      if(bond.jointGuarantorList.filter(j => j.isLimit).length !== 0) { //연대보증인이 있으면
        c = c.replace('이를 지급', '이를, ');
      }
      else {
        c = c.replace(' 지급', ', ');
      }
      multipleClaimPurposeList.push(c);
    }
    else {
      c += '하라.';
      singleClaimPurposeList.push(c);
    }

    contents = '';

  });

  if(claimCause !== '') claimCause = `따라서, ${claimCause}하여야 합니다.`;

  textList.push(claimCause);
  claimCauseList.push(claimCause);

  if(defendantList.length === 1) {
    contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  else {
    contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  
  textList.push(contents);
  claimCauseList.push(contents);

  textList.push('======================')
  
  singleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose1List.push(t);
  })

  
  
  const showBondList = complaint.bondList.filter(b => b.balance !== 0);
  if(showBondList.length !== 1) {
    if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}는 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}는 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}는 ${wongo}에게 `, ''));
    }
    else if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}들은 연대하여 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}들은 연대하여 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}들은 연대하여 ${wongo}에게 `, ''));
    }
    else {
      claimPurposeHeader = `${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${wongo}에게 `, ''))
    }
  };

  if(claimPurposeHeader !== '') {
    textList.push(claimPurposeHeader);
  }

  multipleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose2List.push(t);
  });

  if(multipleClaimPurposeList.length !== 0) {
    textList.push('각 지급하라.');
  }

  contents = '';
  if(documentType === '소장') {
    if(defendantList.length === 1) {
      contents = `소송비용은 ${pigo}가 부담한다.`;
      //textList.push(`소송비용은 ${pigo}가 부담한다.`);
    }
    else {
      contents = `소송비용은 ${pigo}들이 부담한다.`;
    }

    textList.push(contents);
    claimPurpose1List.push(contents);
    
    textList.push('제1항은 가집행할 수 있다.')
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
  }
  else {
    if(defendantList.length === 1) {
      contents = `독촉절차비용은 ${pigo}가 부담한다.`;
    }
    else {
      contents = `독촉절차비용은 ${pigo}들이 부담한다.`;
    }

    textList.push(contents);
    claimPurpose1List.push(contents);
    
  }
  

  textList.push('라는 판결을 구합니다.')
  
  complaint.claimPrice = complaint.bondList.map(b => b.balance).reduce((a, c) => a + c, 0);
  
  //인지대, 송달료 계산
  complaint.deliveryCharge1 = 0;
  complaint.deliveryCharge2 = (1 + defendantList.length) * deliveryCharge * 6;

  if(complaint.claimPrice <= 30000000) {
    complaint.deliveryCharge1 = (1 + defendantList.length) * deliveryCharge * 10;
  }
  else {
    complaint.deliveryCharge1 = (1 + defendantList.length) * deliveryCharge * 15;
  }

  if(complaint.claimPrice < 10000000) {
    complaint.recognition1 = complaint.claimPrice * 50 / 10000
  }
  else if(10000000 <= complaint.claimPrice && complaint.claimPrice < 100000000) {
    complaint.recognition1 = complaint.claimPrice * 45 / 10000 + 5000
  }
  else if(10000000 <= complaint.claimPrice && complaint.claimPrice < 1000000000) {
    complaint.recognition1 = complaint.claimPrice * 40 / 10000 + 55000
  }
  else {
    complaint.recognition1 = complaint.claimPrice * 35 / 10000 + 555000
  }

  complaint.recognition2 = parseInt(complaint.recognition1 / 10)
  
  complaint.recognition1 = parseInt(complaint.recognition1);
  
  if(complaint.recognition1 < 1000) {
    complaint.recognition1 = 1000;
  }
  else {
    complaint.recognition1 = (parseInt(complaint.recognition1 / 100)) * 100;
  }

  if(complaint.recognition2 < 1000) {
    complaint.recognition2 = 1000;
  }
  else {
    complaint.recognition2 = (parseInt(complaint.recognition2 / 100)) * 100;
  }

  if(documentType === '소장') {
    textList.push(`인지대: ${addComma(complaint.recognition1)}, 송달료: ${addComma(complaint.deliveryCharge1)}`);
  }
  else {
    textList.push(`청구금액: ${addComma(complaint.claimPrice)}`);
    textList.push(`독촉절차비용: ${addComma(complaint.deliveryCharge2 + complaint.recognition2)} (인지대: ${addComma(complaint.recognition2)}, 송달료: ${addComma(complaint.deliveryCharge2)})`);
  }

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: claimPurpose2List
  }
  return {textList, claimCauseList, claimPurpose};
}

const getTradingServiceText = (complaint, defendantList, textList) => {

  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const documentType = complaint.documentType;
  const virtualBondList = complaint.virtualBondList;

  let didPaybackDateCount = 0;
  didPaybackDateCount += complaint.didPaybackDateList.filter(d => d.isChecked).length;
  didPaybackDateCount += complaint.bondList.map(b => b.didPaybackDateList.filter(d => d.isChecked).length).reduce((a, c) => a + c, 0);

  const bondCount = virtualBondList.filter(b => b.balance !== 0).length;

  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';

  /**
   * 총 변제충당금, 최종변제일, 최종변제일 다음날
   */
   let totalPaybackPrice = complaint.didPaybackDateList.map(p => p.price).reduce((a, c) => a + c, 0);
   const latestPaybackDateArray = complaint.didPaybackDateList.filter(d => d.isChecked).map(p => p).sort((a, b) => a.date > b.date ? -1 : a.date < b.date ? 1 : 0).map(p => p.date).slice(0, 1);
   let latestPaybackDate = latestPaybackDateArray.length !== 0 ? latestPaybackDateArray[0] : '' //최종변제일
   let nextLatestPaybackDate = latestPaybackDate !== '' ? moment(latestPaybackDate).add(1, 'd').format('YYYY-MM-DD') : ''; //최종변제일 다음날

  complaint.bondList.forEach((bond, i) => {

    var causeOfAction = '원고는 ';

    causeOfAction += `${moment(bond.tradingPriceContractDate).format(dateFormat)} `;
    causeOfAction += `${defendantList.length === 1 ? '피고에게 ' : `피고 ${getName(defendantList[0])}에게 `}`

    //인도약정일에 따른 문구
    if(bond.willDeliveryDateType === 'none') {
      if(complaint.tradingPriceType === '동산') {
        causeOfAction += bond.stuffList.map((stuff, index) => `${stuff.name} ${stuff.count}${stuff.unit}${bond.stuffList.length - 1 === index ? getNameWithJosa(stuff.name, '을/를') : ''}`).join(', ');
      }
      else {
        let realEstateArray = bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`);
        realEstateArray = realEstateArray.concat(bond.buildingList.map((building, index) => `${building.address} ${building.detail === '' ? '지상건물' : building.detail}`))
        //causeOfAction += bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`).join(', ');
        //causeOfAction += bond.buildingList.map((building, index) => `${building.address} ${building.detail}`).join(', ');
        causeOfAction += realEstateArray.join(', ');
        causeOfAction += `(이하 '이 사건 부동산'이라 합니다)에 관하여 `;
      }
    }
    else if(bond.willDeliveryDateType === 'fix') {
      if(complaint.tradingPriceType === '동산') {
        causeOfAction += bond.stuffList.map(stuff => `${stuff.name} ${stuff.count}${josajs.r(stuff.unit, '을/를')} ${moment(bond.willDeliveryDate).format(dateFormat)}에`).join(', ');
      }
      else {
        let realEstateArray = bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`);
        realEstateArray = realEstateArray.concat(bond.buildingList.map((building, index) => `${building.address} ${building.detail === '' ? '지상건물' : building.detail}`))
        //causeOfAction += bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`).join(', ');
        //causeOfAction += bond.buildingList.map((building, index) => `${building.address} ${building.detail}`).join(', ');
        causeOfAction += realEstateArray.join(', ');
        causeOfAction += `(이하 '이 사건 부동산'이라 합니다)에 관하여 `;
      }

      if(bond.stuffList.length >= 2) {
        causeOfAction += ' 각 인도하기로 정하고, ';
      }
      else {
        causeOfAction += ' 인도하기로 정하고, ';
      }
    }
    else {
      causeOfAction += bond.willDeliveryDateList.map(d => `${d.stuff.name} ${d.stuff.count}${josajs.r(d.stuff.unit, '을/를')} ${moment(d.date).format(dateFormat)}에`).join(', ');
      causeOfAction += ' 각 인도하기로 정하고, ';
    }

    if(complaint.tradingPriceType === '부동산') {
      causeOfAction = causeOfAction.replace('인도', `${moment(bond.willDeliveryDate).format(dateFormat)}에 소유권이전등기`);
    }

    causeOfAction += ` 매매대금 ${addComma(bond.tradingPrice)}원`

    if(bond.willPaybackDateType === 'none') {
      causeOfAction += `으로 정하고, `;

      if(bond.isDeferInterestRate) {
        causeOfAction += `지연손해금율 연 ${bond.deferInterestRate}%로 정하고, 변제기의 정함이 없이 매도하기로 약정하였습니다.`;
      }
      else {
        causeOfAction += `변제기, 지연손해금율의 정함이 없이 매도하기로 약정하였습니다.`;
      }
    }
    else if(bond.willPaybackDateType === 'fix') {
      causeOfAction += `으로 정하고, 변제기 ${moment(bond.willPaybackDate).format(dateFormat)}로 정하고, `;

      if(bond.isDeferInterestRate) {
        causeOfAction += `지연손해금율 연 ${bond.deferInterestRate}%로 정하여 매도하기로 약정하였습니다.`;
      }
      else {
        causeOfAction += `지연손해금율의 정함이 없이 매도하기로 약정하였습니다.`;
      }
    }
    else {
      causeOfAction += ' 중 ';
      causeOfAction += bond.willPaybackDateList.map((d, index) => `${d.unit} ${addComma(d.price)}원은 ${moment(d.date).format(dateFormat)}에`).join(', ');

      causeOfAction += ' 각 지급하기로 정하고, ';

      if(bond.isDeferInterestRate) {
        causeOfAction += `지연손해금율 연 ${bond.deferInterestRate}%로 정하여 매도하기로 약정하였습니다.`;
      }
      else {
        causeOfAction += `지연손해금율의 정함이 없이 매도하기로 약정하였습니다.`;
      }
    }

    if(complaint.documentType === '지급명령') {
      causeOfAction = causeOfAction.replace('원고', '채권자').replace('피고', '채무자');
    }

    claimCauseList.push(causeOfAction);
    textList.push(causeOfAction);
  });

  let contents = '';
  //연대보증 문장
  complaint.bondList.forEach((bond, index) => {
    bond.jointGuarantorList.forEach(jointGuarantor => {
      if(contents !== '') contents += '하였고, ';

      if(defendantList.length === 1) {
        contents += '피고는 ';
      }
      else {
        contents += `피고 ${getNameWithJosa(jointGuarantor.defendant, '은/는')} `;
      }

      if(jointGuarantor.isLimit) {
        contents += `위 ${index + 1}.항의 매매대금 반환채무를 ${addComma(jointGuarantor.price)}원 한도 내에서 연대보증`;
      }
      else {
        contents += `위 ${index + 1}.항의 매매대금 반환채무를 전액 연대보증`;
      }

    });
  });

  if(contents !== '') contents += '하였습니다.';

  if(documentType === '지급명령') contents = contents.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');

  if(contents !== '') {
    claimCauseList.push(contents);
    textList.push(contents);
  }


  //물건 인도 문장
  contents = '';

  const realEstateCount = complaint.bondList.map(b => b.landList.length + b.buildingList.length).reduce((a, c) => a + c, 0);

  if(complaint.tradingPriceType === '동산') {
    
    const deliveryCount = complaint.bondList.filter(b => b.didDeliveryDateType === 'all').length;

    if(deliveryCount === 1) {
      const bond = complaint.bondList.filter(b => b.didDeliveryDateType === 'all')[0];
      const stuffs = bond.stuffList.map(stuff => `${stuff.name} ${stuff.count}${stuff.unit}`).join(', ');
      if(stuffs !== '') {
        contents += `${moment(bond.didDeliveryDate).format(dateFormat)} ${defendantList.length === 1 ? '피고에게 ' : `피고 ${getName(defendantList[0])}에게 `} ${stuffs}${josajs.c(bond.stuffList[bond.stuffList.length - 1].unit, '을/를')} 모두 인도하였습니다.`
      }
    }
    else if(deliveryCount > 1) {
      complaint.bondList.filter(b => b.didDeliveryDateType === 'all').forEach(bond => {
        const stuffs = bond.stuffList.map(stuff => `${stuff.name} ${stuff.count}${stuff.unit}`).join(', ');
        if(contents !== '') contents += ', ';
  
        if(stuffs !== '') {
          contents += `${moment(bond.didDeliveryDate).format(dateFormat)} ${stuffs}${josajs.c(bond.stuffList[bond.stuffList.length - 1].unit, '을/를')}`
        }
      });
      if(contents !== '') {
        contents = `${`${defendantList.length === 1 ? '피고에게 ' : `피고 ${getName(defendantList[0])}에게 `}`} ${contents} 모두 인도하였습니다.`;
      }
    }

    if(contents !== '') {
      contents = `원고는 ${contents}`;
      if(complaint.documentType === '지급명령') {
        contents = contents.replace('원고', '채권자').replace('피고', '채무자');
      }
      claimCauseList.push(contents);
      textList.push(contents);
    }
  }
  else {
    complaint.bondList.filter(b => b.didDeliveryDateType === 'all').forEach(bond => {
      let realEstateArray = bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`);
      realEstateArray = realEstateArray.concat(bond.buildingList.map((building, index) => `${building.address} ${building.detail === '' ? '지상건물' : building.detail}`))
      //const lands = realEstateArray.join(', ');
      const lands = '이 사건 부동산';
      if(contents !== '') contents += ', ';

      if(lands !== '') {
        contents += `${moment(bond.didDeliveryDate).format(dateFormat)} ${lands}에 관하여 `;
      }
    })

    if(contents !== '') {
      contents += `${realEstateCount > 1 ? '모두 ' : ''}소유권이전등기하였`;

      if(complaint.bondList.filter(b => b.didDeliveryDateType === 'part').length !== 0) {
        contents += '고, ';
      }
      else {
        contents += '습니다.';
      }
    }

    complaint.bondList.filter(b => b.didDeliveryDateType === 'part').forEach(bond => {
      let realEstateArray = bond.landList.map((land, index) => `${land.address} ${land.category} ${land.area}m²`);
      realEstateArray = realEstateArray.concat(bond.buildingList.map((building, index) => `${building.address} ${building.detail === '' ? '지상건물' : building.detail}`))
      //const lands = realEstateArray.join(', ');
      const lands = '이 사건 부동산';
      if(contents !== '') contents += ', ';

      if(lands !== '') {
        contents += `${moment(bond.didDeliveryDate).format(dateFormat)} ${lands}에 관하여 `;
      }
    });

    if(complaint.bondList.filter(b => b.didDeliveryDateType === 'part').length !== 0) {
      contents += '소유권이전등기에 필요한 서류를 계속 제공하고 있습니다.';
    }

    if(contents !== 0) {
      contents = `원고는 ${`${defendantList.length === 1 ? '피고에게 ' : `피고 ${getName(defendantList[0])}에게 `}`} ${contents}`;
      if(complaint.documentType === '지급명령') {
        contents = contents.replace('원고', '채권자').replace('피고', '채무자');
      }
      claimCauseList.push(contents);
      textList.push(contents);
    }

    

  }

  
  

  //일부변제 문장
  contents = '';
  if(defendantList.length === 1) {
    complaint.bondList.forEach((bond, index) => {
      const temp = bond.didPaybackDateList.filter(d => d.isChecked).map(d => d.unit).join(', ');
      
      if(bond.didPaybackDateList.filter(d => d.isChecked).map(d => d.unit).join(', ') !== '') {
        if(contents !== '') {
          contents += `하였고, 위 ${index + 1}.항의 ${temp}을 지급`;
        }
        else {
          contents += `위 ${index + 1}.항의 ${temp}을 지급`;
        }
      }
    });

    
    complaint.didPaybackDateList.filter(d => d.isChecked).forEach(didPaybackDate => {
      if(contents !== '') {
        contents += `하였고, ${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
      }
      else {
        contents += `${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
      }
    });
  
    if(contents !== '') contents = `피고는 ${contents}하였습니다.`;
  }
  else { //피고가 2명 이상
    defendantList.forEach(defendant => {
      let a = '';
      complaint.bondList.forEach((bond, index) => {
        const temp = bond.didPaybackDateList.filter(d => d.isChecked && d.defendant?.id === defendant.id).map(d => d.unit).join(', ');
        if(temp !== '') {
          if(a !== '') {
            a += `하였고, 위 ${index + 1}.항의 ${temp}을 지급`;
          }
          else {
            a += `위 ${index + 1}.항의 ${temp}을 지급`;
          }
        }
      });

      complaint.didPaybackDateList.filter(d => d.isChecked && d.defendant?.id === defendant.id).forEach(didPaybackDate => {
        if(a !== '') {
          a += `하였고, ${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
        }
        else {
          a += `${moment(didPaybackDate.date).format(dateFormat)}에 ${addComma(didPaybackDate.price)}원을 변제`;
        }
      });
      if(a !== '') {
        if(contents !== '') {
          contents += `하였고, 피고 ${getNameWithJosa(defendant, '은/는')} ${a}`;
        }
        else {
          contents += `피고 ${getNameWithJosa(defendant, '은/는')} ${a}`;
        }
      }
    });

    if(contents !== '') {
      if(complaint.documentType === '지급명령') {
        contents = contents.replace('원고', '채권자').replace('피고', '채무자');
      }
      contents = `한편, ${contents}`;
      contents += '하였습니다.';
    }
  }

  if(contents !== '') {
    claimCauseList.push(contents);
    textList.push(contents);
  }

  contents = '';
  if(complaint.didPaybackDateList.map(p => p.isChecked).includes(true)) {
    let defendantNameArray = complaint.didPaybackDateList.filter(p => p.isChecked).map(p => getName(p.defendant));
    defendantNameArray = [...new Set(defendantNameArray)]
    contents = '';
    if(defendantList.length === 1) {
      contents = `피고가 변제한 금원은 별지 표 기재와 같이 민법 제477조에 따라 법정변제충당되었습니다.`;
    }
    else {
      if(defendantList.length === defendantNameArray.length) {
        contents = `피고들이 변제한 금원은 별지 표 기재와 같이 민법 제477조에 따라 법정변제충당되었습니다.`;
      }
      else {
        contents = `피고 ${defendantNameArray.join(',') + josajs.c(defendantNameArray[defendantNameArray.length - 1], '이/가')} 변제한 금원은 별지 표 기재와 같이 민법 제477조에 따라 법정변제충당되었습니다.`
      }
    }
  
    claimCauseList.push(contents);
    textList.push(contents);
  }

  contents = '';
  if(defendantList.length === 1) {
    contents = `그러나 피고는 현재까지 매매대금을 지급하지 아니하고 있습니다.`;
  }
  else {
    if(complaint.bondList.map(b => b.jointGuarantorList.length).reduce((a, c) => a + c, 0) !== 0) {
      contents = `그러나 피고들은 현재까지 매매대금을 지급하지 아니하고 있습니다.`;
    }
    else {
      contents = `그러나 피고 ${getNameWithJosa(defendantList[0], '은/는')} 현재까지 매매대금을 지급하지 아니하고 있습니다.`;
    } 
  }

  if(didPaybackDateCount !== 0) contents = contents.replace('매매대금', '나머지 매매대금');

  if(complaint.documentType === '지급명령') {
    contents = contents.replace('원고', '채권자').replace('피고', '채무자');
  }

  claimCauseList.push(contents);
  textList.push(contents);

  if(complaint.isShowComment && complaint.comment !== '') {
    claimCauseList.push(complaint.comment);
  }

  //결구
  let singleClaimPurposeList = [];
  let multipleClaimPurposeList = [];

  contents = '';
  virtualBondList.filter(b => b.balance !== 0).forEach((bond, index) => {

    if(contents !== '') contents += '하여야 하고, ';
    contents += bondCount >= 2 ? `${numberType[index]} ` : '';

    let claimPurpose = `${bond.pigo}원고에게 `;

    if(!bond.isPayment) { //일부변제가 없는 경우 (기본형)
      claimPurpose += getClaimPurpose(
        bond.tradingPricePaybackIndex !== -1, 
        bond.willPaybackDate !== '',
        bond.isDeferInterestRate,
        bond.didDeliveryDate !== '',
        bond.willDeliveryDate !== '', 
        bond,
        false
      )
    }
    else {
      if(bond.price <= bond.balance) { //지손금 영향
        claimPurpose += getClaimPurpose1(
          bond.tradingPricePaybackIndex !== -1, 
          bond.willPaybackDate !== '',
          bond.isDeferInterestRate,
          bond.didDeliveryDate !== '',
          bond.willDeliveryDate !== '', 
          bond,
          latestPaybackDate
        )
      }
      else {
        const status = checkBeforeAfterAffectPrincipal(
          bond.tradingPricePaybackIndex !== -1, 
          bond.willPaybackDate !== '',
          bond.isDeferInterestRate,
          bond.didDeliveryDate !== '',
          bond.willDeliveryDate !== '', 
          bond,
          latestPaybackDate
        )

        if(status === 'before') {
          claimPurpose += getClaimPurpose(
            bond.tradingPricePaybackIndex !== -1, 
            bond.willPaybackDate !== '',
            bond.isDeferInterestRate,
            bond.didDeliveryDate !== '',
            bond.willDeliveryDate !== '', 
            bond,
            true
          )
        }
        else {
          claimPurpose += getClaimPurpose3(
            bond.tradingPricePaybackIndex !== -1, 
            bond.willPaybackDate !== '',
            bond.isDeferInterestRate,
            bond.didDeliveryDate !== '',
            bond.willDeliveryDate !== '', 
            bond,
            latestPaybackDate
          )
        }
      }
    }

    if(complaint.tradingPriceType === '부동산') {
      claimPurpose = claimPurpose.replace(/인도약정일/gi, '소유권이전등기 약정일');
      claimPurpose = claimPurpose.replace(/인도일/gi, '소유권이전등기의무 이행일');
    }
 
    contents += `${claimPurpose}`;

    let c = claimPurpose;

    c = c.replace('계약금 ', '').replace('잔금 ', '').replace('매매대금 및 지연손해금 합계인 ', '').replace('매매대금 ', '').replace('나머지 ', '');
    
    if(bond.unit !== '') c = c.replace(`${bond.unit} `, '');

    c = c.replace('변제기 다음날인 ', '').replace('인도약정일인 ', '').replace('인도일인 ', '').replace('최종 변제일 다음날인 ', '');

    c = c.replace('소유권이전등기 약정일 ', '').replace('소유권이전등기의무 이행일 ', '');

    c = c.replace('소송촉진 등에 관한 특례법이 정한 ', '');
    c = c.replace(/약정지연손해금율인 /gi, '').replace(/민법이 정한 /gi, '').replace(/상법이 정한 /gi, '');

    if(virtualBondList.filter(b => b.balance !== 0).length !== 1) {
      
      c = c.replace(' 지급', ',');

      multipleClaimPurposeList.push(c);
      //state.contents.claimPurpose.secondList.push(c);
    }
    else {

      if(complaint.documentType === '지급명령') {
        c = c.replace('원고', '채권자').replace('피고', '채무자');
      }

      c += '하라.'
      singleClaimPurposeList.push(c);
    }
  });


  if(contents !== '') {
    contents = `따라서, ${contents}하여야 합니다.`;
  }

  claimCauseList.push(contents);
  textList.push(contents);  

  if(defendantList.length === 1) {
    contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  else {
    contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  
  claimCauseList.push(contents);
  textList.push(contents)

  textList.push('======================')
  
  singleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose1List.push(t);
  })

  claimPurposeHeader = '';
  
  const showBondList = virtualBondList.filter(b => b.balance !== 0);
  if(showBondList.length !== 1) {
    if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}는 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}는 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}는 ${wongo}에게 `, ''));
    }
    else if(showBondList.length === multipleClaimPurposeList.filter(c => c.includes(`${pigo}들은 연대하여 ${wongo}에게`)).length) {
      claimPurposeHeader = `${pigo}들은 연대하여 ${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${pigo}들은 연대하여 ${wongo}에게 `, ''));
    }
    else {
      claimPurposeHeader = `${wongo}에게,`;
      multipleClaimPurposeList = multipleClaimPurposeList.map(c => c.replace(`${wongo}에게 `, ''))
    }
  };

  if(claimPurposeHeader !== '') textList.push(claimPurposeHeader);
  multipleClaimPurposeList.forEach(t => {
    textList.push(t);
    claimPurpose2List.push(t);
  });

  if(multipleClaimPurposeList.length !== 0) {
    textList.push('각 지급하라.')
  }

  if(documentType === '소장') {
    claimPurpose1List.push(`소송비용은 ${pigo}${defendantList.length === 1 ? '가' : '들이'} 부담한다.`);
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
  }
  else {
    claimPurpose1List.push(`독촉절차비용은 ${pigo}${defendantList.length === 1 ? '가' : '들이'} 부담한다.`);
  }

  textList.push('라는 판결을 구합니다.')
  

  if(documentType === '소장') {
    textList.push(`인지대: ${addComma(complaint.recognition1)}, 송달료: ${addComma(complaint.deliveryCharge1)}`);
  }
  else {
    textList.push(`청구금액: ${addComma(complaint.claimPrice)}`);
    textList.push(`독촉절차비용: ${addComma(complaint.deliveryCharge2 + complaint.recognition2)} (인지대: ${addComma(complaint.recognition2)}, 송달료: ${addComma(complaint.deliveryCharge2)})`);
  }

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: claimPurpose2List
  }
  return {textList, claimCauseList, claimPurpose};
}

const wage = (complaint, defendantList, textList) => {

  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const documentType = complaint.documentType;

  const bond = complaint.bondList[0];

  const pigo = complaint.documentType === '소장' ? '피고' : '채무자';
  const wongo = complaint.documentType === '소장' ? '원고' : '채권자';

  const price = addComma(bond.price);
  const joinDate = moment(bond.joinDate).format(dateFormat);
  const leaveDate = moment(bond.leaveDate).format(dateFormat);

  let contents = '';


  contents = `${wongo}는 ${joinDate} ${pigo}가 운영하는 사업장에 입사하여 `;
  if(bond.isLeave) {
    contents += `근무하다 ${leaveDate} 퇴직하였습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    contents = `그런데 ${pigo}는 ${wongo}의 퇴직에도 불구하고 현재까지 임금 합계 ${price}원을 지급하지 아니하고 있습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    if(complaint.isShowComment && complaint.comment !== '') {
      claimCauseList.push(complaint.comment);
    }

    contents = `따라서, ${pigo}는 ${wongo}에게 미지급 임금 합계 ${price}원 및 이에 대한 ${wongo}가 퇴사한 날 로부터 14일이 지난 다음 날인 
    ${moment(bond.leaveDate).add(15, 'days').format(dateFormat)}부터 다 갚는 날까지 근로기준법이 정한 연 20%의 비율에 의한 지연손해금을 지급할 의무가 있습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    contents = '';
    if(complaint.documentType === '지급명령') {
      contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 지급명령신청을 하기에 이르렀습니다.`;
      
    }
    else {
      contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 소를 제기하기에 이르렀습니다.`;
    }
    claimCauseList.push(contents);

    textList.push('===========================');

    contents = `${pigo}는 ${wongo}에게 ${price}원 및 이에 대한 ${moment(bond.leaveDate).add(15, 'days').format(dateFormat)}부터 다 갚는 날까지 연 20%의 비율에 의한 금원을 지급하라.`;
    textList.push(contents);
    claimPurpose1List.push(contents);
  }
  else {
    contents += `현재까지 근무하고 있습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    contents = `그런데 ${pigo}는 임금 합계 ${price}원을 지급하지 아니하고 있습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    contents = `따라서, ${pigo}는 ${wongo}에게 미지급 임금 합계 ${price}원 및 이에 대한 이 사건 소장부본 송달일 다음날부터 
    다 갚는 날까지 소송촉진 등에 관한 특례법이 정한 연 12%의 비율에 의한 지연손해금을 지급할 의무가 있습니다.`;
    textList.push(contents);
    claimCauseList.push(contents);

    contents = '';
    if(complaint.documentType === '지급명령') {
      contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 지급명령신청을 하기에 이르렀습니다.`;
    }
    else {
      contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 소를 제기하기에 이르렀습니다.`;
    }
    claimCauseList.push(contents);

    textList.push('===========================');

    contents = `${pigo}는 ${wongo}에게 ${price}원 및 이에 대한 이 사건 소장부본 송달일 다음날부터 다 갚는 날까지 연 12%의 비율에 의한 금원을 지급하라.`;
    textList.push(contents);
    claimPurpose1List.push(contents);
  }

  contents = '';
  if(documentType === '소장') {
    if(defendantList.length === 1) {
      contents = `소송비용은 ${pigo}가 부담한다.`;
    }
    else {
      contents = `소송비용은 ${pigo}들이 부담한다.`;
    }

    textList.push(contents);
    claimPurpose1List.push(contents);
    
    textList.push('제1항은 가집행할 수 있다.')
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
  }
  else {
    if(defendantList.length === 1) {
      contents = `독촉절차비용은 ${pigo}가 부담한다.`;
    }
    else {
      contents = `독촉절차비용은 ${pigo}들이 부담한다.`;
    }

    textList.push(contents);
    claimPurpose1List.push(contents);
    
  }

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: []
  }
  return {textList, claimCauseList, claimPurpose};
}

const deposit = (complaint, defendantList, textList) => {

  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const defendantCount = defendantList.length;

  let pigo = '피고';

  if(complaint.bondList[0].isChangedMaster) {
    pigo = '';
    if(complaint.bondList[0].changedMasterName !== '')
      pigo = complaint.bondList[0].changedMasterName;
  }
  else {
    if(defendantList.length !== 1) {
      pigo = '피고들'
    }
  }
  
  let contents = '';

  complaint.bondList.forEach(bond => {

    const depositPrice = bond.depositPriceType === 'fix' ? bond.depositPrice : bond.prevDepositPrice;

    let contractFinishDate = moment(bond.contractDateType === 'fix' ? bond.endContractDate : bond.delayFinalContractDate).format(dateFormat);
    if(bond.contractExitReason === 'midway') {
      contractFinishDate = moment(bond.cancelContractDate).format(dateFormat);
    }
    
    const didDeliveryDate = moment(bond.didDeliveryDate).format(dateFormat);
    let nextContractFinishDate = moment(contractFinishDate).add(1, 'days').format(dateFormat);

    if(nextContractFinishDate < didDeliveryDate) {
      nextContractFinishDate = didDeliveryDate;
    }


    contents = `원고는 ${moment(bond.tradingPriceContractDate).format(dateFormat)} `;

    contents += josa(`${pigo}${pigo !== '' ? '#{과}' : 'OOO과'} `);

    const landText = bond.landList.map((land, index) => `${land.address} ${land.category} ${addComma(land.area)}m²`).join(', ');
    let buildingText = bond.buildingList.map((building, index) => `${building.address} ${building.detail === '' ? '지상 건물' : building.detail}`).join(', ');

    if(landText === '') {
      if(buildingText !== '') {
        contents += `${buildingText}(이하 ‘이 사건 부동산’이라 합니다)에 관하여 `;
      }
    }
    else {
      contents += `${landText}`;
      if(buildingText !== '') {
        contents += `, ${buildingText}(이하 ‘이 사건 부동산’이라 합니다)에 관하여 `;
      }
      else {
        contents += '(이하 ‘이 사건 부동산’이라 합니다)에 관하여 ';
      }
    }

    contents += `임대차보증금 ${addComma(depositPrice)}원으로 정하고, `;

    const isRentType = (bond.rentType === 'month' || bond.rentType === 'year');
    const isContractDateType = bond.contractDateType === 'fix' || bond.contractDateType === 'delay';

    if(isRentType) {
      if(bond.rentType === 'month') {
        contents += `월 임대료 ${addComma(bond.monthRentPrice)}원으로 정하고, `;
      }
      else if(bond.rentType === 'year') {
        contents += `연 임대료 ${addComma(bond.yearRentPrice)}원으로 정하고, `;
      }

      if(bond.contractDateType === 'fix') {
        contents += `임대차계약기간 ${moment(bond.startContractDate).format(dateFormat)}부터 ${moment(bond.endContractDate).format(dateFormat)}까지로 정하여 `;
      }
      else if(bond.contractDateType === 'delay') {
        contents += `임대차계약기간 ${moment(bond.delayStartContractDate).format(dateFormat)}부터 ${moment(bond.delayEndContractDate).format(dateFormat)}까지로 정하여 `;
      }
      else {
        contents += '임대차계약기간의 정함이 없이 ';
      }
    }
    else {
      if(bond.contractDateType === 'fix') {
        contents += `임대차계약기간 ${moment(bond.startContractDate).format(dateFormat)}부터 ${moment(bond.endContractDate).format(dateFormat)}까지로 정하고, 임대료의 정함이 없이 `;
      }
      else if(bond.contractDateType === 'delay') {
        contents += `임대차계약기간 ${moment(bond.delayStartContractDate).format(dateFormat)}부터 ${moment(bond.delayEndContractDate).format(dateFormat)}까지로 정하여, 임대료의 정함이 없이 `;
      }
      else {
        contents += '임대료, 임대차계약기간의 정함이 없이 ';
      }
    }

    contents += '임대차계약(이하 ‘이 사건 임대차계약’이라 합니다)을 체결하였습니다.';
    
    textList.push(contents);
    claimCauseList.push(contents);

    contents = '';

    if(bond.contractDateType === 'delay') {
      contents = `위 임대차계약기간은 ${moment(bond.delayFinalContractDate).format(dateFormat)}까지로 갱신`;
    }

    if(bond.depositPriceType === 'increase') {
      if(contents === '') {
        contents += `위 임대차보증금은 ${addComma(bond.nextDepositPrice)}원으로 증액`;
      }
      else  {
        contents += `되었고, 위 임대차보증금은 ${addComma(bond.nextDepositPrice)}원으로 증액`;
      }
    }

    if(bond.isChangedMaster) {
      if(contents === '') {
        contents += `${josajs.r(defendantCount === 1 ? '피고' : '피고들', '은/는')} ${moment(bond.changedMasterDate).format(dateFormat)} `;
      }
      else {
        contents += `되었고, ${josajs.r(defendantCount === 1 ? '피고' : '피고들', '은/는')} ${moment(bond.changedMasterDate).format(dateFormat)} `;
      }

      contents += '이 사건 임대차계약의 임대인 지위를 승계하였습니다.';
    }
    else {
      if(contents !== '') {
        contents += '되었습니다.';
      }
    }

    if(contents !== '') {
      textList.push(contents);
      claimCauseList.push(contents);
    }

    contents = `원고는 ${moment(bond.didDepositDate).format(dateFormat)} ${pigo}에게 임대차보증금을 모두 지급하였습니다.`;

    if(contents !== '') {
      textList.push(contents);
      claimCauseList.push(contents);
    }

    contents = '이 사건 임대차계약은 ';

    if(bond.contractExitReason === 'expiration') {
      contents += '임대차계약기간 만료로 종료되었';
    }
    else {
      contents += `${moment(bond.cancelContractDate).format(dateFormat)} 계약해지로 종료되었`;
    }

    if(bond.didDeliveryDateType === 'all') {
      if(bond.isChangedMaster) {
        contents += `고, 원고는 ${moment(bond.didDeliveryDate).format(dateFormat)} ${defendantList.length === 1 ? '피고' : '피고들'}에게 이 사건 부동산을 인도하였습니다.`;
      }
      else {
        contents += `고, 원고는 ${moment(bond.didDeliveryDate).format(dateFormat)} ${pigo}에게 이 사건 부동산을 인도하였습니다.`;
      }
      
    }
    else {
      contents += '습니다.';
    }

    textList.push(contents);
    claimCauseList.push(contents);
  
  
    const totalDeductionPrice = bond.deductionDeposit + bond.deductionRent + bond.deductionFix + bond.deductionEtc;
    const isDeduction = (bond.isDeductionDeposit || bond.isDeductionRent || bond.isDeductionFix || bond.isDeductionEtc);
    
    contents = '';
    if(isDeduction) {
      //contents = `한편, 임대차보증금에서 `;
      if(bond.isDeductionDeposit) {
        contents += `이미 반환받은 임대차보증금 ${addComma(bond.deductionDeposit)}원`;
      }
      
      if(bond.isDeductionRent) {
        if(contents !== '') contents += ', ';
        contents += `연체차임 ${addComma(bond.deductionRent)}원`;
      }
  
      if(bond.isDeductionFix) {
        if(contents !== '') contents += ', ';
        contents += `수리비 ${addComma(bond.deductionFix)}원`;
      }
      
      if(bond.isDeductionEtc) {
        if(contents !== '') contents += ', ';
        contents += `${bond.deductionEtcReason} ${addComma(bond.deductionEtc)}원`;
      }
    }
  
    if(contents !== '') {
      contents = `한편, 임대차보증금에서 ${contents}을 공제하여야 합니다.`;
      textList.push(contents);
      claimCauseList.push(contents);
    };

    if(complaint.isShowComment && complaint.comment !== '') {
      claimCauseList.push(complaint.comment);
    }

    contents = '';

    if(isDeduction) {
      contents = `${addComma(totalDeductionPrice)}원을 공제한 나머지 임대차보증금 ${addComma(depositPrice - totalDeductionPrice)}원`;
    }
    else {
      contents = `임대차보증금 ${addComma(depositPrice)}원`;
    }

    if(bond.didDeliveryDateType === 'none') {
      contents += '을 지급하여야 합니다.'
    }
    else {
      contents += ' 및 이에 대한 ';

      if(didDeliveryDate === '') {
        contents += `임대차계약 종료 다음날인 ${moment(nextContractFinishDate).format(dateFormat)}`;
      }
      else {
        if(didDeliveryDate < nextContractFinishDate) {
          contents += `임대차계약 종료 다음날인 ${moment(nextContractFinishDate).format(dateFormat)}`;
        }
        else {
          contents += `인도일인 ${moment(didDeliveryDate).format(dateFormat)}`;
        }
      }

      contents += '부터 이 사건 소장부본 송달일까지는 ';

      if(bond.depositPurpose === 'home') {
        contents += '민법이 정한 연 5%의, ';
      }
      else {
        contents += '상법이 정한 연 6%의, ';
      }

      contents += '그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 금원을 지급하여야 합니다.'
    }

    let title = '피고는';
    if(defendantList.length >= 2) {
      title = '피고들은 공동하여';
    }
    
    if(contents !== '') {
      contents = `따라서, ${title} 원고에게 ${contents}`;
    }

    textList.push(contents);
    claimCauseList.push(contents);

    contents = contents
    .replace(`따라서, `, '')
    //.replace(`${title} `, '')
    .replace(`${addComma(totalDeductionPrice)}원을 공제한 나머지 임대차보증금 `, '')
    .replace(`임대차보증금 `, '')
    .replace(`인도일인 `, '')
    .replace(`임대차계약 종료 다음날인 `, '')
    .replace(`민법이 정한 `, '')
    .replace(`상법이 정한 `, '')
    .replace(`소송촉진 등에 관한 특례법이 정한 `, '')
    .replace(`하여야 합니다.`, '하라.');

    claimPurpose1List.push(contents);

    const documentType = complaint.documentType;
    const wongo = documentType === '소장' ? '원고' : '채권자';
    pigo = documentType === '소장' ? '피고' : '채무자';

    if(defendantList.length === 1) {
      contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
    }
    else {
      contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
    }
    
    claimCauseList.push(contents);

    if(complaint.documentType === '소장') {
      claimPurpose1List.push(`소송비용은 ${defendantList.length === 1 ? '피고가' : '피고들이'} 부담한다.`);
      claimPurpose1List.push('제1항은 가집행할 수 있다.')
    }
    else {
      claimPurpose1List.push(`독촉절차비용은 ${defendantList.length === 1 ? '피고가' : '피고들이'} 부담한다.`);
    }
  
    //claimPurpose1List.push('라는 판결을 구합니다.');
  });

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: []
  }
  return {textList, claimCauseList, claimPurpose};
}

const contractTradingPriceText = (complaint, defendantList, textList) => {
  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const documentType = complaint.documentType;
  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';

  const bond = complaint.bondList[0];

  let causeOfAction = `원고는 ${moment(bond.tradingPriceContractDate).format(dateFormat)} 피고에게 `;

  //인도약정일에 따른 문구
  if(bond.willDeliveryDateType === 'none') {
    causeOfAction += bond.stuffList.map((stuff, index) => `${stuff.name} ${stuff.count}${stuff.unit}${bond.stuffList.length - 1 === index ? josajs.c(stuff.unit, '을/를') : ''}`).join(', ');
    causeOfAction += ' 각 인도받기로 정하고, ';
  }
  else if(bond.willDeliveryDateType === 'fix') {
    //causeOfAction += bond.stuffList.map(stuff => `${stuff.name} ${stuff.count}${josajs.r(stuff.unit, '을/를')} ${moment(bond.willDeliveryDate).format(dateFormat)}에`).join(', ');
    causeOfAction += bond.stuffList.map((stuff, index) => `${stuff.name} ${stuff.count}${stuff.unit}${bond.stuffList.length - 1 === index ? josajs.c(stuff.unit, '을/를') : ''}`).join(', ');
    causeOfAction += ` ${moment(bond.willDeliveryDate).format(dateFormat)}에`

    if(bond.stuffList.length >= 2) {
      causeOfAction += ' 각 인도받기로 정하고, ';
    }
    else {
      causeOfAction += ' 인도받기로 정하고, ';
    }
  }
  else {
    causeOfAction += bond.willDeliveryDateList.map(d => `${d.stuff.name} ${d.stuff.count}${josajs.r(d.stuff.unit, '을/를')} ${moment(d.date).format(dateFormat)}에`).join(', ');
    causeOfAction += ' 각 인도받기로 정하고, ';
  }


  causeOfAction += ` 매매대금 ${addComma(bond.tradingPrice)}원으로 정하여 매수하기로 약정하였습니다.`;

  if(complaint.documentType === '지급명령') {
    causeOfAction = causeOfAction.replace('원고', '채권자').replace('피고', '채무자');
  }

  claimCauseList.push(causeOfAction);
  textList.push(causeOfAction);

  let contents = '';

  contents = '원고는 ';

  if(bond.didPaybackDateList.length === 1) {
    const didPaybackDate = bond.didPaybackDateList[0];
    contents += `${moment(didPaybackDate.date).format(dateFormat)} 피고에게 ${addComma(didPaybackDate.price)}원을 지급하였습니다.`;
  }
  else {
    contents += '피고에게';
    contents += bond.didPaybackDateList.map((didPaybackDate, index) => {
      return ` ${numberType[index]} ${moment(didPaybackDate.date).format(dateFormat)} ${addComma(didPaybackDate.price)}원을`
    }).join(',');
    contents += ' 각 지급하였습니다.';
  }

  claimCauseList.push(contents);
  
  contents = '';

  switch (bond.contractTradingPriceClaimReason) {
    case 0:
      contents = '피고가 매매목적물을 인도하였으나, 매매목적물의 하자로 인하여 계약의 목적을 달성할 수 없습니다.';
      break;
    case 1:
      contents = '그러나 피고는 현재까지 원고에게 매매목적물을 전혀 인도하지 아니하고 있습니다.';
      break;
    case 2:
      contents = '그러나 피고는 현재까지 원고에게 매매목적물 일부를 인도하지 아니하고 있습니다.';
      break;
    case 3:
      contents = '그러나 매매목적물은 현재 인도할 수 없는 상태가 되었습니다.';
      break;
    case 4:
      contents = '그러나 피고는 원고에게 매매목적물 인도의무를 이행하지 아니할 의사를 명백히 표시하였습니다.';
      break;
    case 5:
      contents = '그러나 원고는 중요부분의 착오로 인하여 위 매매계약을 체결하였습니다.';
      break;
    case 6:
      contents = '그러나 원고는 상대방의 기망행위로 인하여 위 매매계약을 체결하였습니다.';
      break; 
    case 7:
      contents = '그러나 원고는 상대방의 강박행위로 인하여 위 매매계약을 체결하였습니다.';
      break;
    default:
      break;
  }

  let sub1 = '피고에게';
  if(defendantList.length !== 1) sub1 = '피고들에게';

  claimCauseList.push(contents);

  contents = '';

  if(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) {
    switch (bond.dunningType) {
      case 0:
        contents = `이에 원고는 ${moment(bond.dunning1Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning1Date2).format(dateFormat)}까지 
        매매목적물을 인도하라고 최고하였고, 위 최고는 ${moment(bond.dunning1Date3).format(dateFormat)} 피고에게 도달하였습니다.`;
        break;
      case 1:
        contents = `이에 원고는 ${moment(bond.dunning2Date1).format(dateFormat)} 피고에게 지체없이 매매목적물을 인도하라고 최고하였고, 위 최고는 ${moment(bond.dunning2Date2).format(dateFormat)} 피고에게 도달하였습니다.`;
        break;
      case 2:
        contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning3Date2).format(dateFormat)}까지 매매목적물을 인도하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
        break;
      case 3:
        contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 지체없이 매매목적물을 인도하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
        break;
      case 4:
        // contents = `
        // 그럼에도 피고는 현재까지 매매목적물을 인도하지 아니하고 있는바, 원고는 이 사건 소장부본 송달일로부터 1주일 이내에 매매목적물을 인도할 것을 최고하고, 피고가 위 기한까지 매매목적 물을 인도하지 아니하는 경우 위 1.항의 매매계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.
        // `;
        contents = `이에 원고는 피고에게 이 사건 소장부본 송달일로부터 1주일 이내에 매매목적물을 인도할 것을 최고하고, 피고가 위 기한까지 매매목적 물을 인도하지 아니하는 경우 위 1.항의 매매계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
        break;
      default:
        break;
    }

    if(bond.contractTradingPriceClaimReason === 2) {
      contents = contents.replace('매매목적물', '나머지 매매목적물');
    }
  }

  if(contents !== '') claimCauseList.push(contents);

  contents = '';

  const expression1Date1 = moment(bond.expression1Date1).format(dateFormat);
  const expression1Date2 = moment(bond.expression1Date2).format(dateFormat);
  const expression2Date1 = moment(bond.expression2Date1).format(dateFormat);

  let claimReason = '';
  
  if(bond.contractTradingPriceClaimReason === 0) claimReason = '매매목적물의 하자를';
  else if(bond.contractTradingPriceClaimReason === 3) claimReason = '이행불능을';
  else if(bond.contractTradingPriceClaimReason === 4) claimReason = '이행거절을';
  else if(bond.contractTradingPriceClaimReason === 5) claimReason = '착오를';
  else if(bond.contractTradingPriceClaimReason === 6) claimReason = '사기를';
  else if(bond.contractTradingPriceClaimReason === 7) claimReason = '강박을';

  let cancelTitle = (bond.contractTradingPriceClaimReason === 5 || bond.contractTradingPriceClaimReason === 6 || bond.contractTradingPriceClaimReason === 7) ? '취소' : '해제';

  let isDisplay = true; //독촉 여부에서 독촉하지 아니한 경우는 4번문장에서 끝
  let isDelay = false; //이행지체 여부

  if(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) {
    isDelay = true;
    
    if(bond.dunningType === 4) {
      isDisplay = false;
    }
  }

  if(isDisplay) {
    if(isDelay) { //이행지체
      switch (bond.expressionType) {
        case 0:
          contents = `그럼에도 피고는 매매목적물을 인도하지 아니하였는바, 원고는 ${expression1Date1} 피고에게 위 매매계약 해제의 의사표시를 발송하였고, 위 의사표시는 ${expression1Date2} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `그럼에도 피고는 매매목적물을 인도하지 아니하였는바, 원고는 ${expression2Date1} 피고에게 위 매매계약 해제의 의사표시를 발송하였고, 위 의사표시는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `그럼에도 피고는 현재까지 매매목적물을 인도하지 아니하고 있는바, 원고는 이 사건 소장부본 송달일부터 1주일 이내에 매매목적물을 인도할 것을 최고하고, 피고가 위 기한까지 매매목적물을 인도하지 아니하는 경우 위 1.항의 매매계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
          break;
        default:
          break;
      }
    }
    else {
      switch (bond.expressionType) {
        case 0:
          contents = `이에 원고는 ${expression1Date1} 피고에게 ${claimReason} 원인으로 위 매매계약 ${cancelTitle}의 의사표시를 발송하였고, 위 의사표시는 ${expression1Date2} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `이에 원고는 ${expression2Date1} 피고에게 ${claimReason} 원인으로 위 매매계약 ${cancelTitle}의 의사표시를 발송하였고, 위 의사표시는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `이에 원고는 ${claimReason} 원인으로 위 1.항의 매매계약 ${cancelTitle}하고, 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
          break;
        default:
          break;
      }
    }
  }

  if(contents !== '') {
    contents = contents.replace('매매목적물', '나머지 매매목적물')
    claimCauseList.push(contents);
  }

  if(complaint.isShowComment && complaint.comment !== '') {
    claimCauseList.push(complaint.comment);
  }

  contents = '';

  contents = `따라서, 피고는 원고에게 계약해제에 따른 원상회복으로 ${addComma(bond.tradingPrice)}원 및 `;

  if(bond.didPaybackDateList.length === 1) {
    const didPaybackDate = bond.didPaybackDateList[0];
    contents += `이에 대한 그 수령일인 ${moment(didPaybackDate.date).format(dateFormat)}부터 `;
  }
  else {
    contents += `그 중 ${bond.didPaybackDateList.map(d => ` ${addComma(d.price)}원에 대하여는 그 수령일인 ${moment(d.date).format(dateFormat)}부터`).join(',')} 각 `;
  }

  contents += '이 사건 소장부본 송달일까지는 ';

  if(bond.law === '상법') {
    contents += '상법이 정한 연 6%의, ';
  }
  else {
    contents += '민법이 정한 연 5%의, ';
  }

  contents += '그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 지연손해금을 지급하여야 합니다.';

  claimCauseList.push(contents);

  
  contents = contents
    .replace('따라서, ', '')
    .replace('계약해제에 따른 원상회복으로 ', '')
    .replace(/그 수령일인 /gi, '')
    .replace('소송촉진 등에 관 한 특례법이 정한 ', '')
    .replace('지연손해금을 지급하여야 합니다.', '금원을 지급하라.')

  claimPurpose1List.push(contents);

  if(defendantList.length === 1) {
    contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  else {
    contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  
  claimCauseList.push(contents);

  if(documentType === '소장') {
    claimPurpose1List.push(`소송비용은 ${pigo}가 부담한다.`);
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
    textList.push(`소송비용은 ${pigo}가 부담한다.`);
    textList.push('제1항은 가집행할 수 있다.')
  }
  else {
    claimPurpose1List.push(`독촉절차비용은 ${pigo}가 부담한다.`);
    textList.push(`독촉절차비용은 ${pigo}가 부담한다.`);
  }


  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: []
  }
  return {textList, claimCauseList, claimPurpose};
}

const contractServicePriceText = (complaint, defendantList, textList) => {
  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  const documentType = complaint.documentType;
  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';

  const bond = complaint.bondList[0];

  var causeOfAction = '원고는 ';

  
  causeOfAction += `${moment(bond.tradingPriceContractDate).format(dateFormat)} `;
  causeOfAction += `${defendantList.length === 1 ? '피고와 ' : `피고 ${getName(defendantList[0])}과 `}`

  causeOfAction += `용역대금 ${addComma(bond.tradingPrice)}원으로`;

  const isWillDeliveryDate = bond.willDeliveryDateType !== 'none';

  const serviceType = bond.serviceType === 'delivery' ? '인도약정일' : '완료예정일';

  if(isWillDeliveryDate) {
    causeOfAction += `, ${serviceType} ${moment(bond.willDeliveryDate).format(dateFormat)}로 정하고, `;
  }
  else {
    causeOfAction += ` 정하여, `;
  }

  causeOfAction += `${bond.serviceTitle} 계약을 체결하였습니다.`;
  
  if(complaint.documentType === '지급명령') {
    causeOfAction = causeOfAction.replace('원고', '채권자').replace('피고', '채무자');
  }

  claimCauseList.push(causeOfAction);
  textList.push(causeOfAction);

  let contents = '';

  contents = '원고는 ';

  if(bond.didPaybackDateList.length === 1) {
    const didPaybackDate = bond.didPaybackDateList[0];
    contents += `${moment(didPaybackDate.date).format(dateFormat)} 피고에게 ${addComma(didPaybackDate.price)}원을 지급하였습니다.`;
  }
  else {
    contents += '피고에게';
    contents += bond.didPaybackDateList.map((didPaybackDate, index) => {
      return ` ${numberType[index]} ${moment(didPaybackDate.date).format(dateFormat)} ${addComma(didPaybackDate.price)}원을`
    }).join(',');
    contents += ' 각 지급하였습니다.';
  }

  claimCauseList.push(contents);
  
  contents = '';

  if(bond.serviceType === 'delivery') {
    switch (bond.contractTradingPriceClaimReason) {
      case 0:
        contents = '피고가 용역목적물을 인도하였으나, 용역목적물의 하자로 인하여 계약의 목적을 달성할 수 없습니다.';
        break;
      case 1:
        contents = '그러나 피고는 현재까지 원고에게 용역목적물을 전혀 인도하지 아니하고 있습니다.';
        break;
      case 2:
        contents = '그러나 피고는 현재까지 원고에게 용역목적물 일부를 인도하지 아니하고 있습니다.';
        break;
      case 3:
        contents = '그러나 피고는 현재 용역목적물을 인도할 수 없게 되었습니다.';
        break;
      case 4:
        contents = '그러나 피고는 원고에게 용역목적물 인도의무를 이행하지 아니할 의사를 명백히 표시하였습니다.';
        break;
      case 5:
        contents = '그러나 원고는 중요부분의 착오로 인하여 위 용역계약을 체결하였습니다.';
        break;
      case 6:
        contents = '그러나 원고는 상대방의 기망행위로 인하여 위 용역계약을 체결하였습니다.';
        break; 
      case 7:
        contents = '그러나 원고는 상대방의 강박행위로 인하여 위 용역계약을 체결하였습니다.';
        break;
      default:
        break;
    }
  }
  else {
    switch (bond.contractTradingPriceClaimReason) {
      case 0:
        contents = '그러나 피고는 현재까지 일을 완성하지 아니하고 있습니다.';
        break;
      case 1:
        contents = '그러나 피고가 현재 일을 완성할 수 없게 되었습니다.';
        break;
      case 2:
        contents = '그러나 피고는 원고에게 일을 완성할 의무를 이행하지 아니할 의사를 명백히 표시하였습니다.';
        break;
      case 3:
        contents = '그러나 원고는 중요부분의 착오로 인하여 위 용역계약을 체결하였습니다.';
        break;
      case 4:
        contents = '그러나 원고는 상대방의 기망행위로 인하여 위 용역계약을 체결하였습니다.';
        break;
      case 5:
        contents = '그러나 원고는 상대방의 강박행위로 인하여 위 용역계약을 체결하였습니다.';
        break;
      default:
        break;
    }
  }

  claimCauseList.push(contents);

  contents = '';

  let subTitle = '';

  if(bond.serviceType === 'delivery') {
    if(bond.contractTradingPriceClaimReason === 2) {
      subTitle = '나머지 ';
    }
  }
  else if(bond.serviceType === 'complete') {
    if(bond.contractTradingPriceClaimReason === 0) {
      subTitle = '현재까지 ';
    }
  }

  if(bond.serviceType === 'delivery') {
    if(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) {
      switch (bond.dunningType) {
        case 0:
          contents = `이에 원고는 ${moment(bond.dunning1Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning1Date2).format(dateFormat)}까지 
          ${subTitle}용역목적물을 인도하라고 최고하였고, 위 최고는 ${moment(bond.dunning1Date3).format(dateFormat)} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `이에 원고는 ${moment(bond.dunning2Date1).format(dateFormat)} 피고에게 지체없이 ${subTitle}용역목적물을 인도하라고 최고하였고, 위 최고는 ${moment(bond.dunning2Date2).format(dateFormat)} 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning3Date2).format(dateFormat)}까지 ${subTitle}용역목적물을 인도하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 3:
          contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 지체없이 ${subTitle}용역목적물을 인도하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 4:
          // contents = `
          // 그럼에도 피고는 현재까지 ${subTitle}용역목적물을 인도하지 아니하고 있는바, 원고는 이 사건 소장부본 송달일로부터 1주일 이내에 용역목적물을 인도할 것을 최고하고, 피고가 위 기한까지 용역목적물을 인도하지 아니하는 경우 위 1.항의 용역계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.
          // `;
          contents = `이에 원고는 피고에게 이 사건 소장부본 송달일로부터 1주일 이내에 ${subTitle}용역목적물을 인도할 것을 최고하고, 피고가 위 기한까지 용역목적물을 인도하지 아니하는 경우 위 1.항의 용역계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
          break;
        default:
          break;
      }
    }
  }
  else {
    if(bond.contractTradingPriceClaimReason === 0) {
      switch (bond.dunningType) {
        case 0:
          contents = `이에 원고는 ${moment(bond.dunning1Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning1Date2).format(dateFormat)}까지 
          일을 완성하라고 최고하였고, 위 최고는 ${moment(bond.dunning1Date3).format(dateFormat)} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `이에 원고는 ${moment(bond.dunning2Date1).format(dateFormat)} 피고에게 지체없이 일을 완성하라고 최고하였고, 위 최고는 ${moment(bond.dunning2Date2).format(dateFormat)} 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 ${moment(bond.dunning3Date2).format(dateFormat)}까지 일을 완성하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 3:
          contents = `이에 원고는 ${moment(bond.dunning3Date1).format(dateFormat)} 피고에게 지체없이 일을 완성하라고 최고하였고, 위 최고는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 4:
          contents = `
          그럼에도 피고는 현재까지 일을 완성하지 아니하고 있는바, 원고는 이 사건 소장부본 송달일로부터 1주일 이내에 일을 완성할 것을 최고하고, 피고가 위 기한까지 일을 완성하지 아니하는 경우 위 1.항의 용역계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.
          `;
          break;
        default:
          break;
      }
    }
  }

  if(contents !== '') claimCauseList.push(contents);

  contents = '';

  const serviceTitle = bond.serviceType === 'delivery' ? '용역목적물을 인도' : '일을 완성';
  

  const expression1Date1 = moment(bond.expression1Date1).format(dateFormat);
  const expression1Date2 = moment(bond.expression1Date2).format(dateFormat);
  const expression2Date1 = moment(bond.expression2Date1).format(dateFormat);

  let claimReason = '';
  
  let cancelTitle = (bond.contractTradingPriceClaimReason === 5 || bond.contractTradingPriceClaimReason === 6 || bond.contractTradingPriceClaimReason === 7) ? '취소' : '해제';

  let isDisplay = true; //독촉 여부에서 독촉하지 아니한 경우는 4번문장에서 끝
  let isDelay = false; //이행지체 여부

  if(bond.serviceType === 'delivery') {

    if(bond.contractTradingPriceClaimReason === 3) claimReason = '이행불능을';
    else if(bond.contractTradingPriceClaimReason === 4) claimReason = '이행거절을';
    else if(bond.contractTradingPriceClaimReason === 5) claimReason = '착오를';
    else if(bond.contractTradingPriceClaimReason === 6) claimReason = '사기를';
    else if(bond.contractTradingPriceClaimReason === 7) claimReason = '강박을';

    if(bond.contractTradingPriceClaimReason === 1 || bond.contractTradingPriceClaimReason === 2) {
      isDelay = true;
      
      if(bond.dunningType === 4) {
        isDisplay = false;
      }
    }
  }
  else {
    if(bond.contractTradingPriceClaimReason === 0) claimReason = '용역목적물의 하자를';
    else if(bond.contractTradingPriceClaimReason === 1) claimReason = '이행불능을';
    else if(bond.contractTradingPriceClaimReason === 2) claimReason = '이행거절을';
    else if(bond.contractTradingPriceClaimReason === 3) claimReason = '착오를';
    else if(bond.contractTradingPriceClaimReason === 4) claimReason = '사기를';
    else if(bond.contractTradingPriceClaimReason === 5) claimReason = '강박을';

    if(bond.contractTradingPriceClaimReason === 0) {
      isDelay = true;
      if(bond.dunningType === 4) {
        isDisplay = false;
      }
    }

  }

  if(isDisplay) {
    if(isDelay) { //이행지체
      switch (bond.expressionType) {
        case 0:
          contents = `그럼에도 피고는 ${subTitle}${serviceTitle}하지 아니하였는바, 원고는 ${expression1Date1} 피고에게 위 용역계약 해제의 의사표시를 발송하였고, 위 의사표시는 ${expression1Date2} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `그럼에도 피고는 ${subTitle}${serviceTitle}하지 아니하였는바, 원고는 ${expression2Date1} 피고에게 위 용역계약 해제의 의사표시를 발송하였고, 위 의사표시는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `그럼에도 피고는 ${subTitle}${serviceTitle}하지 아니하였는바, 원고는 이 사건 소장부본 송달일부터 1주일 이내에 ${serviceTitle}할 것을 최고하고, 피고가 위 기한까지 ${serviceTitle}하지 아니하는 경우 위 1.항의 용역계약을 해제합니다. 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
          break;
        default:
          break;
      }
    }
    else {
      switch (bond.expressionType) {
        case 0:
          contents = `이에 원고는 ${expression1Date1} 피고에게 ${claimReason} 원인으로 위 용역계약 ${cancelTitle}의 의사표시를 발송하였고, 위 의사표시는 ${expression1Date2} 피고에게 도달하였습니다.`;
          break;
        case 1:
          contents = `이에 원고는 ${expression2Date1} 피고에게 ${claimReason} 원인으로 위 용역계약 ${cancelTitle}의 의사표시를 발송하였고, 위 의사표시는 그 무렵 피고에게 도달하였습니다.`;
          break;
        case 2:
          contents = `이에 원고는 ${claimReason} 원인으로 위 1.항의 용역계약 ${cancelTitle}하고, 위 의사표시는 이 사건 소장부본 송달로써 갈음합니다.`;
          break;
        default:
          break;
      }
    }
  }

  if(contents !== '') claimCauseList.push(contents);

  if(complaint.isShowComment && complaint.comment !== '') {
    claimCauseList.push(complaint.comment);
  }
  
  contents = '';

  const totalPrice = bond.didPaybackDateList.map(d => d.price).reduce((a, c) => a + c, 0);

  contents = `따라서, 피고는 원고에게 계약해제에 따른 원상회복으로 ${addComma(totalPrice)}원 및 `;

  if(bond.didPaybackDateList.length === 1) {
    const didPaybackDate = bond.didPaybackDateList[0];
    contents += `이에 대한 그 수령일인 ${moment(didPaybackDate.date).format(dateFormat)}부터 `;
  }
  else {
    contents += `그 중 ${bond.didPaybackDateList.map(d => ` ${addComma(d.price)}원에 대하여는 그 수령일인 ${moment(d.date).format(dateFormat)}부터`).join(',')} 각 `;
  }

  contents += '이 사건 소장부본 송달일까지는 ';

  if(bond.law === '상법') {
    contents += '상법이 정한 연 6%의, ';
  }
  else {
    contents += '민법이 정한 연 5%의, ';
  }

  contents += '그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 지연손해금을 지급하여야 합니다.';

  claimCauseList.push(contents);

  contents = contents
    .replace('따라서, ', '')
    .replace('계약해제에 따른 원상회복으로 ', '')
    .replace(/그 수령일인 /gi, '')
    .replace('소송촉진 등에 관 한 특례법이 정한 ', '')
    .replace('지연손해금을 지급하여야 합니다.', '금원을 지급하라.')

  claimPurpose1List.push(contents);

  if(defendantList.length === 1) {
    contents = `이에 ${wongo}는 ${pigo}로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  else {
    contents = `이에 ${wongo}는 ${pigo}들로부터 청구취지 기재 금원을 지급받기 위하여 이 사건 ${documentType === '소장' ? '소를' : '지급명령신청을'} 제기하기에 이르렀습니다.`;
  }
  
  claimCauseList.push(contents);

  if(documentType === '소장') {
    claimPurpose1List.push(`소송비용은 ${pigo}가 부담한다.`);
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
    textList.push(`소송비용은 ${pigo}가 부담한다.`);
    textList.push('제1항은 가집행할 수 있다.')
  }
  else {
    claimPurpose1List.push(`독촉절차비용은 ${pigo}가 부담한다.`);
    textList.push(`독촉절차비용은 ${pigo}가 부담한다.`);
  }

  if(documentType === '지급명령') {
    claimCauseList = claimCauseList.map(cause => {
      return cause.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');
    });
    claimPurpose1List = claimPurpose1List.map(p => {
      return p.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');
    });
  }

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: []
  }
  return {textList, claimCauseList, claimPurpose};
}

const getLossText = (complaint, defendantList, textList) => {
  let claimPurposeHeader = '';
  let claimCauseList = [];
  let claimPurpose1List = [];
  let claimPurpose2List = [];

  let hurtTextList = [];
  let lossPriceTextList = [];

  const documentType = complaint.documentType;
  const wongo = documentType === '소장' ? '원고' : '채권자';
  const pigo = documentType === '소장' ? '피고' : '채무자';

  const defendantCount = defendantList.length;
  
  const lossList = complaint.lossList;

  lossList.forEach((loss, index) => {

    const totalLossPrice = loss.priceList.filter(p => p.isSelected).map(p => p.price).reduce((a, c) => a + c, 0);

    let contents = '';

    let hurtText = [];
    let lossPriceText = [];
    
    //일시
    if(loss.occurrenceDateType === 0) {
      contents = `${moment(loss.occurrence1DateTime1).format(dateFormat)} ${moment(loss.occurrence1DateTime1).format("HH:mm")}`
    }
    else if(loss.occurrenceDateType === 1) {
      contents = `${moment(loss.occurrence2Date1).format(dateFormat)}`
    }
    else if(loss.occurrenceDateType === 2) {
      contents = `${moment(loss.occurrence3Date1).format(dateFormat)}부터 ${moment(loss.occurrence3Date2).format(dateFormat)}`
    }
    else if(loss.occurrenceDateType === 3) {
      contents = `${moment(loss.occurrence4Date1).format(dateFormat)}부터 현재까지`
    }

    if(contents !== '') hurtText.push(contents);
    contents = '';

    //장소
    if(loss.occurrencePlaceType === 0) {
      contents = loss.occurrence1Place1 + ' ' + loss.occurrence1Place2;
    }
    else if(loss.occurrencePlaceType === 1) {
      contents = loss.occurrence2Place1;
    }
    else {
      contents = '불상';
    }

    hurtText.push(contents);

    hurtText.push(loss.hurt);
    hurtText.push(loss.result);

    hurtTextList.push(hurtText);

    loss.priceList.filter(p => p.isSelected).forEach(price => {
      lossPriceText.push({name: price.type, price: `${addComma(price.price)}원`});
    });

    lossPriceText.push({name: '합 계', price: `${addComma(totalLossPrice)}원`});

    lossPriceTextList.push(lossPriceText);

    contents = '';

    contents += `피고${defendantCount === 1 ? '는' : '들은 공동하여'} 원고에게 손해배상금 ${addComma(totalLossPrice)}원 및 이에 대한 `;

    if(loss.occurrenceDateType === 0) {
      contents += `불법행위일인 ${moment(loss.occurrence1Date1).format(dateFormat)}부터 이 사건 소장부본 송달일까지는 민법이 정한 연 5%의, 그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 지연손해금을`;
    }
    else if(loss.occurrenceDateType === 1) {
      contents += `불법행위일인 ${moment(loss.occurrence2Date1).format(dateFormat)}부터 이 사건 소장부본 송달일까지는 민법이 정한 연 5%의, 그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 지연손해금을`;
    }
    else if(loss.occurrenceDateType === 2) {
      contents += `마지막 불법행위일인 ${moment(loss.occurrence3Date2).format(dateFormat)}부터 이 사건 소장부본 송달일까지는 민법이 정한 연 5%의, 그 다음날부터 다 갚는 날까지는 소송촉진 등에 관한 특례법이 정한 연 12%의 각 비율에 의한 지연손해금을`;
    }
    else if(loss.occurrenceDateType === 3) {
      contents += `이 사건 소장부본 송달일 다음날부터 다 갚는 날까지 소송촉진 등에 관한 특례법이 정한 연 12%의 비율에 의한 지연손해금을`;
    }

    claimCauseList.push(contents);

    if(lossList.length !== 1) {
      claimPurposeHeader = defendantCount === 1 ? '피고는 원고에게' : '피고들은 공동하여 원고에게';
    }

    contents = contents
                .replace('손해배상금 ', '')
                .replace('불법행위일인 ', '')
                .replace('마지막 ', '')
                .replace('민법이 정한 ', '')
                .replace('소송촉진 등에 관한 특례법이 정한 ', '')

    if(lossList.length === 1) { 
      contents = contents.replace('지연손해금을', '금원을 지급하라.')
      claimPurpose1List.push(contents);
    }
    else {
      contents = contents.replace('지연손해금을', '금원을,')
      contents = contents.replace(`${claimPurposeHeader} `, '');
      claimPurpose2List.push(contents);
    }
    
  });

  let claimCause = `따라서, ${claimCauseList.map((c, i) => `${lossList.length !== 1 ? `${numberType[i]} ` : ''}${c}`).join(' 지급하여야 하고, ')} 지급하여야 합니다.`;

  

  claimCauseList = [];
  claimCauseList.push(claimCause);

  if(documentType === '소장') {
    claimPurpose1List.push(`소송비용은 ${pigo}${defendantCount === 1 ? '가' : '들이'} 부담한다.`);
    claimPurpose1List.push('제1항은 가집행할 수 있다.');
  }
  else {
    claimPurpose1List.push(`독촉절차비용은 ${pigo}${defendantCount === 1 ? '가' : '들이'} 부담한다.`);
  }

  if(documentType === '지급명령') {
    claimCauseList = claimCauseList.map(cause => {
      return cause.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');
    });
    claimPurpose1List = claimPurpose1List.map(p => {
      return p.replace(/원고/gi, '채권자').replace(/피고/gi, '채무자');
    });
  }

  const claimPurpose = {
    header: claimPurposeHeader,
    firstList: claimPurpose1List, 
    secondList: claimPurpose2List
  }
  return {hurtTextList, lossPriceTextList, claimCauseList, claimPurpose};
}

const addComma = (num) => {
  let regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
}

export {
  getText, wage, getTradingServiceText, deposit, contractTradingPriceText, contractServicePriceText,
  getLossText
}