//ui
import { TextField, Radio, Button, Select, MenuItem, FormControl } from '@mui/material';
import EditJointGuarantor from '../EditJointGuarantor';
import SJDatePicker from './SJDatePicker';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//redux
import { connect, useDispatch, useSelector } from 'react-redux'
import { 
  addDefendant, removeDefendant, addBond, removeBond, refreshComplaint,
  addDidPaybackDate, removeDidPaybackDate,
  addJointGuarantor, removeJointGuarantor,
  setTextList
} from '../../../store/modules/app'

//etc
import { getName } from '../../../utils/utils'
import _ from 'lodash'
import { useAppSelector } from '../../../store';

function AssignmentPrice(props) {

  const dispatch = useDispatch()

  let complaint = useAppSelector((state) => state.app.complaint)
  const defendantList = useAppSelector(state => state.app.defendantList)

  const changeAssignmentName = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].assignmentName = e.target.value;
    props.refreshComplaint(c);
  }

  const chanageAssignmentDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].assignmentDateType = parseInt(e.target.value);
    props.refreshComplaint(c);
  }

  const changeAssignmentDate1 = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].assignmentDate1 = date;
    props.refreshComplaint(c);
  }

  const changeAssignmentDate2 = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].assignmentDate2 = date;
    props.refreshComplaint(c);
  }

  const changeAssignmentDate3 = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].assignmentDate3 = date;
    props.refreshComplaint(c);
  }

  const refreshJointGuarantorList = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].jointGuarantorList = e;
    props.refreshComplaint(c);
  }

  const changePrice = (e, index) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;

    let c = _.cloneDeep(complaint)
    c.bondList[index].price = price;
    props.refreshComplaint(c);
  }

  const changeLendDate = (date, index) => {
  
    let c = _.cloneDeep(complaint)
    c.bondList[index].lendDate = date;
    props.refreshComplaint(c);
  }

  const changeFixDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].fixDate = date;
    props.refreshComplaint(c);
  }

  const changeConditionDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].conditionDate = date;
    props.refreshComplaint(c);
  }

  const changeReminderDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].reminderDate = date;
    props.refreshComplaint(c);
  }

  const changeWillPaybackDateType = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].willPaybackDateType = e.target.value;
    props.refreshComplaint(c);
  }

  const changeInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].interestRate = rate;
    props.refreshComplaint(c);
  }

  const changeDeferInterest = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isDeferInterestRate = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDeferInterestRate = (e, index) => {
    const rate = e.target.value ? parseFloat(e.target.value) : 0;

    let c = _.cloneDeep(complaint);
    c.bondList[index].deferInterestRate = rate;
    props.refreshComplaint(c);
  }

  const changeIsRelativeAssignment1 = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isRelativeAssignment1 = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeIsRelativeAssignment2 = (e, index) => {
    let c = _.cloneDeep(complaint);
    c.bondList[index].isRelativeAssignment2 = e.target.value === 'on';
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDefendant = (e, index) => {
    let c = _.cloneDeep(complaint);
    const defendantIndex = defendantList.map(d => d.id).indexOf(parseInt(e.target.value));
    c.didPaybackDateList[index].defendant = defendantList[defendantIndex];
    props.refreshComplaint(c);
  }
  

  const changeDidPaybackDatePrice = (e, index) => {
    const price = e.target.value ? parseInt(e.target.value) : 0;
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].price = price;
    props.refreshComplaint(c);
  }

  const changeDidPaybackDateDate = (date, index) => {
    let c = _.cloneDeep(complaint);
    c.didPaybackDateList[index].date = date;
    props.refreshComplaint(c);
  }

  return(
    <div className="assignment-price">
      <p className="head">채권내역</p>
      {complaint.bondList.map((bond, index) => (
        <div className="cont-bx" key={`bond${index}`}>
          {index !== 0 && <Button className="remove-btn" onClick={e => props.removeBond(index)}><RemoveIcon /></Button>}
          <p className="ttl">1) 주채무자</p>
          <div className="answer-area">
            <span>{getName(defendantList[0])} 입니다.</span>
          </div>
          <hr />
          <p className="ttl">2) 언제 누구와 대여금 채권양도계약을 체결하였나요?</p>
          <div className="answer-area flex-row wrap">
            <SJDatePicker
              date={bond.realAssignmentDate}
              onChangeDate={date => {
                dispatch(refreshComplaint({...complaint, bondList: _.cloneDeep(complaint.bondList).map((value, i) => {
                  if(bond.id === value.id) {
                    value.realAssignmentDate = date
                  }
                  return value
                })}))
              }}
            />
            <span className="mr_10">에</span>
            <TextField
              variant="outlined"
              value={bond.assignmentName}
              placeholder={'양도계약체결자'}
              onChange={e => changeAssignmentName(e, index)}
            />
            <span className="ml_4">와(과)</span>
            <span className="ml_4">대여금</span>
            <span className="ml_4">채권양도계약을</span>
            <span className="ml_4">체결하였습니다.</span>
          </div>
          <hr />
          <EditJointGuarantor 
            bondIndex={index}
            jointGuarantorList={bond.jointGuarantorList} 
            defendantList={defendantList} 
            onAdd={e => props.addJointGuarantor(index)}
            onRemove={jIndex => props.removeJointGuarantor({bondIndex: index, index: jIndex})}
            refresh={e => refreshJointGuarantorList(e, index)}/>
          <hr />
          <p className="ttl">4) 채권양도 통지나 승낙이 있었나요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='0'
                checked={bond.assignmentDateType === 0}
                onChange={e => chanageAssignmentDateType(e, index)}
              />
              <span className="mr_10">{bond.assignmentName}이(가)</span>
              <SJDatePicker 
                date={bond.assignmentDate1}
                onChangeDate={date => changeAssignmentDate1(date, index)}/>
              <span>에</span>
              <span className="ml_4">채무자에게</span>
              <span className="ml_4">채권양도를</span>
              <span className="ml_4">통지하였습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='1'
                checked={bond.assignmentDateType === 1}
                onChange={e => chanageAssignmentDateType(e, index)}
              />
              <span className="mr_10">제가</span>
              <SJDatePicker 
                date={bond.assignmentDate2}
                onChangeDate={date => changeAssignmentDate2(date, index)}/>
              <span>에</span>
              <span className="ml_4">{bond.assignmentName}의</span>
              <span className="ml_4">위임을</span>
              <span className="ml_4">받아</span>
              <span className="ml_4">채무자에게</span>
              <span className="ml_4">채권양도를</span>
              <span className="ml_4">통지하였습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='2'
                checked={bond.assignmentDateType === 2}
                onChange={e => chanageAssignmentDateType(e, index)}
              />
              <span className="mr_10">채무자가</span>
              <SJDatePicker 
                date={bond.assignmentDate3}
                onChangeDate={date => changeAssignmentDate3(date, index)}/>
              <span>에 채권양도를 승낙하였습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='3'
                checked={bond.assignmentDateType === 3}
                onChange={e => chanageAssignmentDateType(e, index)}
              />
              <span>채무자에 대한 채권양도 통지나 채무자의 승낙이 없었습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">5) 이(가) 채무자에게 언제 얼마를 빌려주었나요?</p>
          <div className="answer-area flex-row">
            <SJDatePicker
              date={bond.lendDate}
              onChangeDate={date => changeLendDate(date, index)}/>
            <span className="mr_10">에</span>
            <TextField
              variant="outlined"
              value={bond.price === 0 ? '' : bond.price}
              onChange={e => changePrice(e, index)}
              className="input-price"
            />
            <span>원을 빌려주었습니다.</span>
          </div>
          <hr />
          <p className="ttl">6) 채무자가 갚기로 약속한 날짜가 언제인가요?</p>
          <div className="answer-area">
            <label className="radio-item">
              <Radio
                value='fix'
                checked={bond.willPaybackDateType === 'fix'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">날짜를 정하였으며,</span>
              <SJDatePicker 
                date={bond.fixDate}
                onChangeDate={date => changeFixDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='condition'
                checked={bond.willPaybackDateType === 'condition'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">조건(예:보험금 받은 날)을 정하였으며,</span>
              <SJDatePicker 
                date={bond.conditionDate}
                onChangeDate={date => changeConditionDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='reminder'
                checked={bond.willPaybackDateType === 'reminder'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span className="mr_10">최초 독촉한 날짜는</span>
              <SJDatePicker 
                date={bond.reminderDate}
                onChangeDate={date => changeReminderDate(date, index)}/>
              <span>입니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='none'
                checked={bond.willPaybackDateType === 'none'}
                onChange={e => changeWillPaybackDateType(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">7) 이자율을 얼마로 정하였나요?</p>
          <div className="answer-area item7">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isInterestRate}
                onChange={e => changeInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" variant="outlined" autoComplete='off'
                value={bond.interestRate === 0 ? '' : bond.interestRate}
                onChange={e => changeInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isInterestRate}
                onChange={e => changeInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">8) 지연이자율을 얼마로 정하였나요? <span>(갚기로 약속한 날짜 이후 이자)</span></p>
          <div className="answer-area item8">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span className="mr_10">연</span>
              <TextField type="number" id="outlined-basic" variant="outlined" autoComplete='off'
                value={bond.deferInterestRate === 0 ? '' : bond.deferInterestRate}
                onChange={e => changeDeferInterestRate(e, index)}
                className="input-percentage" />
              <span>%로 정했습니다.</span>
            </label>
    
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isDeferInterestRate}
                onChange={e => changeDeferInterest(e, index)}
              />
              <span>정하지 않았습니다.</span>
            </label>
          </div>
          <hr />
          <p className="ttl">9) 이가 회사 또는 개인사업자인가요? <span>(상인 여부)</span></p>
          <div className="answer-area item9">
            <label className="radio-item">
              <Radio
                value='on'
                checked={bond.isRelativeAssignment1}
                onChange={e => changeIsRelativeAssignment1(e, index)}
              />
              <span>예</span>
            </label>
            <label className="radio-item">
              <Radio
                value='off'
                checked={!bond.isRelativeAssignment1}
                onChange={e => changeIsRelativeAssignment1(e, index)}
              />
              <span>아니요</span>
            </label>
          </div>
          
          {bond.isRelativeAssignment1 && (
            <div>
              <hr />
              <p className="ttl">10) 양도받은 대여금 채권이 의 업무와 관련된 채권(상사채권)인가요?</p>
              <div className="answer-area item10">
                <label className="radio-item">
                  <Radio
                    value='on'
                    checked={bond.isRelativeAssignment2}
                    onChange={e => changeIsRelativeAssignment2(e, index)}
                  />
                  <span>예</span>
                </label>
                <label className="radio-item">
                  <Radio
                    value='off'
                    checked={!bond.isRelativeAssignment2}
                    onChange={e => changeIsRelativeAssignment2(e, index)}
                  />
                  <span>아니요</span>
                </label>
              </div>
            </div>
          )}
        </div>
      ))}
      <Button className="contained" onClick={e => props.addBond()}>채권 추가</Button>
      <div className="head-bx">
        <p className="head">피고가 빌린 돈을 일부 갚았나요?</p>
        <Button className="add-btn" onClick={e => props.addDidPaybackDate(true)}><AddIcon /></Button>
      </div>
      <div className="cont-bx">
        <p className="sub-txt">* 일부 갚은 돈이 있는 경우, 우측의 +버튼을 눌러 추가하세요.</p>
        <p className="sub-txt">* 갚은 돈이 없는 경우, 다음 단계로 넘어가세요.</p>
        {complaint.didPaybackDateList.map((didPaybackDate, index) => (
          <div className="border-bx flex" key={`didPaybackDate${index}`}>
            <div className="input-txt-bx flex-row wrap">
              <FormControl variant="outlined" className="select">
                <Select
                  value={didPaybackDate.defendant ? didPaybackDate.defendant.id : -1} 
                  onChange={e => changeDidPaybackDateDefendant(e, index)}
                >
                  <MenuItem value="-1" disabled>선택</MenuItem>
                  {defendantList.map((defendant, defendantIndex) => (
                    <MenuItem value={defendant.id} key={`didPaybackDate${index}${defendantIndex}`}>{getName(defendant)}</MenuItem>
                  ))}
                  
                </Select>
              </FormControl>
              <span className="ml_4 mr_10">이(가)</span>
              <SJDatePicker 
              date={didPaybackDate.date}
              onChangeDate={date => changeDidPaybackDateDate(date, index)}/>
              <span className="mr_10">에</span>
              <TextField
                type="number"
                variant="outlined"
                value={didPaybackDate.price === 0 ? '' : didPaybackDate.price}
                onChange={e => changeDidPaybackDatePrice(e, index)}
                placeholder="1,000,000"
                className="input-price"
              />
              <span className="mr_10">원을</span>
              <span>갚았습니다.</span>
            </div>

            <Button className="add-btn btn-24" onClick={e => props.removeDidPaybackDate(index)}><RemoveIcon /></Button>
          </div>
        ))}
      </div>
    </div>
  )
}

let mapStateToProps = (state, /*ownProps*/) => {
  return {
      complaint: state.app.complaint,
  };
};

let mapDispatchToProps = (dispatch, /*ownProps*/) => {
  return {
      addDefendant: () => dispatch(addDefendant()),
      removeDefendant: (index) => dispatch(removeDefendant(index)),
      addBond: () => dispatch(addBond()),
      removeBond: (index) => dispatch(removeBond(index)),
      addDidPaybackDate: (isChecked) => dispatch(addDidPaybackDate(isChecked)),
      removeDidPaybackDate: (index) => dispatch(removeDidPaybackDate(index)),
      addJointGuarantor: (bondIndex) => dispatch(addJointGuarantor(bondIndex)),
      removeJointGuarantor: (index) => dispatch(removeJointGuarantor(index)),
      refreshComplaint: (complaint) => dispatch(refreshComplaint(complaint)),
      setTextList: (textList) => dispatch(setTextList(textList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentPrice)